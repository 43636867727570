<template>
  <div id="notes-card" class="p-3 bg-white dark:bg-gray-900 rounded-lg shadow-lg">
    <ImageShow
      v-if="showImageGallery"
      @close="showImageGallery = false"
      :images="images"
      :startImageId="showImageId"
    />
    <NoteAdd
      v-if="addNoteModal"
      @close="addNoteModal = false"
      @added="noteAdded"
      :parent="parent"
      :parentId="parentId"
    />
    <NoteEdit
      v-if="editNoteModal"
      @close="cancelEditNote"
      @updated="noteUpdated"
      :note="noteToEdit"
    />
    <div class="flex items-center -mt-3 mb-3 -mx-3 px-3 py-1 border-b dark:border-gray-600 border-gray-400">
      <div class="flex flex-grow items-center">
        <div class="flex gap-3 items-center  text-muted">
          <span class="uppercase font-semibold">notatki i załączniki</span>
        </div>
      </div>
      <div v-if="validating === 1">
        <button @click="addNoteModal= true" class="tw--btn-icon p-0" title="dodaj notatkę/załącznik"><FontAwesomeIcon icon="plus" /><FontAwesomeIcon :icon="['far', 'file-alt']" size="lg" /></button>
      </div>
      <span v-else-if="validating === -1" class="mt-1 text-xs text-muted">
        błąd modułu!<FontAwesomeIcon class="ml-2 text-yellow-400 dark:text-yellow-600" icon="exclamation-triangle" size="lg" />
      </span>
      <span v-else class="mt-1 text-xs text-muted">wczytuję<FontAwesomeIcon class="ml-2" icon="sync-alt" size="lg" spin /></span>
    </div>

    <div class="flex flex-col divide-y dark:divide-gray-600">
      <NoteItem v-for="note in notes"
        :key="note.id"
        :note="note"
        @imageShow="openImageGallery"
        @noteDeleted="noteDeleted"
        @editNote="editNote"
      />
    </div>
  </div>
</template>

<script>
import NoteAdd from './NoteAdd.vue';
import NoteEdit from './NoteEdit.vue';
import NoteItem from './NoteItem.vue';
import ImageShow from '../ImageShow.vue';

export default {
  name: 'NotesCard',
  components: {
    NoteAdd,
    NoteEdit,
    NoteItem,
    ImageShow,
  },
  props: {
    notes: {
      type: Array,
      required: true,
    },
    parent: {
      type: String,
      required: true,
    },
    parentId: {
      required: true,
    },
  },
  data() {
    return {
      showImageGallery: false,
      showImageId: '',
      addNoteModal: false,
      editNoteModal: false,
      noteToEdit: {},
    };
  },
  computed: {
    validating() {
      return 1;
    },
    images() {
      return this.notes.filter(obj => obj.type_id === 1 || obj.type_id === '1');
    },
  },
  methods: {
    openImageGallery(noteId) {
      this.showImageId = noteId;
      this.showImageGallery = true;
    },
    noteAdded(note) {
      this.$emit('noteAdded', note);
    },
    editNote(id) {
      this.noteToEdit = this.notes.find(obj => Number(obj.id) === Number(id));
      this.editNoteModal = true;
    },
    cancelEditNote() {
      this.noteToEdit = {};
      this.editNoteModal = false;
    },
    noteUpdated(note) {
      this.$emit('noteUpdated', note);
      this.editNoteModal = false;
    },
    noteDeleted(id) {
      this.$emit('noteDeleted', id);
    },
  },
};
</script>
