<template>
  <div class="flex items-start py-1">
    <div v-if="Number(note.type_id) === 3"
      class="flex-shrink-0 flex justify-center bg-gray-200 text-gray-400 dark:bg-gray-600 dark:text-gray-800 rounded mr-3 w-16"
      title="notatka tekstowa"
    >
      <FontAwesomeIcon :icon="['far', 'file-alt']" size="4x" class="p-1" />
    </div>
    <div v-else-if="Number(note.type_id) === 1" class="flex-shrink-0 rounded mr-3 cursor-pointer" title="kliknij, aby otworzyć/pobrać załącznik" alt="miniatura">
      <a href="#" @click.prevent="showImage" title="kliknij, aby powiększyć">
        <img class="rounded w-16 hover:opacity-70" :src="`${apiUrl}/notatki/show-image/${note.id}/t`" alt="Miniaturka">
      </a>
    </div>
    <div v-else
      class="flex-shrink-0 flex justify-center bg-yellow-200 text-gray-400 hover:bg-yellow-300 dark:bg-yellow-900 dark:hover:bg-yellow-800 dark:text-gray-800 rounded mr-3 w-16 cursor-pointer"
      title="kliknij, aby otworzyć/pobrać załącznik"
    >
      <a :href="`${apiUrl}/notatki/show-file/${note.id}`" class="cursor-pointer" target="_blank" title="kliknij, aby otworzyć/pobrać załącznik">
        <FontAwesomeIcon v-if="note.file_type === 'application/pdf'" :icon="['far', 'file-pdf']" size="4x" class="p-1" />
        <FontAwesomeIcon v-else-if="note.file_type === 'application/vnd.ms-excel' || note.file_type === 'application/vnd.openxmlformats-officedocument.spre'" :icon="['far', 'file-excel']" size="4x" class="p-1" />
        <FontAwesomeIcon v-else-if="note.file_type === 'application/msword' || note.file_type === 'application/vnd.openxmlformats-officedocument.word'" :icon="['far', 'file-word']" size="4x" class="p-1" />
        <FontAwesomeIcon v-else-if="note.file_type === 'application/zip'" :icon="['far', 'file-archive']" size="4x" class="p-1" />
        <FontAwesomeIcon v-else :icon="['far', 'file']" size="4x" class="p-1" />
      </a>
    </div>
    <div class="flex flex-col flex-grow min-w-0">
      <div class="flex justify-between text-sm">
        <div class="flex flex-wrap gap-x-1">
          <span class="text-xs mr-2"><em>{{ note.created_time_h }}</em></span>
          <a v-if="Number(currentUserId) !== Number(note.user_id)" :href="`mailto:${note.email}`" class="tw--link mr-2">{{ note.forename }} {{ note.surname }}</a>
          <span v-else class="font-semibold">{{ note.forename }} {{ note.surname }}</span>
        </div>
        <div v-if="showActions" class="flex items-start gap-1">
          <button  @click="$emit('editNote', note.id)" class="tw--btn-icon text-gray-400 dark:text-gray-500 p-0">
            <FontAwesomeIcon :icon="['far', 'edit']" title="edytuj" />
          </button>
          <button  @click="confirmDelNote" class="tw--btn-icon tw--btn-icon-danger text-gray-400 dark:text-gray-500 p-0">
            <FontAwesomeIcon :icon="['far', 'trash-alt']" title="usuń" />
          </button>
        </div>
      </div>
      <span class="font-bold">{{ note.title }}</span>
      <span class="text-sm break-words" v-html="descriptionNl2br"></span>
      <span v-if="note.filename && (!note.description) && (!note.title)" class="text-sm"><em>{{ note.filename }}</em></span>
    </div>
  </div>
</template>

<script>
import NoteService from '../../services/NoteService';
import { confirm } from '../../mixins/confirm';
import { errorHandler } from '../../mixins/errorHandler';

export default {
  name: 'NoteItem',
  mixins: [confirm, errorHandler],
  props: {
    note: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL,
    };
  },
  computed: {
    currentUserId() {
      return this.$store.getters.currentUserData.id;
    },
    currentUserType() {
      return this.$store.getters.currentUserType;
    },
    showActions() {
      return ((this.currentUserType === 'root' || this.currentUserType === 'admin') || Number(this.currentUserId) === Number(this.note.user_id));
    },
    descriptionNl2br() {
      return `${this.note.description}`.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>$2');
    },
  },
  methods: {
    showImage() {
      this.$emit('imageShow', this.note.id);
    },
    confirmDelNote() {
      this.confirm(() => this.delNote(), 'Potwierdasz usunięcie notatki?');
    },
    delNote() {
      NoteService.delNote(this.note.id)
        .then(() => this.$emit('noteDeleted', this.note.id))
        .catch(error => this.resolveError(error));
    },
  },
};
</script>
