<template>
  <div class="fixed h-screen w-screen inset-0 flex  justify-center items-center bg-white opacity-50 z-50">
    <div class="flex flex-row items-center">
      <FontAwesomeIcon class="mr-6" icon="spinner" size="2x" spin />
      <h1 class="text-4xl">Trwa wylogowywanie...</h1>
    </div>
  </div>
</template>

<script>
import AuthService from '../services/AuthService';

export default {
  name: 'Logout',
  data() {
    return {
      processing: true,
    };
  },
  mounted() {
    AuthService.logout()
      .then(() => {
        this.$store.dispatch('notifications/add', {
          type: 'success',
          message: 'Poprawnie wylogowano z aplikacji.',
          time: 1.5,
        });
        this.$store.dispatch('employees/clearEmployeeSimpleList');
        this.$store.dispatch('employees/clearManagerList');
        this.$store.dispatch('employees/clearEmplProvList');
        this.$store.dispatch('logout');

        this.$router.push({ name: 'Login' });
      })
      .catch(() => {
        this.$store.dispatch('employees/clearEmployeeSimpleList');
        this.$store.dispatch('employees/clearManagerList');
        this.$store.dispatch('employees/clearEmplProvList');
        this.$store.dispatch('logout');
        this.$store.dispatch('notifications/add', {
          type: 'warning',
          message: 'Niepoprawnie wylogowano z aplikacji (błąd odpowiedzi serwera). Odśwież stronę klawiszem F5 lub zamknij przeglądarkę.',
          time: 0,
        });
      });
  },
};
</script>
