/* eslint-disable no-shadow */
import ApiService from '../../services/ApiService';

const state = {
  insurerList: [],
  productCategoryList: [],
};

const mutations = {
  UPDATE_INSURER_LIST(state, payload) {
    state.insurerList = payload;
  },
  UPDATE_PROD_CATEGORY_LIST(state, payload) {
    state.productCategoryList = payload;
  },
};

const actions = {
  getInsurerList({ commit }) {
    const result = new Promise((resolve, reject) => {
      ApiService.getInsurerList()
        .then(response => {
          commit('UPDATE_INSURER_LIST', response.data.insurerList);
          resolve();
        })
        .catch(error => reject(error));
    });
    return result;
  },
  getProductCategoryList({ commit }) {
    const result = new Promise((resolve, reject) => {
      ApiService.getProductCategoryList()
        .then(response => {
          commit('UPDATE_PROD_CATEGORY_LIST', response.data.prodCatlist);
          resolve();
        })
        .catch(error => reject(error));
    });
    return result;
  },
};

const getters = {
  insurerList: state => state.insurerList,
  productCategoryList: state => state.productCategoryList,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
