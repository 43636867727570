<template>
  <div class="flex flex-col gap-2">
    <div v-for="(formRow, index) in subjectFormRows" :key="index" class="flex flex-wrap gap-4">
      <div class="text-sm" :class="formRow.input.length > 0 ? 'w-1/3' : 'w-full'" v-html="formRow.label"></div>
      <div class="flex-grow flex gap-2">
        <div class="flex-grow" v-for="(field, index) in formRow.input" :key="`field-${index}`">
          <BaseInputWithAddon v-if="field.cat === 'input'"
            :value="value[field.name]"
            @input="emitFormChanged($event, field.name)"
            :type="field.type"
            :placeholder="field.placeholder"
            :error="saveErrors[field.name] !== undefined ? saveErrors[field.name] : ''"
            :disabled="disabled"
          />
          <BaseSelectWithAddon v-if="field.cat === 'select'"
            :value="value[field.name]"
            @input="emitFormChanged($event, field.name)"
            :error="saveErrors[field.name] !== undefined ? saveErrors[field.name] : ''"
            :options="field.options"
            :disabled="disabled"
          />
          <label v-if="field.cat === 'checkbox'" :for="field.name">
            <input
              :id="field.name"
              type="checkbox"
              true-value="1" false-value="0"
              :value="value[field.name]"
              @input="emitFormChanged($event.target.checked ? '1' : '0', field.name)"
              :disabled="disabled"
            > {{ field.label }}
              <!-- @input="emitFormChanged($event.target.value, field.name)" -->
              <!-- @input="test($event)" -->
          </label>
          <textarea
            v-if="field.cat === 'textarea'"
            class="w-full resize-none"
            :value="value[field.name]"
            @input="emitFormChanged($event.target.value, field.name)"
            :rows="field.rows"
            :placeholder="field.placeholder"
            :disabled="disabled"
          ></textarea>
          <div v-if="field.cat ==='label'" class="font-semibold" v-html="field.label"></div>
          <p v-if="saveErrors[field.name] != undefined && (field.cat === 'textarea' || field.cat === 'checkbox')" class="text-red-400 dark:text-red-600 text-xs">
            {{ saveErrors[field.name] }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { errorHandler } from '../../mixins/errorHandler';
import OrderService from '../../services/OrderService';

export default {
  name: 'SubjectForm',
  mixins: [errorHandler],
  props: {
    value: {
      type: Object,
      required: true,
    },
    orderTypeId: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    saveErrors: {
      type: [Array, Object],
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      subjectFormRows: [],
    };
  },
  mounted() {
    if (this.orderTypeId) {
      this.getFormRows();
    }
  },
  methods: {
    getFormRows() {
      OrderService.getSubjectForm(this.orderTypeId)
        .then(response => { this.subjectFormRows = response.data.result; })
        .catch(error => this.resolveError(error));
    },
    emitFormChanged(value, key) {
      this.$emit('input', { ...this.value, [key]: value });
    },
    test(event) {
      console.log(event.target.value);
    },
  },
  watch: {
    orderTypeId(newVal) {
      if (newVal) {
        this.getFormRows();
      } else {
        this.subjectFormRows = [];
      }
    },
  },
};
</script>
