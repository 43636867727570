<template>
  <div class="flex flex-col">

    <SubjectModal
      v-if="showSubjectModal"
      :subject="currentSubject"
      :orderId="orderId"
      :orderTypeId="orderTypeId"
      :action="modalType"
      @close="showSubjectModal = false"
      @added="added"
      @updated="updated"
      @deleted="deleted"
    />

    <div id="subject-list" class="flex items-end justify-between text-muted border-b dark:border-gray-600 border-gray-400">
      <div class="text-sm font-semibold uppercase">przedmiot ubezpieczenia</div>
      <button @click="addSubject" class="tw--btn-icon p-0" title="dodaj nowy przedmiot ubezpieczenia">
        <FontAwesomeIcon :icon="['far', 'plus-square']" size="lg" />
      </button>
    </div>
    <div class="flex flex-col gap-1">
      <span v-if="orderSubjects.subjects.length > 0" class="text-xs text-muted italic">* kliknij w element, aby wyświetlić szczegóły, edytować lub usunąć</span>
      <a v-else
        @click.prevent="addSubject"
        href="#"
        class="flex flex-wrap gap-1 justify-between py-1 border dark:border-gray-600 border-gray-400 rounded mt-1"
      >
        <div v-for="(description, name, index) in orderSubjects.fieldListShort" :key="`${name}-${index}`" class="flex px-2 gap-2 items-center">
          <span class="text-muted">{{ description }}</span>
          <span class="italic text-sm">nie podano</span>
        </div>
      </a>
      <a
        @click.prevent="showEditSubject(subject)"
        href="#"
        v-for="subject in orderSubjects.subjects"
        :key="subject.id"
        class="flex flex-wrap gap-1 justify-between py-1 border dark:border-gray-600 border-gray-400 rounded"
      >
        <div v-for="(description, name, index) in orderSubjects.fieldListShort" :key="`${name}-${index}`" class="flex px-2 gap-2 items-center">
          <span class="text-muted">{{ description }}</span>
          <span v-if="subject[name] !== '' && subject[name] !== ' ' && subject[name] !== 'Tak'" class="text-bright font-bold">{{ subject[name] }}</span>
          <span v-else-if="subject[name] === ''" class="italic text-sm">nie podano</span>
          <span v-else-if="subject[name] === 'Tak'" class="font-bold text-green-400 dark:text-green-600">&check;</span>
          <FontAwesomeIcon v-else-if="subject[name] === ' '" class="text-gray-300 dark:text-gray-600" icon="times" />
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import SubjectModal from './SubjectModal.vue';

export default {
  name: 'SubjectList',
  components: { SubjectModal },
  props: {
    orderSubjects: {
      type: Object,
      required: true,
    },
    orderId: {
      type: [Number, String],
      required: true,
    },
    orderTypeId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      currentSubject: {},
      modalType: 'add',
      showSubjectModal: false,
    };
  },
  methods: {
    addSubject() {
      this.modalType = 'add';
      this.currentSubject = {};
      this.showSubjectModal = true;
    },
    showEditSubject(subject) {
      this.modalType = 'show-edit';
      this.currentSubject = subject;
      // Object.assign(this.currentSubject, subject);
      this.showSubjectModal = true;
    },
    added(subject){
      this.$emit('added', subject);
      this.showSubjectModal = false;
    },
    updated(subject) {
      this.$emit('updated', subject);
      this.showSubjectModal = false;
    },
    deleted(id) {
      this.$emit('deleted', id);
      this.showSubjectModal = false;
    },
  },
};
</script>
