<template>
  <div id="customer-contact-persons-card" class="w-full text-sm">
    <ContactPersonAddEdit
      v-if="addEditContactPerson"
      :customerId="customerId"
      :contactPerson="personToEdit"
      :action="modalAction"
      @close="cancelEditContactPerson"
      @added="contactPersonAdded"
      @updated="contactPersonUpdated"
    />
    <div class="flex items-end justify-between text-muted border-b dark:border-gray-600">
      <div class="uppercase font-semibold">osoby kontaktowe</div>
      <div>
        <button @click="addContactPerson" class="tw--btn-icon p-0" title="dodaj osobe kontaktową">
          <FontAwesomeIcon icon="plus" /><FontAwesomeIcon :icon="['far', 'user']" size="lg" />
        </button>
      </div>
    </div>

    <div class="flex flex-col">
      <ContactPersonItem v-for="contactPerson in contactPersons"
        :key="contactPerson.id"
        :contactPerson="contactPerson"
        @deleted="deleted"
        @editContactPerson="editContactPerson"
        @phoneAdded="phoneAdded"
        @phoneUpdated="phoneUpdated"
        @phoneDeleted="phoneDeleted"
        @emailAdded="emailAdded"
        @emailUpdated="emailUpdated"
        @emailDeleted="emailDeleted"
      />
    </div>
  </div>
</template>

<script>
import ContactPersonAddEdit from './ContactPersonAddEdit.vue';
import ContactPersonItem from './ContactPersonItem.vue';

export default {
  name: 'ContactPersons',
  components: {
    ContactPersonAddEdit,
    ContactPersonItem,
  },
  props: {
    customerId: {
      type: [Number, String],
      required: true,
    },
    contactPersons: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      addEditContactPerson: false,
      modalAction: 'add',
      personToEdit: {},
    };
  },
  methods: {
    addContactPerson() {
      this.personToEdit = {},
      this.modalAction = 'add';
      this.addEditContactPerson = true;
    },
    editContactPerson(id) {
      this.personToEdit = this.contactPersons.find(obj => Number(obj.id) === Number(id));
      this.modalAction = 'edit';
      this.addEditContactPerson = true;
    },
    cancelEditContactPerson() {
      this.personToEdit = {};
      this.addEditContactPerson = false;
    },
    contactPersonAdded(person) {
      this.$emit('contactPersonAdded', person);
      this.addEditContactPerson = false;
    },
    contactPersonUpdated(person) {
      this.$emit('contactPersonUpdated', person);
      this.addEditContactPerson = false;
    },
    deleted(id) {
      this.$emit('contactPersonDeleted', id);
    },
    phoneAdded(object) {
      this.$emit('phoneAdded', object);
    },
    phoneUpdated(object) {
      this.$emit('phoneUpdated', object);
    },
    phoneDeleted(object) {
      this.$emit('phoneDeleted', object);
    },
    emailAdded(object) {
      this.$emit('emailAdded', object);
    },
    emailUpdated(object) {
      this.$emit('emailUpdated', object);
    },
    emailDeleted(object) {
      this.$emit('emailDeleted', object);
    },
  },
};
</script>
