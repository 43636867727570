<template>
  <div>
    <div
      id="wrapper"
      @click="goTo({ name: 'Order', params: { id: order.id }})"
      class="flex flex-wrap border dark:border-gray-600 rounded shadow cursor-pointer relative"
      :class="{
        'opacity-50': rowClass === 'muted',
        'bg-yellow-200 dark:bg-yellow-500 bg-opacity-20 dark:bg-opacity-10': rowClass === 'working',
        'bg-green-300 dark:bg-green-300 bg-opacity-20 dark:bg-opacity-10': rowClass === 'active',
      }"
    >

      <div id="order-type-icon--2xl"
        class="order-first w-1/4 md:w-1/2 2xl:w-9 flex justify-center items-center text-gray-400 dark:text-gray-500 rounded-tl 2xl:rounded-l border-b 2xl:border-b-0 dark:border-gray-600"
        :class="{'bg-yellow-100 text-gray-500 dark:bg-yellow-700 dark:text-gray-800': Number(order.is_leasing) === 1}"
      >
        <span v-if="[3, 8, 9].includes(Number(order.type_id))" class="font-extrabold text-xl leading-3 px-1">&sect;</span>
        <FontAwesomeIcon v-else
          :icon="orderTypeIcon"
          size="lg"
        />
      </div>

      <div id="order-details" class="order-2 w-full lg:w-1/2 2xl:w-2/5 flex flex-col px-2">

        <div id="order-type--insurer-name" class="flex flex-wrap sm:flex-nowrap justify-between">

          <div id="order-type"
            class="w-full sm:w-auto uppercase tracking-tighter font-semibold text-indigo-600 dark:text-indigo-600"
            :class="order.type_name.length > 20 ? 'text-xs' : 'text-sm'"
          >{{ order.type_name }} <span v-if="order.is_leasing == 1">&nbsp;- LEASING</span></div>

          <div id="insurer-name" v-if="order.policy_no !== ''" class="font-semibold xl:text-right">
            <span>{{ order.insurer_name }}</span>
          </div>
          <div v-else class="flex items-center px-2 py-0 bg-gray-200 dark:bg-gray-700 rounded-sm">
            <span @click.stop class="font-semibold text-xs py-1 leading-3">
              <router-link
                :to="{ name: 'Order', params: { id: order.id }}"
                class="text-blue-700 hover:text-blue-500 dark:text-blue-600 dark:hover:text-blue-400 hover:underline"
              >Oferta</router-link>
            </span>
          </div>

        </div> <!-- order-type--insurer-name -->

        <div id="order-dates--policy-no" class="flex flex-wrap sm:flex-nowrap items-center justify-between">

          <div id="order-dates" class="w-full sm:w-auto text-black dark:text-gray-300 text-sm lg:text-base">
            <FontAwesomeIcon :icon="['far', 'calendar-alt']" class="mr-1" />
            <span>{{ order.date_from_h }}</span>
            <FontAwesomeIcon icon="long-arrow-alt-right" class="mx-1 text-gray-400 dark:text-gray-500" />
            <span>{{ order.date_to_h ? order.date_to_h : 'nieokreślony' }}</span>
            <FontAwesomeIcon
              icon="forward"
              class="ml-0.5"
              :class="Number(order.has_next_ord) === 1 ? 'text-green-300 dark:text-green-800': 'text-transparent'"
              :title="Number(order.has_next_ord) === 1 ? 'Polisa posiada kontynuację (wznowienie)' : ''"
            />
          </div>

          <div v-if="order.policy_no !== ''" class="order-first sm:order-last text-right">
            <span><span class="hidden 2xl:inline">polisa </span>nr: </span>
            <span @click.stop>
              <router-link :to="{ name: 'Order', params: { id: order.id }}" class="tw--link font-semibold" :class="order.policy_no.length > 12 ? 'text-xs' : 'text-base'">
                {{ order.policy_no }}
              </router-link>
            </span>
          </div>

        </div> <!-- order-dates--policy-no -->

      </div> <!-- order-details -->

      <div id="order-subject" class="order-3 w-full lg:w-1/2 2xl:w-2/5 px-2 border-t lg:border-t-0 lg:border-l dark:border-gray-600  text-xs sm:text-sm lg:text-base">
        <div class="flex flex-wrap items-center gap-x-3">
          <div class="text-muted text-sm">przedmiot ub.:</div>
          <div v-if="order.subject_name !== ''" class="text-bright font-normal">{{ order.subject_name }}</div>
          <div v-else class="text-muted italic">brak informacji</div>
        </div>
        <div class="flex flex-wrap items-center gap-x-3">
          <div class="text-muted text-sm">szczegóły:</div>
          <div v-if="order.subject_attribute !== ''" class="text-bright font-normal">{{ order.subject_attribute }}</div>
          <div v-else class="text-muted italic">brak informacji</div>
        </div>
        <!-- <div v-show="order.subject_attribute !== ''">{{ order.subject_attribute }}</div> -->
      </div>

      <div id="order-status--employee" class="order-1 2xl:order-4 w-3/4 md:w-1/2 2xl:w-auto 2xl:flex-grow flex flex-col items-end px-2 border-l border-b 2xl:border-b-0 dark:border-gray-600">
        <div id="order-status" :class="statusClass">{{ order.status_name }}</div>
        <div id="employee" class="flex items-center text-sm">
          <div><FontAwesomeIcon icon="user-tie" title="Agent" size="sm" class="mr-1" /></div>
          <span @click.stop>
            <router-link
              :to="{ name: 'Employee', params: { id: order.employee_id }}"
              class="tw--link font-normal text-gray-800 dark:text-gray-400 leading-4"
              :class="{'2xl:text-base text-xs': order.employee_name.length > 20}"
            >{{ order.employee_name }}</router-link>
          </span>
        </div>
      </div>

    </div> <!-- wrapper -->
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'CustomerOrderListItem',
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  computed: {
    rowLabel() {
      let label = 'none__label';
      if (this.order.is_leasing === '1' || this.order.is_leasing === 1) {
        label = 'leasing__label';
      }
      return label;
    },
    orderTypeIcon() {
      const icons = [
        'car-side',
        'home',
        'file-signature',
        'luggage-cart',
        'heartbeat',
        'heartbeat',
        'warehouse',
        'file-signature',
        'file-signature',
        'seedling',
        'tractor',
        'users',
        'users',
        ['far', 'file-alt'],
      ];
      return icons[Number(this.order.type_id) - 1];
    },
    subjectTypeIcon() {
      let icon = 'file-signature';
      if (Number(this.order.type_id) === 1) {
        icon = 'car';
      } else if ([2,7].includes(Number(this.order.type_id))) {
        icon = 'home';
      } else if (Number(this.order.type_id) === 9) {
        icon = 'user-tie';
      } else if (Number(this.order.type_id) === 4) {
        icon = 'globe-americas';
      } else if (Number(this.order.type_id) === 10) {
        icon = 'seedling';
      }
      return icon;
    },
    statusClass() {
      const status = this.order.status_id;
      let stClass = '';
      if (['1', '4'].includes(status)) {
        stClass = 'font-semibold text-indigo-600 dark:text-indigo-800';
      } else if (['3', '8'].includes(status)) {
        stClass = 'font-semibold text-red-600 dark:text-red-800';
      } else if (status === '6') {
        stClass = 'font-semibold text-green-600 dark:text-green-800';
      } else {
        stClass = 'text-bright';
      }
      return stClass;
    },
    rowClass() {
      let color = '';
      if (this.order.date_to_h !== '' && this.order.date_to_h < moment().format('YYYY-MM-DD')) {
        color = 'muted';
      } else if ([1, 2, 3, 4, 5, 6].includes(Number(this.order.status_id))) {
        color = 'working';
      } else if ( [7, 8, 9, 10].includes(Number(this.order.status_id))) {
        color = 'active';
      }
      return color;
    },
    // Wywalić - wrzucić do :class='{}'
    rowColor() {
      let rowClass = [];
      switch (this.order.status_id) {
        case '1':
        case '2':
        case '3':
        case '4':
        case '5':
        case '6':
          rowClass.push('order-row__prepare');
          break;
        case '7':
        case '8':
        case '9':
        case '10':
          rowClass.push('order-row__active');
          break;
        default:
          rowClass.push();
      }
      if (this.order.date_to_h !== '' && this.order.date_to_h < moment().format('YYYY-MM-DD')) {
        rowClass = [];
        rowClass.push('has-text-grey-light', 'is-italic');
      }
      return rowClass.join(' ');
    },
    // koniec wywalania
  },
  methods: {
    goTo(route) {
      const selection = window.getSelection().toString();
      if (selection.length === 0) {
        this.$router.push(route);
      }
    },
  },
};
</script>

<style scoped>
  .order-row__prepare {
    background-color: #FFFFe0;
  }
  .order-row__active {
    background-color: #DDFFDD;
  }
</style>
