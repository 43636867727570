<template>
  <div class="md:whitespace-nowrap">
    <button @click="$emit('toggleMenu')" class="absolute top-0 right-2 opacity-60 hover:opacity-100 cursor-pointer">
      <FontAwesomeIcon class=" text-blue-200 dark:text-gray-400" icon="times" title="ukryj menu" />
    </button>
    <div class="hidden md:block text-center mt-2">
      <img class="inline-block rounded shadow-xl h-6 opacity-75" :src="companyLogoUrl" alt="Logo">
    </div>
    <ul class="list-reset flex flex-row flex-wrap md:flex-col mt-4 text-center md:text-left md:border-t md:border-blue-800 md:dark:border-gray-800">
      <li v-for="(item, index) in verifiedItems" :key="index" @click="closeMenuIfSmallScreen" class="flex-1 md:block mx-3 md:mx-0 text-gray-300 md:dark:text-gray-500">
        <router-link
          class="block py-1 md:py-3 md:pl-3 md:pr-7 align-middle no-underline border-b-2 md:border-b border-gray-800 md:border-blue-800 md:dark:border-gray-800
                  md:hover:border-blue-800 md:dark:hover:border-gray-800 md:hover:bg-blue-700 md:dark:hover:bg-gray-700
                  hover:text-white dark:hover:text-gray-300"
          :to="{ name: item.routeName }"
          active-class="border-yellow-500 md:border-blue-800 md:dark:border-gray-800 md:bg-blue-700 md:dark:bg-gray-700 text-white md:dark:text-gray-300"
          :exact="item.exact"
        >
          <FontAwesomeIcon class="mr-2 text-gray-400" :icon="item.icon ? item.icon : 'mouse-pointer'" />
          <span class="block md:inline-block pb-1 md:pb-0 text-xs font-semibold md:text-sm xl:text-sm whitespace-nowrap">
            {{ item.label }}
          </span>
        </router-link>
      </li>
    </ul>
    <div class="hidden md:block text-right pr-4 pt-4">
      <label class="text-gray-400 text-xs" for="dark-mode">
        <span class="mr-2 font-semibold">tryb ciemny</span>
        <input id="dark-mode" type="checkbox" :true-value="true" :false-value="false" v-model="darkModeBox">
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainSidebar',
  data() {
    return {
      darkModeBox: false,
      items: [
        {
          routeName: 'Calendar',
          label: 'Kalendarz',
          icon: ['far', 'calendar-alt'],
          exact: false,
          allow: ['all'],
        },
        {
          routeName: 'Orders',
          label: 'Sprzedaż',
          icon: ['fas', 'file-signature'],
          exact: false,
          allow: ['all'],
        },
        {
          routeName: 'OrderAdd',
          label: 'Nowa oferta',
          icon: ['fas', 'plus'],
          exact: false,
          allow: ['all'],
        },
        {
          routeName: 'Customers',
          label: 'Klienci',
          icon: ['fas', 'users'],
          exact: false,
          allow: ['all'],
        },
        {
          routeName: 'SalesReport',
          label: 'Raporty',
          icon: ['fas', 'chart-bar'],
          exact: false,
          allow: ['all'],
        },
        {
          routeName: 'Provisions',
          label: 'Prowizje',
          icon: ['fas', 'coins'],
          exact: false,
          allow: ['agent', 'pracownik_b', 'asystent', 'manager'],
        },
        {
          routeName: 'Extras',
          label: 'Przydatne',
          icon: ['fas', 'link'],
          exact: false,
          allow: ['all'],
        },
        {
          routeName: 'Employees',
          label: 'Pracownicy',
          icon: ['fas', 'user-tie'],
          exact: false,
          allow: ['manager', 'asystent'],
        },
        {
          routeName: 'Settings',
          label: 'Ustawienia',
          icon: ['fas', 'tools'],
          exact: false,
          allow: ['admin'],
        },
        {
          routeName: 'Logout',
          label: 'Wyloguj',
          icon: ['fas', 'sign-out-alt'],
          mobile: false,
          exact: false,
          allow: ['all'],
        },
      ],
    };
  },
  computed: {
    darkMode() {
      return this.$store.getters.darkMode;
    },
    currentUserData() {
      return this.$store.getters.currentUserData;
    },
    currentUserType() {
      return this.$store.getters.currentUserType;
    },
    companyLogoUrl() {
      let url = this.$store.getters.configParams.company_logo_s;
      if (url === undefined) {
        url = '';
      } else if (url === '') {
        url = '/images/logo/logotyp-emultia.png';
      } else {
        url = `/${url}`;
      }
      return url;
    },
    showAdmin() {
      return (this.currentUserType === 'root' || this.currentUserType === 'admin');
    },
    showAsistant() {
      return (this.showAdmin || this.currentUserType === 'asystent');
    },
    showManager() {
      return (this.showAdmin || this.currentUserType === 'manager');
    },
    verifiedItems() {
      return this.items.filter(item => this.showAdmin || item.allow.includes(this.currentUserType) || item.allow.includes('all'));
    },
  },
  created() {
    this.darkModeBox = this.$store.getters.darkMode;
  },
  methods: {
    setDarkMode() {
      this.$store.dispatch('setDarkMode', !this.darkMode);
    },
    closeMenuIfSmallScreen() {
      if (window.innerWidth < 768) {
        this.$emit('toggleMenu');
      }
    },
  },
  watch: {
    darkModeBox(newVal) {
      this.$store.dispatch('setDarkMode', newVal);
    },
  },
};
</script>
