<template>
  <BaseModal
    @closeModal="$emit('close')"
    @ok="$emit('close')"
    size="md"
    okLabel="Zamknij"
    :cancel="false"
  >
    <template #header>Zarządzanie {{ type === 'emails' ? 'adresami email' : 'numerami telefonów' }}</template>
    <template #default>
      <div class="flex flex-col gap-y-3">
        <div id="header" class="flex justify-between text-base">
          <div class="flex gap-2">
            <div class="text-muted">
              <span v-if="type === 'emails'">Adresów email: </span>
              <span v-else>Numerów telefonów: </span>
            </div>
            <div class="text-bright">{{ items.length }}</div>
          </div>
          <button v-show="!addItemBlock" @click.stop="addItemBlock = true" class="tw--btn">Dodaj nowy</button>
        </div>

        <EmailsPhonesAdd v-if="addItemBlock"
          :ownerType="ownerType"
          :ownerId="ownerId"
          :type="type"
          @added="added"
          @cancel="addItemBlock = false"
        />

        <div v-for="item in items" :key="item.id" class="flex gap-4">
          <EmailsPhonesEditElement
            :ownerType="ownerType"
            :ownerId="ownerId"
            :type="type"
            :item="item"
            @updated="updated"
            @deleted="deleted"
          />
        </div>
        <div id="info" class="border-t dark:border-gray-600 text-sm text-muted">
          <p>Możesz dodać kilka {{ type === 'emails' ? 'adresów email' : 'numerów telefonów' }}. Element pierwszy na liście będzie w przyszłości (planowana nowa funkcjonalność) wykorzystywany do
            automatycznej komunikacji z klientem (powiadomienia o terminach płatności, wysyłka polis elektronicznych itp).
          </p>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import EmailsPhonesEditElement from './EmailsPhonesEditElement.vue';
import EmailsPhonesAdd from './EmailsPhonesAdd.vue';

export default {
  name: 'EmailsPhonesEdit',
  components: {
    EmailsPhonesEditElement,
    EmailsPhonesAdd,
  },
  props: {
    type: {
      type: String,
      default: 'emails',
    },
    items: {
      type: Array,
      required: true,
    },
    ownerType: String,
    ownerId: [String, Number],
  },
  data() {
    return {
      addItemBlock: false,
    };
  },
  mounted() {
    if (this.items.length === 0) {
      this.$nextTick(() => this.addItemBlock = true);
    }
  },
  methods: {
    updated(item) {
      this.$emit('updated', item);
      if (this.items.length === 1) {
        this.$emit('close');
      }
    },
    added(item) {
      this.$emit('added', item);
      this.addItemBlock = false;
      if (this.items.length === 1) {
        this.$emit('close');
      }
    },
    deleted(id) {
      this.$emit('deleted', id);
    },
  },
};
</script>
