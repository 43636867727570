<template>
  <div id="short-summary" class="px-3 py-2 bg-white dark:bg-gray-900 rounded-lg shadow-lg text-sm flex flex-col lg:flex-row gap-x-3">
      <FontAwesomeIcon icon="chart-line" size="lg" class="text-purple-500 dark:text-purple-700" />
    <div class="flex flex-wrap gap-x-10 gap-y-2 items-center">
      <div class="flex flex-wrap gap-x-5">
        <div><span class="text-muted">przypis ogółem:</span> <span class="text-bright">{{ summary.przypis_all | moneyPL }}</span></div>
        <div><span class="text-muted">polis ogółem:</span> <span class="text-bright">{{ summary.orders_sum }}</span></div>
      </div>
      <div class="flex flex-wrap gap-x-5">
        <div><span class="text-muted">przypis 12m-cy:</span> <span class="text-bright">{{ summary.przypis_12m | moneyPL }}</span></div>
        <div><span class="text-muted">polis 12m-cy:</span> <span class="text-bright">{{ summary.orders_sum_12m }}</span></div>
      </div>
      <div class="flex flex-wrap gap-x-5">
        <div><span class="text-muted">przypis bieżący mc:</span> <span class="text-bright">{{ summary.przypis_curr_m | moneyPL }}</span></div>
        <div><span class="text-muted">polis bieżący mc:</span> <span class="text-bright">{{ summary.orders_sum_curr_m }}</span></div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ShortSalesSummary',
  props: {
    summary: {
      type: Object,
      default() {
        return {
          przypis_all: 0,
          przypis_12m: 0,
          przypis_curr_m: 0,
          orders_sum: 0,
          orders_sum_12m: 0,
          orders_sum_curr_m: 0,
        };
      },
    },
  },
};
</script>
