<template>
  <div id="customer-card" class="p-3 bg-white dark:bg-gray-900 rounded-lg shadow-lg">

    <OrderChangeCustomer
      v-if="changeCustomerModal"
      @close="changeCustomerModal = false"
      @newCustomerId="newCustomerId"
    />

    <CustomerEdit
      v-if="editModal"
      :customer="customer"
      :customerExclusiveToUser="customerExclusiveToUser"
      @close="editModal = false"
      @customerUpdated="customerUpdated"
    />

    <div class="flex items-center -mt-3 mb-1 -mx-3 px-3 py-1 border-b dark:border-gray-600 border-gray-400">
      <div class="flex flex-grow items-center">
        <div class="flex gap-3 items-center  text-muted">
          <span class="uppercase font-semibold">
            <router-link v-if="$route.name !== 'Customer'" :to="{ name: 'Customer', params: { id: customer.id } }" class="hover:text-blue-500 hover:underline" title="przejdź do klienta">
              karta klienta
            </router-link>
            <span v-else>karta klienta</span>
          </span>
          <FontAwesomeIcon v-if="Number(customer.is_company) === 0" :icon="['far','user']" title="prywatny" />
          <FontAwesomeIcon v-else :icon="['far','building']" title="firma" />
          <router-link v-if="$route.name !== 'Customer'" :to="{ name: 'Customer', params: { id: customer.id } }" class="tw--btn-icon py-0" title='przejdź do klienta'>
            <FontAwesomeIcon icon="external-link-alt" />
          </router-link>
        </div>
      </div>
      <div v-if="validating === 1">
        <CardMenu>
          <template #default>
            <ul class="flex flex-col gap-2 text-sm whitespace-nowrap">
              <li @click="editModal = true" class="cursor-pointer hover:underline"><FontAwesomeIcon class="text-blue-400 dark:text-blue-600" :icon="['far', 'edit']" /> edytuj</li>
              <li v-if="changeCustomer" @click="changeCustomerModal = true" class="cursor-pointer hover:underline" title="zamień klienta na innego z bazy lub nowego">
                <FontAwesomeIcon class="text-purple-400 dark:text-purple-600" icon="exchange-alt" /> zamień
              </li>
            </ul>
          </template>
        </CardMenu>
      </div>
      <span v-else-if="validating === -1" class="mt-1 text-xs text-muted">
        uzupełnij<FontAwesomeIcon class="ml-2 text-yellow-400 dark:text-yellow-600" icon="exclamation-triangle" size="lg" />
      </span>
      <span v-else class="mt-1 text-xs text-muted">wczytuję<FontAwesomeIcon class="ml-2" icon="sync-alt" size="lg" spin /></span>
    </div>
    <div class="flex flex-col gap-3">
      <div id="customer-basic-data" class="border-b dark:border-gray-600 border-gray-400">
        <div id="not-exclusive-to-user-info" v-if="!customerExclusiveToUser && notExclusiveInfo" class="py-1 px-2 -mx-2 bg-yellow-50 dark:bg-yellow-500 dark:bg-opacity-20 text-xs rouded-sm">
          Klient jest obsługiwany również przez innego agenta
        </div>
        <div v-if="Number(customer.is_company) === 0" id="personal-data" class="flex flex-wrap justify-between">
          <div class="flex gap-1 text-bright font-bold text-lg">
            <span>{{ customer.person.surname }}</span>
            <span>{{ customer.person.forename1 }}</span>
            <span>{{ customer.person.forename2 }}</span>
          </div>
          <div class="flex gap-2">
            <span class="text-muted">{{ Number(customer.person.foreigner) === 0 ? 'pesel' : 'nr paszportu' }}</span><span class="text-bright">{{ customer.person.pesel }}</span>
          </div>
          <div class="flex justify-between w-full text-base">
            <div class="flex gap-3">
              <span class="text-muted">data ur.</span>
              <span v-if="customer.person.birth_date_h" class="text-bright">{{ customer.person.birth_date_h }}</span>
              <span v-else class="italic">nie podano</span>
            </div>
            <div v-if="customer.person.nationality || Number(customer.person.foreigner) === 1" class="flex gap-3">
              <span class="text-muted">narodowość</span>
              <span v-if="customer.person.nationality" class="text-bright">{{ customer.person.nationality }}</span>
              <span v-else class="italic">nie podano</span>
            </div>
          </div>
        </div>
        <div v-if="Number(customer.is_company) === 1" id="company-data" class="flex flex-wrap items-center justify-between">
          <div class="order-1 flex items-center gap-3 w-full sm:w-2/3 lg:w-full xl:w-1/2">
            <span class="text-muted">nazwa</span><span class="text-bright font-bold text-lg">{{ customer.company.name }}</span>
          </div>
          <div class="order-3 sm:order-2 lg:order-3 xl:order-2 flex gap-3 w-full sm:w-auto">
            <span class="text-muted">regon</span><span class="text-bright">{{ customer.company.regon }}</span>
          </div>
          <div class="order-2 sm:order-3 lg:order-2 xl:order-3 flex gap-3 w-full sm:w-1/2 lg:w-full xl:w-1/2">
            <span class="text-muted">skrót</span><span class="whitespace-nowrap">{{ customer.company.shortname }}</span>
          </div>
          <div class="order-4 flex gap-3 w-full sm:w-auto">
            <span class="text-muted">NIP</span>
            <span v-if="customer.company.nip" class="text-bright">{{ customer.company.nip }}</span>
            <span v-else class="italic">nie podano</span>
          </div>
        </div>
      </div>
      <div class="flex flex-col">
        <div id="customer-main-address" class="flex gap-3 w-full">
          <span class="text-muted">adres główny</span>
          <div v-if="mapsMainAddressUrl" class="flex flex-wrap items-center gap-2">
            <span class="font-normal"><em>{{ customerAddress.street }}</em></span>
            <span class="text-bright">{{ customerAddress.postal_code }} {{ customerAddress.city }}</span>
            <span v-show="customerAddress.region" class="italic font-normal">{{ customerAddress.region }}</span>
            <span v-show="customerAddress.country" class="font-semibold">{{ customerAddress.country }}</span>
            <a @click.stop :href="mapsMainAddressUrl" target="_blank">
              <FontAwesomeIcon icon="map-marked-alt" class="tw--link" />
            </a>
          </div>
          <div v-else class="text-muted text-sm"><em>nie podano adresu</em></div>
        </div>
        <div id="customer-ext-address" v-if="mapsExtAddressUrl" class="flex gap-3 w-full">
          <span class="text-muted">adres koresp.</span>
          <div class="flex flex-wrap items-center gap-2">
            <span class="text-bright font-normal"><em>{{ customerAddress.c_street }}</em></span>
            <span class="text-bright">{{ customerAddress.c_postal_code }} {{ customerAddress.c_city }}</span>
            <span v-show="customerAddress.c_region" class="italic font-normal">{{ customerAddress.c_region }}</span>
            <span v-show="customerAddress.c_country" class="font-semibold">{{ customerAddress.c_country }}</span>
            <a @click.stop :href="mapsExtAddressUrl" target="_blank">
              <FontAwesomeIcon icon="map-marked-alt" class="tw--link" />
            </a>
          </div>
        </div>
      </div>

      <div class="flex flex-col gap-y-0.5">
        <div class="flex gap-x-3 text-sm">
          <span class="text-muted">preferowana forma kontaktu:</span>
          <span :class="customer.pref_contact_form === '' ? 'italic' : 'text-bright'">{{ prefContactForm }}</span>
        </div>
        <div id="customer-phones-emails" class="flex flex-wrap gap-2">
          <EmailsPhones
            ownerType="customer"
            :ownerId="customer.id"
            type="phones"
            :phones="customer.phones"
            @added="phoneAdded"
            @updated="phoneUpdated"
            @deleted="phoneDeleted"
          />
          <EmailsPhones
            ownerType="customer"
            :ownerId="customer.id"
            type="emails"
            :emails="customer.emails"
            @added="emailAdded"
            @updated="emailUpdated"
            @deleted="emailDeleted"
          />
        </div>
      </div>

      <div v-if="Number(customer.is_company) === 1 && customer.company.vat_ue !== ''" class="w-full flex gap-3">
        <span class="text-muted">pesel właściciela (IDG):</span><span>{{ customer.company.vat_ue }}</span>
      </div>
      <div v-if="customerBankAccount.bank_acc_no" id="customer-bank-account" class="flex gap-3" title="nr konta klienta">
        <span class="text-muted">Konto bankowe klienta</span>
        <span class="flex gap-1"><span>{{ customerBankAccount.bank }}</span><span class="text-bright">{{ customerBankAccount.bank_acc_no }}</span></span>
      </div>
      <PersonConsents v-if="customer.consents.length > 0 || Number(customer.is_company) === 0"
        :consents="customer.consents"
        :personId="customer.personal_data_id"
        :allowEdit="gdprEditPermission"
        @consentAdded="consentAdded"
        @consentUpdated="consentUpdated"
        @consentDeleted="consentDeleted"
      />

      <ContactPersons v-if="showContactPersons"
        :customerId="customer.id"
        :contactPersons="contactPersons"
        @contactPersonAdded="contactPersonAdded"
        @contactPersonUpdated="contactPersonUpdated"
        @contactPersonDeleted="contactPersonDeleted"
        @phoneAdded="ccpPhoneAdded"
        @phoneUpdated="ccpPhoneUpdated"
        @phoneDeleted="ccpPhoneDeleted"
        @emailAdded="ccpEmailAdded"
        @emailUpdated="ccpEmailUpdated"
        @emailDeleted="ccpEmailDeleted"
      />

      <div class="flex flex-wrap gap-x-3 justify-end text-sm">
        <span class="text-muted">źródło pozyskania klienta: </span>
        <span class="italic">{{ customer.cust_type_name ? customer.cust_type_name : 'brak informacji' }}</span>
      </div>

    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { errorHandler } from '../../mixins/errorHandler';
import CustomerService from '../../services/CustomerService';
import PersonConsents from '../gdpr/PersonConsents.vue';
import OrderChangeCustomer from '../order/OrderChangeCustomer.vue';
import CustomerEdit from './CustomerEdit.vue';
import EmailsPhones from '../emailsPhones/EmailsPhones.vue';
import ContactPersons from './ContactPersons.vue';

export default {
  name: 'CustomerCard',
  components: {
    PersonConsents,
    OrderChangeCustomer,
    CustomerEdit,
    EmailsPhones,
    ContactPersons,
  },
  mixins: [errorHandler],
  props: {
    customer: {
      type: Object,
      required: true,
    },
    contactPersons: {
      type: Array,
      default() {
        return [];
      },
    },
    notExclusiveInfo: {
      type: Boolean,
      default: true,
    },
    permissions: {
      type: Object,
      default() {
        return {};
      },
    },
    changeCustomer: {
      type: Boolean,
      default: false,
    },
    showContactPersons: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editModal: false,
      changeCustomerModal: false,
      customerExclusiveToUser: false,
    };
  },
  computed: {
    ...mapState('customers', [
      'prefContactFormList',
    ]),
    prefContactForm() {
      const contactFormObj = this.prefContactFormList.find(obj => `${obj.id}` === `${this.customer.pref_contact_form}`);
      return contactFormObj !== undefined ? contactFormObj.name : 'brak informacji';
    },
    validating() {// -1 błąd, 0 łąduję dane, 1 ok = MenuCard
      return this.customer.id !== undefined ? 1 : 0;
    },
    customerAddress() {
      const cust = Number(this.customer.is_company) === 0 ? this.customer.person : this.customer.company;
      return {
        street: `${cust.street} ${cust.house_ap_no}`,
        postal_code: cust.postal_code,
        city: cust.city,
        region: cust.region,
        country: cust.country,
        c_street: `${cust.c_street} ${cust.c_house_ap_no}`,
        c_postal_code: cust.c_postal_code,
        c_city: cust.c_city,
        c_region: cust.c_region,
        c_country: cust.c_country,
      };
    },
    customerBankAccount() {
      return {
        bank: Number(this.customer.is_company) === 0 ? this.customer.person.bank : this.customer.company.bank,
        bank_acc_no: Number(this.customer.is_company) === 0 ? this.customer.person.bank_acc_no : this.customer.company.bank_acc_no,
      };
    },
    mapsMainAddressUrl() {
      let url = 'https://maps.google.com/?q=';
      let params = '';
      const cust = Number(this.customer.is_company) === 0 ? this.customer.person : this.customer.company;
      if (cust.street) {
        params = `${params}${cust.street} ${cust.house_ap_no}`;
      }
      if (cust.postal_code) {
        params = `${params}+${cust.postal_code}`;
      }
      if (cust.city) {
        params = `${params}+${cust.city}`;
      }
      if (params.length === 0) {
        return false;
      }
      return `${url}${params}`;
    },
    mapsExtAddressUrl() {
      let url = 'https://maps.google.com/?q=';
      let params = '';
      const cust = Number(this.customer.is_company) === 0 ? this.customer.person : this.customer.company;
      if (cust.c_street) {
        params = `${params}${cust.c_street} ${cust.c_house_ap_no}`;
      }
      if (cust.c_postal_code) {
        params = `${params}+${cust.c_postal_code}`;
      }
      if (cust.c_city) {
        params = `${params}+${cust.c_city}`;
      }
      if (params.length === 0) {
        return false;
      }
      return `${url}${params}`;
    },
    gdprEditPermission() {
      return this.permissions.gdpr_consent_edit_rights === 1 || this.permissions.gdpr_consent_edit_rights === '1';
    },
  },
  created() {
    CustomerService.checkCustomerIsExclusiveTouser(this.customer.id)
      .then(response => this.customerExclusiveToUser = response.data.result)
      .catch(error => this.resolveError(error));
  },
  methods: {
    consentAdded(consent) {
      this.$emit('consentAdded', consent);
    },
    consentUpdated(consent) {
      this.$emit('consentUpdated', consent);
    },
    consentDeleted(id) {
      this.$emit('consentDeleted', id);
    },
    newCustomerId(id) {
      this.$emit('newCustomerId', id);
      this.changeCustomerModal = false;
    },
    customerUpdated(updatedFields) {
      this.$emit('customerUpdated', updatedFields);
      this.editModal = false;
    },
    phoneAdded(item) {
      this.$emit('phoneAdded', item);
    },
    emailAdded(item) {
      this.$emit('emailAdded', item);
    },
    phoneUpdated(item) {
      this.$emit('phoneUpdated', item);
    },
    emailUpdated(item) {
      this.$emit('emailUpdated', item);
    },
    phoneDeleted(id) {
      this.$emit('phoneDeleted', id);
    },
    emailDeleted(id) {
      this.$emit('emailDeleted', id);
    },
    contactPersonAdded(ccp) {
      this.$emit('contactPersonAdded', ccp);
    },
    contactPersonUpdated(ccp) {
      this.$emit('contactPersonUpdated', ccp);
    },
    contactPersonDeleted(id) {
      this.$emit('contactPersonDeleted', id);
    },
    ccpPhoneAdded(object) {
      this.$emit('ccpPhoneAdded', object);
    },
    ccpPhoneUpdated(object) {
      this.$emit('ccpPhoneUpdated', object);
    },
    ccpPhoneDeleted(object) {
      this.$emit('ccpPhoneDeleted', object);
    },
    ccpEmailAdded(object) {
      this.$emit('ccpEmailAdded', object);
    },
    ccpEmailUpdated(object) {
      this.$emit('ccpEmailUpdated', object);
    },
    ccpEmailDeleted(object) {
      this.$emit('ccpEmailDeleted', object);
    },
  },
};
</script>
