<template>
  <div>
    <div id="wrapper"
      @click="goTo({ name: 'Customer', params: { id: customer.id }})"
      class="mb-2 py-0.5 pl-0.5 flex flex-wrap lg:items-stretch border border-gray-200 dark:border-gray-700 dark:hover:border-gray-600 rounded shadow hover:shadow-inner cursor-pointer"
    >
      <div id="customer-name" class="flex items-center w-full sm:w-1/2 2xl:w-1/4 px-2 xl:pl-1 xl:pr-2">
        <FontAwesomeIcon
          :icon="customer.is_company === '1' || customer.is_company === 1 ? ['far', 'building'] : ['far', 'user']"
          :title="customer.is_company === '1' || customer.is_company === 1 ? 'firma' : 'prywatny'"
          size="sm"
          class="mr-1"
        />
        <span @click.stop>
          <router-link :to="{ name: 'Customer', params: { id: customer.id }}" class="tw--link">{{ customer.name }}</router-link>
        </span>
      </div>
      <div id="customer-identification-no" class="flex items-center w-full sm:w-1/2 sm:justify-end 2xl:w-1/4 2xl:justify-start px-2 xl:pl-1 xl:pr-2">
        <div>
          <span
            v-html="customer.is_company === '1' || customer.is_company === 1 ? 'regon' : 'pesel'"
            class="inline-block w-12 text-muted"
          ></span>
          <span class="font-mono font-normal text-bright">{{ customer.pesel_regon }}</span>
        </div>
      </div>
      <div id="customer-address" class="flex items-center w-full xl:w-1/2 2xl:w-1/4 px-2 xl:pl-1 xl:pr-2">
        <div v-if="customer.address || customer.addr_description" class="flex flex-wrap items-center">
          <a @click.stop :href="mapsUrl" target="_blank" class="mr-2">
            <FontAwesomeIcon icon="map-marked-alt" class="tw--link" />
          </a>
          <div class="mr-2"><em>{{ customer.addr_description }}</em></div>
          <div class="font-semibold">{{ customer.address }}</div>
        </div>
        <div v-else class="flex items-center text-muted text-sm ml-7"><em>nie podano adresu</em></div>
      </div>
      <div id="customer-contact" class="flex flex-wrap items-center justify-between w-full xl:w-1/2 2xl:w-1/4 px-2 xl:pl-1 xl:pr-2">
        <div>
          <FontAwesomeIcon icon="at" class="text-muted mr-1" />&nbsp;
          <a @click.stop v-if="customer.email" :href="`mailto:${customer.email}`" class="tw--link lowercase">{{ customer.email }}</a>
          <span v-else class="text-muted"><em>nie podano</em></span>
        </div>
        <div>
          <FontAwesomeIcon icon="phone-alt" class="text-muted mr-1" />&nbsp;
          <a @click.stop v-if="customer.phone" :href="`tel:${customer.phone}`" class="tw--link">{{ customer.phone | phoneNo }}</a>
          <span v-else class="text-muted"><em>nie podano</em></span>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
export default {
 name: 'CustomerListItem',
 props: {
    customer: {
      type: Object,
      required: true,
    },
  },
  computed: {
    mapsUrl() {
      let url = 'https://maps.google.com/?q=';
      if (this.customer.addr_description) {
        url = `${url}${this.customer.addr_description}`;
      }
      if (this.customer.addr_postal_code) {
        url = `${url}+${this.customer.addr_postal_code}`;
      }
      if (this.customer.address) {
        url = `${url}+${this.customer.address}`;
      }
      return url;
    },
  },
  methods: {
    goTo(route) {
      const selection = window.getSelection().toString();
      if (selection.length === 0) {
        this.$router.push(route);
      }
    },
  },
};
</script>
