import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './filters';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome';
import HelpContainer from './components/HelpContainer.vue';
import BaseAutocompleteInput from './components/BaseAutocompleteInput.vue';
import BaseInputWithAddon from './components/BaseInputWithAddon.vue';
import BaseSelectWithAddon from './components/BaseSelectWithAddon.vue';
import BaseTextareaWithAddon from './components/BaseTextareaWithAddon.vue';
import BaseMultiselectWithAddon from './components/BaseMultiselectWithAddon.vue';
import BaseFilterSelectWithAddon from './components/BaseFilterSelectWithAddon.vue';
import BaseModal from './components/BaseModal.vue';
import BaseCard from './components/BaseCard.vue';
import CardMenu from './components/CardMenu.vue';
import SubmenuWrapper from './components/SubmenuWrapper.vue';
import DivLoadingSpinner from './components/DivLoadingSpinner.vue';

import {
  faUserSecret, faSpinner, faTimes, faSignOutAlt, faBars, faMousePointer, faFileSignature, faUsers, faUserTie, faChartBar, faChartLine, faCoins, faLink, faTools, faMoneyBill,
  faStepForward, faBirthdayCake, faChevronLeft, faChevronRight, faSort, faSortAmountDown, faSortAmountDownAlt, faCarSide, faHome, faLuggageCart, faHeartbeat, faSeedling, faWarehouse,
  faTractor, faLongArrowAltRight, faGlobeAmericas, faCar, faForward, faFilter, faDatabase, faSyncAlt, faGreaterThanEqual, faLessThanEqual, faHistory, faListOl, faCircleNotch, faMapMarkedAlt,
  faAt, faPhoneAlt, faUserSlash, faUserTimes, faUserLock, faUserClock, faUserCheck, faBriefcase, faSearch, faUserPlus, faPlus, faExclamationTriangle, faEllipsisH, faPrint, faCloudUploadAlt,
  faCloudDownloadAlt, faDownload, faFileDownload, faExchangeAlt, faAngleUp, faAngleDown, faCameraRetro, faCalculator, faPhoneVolume, faBan, faUndo, faExternalLinkAlt, faClipboardCheck,
  faHandHoldingUsd, faThumbsUp, faThumbsDown, faEquals, faNotEqual, faFileCsv, faUserFriends, faRedo, faPlaneDeparture,
} from '@fortawesome/free-solid-svg-icons';

import {
  faSquare, faCheckSquare, faMinusSquare, faUserCircle, faCalendarAlt, faBuilding, faUser, faClone, faFile, faFileAlt, faFilePdf, faEdit, faQuestionCircle, faTrashAlt, faPlusSquare,
  faKeyboard, faEnvelope, faEye, faEyeSlash, faFileExcel, faFileArchive, faFileWord, faClock,
} from '@fortawesome/free-regular-svg-icons';

library.add(
  faUserSecret, faEye, faEyeSlash, faSquare, faCheckSquare, faMinusSquare, faSpinner, faTimes, faUserCircle, faSignOutAlt, faBars, faCalendarAlt, faMousePointer, faFileSignature, faUsers,
  faUserTie, faChartBar, faChartLine, faCoins, faLink, faTools, faMoneyBill, faStepForward, faBirthdayCake, faChevronLeft, faChevronRight, faSort, faSortAmountDown, faSortAmountDownAlt,
  faBuilding, faUser, faClone, faCarSide, faHome, faLuggageCart, faHeartbeat, faSeedling, faFile, faFileAlt, faFilePdf, faWarehouse, faTractor, faLongArrowAltRight, faGlobeAmericas, faCar,
  faForward, faEdit, faQuestionCircle, faFilter, faDatabase, faSyncAlt, faGreaterThanEqual, faLessThanEqual, faHistory, faListOl, faCircleNotch, faMapMarkedAlt, faAt, faPhoneAlt, faUserSlash,
  faUserTimes, faUserLock, faUserClock, faUserCheck, faBriefcase, faSearch, faUserPlus, faPlus, faExclamationTriangle, faEllipsisH, faPrint, faTrashAlt, faCloudUploadAlt, faCloudDownloadAlt,
  faDownload, faFileDownload, faExchangeAlt, faAngleUp, faAngleDown, faPlusSquare, faKeyboard, faCameraRetro, faCalculator, faPhoneVolume, faBan, faUndo, faExternalLinkAlt, faClipboardCheck,
  faEnvelope, faHandHoldingUsd, faThumbsUp, faThumbsDown, faEye, faEyeSlash, faEquals, faNotEqual, faFileCsv, faFileExcel, faFileArchive, faFileWord, faUserFriends, faRedo, faPlaneDeparture,
  faClock,
);

Vue.directive('focus', {
  inserted(el, binding) {
    if (binding.value !== false) {
      el.focus();
    }
  },
});

Vue.component('FontAwesomeIcon', FontAwesomeIcon);
Vue.component('FontAwesomeLayers', FontAwesomeLayers);
Vue.component('HelpContainer', HelpContainer);
Vue.component('BaseAutocompleteInput', BaseAutocompleteInput);
Vue.component('BaseInputWithAddon', BaseInputWithAddon);
Vue.component('BaseSelectWithAddon', BaseSelectWithAddon);
Vue.component('BaseTextareaWithAddon', BaseTextareaWithAddon);
Vue.component('BaseMultiselectWithAddon', BaseMultiselectWithAddon);
Vue.component('BaseFilterSelectWithAddon', BaseFilterSelectWithAddon);
Vue.component('BaseModal', BaseModal);
Vue.component('BaseCard', BaseCard);
Vue.component('CardMenu', CardMenu);
Vue.component('SubmenuWrapper', SubmenuWrapper);
Vue.component('DivLoadingSpinner', DivLoadingSpinner);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  created() {
    const darkMode = localStorage.getItem('darkMode');
    if (darkMode) {
      this.$store.dispatch('setDarkMode', darkMode === 'true');
    }
  },
  render: h => h(App),
}).$mount('#app');
