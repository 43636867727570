<template>
  <BaseModal
    @closeModal="$emit('close')"
    :cancel="true"
    :ok="true"
    :busy="saving"
    okLabel="zapisz"
    @ok="createOrGetCustomerId"
  >
    <template #header>
      Zmiana klienta
    </template>
    <template #default>
      <div class="flex justify-between pb-2 mb-2 border-b dark:border-gray-600">
        <button class="tw--btn tw--btn-secondary" @click="clearCustomer">Wyczyść formularz</button>
        <div class="flex w-1/2 items-center">
          <HelpContainer>
            <strong>Wyszukiwanie klientów po:</strong>
            <p>nazwisko, nazwa firmy, pesel, regon, nip. Jeśli klient istnieje w bazie ale agent nie posiada ofert powiązanych, należy numer pesel/regon/NIP wpisać w całości.</p>
          </HelpContainer>
          <FindCustomer class="flex-grow" @selected="getCustomer" />
        </div>
      </div>
      <CustomerForm :customer="customer" @selected="getCustomer" @formChanged="setCustomerData" :validationErrors="{ errors: saveErrors, warnings: {} }" :clear="clearForm" />
    </template>
  </BaseModal>
</template>

<script>
import { errorHandler } from '../../mixins/errorHandler';
import CustomerService from '../../services/CustomerService';
import FindCustomer from './FindCustomer.vue';
import CustomerForm from '../customer/CustomerForm.vue';

export default {
  name: 'OrderChangeCustomer',
  components: { FindCustomer, CustomerForm },
  mixins: [errorHandler],
  data() {
    return {
      customer: {},
      clearForm: false,
      saveErrors: {},
      saving: false,
    };
  },
  methods: {
    getCustomer(customer) {
      const { pesel, paszport, regon } = customer;
      CustomerService.getCustomerToAddOrder(customer.id, {pesel, paszport, regon})
        .then(response => {
          this.customer = response.data.result;
          this.saveErrors = {};
        })
        .catch(error => this.resolveError(error)); //mixin
    },
    setCustomerData(customerForm) {
      if (this.customer.id === undefined) {
        Object.assign(this.customer, customerForm);
      } else {
        this.saveErrors = {};
      }
    },
    createOrGetCustomerId() {
      if (this.customer.id !== undefined) {
        this.$emit('newCustomerId', this.customer.id);
      } else {
        this.saving = true;
        CustomerService.validateNewCustomerForm(this.customer)
          .then(response => {
            if (response.data.state === 'ok') {
              this.saveErrors = {};
              CustomerService.storeNewCustomer(this.customer)
                .then((response) => this.$emit('newCustomerId', response.data.newCustomerId))
                .catch(error => {
                  this.saveErrors = this.resolveError(error);
                  this.saving = false;
                });
            } else {
              this.saveErrors = response.data.error_warnings.length !== 0 ? response.data.error_warnings : {};
              const saveEmptyErrors = response.data.empty_warnings.length !== 0 ? response.data.empty_warnings : {};
              Object.assign(this.saveErrors, saveEmptyErrors);
              this.saving = false;
            }
          })
          .catch(error => {
            this.resolveError(error);
            this.saving = false;
          }); //mixin
      }
    },
    clearCustomer() {
      this.customer = {};
      this.clearForm = !this.clearForm;
      this.$nextTick(() => { this.clearForm = false; });
    },
  },
};
</script>
