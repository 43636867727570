<template>
  <div class="border dark:border-gray-600 border-gray-400 px-2 py-1 rounded shadow">
    <div class="flex justify-between items-center -mx-2 pb-1 px-2 border-b dark:border-gray-600 border-gray-400">
      <span class="uppercase text-muted text-xs font-semibold">Składki i progn. prowizja</span>
      <button v-if="addItemsInstallmentsAllow" @click="editItems = true" class="tw--btn-icon p-0" title="dodaj pozycję">
        <FontAwesomeIcon :icon="['far', 'plus-square']" size="lg" />
      </button>
    </div>

    <OrderItemsAddEdit
      v-if="editItems && addItemsInstallmentsAllow"
      @cancel="cancelEdit"
      @changed="itemsChanged"
      @newPolicyNo="newPolicyNo"
      :order="order"
      :items="items"
      :item="item"
      :action="action"
    />

    <div v-if="items.length === 0" class="w-full text-base italic text-muted">brak informacji o składkach</div>
    <div v-else class="flex flex-col gap-1">

      <div id="items-header"
        :class="currentUserType === 'owfca' ? 'flex' : 'hidden sm:flex lg:hidden xl:flex'"
        class="items-center gap-2 text-muted border-b-2 dark:border-gray-600 border-gray-400"
      >
        <div class="flex-grow">produkt</div>
        <div class="flex-shrink-0 w-24 text-right">składka</div>
        <div v-if="provisionCols === 1" class="w-2/12 text-right">prowizja</div>
        <div v-else-if="provisionCols > 1" class="flex-shrink-0 flex flex-col text-xs" :class="provisionCols > 2 ? 'w-6/12' : 'w-4/12'">
          <div class="border-b dark:border-gray-600 border-gray-400 text-center">prowizja</div>
          <div class="flex gap-1">
            <div v-if="currentUserType !== 'owfca'" class="flex-1 text-right">agent</div>
            <div v-if="showProvisionCopyw" class="flex-1 text-right">wystawiający</div>
            <div v-if="showProvisionOffice" class="flex-1 text-right">agencja</div>
          </div>
        </div>
        <div v-if="addItemsInstallmentsAllow" class="w-10"></div>
      </div>

      <div id="item-list" class="divide-y divide-dashed dark:divide-gray-600">
        <div v-for="item in items" :key="item.id"
          class="flex items-center py-1 text-sm text-bright"
          :class="{
            'flex-nowrap gap-2': currentUserType === 'owfca',
            'flex-wrap gap-0 sm:flex-nowrap sm:gap-2 lg:flex-wrap lg:gap-0 xl:flex-nowrap xl:gap-2': currentUserType !== 'owfca',
            'font-normal': items.length > 1}"
        >
          <div class="flex gap-2" :class="currentUserType === 'owfca' ? 'w-full sm:w-auto sm:flex-grow' : 'w-full flex-grow-0 sm:w-auto sm:flex-grow lg:w-1/2 lg:flex-grow-0 xl:flex-grow xl:w-auto'">
            <div :class="currentUserType === 'owfca' ? 'hidden' : 'block sm:hidden lg:block xl:hidden'" class="text-muted font-normal">produkt</div><div>{{ item.name }}</div>
          </div>
          <div class="flex-shrink-0 flex gap-2 justify-start sm:justify-end" :class="currentUserType === 'owfca' ? 'w-full sm:w-24' : 'w-full sm:w-24 lg:w-1/2 xl:w-24'">
            <div :class="currentUserType === 'owfca' ? 'hidden' : 'block sm:hidden lg:block xl:hidden'" class="text-muted font-normal">składka</div>
            <div class="text-left sm:text-right font-mono">{{ item.value | moneyPL }}</div>
          </div>
          <div v-if="provisionCols > 0"
            class="flex gap-x-1 flex-wrap sm:flex-nowrap lg:flex-wrap xl:flex-nowrap w-full lg:w-full"
            :class="{'sm:w-2/12 xl:w-2/12': provisionCols === 1, 'sm:w-4/12 xl:w-4/12': provisionCols === 2, 'sm:w-6/12 xl:w-6/12': provisionCols === 3}"
          >
            <div v-if="provisionCols > 1" class="block sm:hidden lg:block xl:hidden uppercase text-muted font-normal">prowizja:</div>
            <div v-if="currentUserType !== 'owfca'" class="flex gap-1 justify-start sm:justify-end lg:justify-start xl:justify-end flex-1 text-right">
              <span class="block sm:hidden lg:block xl:hidden whitespace-nowrap">
                <span  v-if="provisionCols === 1" class=" text-muted font-normal">prowizja</span>
                <FontAwesomeIcon v-else :icon="['fas', 'user-tie']" title="agent" class="text-purple-600 dark:text-purple-400" />
              </span>
              <span class="font-mono">{{ item.prov_agent | moneyPL }}</span>
            </div>
            <div v-if="showProvisionCopyw" class="flex gap-2 justify-start sm:justify-end lg:justify-start xl:justify-end flex-1 text-right">
              <span class="block sm:hidden lg:block xl:hidden text-muted whitespace-nowrap">
                <!-- wystawiający -->
                <FontAwesomeIcon :icon="['far', 'keyboard']" title="wystawiający" class="text-purple-600 dark:text-purple-400" />
              </span>
              <span class="font-mono">{{ item.prov_copyw | moneyPL }}</span>
            </div>
            <div v-if="showProvisionOffice" class="flex gap-0.5 justify-start sm:justify-end lg:justify-start xl:justify-end flex-1 text-right">
              <span class="block sm:hidden lg:block xl:hidden text-muted whitespace-nowrap">
                <!-- agencja -->
                <FontAwesomeIcon :icon="['far', 'building']" title="agencja" class="text-purple-600 dark:text-purple-400" />
              </span>
              <span class="font-mono">{{ item.prov_agencja | moneyPL }}</span>
            </div>
          </div>
          <div v-if="addItemsInstallmentsAllow"
            class="flex items-center gap-2"
            :class="currentUserType === 'owfca' ? 'w-10' : 'w-full justify-end order-first sm:w-10 sm:justify-between sm:order-last lg:w-full lg:justify-end lg:order-first xl:w-10 xl:justify-between xl:order-last'"
          >
            <button @click="editItem(item.id)" class="tw--btn-icon text-gray-400 dark:text-gray-500 p-0" title="edytuj"><FontAwesomeIcon :icon="['far', 'edit']" /></button>
            <button @click="delItem(item.id)" class="tw--btn-icon tw--btn-icon-danger text-gray-400 dark:text-gray-500 p-0" title="usuń"><FontAwesomeIcon :icon="['far', 'trash-alt']" /></button>
          </div>
        </div>
      </div>

      <div id="item-list-summary" v-if="items.length > 1"
        class="flex items-center text-sm text-bright border-t-2 dark:border-gray-600 border-gray-400"
        :class="{
          'flex-nowrap gap-2': currentUserType === 'owfca',
          'flex-wrap gap-0 sm:flex-nowrap sm:gap-2 lg:flex-wrap lg:gap-0 xl:flex-nowrap xl:gap-2': currentUserType !== 'owfca'
        }"
      >
        <div
          :class="{
            'flex-grow text-right': currentUserType === 'owfca',
            'w-1/2 flex-grow-0 text-left sm:w-auto sm:flex-grow sm:text-right lg:w-1/2 lg:flex-grow-0 lg:text-left xl:flex-grow xl:w-auto xl:text-right': currentUserType !== 'owfca',
          }"
        >suma</div>
        <div class="flex-shrink-0 flex gap-2 justify-end" :class="currentUserType === 'owfca' ? 'w-24' : 'w-1/2 sm:w-24 lg:w-1/2 xl:w-24'">
          <div :class="currentUserType === 'owfca' ? 'hidden' : 'block sm:hidden lg:block xl:hidden'" class="text-muted font-normal">składka</div><div class="text-right font-mono">{{ itemsSum.value | moneyPL }}</div>
        </div>

        <div v-if="provisionCols > 0"
          class="flex gap-x-1 flex-wrap sm:flex-nowrap lg:flex-wrap xl:flex-nowrap w-full lg:w-full"
          :class="{'sm:w-2/12 xl:w-2/12': provisionCols === 1, 'sm:w-4/12 xl:w-4/12': provisionCols === 2, 'sm:w-6/12 xl:w-6/12': provisionCols === 3}"
        >
          <div v-if="provisionCols > 1" class="block sm:hidden lg:block xl:hidden uppercase text-muted font-normal">prowizja:</div>
          <div v-if="currentUserType !== 'owfca'" class="flex gap-1 justify-start sm:justify-end lg:justify-start xl:justify-end flex-1 text-right">
            <span class="block sm:hidden lg:block xl:hidden whitespace-nowrap">
              <span  v-if="provisionCols === 1" class=" text-muted font-normal">prowizja</span>
              <FontAwesomeIcon v-else :icon="['fas', 'user-tie']" title="agent" class="text-purple-600 dark:text-purple-400" />
            </span>
            <span class="font-mono">{{ itemsSum.valueAgent | moneyPL }}</span>
          </div>
          <div v-if="showProvisionCopyw" class="flex gap-2 justify-start sm:justify-end lg:justify-start xl:justify-end flex-1 text-right">
            <span class="block sm:hidden lg:block xl:hidden text-muted whitespace-nowrap">
              <!-- wystawiający -->
              <FontAwesomeIcon :icon="['far', 'keyboard']" title="wystawiający" class="text-purple-600 dark:text-purple-400" />
            </span>
            <span class="font-mono">{{ itemsSum.valueCopyw | moneyPL }}</span>
          </div>
          <div v-if="showProvisionOffice" class="flex gap-0.5 justify-start sm:justify-end lg:justify-start xl:justify-end flex-1 text-right">
            <span class="block sm:hidden lg:block xl:hidden text-muted whitespace-nowrap">
              <!-- agencja -->
              <FontAwesomeIcon :icon="['far', 'building']" title="agencja" class="text-purple-600 dark:text-purple-400" />
            </span>
            <span class="font-mono">{{ itemsSum.valueBiuro | moneyPL }}</span>
          </div>
        </div>
        <div v-if="addItemsInstallmentsAllow" class="w-10" :class="currentUserType === 'owfca' ? 'block' : 'hidden sm:block lg:hidden xl:block'"></div>
      </div>

    </div>
  </div>
</template>

<script>
import OrderService from '../../services/OrderService';
import { confirm } from '../../mixins/confirm';
import { errorHandler } from '../../mixins/errorHandler';
import OrderItemsAddEdit from './OrderItemsAddEdit.vue';

export default {
  name: 'OrderFinancialDataItems',
  components: {
    OrderItemsAddEdit,
  },
  mixins: [confirm, errorHandler],
  props: {
    order: {
      type: Object,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    employee: {
      type: Object,
      required: true,
    },
    copywriter: {
      type: Object,
      required: true,
    },
    addItemsInstallmentsAllow: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      editItems: false,
      item: {},
      action: 'add',
    };
  },
  computed: {
    currentUserType() {
      return this.$store.getters.currentUserType;
    },
    currentUser() {
      return this.$store.getters.currentUserData;
    },
    showProvisionCopyw() {
      const userAllow = this.currentUserType === 'root' || this.currentUserType === 'admin' || Number(this.currentUser.empl_id) === Number(this.copywriter.id);
      const orderAllow = this.copywriter.id !== undefined && this.itemsSum.valueCopyw !== 0;
      return userAllow && orderAllow && this.currentUserType !== 'owfca';
    },
    showProvisionOffice() {
      return (this.currentUserType === 'root' || this.currentUserType === 'admin');
    },
    provisionCols() {
      let cols = 0;
      if (this.currentUserType !== 'owfca') cols += 1;
      if (this.showProvisionCopyw) cols += 1;
      if (this.showProvisionOffice) cols += 1;
      return cols;
    },
    itemsSum() {
      const sum = {
        value: 0,
        valueAgent: 0,
        valueCopyw: 0,
        valueBiuro: 0,
      };
      this.items.forEach((item) => {
        sum.value += Number(item.value);
        sum.valueAgent += Number(item.prov_agent);
        sum.valueCopyw += Number(item.prov_copyw);
        sum.valueBiuro += Number(item.prov_agencja);
      });
      return sum;
    },
  },
  methods: {
    editItem(id) {
      Object.assign(this.item, this.items.find(obj => obj.id === id));
      this.action = 'edit';
      this.editItems = true;
    },
    delItem(id) {
      this.confirm(() => this.confirmDelItem(id), 'Potwierdź usunięcie pozycji składki');
    },
    confirmDelItem(id) {
      OrderService.delOrderItem(id)
        .then(() => {
          this.$store.dispatch('notifications/add', { type: 'success', message: 'Usunięto pozycję' });
          this.$emit('itemDeleted', id);
        })
        .catch((error) => this.resolveError(error)); // mixin
    },
    itemsChanged(items) {
      this.$emit('itemsChanged', items);
      this.cancelEdit();
    },
    newPolicyNo(no) {
      this.$emit('newPolicyNo', no);
    },
    cancelEdit() {
      this.item = {};
      this.action = 'add';
      this.editItems = false;
    },
  },
};
</script>
