<template>
  <div class="relative" ref="helpContainer">
    <a href="#" @click="isOpen = true" title="kliknij, aby wyświetlić pomoc">
      <FontAwesomeIcon :icon="['far', 'question-circle']" class="text-blue-500 dark:text-blue-700 mx-1" :size="iconSize" />
    </a>
    <div
      v-show="isOpen"
      class="absolute border-2 border-yellow-200 dark:border-yellow-800 bg-white dark:bg-black py-2 px-3 rounded shadow-xl top-0.5 w-72 opacity-95 z-40"
      :class="{'right-0.5': direction === 'left', 'left-0.5': direction === 'right'}"
    >
      <button
        @click="isOpen = false"
        class="absolute -top-0.5 tw--link"
        :class="direction === 'left' ? 'right-0.5' : 'left-0.5'"
      ><FontAwesomeIcon icon="times" /></button>
      <div class="text-xs mt-2">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelpContainer',
  props: {
    iconSize: {
      type: String,
      default: 'lg',
    },
    direction: {
      type: String,
      default: 'left',
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  mounted() {
    document.addEventListener('click', this.documentClick);
  },
  destroyed() {
    document.removeEventListener('click', this.documentClick);
  },
  methods: {
    documentClick(e) {
      const el = this.$refs.helpContainer;
      if (el !== e.target && !el.contains(e.target)) {
        this.isOpen = false;
      }
    },
  },
};
</script>
