<template>
  <div>
    <BaseCard>
      <div id="order-basic-filters" class="flex flex-wrap gap-2 items-center justify-between">

        <!-- Search Button with addons -->
        <div class="flex flex-none items-center order-1">
          <input
            id="filterInput"
            @keyup.enter="executeAllFilters"
            class="border-gray-400 dark:border-gray-600 rounded-r-none"
            :class="{'bg-yellow-100 dark:bg-yellow-800': activeFilters.search.length > 0}"
            v-model="activeFilters.search"
            type="text"
            placeholder="wpisz aby wyszukać..."
            :disabled="activeFilters.todo !== ''"
          >
          <button
            @click="resetSearch"
            class="tw--btn tw--btn-secondary rounded-none border-t border-b border-gray-400 dark:border-gray-600"
            type="button"
            :disabled="activeFilters.search === '' || activeFilters.todo !== ''"
            title="wyczyść"
          ><FontAwesomeIcon icon="times"/></button>
          <button
            @click="executeAllFilters"
            class="tw--btn border border-blue-600 dark:border-blue-900 rounded-l-none"
            type="button"
            :disabled="activeFilters.search === '' || activeFilters.todo !== ''"
          >
            Szukaj
          </button>
          <HelpContainer>
            <strong>Wpisz fragment lub całość, aby szukać:</strong>
            <ul>
              <li>nazwisko</li>
              <li>nazwa firmy</li>
              <li>pesel</li>
              <li>regon</li>
              <li>nip</li>
              <li>numer polisy</li>
              <li>nr. rejestracyjny pojazdu (tylko w całości)</li>
            </ul>
          </HelpContainer>
        </div>
        <!-- End Search Button -->

        <div class="flex flex-col sm:flex-row gap-2 order-3 w-full lg:w-auto lg:order-2">
          <button @click="setFilter('todo', '1')" class="tw--btn tw--btn-filter text-gray-700 shadow" :class="{'active': activeFilters.todo === '1'}"
          >W trakcie - Agent</button>
          <button @click="setFilter('todo', '2')" class="tw--btn tw--btn-filter text-gray-700 shadow" :class="{'active': activeFilters.todo === '2'}"
          >W trakcie - Biuro</button>
          <button @click="setRenewal()" class="tw--btn tw--btn-filter text-gray-700 shadow" :class="{'active': showRenewal}"
          >Wznowienia</button>
        </div>

        <div class="w-full flex-shrink-0 sm:w-auto sm:flex-shrink justify-end flex order-2 lg:order-3 gap-0.5">
          <button @click="resetFilters()" class="tw--btn tw--btn-filter shadow" title="wyczyść wszystkie filtry">
            <FontAwesomeIcon icon="times" size="xs" /> <FontAwesomeIcon icon="filter" />
          </button>
          <button @click="initList()" class="tw--btn tw--btn-filter shadow" title="odśwież dane z serwera">
            <FontAwesomeIcon icon="sync-alt" size="xs" /> <FontAwesomeIcon icon="database" />
          </button>
        </div>
      </div>

      <hr class="2xl:hidden dark:border-gray-600 border-gray-400">
      <div id="order-adv-filters-1" class="flex flex-col md:flex-row md:flex-wrap items-start gap-x-4 gap-y-2">
        <BaseFilterSelectWithAddon class="w-full md:min-w-max flex-1" v-model="activeFilters.tu" label="TU" :options="insurerList" :disabled="activeFilters.todo !== ''" />
        <BaseFilterSelectWithAddon class="w-full md:min-w-max flex-1" v-model="activeFilters.type" label="Rodzaj" :options="orderTypeList" :disabled="activeFilters.todo !== ''" />
        <!-- <BaseFilterSelectWithAddon class="w-full md:min-w-max flex-1" v-model="activeFilters.employee" label="Agent" :options="employeeSimpleList" :disabled="activeFilters.todo !== ''" /> -->
        <div class="w-full md:min-w-max flex-1 flex">
          <BaseFilterSelectWithAddon
            class="flex-grow"
            v-model="activeFilters.employee"
            label="Agent"
            :options="employeeListToSelect"
            :disabled="activeFilters.todo !== ''"
          />
          <label for="include-not-active" class="flex items-center gap-1 border dark:border-gray-600 border-gray-400 border-l-0 pl-1 pr-0.5" title="pokaż również nieaktywnych pracowników">
            <input
              id="include-not-active"
              type="checkbox"
              v-model="includeNotActiveEmployees"
            > <FontAwesomeIcon icon="user-slash" />
          </label>
        </div>
        <BaseMultiselectWithAddon
          class="w-full md:min-w-max flex-1"
          @input="addStatusFilter"
          @remove="removeStatusFilter"
          v-model="selectedStatus"
          label="Status"
          optionZero="Wybierz status(y)"
          :options="notSelectedStatuses"
          :selected="selectedStatuses"
          :disabled="activeFilters.todo !== ''"
        />
      </div>
      <div class="-my-1">
        <button @click.prevent="showAdvancedFiltersTrigger = !showAdvancedFiltersTrigger" class="tw--link px-1 font-normal text-sm italic" :disabled="advFiltersDirty || activeFilters.todo !== ''">
          {{ openAdvFilters ? 'ukryj' : 'wyświetl' }} filtry zaawansowane
        </button>
      </div>
      <div v-show="openAdvFilters" id="order-adv-filters-2" class="flex flex-wrap 2xl:flex-nowrap items-start gap-4">
        <div class="flex flex-1 gap-1 flex-col">
          <BaseInputWithAddon @enter="executeAllFilters" type="date" v-model="activeFilters.sale_date_gt" labelAddon="Wystawiona" labelIconAddon="greater-than-equal" :markDirty="true" :disabled="activeFilters.todo !== ''" />
          <BaseInputWithAddon @enter="executeAllFilters" type="date" v-model="activeFilters.sale_date_lt" labelAddon="Wystawiona" labelIconAddon="less-than-equal" :markDirty="true" :disabled="activeFilters.todo !== ''" />
        </div>
        <div class="flex flex-1 gap-1 flex-col">
          <BaseInputWithAddon @enter="executeAllFilters" type="date" v-model="activeFilters.date_from_gt" labelAddon="Start ochr." labelIconAddon="greater-than-equal" :markDirty="true" :disabled="activeFilters.todo !== ''" />
          <BaseInputWithAddon @enter="executeAllFilters" type="date" v-model="activeFilters.date_from_lt" labelAddon="Start ochr." labelIconAddon="less-than-equal" :markDirty="true" :disabled="activeFilters.todo !== ''" />
        </div>
        <div class="flex flex-1 gap-1 flex-col">
          <BaseInputWithAddon @enter="executeAllFilters" type="date" v-model="activeFilters.date_to_gt" labelAddon="Koniec ochr." labelIconAddon="greater-than-equal" :markDirty="true" :disabled="activeFilters.todo !== ''" />
          <BaseInputWithAddon @enter="executeAllFilters" type="date" v-model="activeFilters.date_to_lt" labelAddon="Koniec ochr." labelIconAddon="less-than-equal" :markDirty="true" :disabled="activeFilters.todo !== ''" />
        </div>
      </div>
      <div v-show="openAdvFilters" id="order-adv-filters-3" class="flex flex-col md:flex-row md:flex-wrap items-start gap-x-4 gap-y-2">
        <BaseFilterSelectWithAddon class="w-full md:min-w-max flex-1" v-model="activeFilters.made_by" label="W / B" :options="[{id: 0, name: 'oferta własna'}, {id: 1, name: 'oferta biura'}]" :disabled="activeFilters.todo !== ''" />
        <!-- <BaseFilterSelectWithAddon v-if="showCopywriterFilter" class="w-full md:min-w-max flex-1" v-model="activeFilters.office" label="Oferta" :options="copywriterSimpleList" :disabled="activeFilters.todo !== ''" /> -->
        <div v-if="showCopywriterFilter" class="w-full md:min-w-max flex-1 flex">
          <BaseFilterSelectWithAddon
            class="flex-grow"
            v-model="activeFilters.office"
            label="Oferta"
            :options="copywriterSimpleList"
            :disabled="activeFilters.todo !== ''"
          />
          <label for="include-not-active" class="flex items-center gap-1 border dark:border-gray-600 border-gray-400 border-l-0 pl-1 pr-0.5" title="pokaż również nieaktywnych pracowników">
            <input
              id="include-not-active"
              type="checkbox"
              v-model="includeNotActiveEmployees"
            > <FontAwesomeIcon icon="user-slash" />
          </label>
        </div>
        <BaseInputWithAddon @enter="executeAllFilters" class="flex-1" type="text" v-model="activeFilters.order_id" labelAddon="Id. oferty" :markDirty="true" :disabled="activeFilters.todo !== ''" />
        <div class="w-full 2xl:w-auto flex flex-wrap sm:flex-nowrap items-center gap-x-3 py-1">
          <label for="leasing" title="filtruj wyłącznie polisy leasingowe">
            <input
              id="leasing"
              type="checkbox"
              true-value="1" false-value=""
              v-model="activeFilters.is_leasing"
              :disabled="activeFilters.todo !== ''"
            > Leasing
          </label>
          <label for="priority" title="filtruj wyłącznie polisy pilne">
            <input
              id="priority"
              type="checkbox"
              true-value="1"
              false-value=""
              v-model="activeFilters.priority"
              :disabled="activeFilters.todo !== ''"
            > Pilne!
          </label>
          <label v-if="userIsAdmin" for="prov-to-set" title="filtruj wyłącznie polisy z wiszącymi niezaksięgowanymi prowizjami">
            <input
              id="prov-to-set"
              type="checkbox"
              true-value="1"
              false-value=""
              v-model="activeFilters.prov_to_set"
              :disabled="activeFilters.todo !== ''"
            > zaw. prowizje
          </label>
        </div>
      </div>
      <div class="flex flex-col sm:flex-row gap-x-4 gap-y-2 justify-between">
        <button @click="executeAllFilters" class="tw--btn w-32">Filtruj</button>
        <div class="order-first sm:order-last flex rounded-sm items-center text-xs">
          <a :href="getOrdersToCsv()"
            target="_blank"
            title="eksport wybranych polis do pliku csv"
            class="tw--link px-2 italic"
          >
            eksport <FontAwesomeIcon :icon="['far', 'file-excel']" size="lg" />
          </a>
          <div class="px-2 py-1 rounded-l-sm bg-gray-800 text-gray-200 dark:bg-black dark:text-gray-400 font-semibold">Znaleziono</div>
          <div class="px-2 py-1 rounded-r-sm bg-blue-300 text-gray-700 dark:bg-blue-900 dark:text-gray-400 font-semibold">{{ pagiAllItems }} pozycji</div>
        </div>
      </div>
    </BaseCard>

    <BaseCard>
      <div class="flex flex-wrap justify-between">
        <router-link :to="{name: 'OrderAdd'}" class="tw--btn inline-block mb-2">
          <i class="fas fa-user-plus"></i>&nbsp;Dodaj ofertę / polisę
        </router-link>

        <SortList v-if="sortedBy.field !== ''" @sort="sortItemsBy" :fields="sortFields" :selected="sortedBy" />

        <Pagination
          :current="pagiCurrentPage"
          :total="pagiTotalPages"
          @page="goToPage"
          @next="goToPage(pagiCurrentPage+1)"
          @prev="goToPage(pagiCurrentPage-1)"
          v-model="rowsOnPage"
          @perPageChanged="executeAllFilters"
        />
      </div>
      <section>
        <ol class="flex flex-col gap-y-2 m-0 p-0">
          <OrderListItemAsTable v-for="order in orders" :order="order" :showRenewal="showRenewal" :key="order.id" />
        </ol>
      </section>
      <!-- <div v-if="false" class="flex flex-col">
        <OrderListItem v-for="order in orders" :order="order" :showRenewal="showRenewal" :key="order.id" />
      </div> -->
    </BaseCard>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapState } from 'vuex';
import { errorHandler } from '../mixins/errorHandler';
import OrderService from '../services/OrderService';
// import OrderListItem from '../components/order/OrderListItem.vue';
import OrderListItemAsTable from '../components/order/OrderListItemAsTable.vue';
import Pagination from '../components/Pagination.vue';
import SortList from '../components/SortList.vue';

export default {
  name: 'OrderList',
  components: {
    // OrderListItem,
    OrderListItemAsTable,
    Pagination,
    SortList,
  },
  mixins: [errorHandler],
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL,
      orders: [],
      sortedBy: {
        field: '',
        dir: 1,
      },
      activeFilters: {
        search: '',
        todo: '',
        renewal: '',
        tu: '',
        type: '',
        employee: '',
        sale_date_gt: '',
        sale_date_lt: '',
        date_from_gt: '',
        date_from_lt: '',
        date_to_gt: '',
        date_to_lt: '',
        made_by: '',
        office: '',
        order_id: '',
        is_leasing: '',
        priority: '',
        prov_to_set: '',
      },
      activeMultiFilters: {
        statuses: [],
      },
      selectedStatus: '',
      includeNotActiveEmployees: false,
      basicFiltersArr: ['tu', 'type', 'employee', 'statuses'],
      advFiltersArr: ['sale_date_gt', 'sale_date_lt', 'date_from_gt', 'date_from_lt', 'date_to_gt', 'date_to_lt', 'made_by', 'office', 'order_id', 'is_leasing', 'priority', 'prov_to_set'],
      basicFilters: false,
      extFilters: false,
      pagiCurrentPage: 1,
      rowsOnPage: 20,
      pagiAllItems: 0,
      sortFields: [
        { id: 'employee', name: 'agent' },
        { id: 'customer', name: 'klient' },
        { id: 'product_type', name: 'rodzaj polisy' },
        { id: 'tu', name: 'tow. ubezp.' },
        { id: 'policy_no', name: 'nr polisy' },
        { id: 'sale_date', name: 'data wystawienia' },
        { id: 'date_from', name: 'start ochrony' },
        { id: 'date_to', name: 'koniec ochrony' },
        { id: 'status', name: 'status' },
        { id: 'last_modification', name: 'data ost. mod.', defaultDir: -1 },
      ],
      showAdvancedFiltersTrigger: false,
      renewOrderOpen: false,
      orderIdToRenew: '',
    };
  },
  computed: {
    ...mapGetters([
      'maxRowsOnPage',
      'currentUserType',
    ]),
    ...mapGetters('orders', [
      'orderTypeList',
      'orderStatusList',
    ]),
    ...mapGetters('products', [
      'insurerList',
    ]),
    ...mapState('employees', [
      'employeeSimpleList',
    ]),
    copywriterSimpleList() {
      return [{id: '-1', fullname: '-nieprzypisany-'}].concat(this.employeeListToSelect);
    },
    userIsAdmin() {
      return this.currentUserType === 'root' || this.currentUserType === 'admin';
    },
    showCopywriterFilter() {
      return this.userIsAdmin || this.currentUserType === 'pracownik_b' || this.currentUserType === 'asystent';
    },
    pagiTotalPages() {
      return Math.ceil(this.pagiAllItems / this.rowsOnPage);
    },
    // ustalają status otwartej sekcji filtrów podstawowych na podstawie statusu klikniętego przycisku rozwijania filtrów + aktywnego jednego z filtrów + statusu filtra TODO
    openBasicFilters() {
      let bf = false;
      this.basicFiltersArr.forEach((value) => {
        if (this.activeFilters[value] !== undefined && this.activeFilters[value] !== '') bf = true;
        if (this.activeMultiFilters[value] !== undefined && this.activeMultiFilters[value].length > 0) bf = true;
      });
      return this.activeFilters.todo === '' && (this.basicFilters || bf);
    },
    // ustalają status otwartej sekcji filtrów zaawansowanych na podstawie statusu klikniętego przycisku rozwijania filtrów + aktywnego jednego z filtrów + statusu filtra TODO
    advFiltersDirty() {
      let bf = false;
      this.advFiltersArr.forEach((value) => {
        if (this.activeFilters[value] !== '') bf = true;
      });
      return bf;
    },
    openAdvFilters() {
      return this.activeFilters.todo === '' && (this.showAdvancedFiltersTrigger || this.advFiltersDirty);
    },
    selectedStatuses() {
      return this.orderStatusList.filter(x => this.activeMultiFilters.statuses.includes(x.id));
    },
    notSelectedStatuses() {
      return this.orderStatusList.filter(x => !this.activeMultiFilters.statuses.includes(x.id));
    },
    showRenewal() {
      return this.activeFilters.renewal === '1';
    },
    employeeListToSelect() {
      return this.employeeSimpleList.filter(obj => Number(obj.status_id) === 1 || this.includeNotActiveEmployees || Number(obj.id) === Number(this.activeFilters.employee) || Number(obj.id) === Number(this.activeFilters.office));
    },
  },
  mounted() {
    this.$store.dispatch('employees/getEmployeeSipleList').catch(error => this.resolveError(error));
    if (this.orderTypeList.length === 0) this.$store.dispatch('orders/getOrderTypeList').catch(error => this.resolveError(error));
    if (this.orderStatusList.length === 0) this.$store.dispatch('orders/getOrderStatusList').catch(error => this.resolveError(error));
    if (this.insurerList.length === 0) this.$store.dispatch('products/getInsurerList').catch(error => this.resolveError(error));
    this.initList();
  },
  methods: {
    initList() {
      // inicjacyjne ustawienie pola i kierunku sortowania
      if (this.$route.query.sf !== undefined && this.sortFields.find(obj => obj.id === this.$route.query.sf) !== undefined) {
        this.sortedBy.field = this.$route.query.sf;
        if (this.$route.query.sd !== undefined) {
          this.sortedBy.dir = Number(this.$route.query.sd) === -1 ? -1 : 1;
        } else {
          this.sortedBy.dir = 1;
        }
      } else {
        this.sortedBy.field = 'last_modification';
        this.sortedBy.dir = -1;
      }
      // Inicjowanie Paginacji - weryfikacja poprawności w URL i zainicjowanie zmiennych
      if (this.$route.query.p !== undefined && Number.isInteger(Number(this.$route.query.p)) && Number(this.$route.query.p) > 0) {
        this.pagiCurrentPage = Number(this.$route.query.p);
      }
      if (this.$route.query.rop !== undefined && Number.isInteger(Number(this.$route.query.rop)) && Number(this.$route.query.rop) > 0 && Number(this.$route.query.rop) <= this.maxRowsOnPage) {
        this.rowsOnPage = Number(this.$route.query.rop);
      }
      // Filtry
      // Filtr TODO powoduje pominięcie inicjowania pozostałych filtrów z URL
      if (this.$route.query.todo !== undefined && this.$route.query.todo !== '') {
        this.activeFilters.todo = decodeURI(this.$route.query.todo);
      } else {
        // Inicjacja wartości filtrów
        Object.keys(this.activeFilters).forEach((key) => {
          if (this.$route.query[key] !== undefined && this.$route.query[key] !== '') {
            this.activeFilters[key] = decodeURI(this.$route.query[key]);
          }
        });
        // Inicjacja wartości filtrów tablicowych
        Object.keys(this.activeMultiFilters).forEach((key) => {
          if (this.$route.query[key] !== undefined && this.$route.query[key].length > 0) {
            if (Array.isArray(this.$route.query[key])) {
              this.activeMultiFilters[key] = this.$route.query[key];
            } else {
              this.activeMultiFilters[key].push(this.$route.query[key]);
            }
          }
        });
      }
      this.getOrders();
    },
    getOrders() {
      this.$store.dispatch('setLoadingData', true);
      const params = {
        sf: this.sortedBy.field, sd: this.sortedBy.dir, p: this.pagiCurrentPage, rop: this.rowsOnPage,
      };
      Object.assign(params, this.activeFilters);
      Object.assign(params, this.activeMultiFilters);
      OrderService.getOrders(params)
        .then((response) => {
          this.orders = response.data.orders;
          this.pagiAllItems = Number(response.data.count_all_results);
          this.pagiCurrentPage = Number(response.data.curr_page);
          this.rowsOnPage = Number(response.data.rows_on_page);
          this.setUrl();
          this.$store.dispatch('setLoadingData', false);
        })
        .catch(error => {
          this.$store.dispatch('setLoadingData', false);
          this.resolveError(error); // mixin
        });
    },
    getOrdersToCsv() {
      const params = {
        sf: this.sortedBy.field, sd: this.sortedBy.dir, p: this.pagiCurrentPage, rop: this.rowsOnPage,
      };
      Object.assign(params, this.activeFilters);
      Object.assign(params, this.activeMultiFilters);
      let query = '';
      Object.keys(params).forEach(key => {
        query = `${query}${key}=${encodeURI(params[key])}&`;
      });
      return `${this.apiUrl}/api/get-order-list-to-csv?${query}`;
    },
    sortItemsBy(object) {
      this.sortedBy.field = object.field;
      this.sortedBy.dir = object.dir;
      this.getOrders();
    },
    setFilter(filter, value) {
      if (filter === 'todo') {
        const prevState = this.activeFilters.todo;
        Object.keys(this.activeFilters).forEach((key) => {
          this.activeFilters[key] = '';
          this.basicFilters = false;
          this.showAdvancedFiltersTrigger = false;
        });
        Object.keys(this.activeMultiFilters).forEach((key) => {
          this.activeMultiFilters[key] = [];
        });
        if (value === '1') {
          this.activeFilters.todo = (prevState === '1') ? '' : '1';
        }
        if (value === '2') {
          this.activeFilters.todo = (prevState === '2') ? '' : '2';
        }
      }
      this.executeAllFilters();
    },
    executeAllFilters() {
      this.pagiCurrentPage = 1;
      this.getOrders();
    },
    addStatusFilter() {
      if (this.selectedStatus !== '') {
        const status = this.selectedStatus;
        this.selectedStatus = '';
        this.activeMultiFilters.statuses.push(status);
      }
    },
    removeStatusFilter(id) {
      this.activeMultiFilters.statuses = this.activeMultiFilters.statuses.filter(x => x !== id);
    },
    resetSearch() {
      this.activeFilters.search = '';
      this.executeAllFilters();
    },
    resetFilters() {
      Object.keys(this.activeFilters).forEach((key) => {
        this.activeFilters[key] = '';
      });
      Object.keys(this.activeMultiFilters).forEach((key) => {
        this.activeMultiFilters[key] = [];
      });
      this.executeAllFilters();
    },
    goToPage(pageNumber) {
      if (Number.isInteger(Number(pageNumber)) && Number(pageNumber) !== this.pagiCurrentPage) {
        if (pageNumber >= 1 && pageNumber <= this.pagiTotalPages) {
          this.pagiCurrentPage = Number(pageNumber);
        } else if (pageNumber < 1) {
          this.pagiCurrentPage = 1;
        } else {
          this.pagiCurrentPage = Number(this.pagiTotalPages);
        }
        this.getOrders();
      }
    },
    setUrl() {
      const query = {
        sf: this.sortedBy.field,
        sd: `${this.sortedBy.dir}`,
        p: `${this.pagiCurrentPage}`,
        rop: `${this.rowsOnPage}`,
        search: encodeURI(this.activeFilters.search),
        todo: this.activeFilters.todo,
      };
      const queryFilters = {};
      Object.keys(this.activeFilters).forEach((key) => {
        queryFilters[key] = encodeURI(this.activeFilters[key]);
      });
      Object.keys(this.activeMultiFilters).forEach((key) => {
        queryFilters[key] = this.activeMultiFilters[key];
      });
      Object.assign(query, queryFilters);
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router.replace({ name: 'Orders', query });
      }
    },
    setRenewal() {
      if (this.activeFilters.renewal === '') {
        this.activeFilters.renewal = '1';
        if (this.activeFilters.date_to_gt === '') {
          this.activeFilters.date_to_gt = moment().subtract(7, 'days').format('YYYY-MM-DD');
        }
        if (this.activeFilters.date_to_lt === '') {
          this.activeFilters.date_to_lt = moment().add(1, 'months').endOf('month').format('YYYY-MM-DD');
        }
        if (this.activeMultiFilters.statuses.length === 0) {
          this.activeMultiFilters.statuses = ['10', '15'];
        }
        this.activeFilters.todo = '';
        this.executeAllFilters();
      } else {
        this.activeFilters.renewal = '';
      }
    },
    renewOrder(id) {
      this.orderIdToRenew = id;
      this.renewOrderOpen = true;
    },
    cancelRenewOrder() {
      this.orderIdToRenew = '';
      this.renewOrderOpen = false;
    },
  },
};
</script>
