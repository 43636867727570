import axios from 'axios';

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  // withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json',
  },
});

export default {
  autocompleteSearch(url, searchString) {
    const result = new Promise((resolve, reject) => {
      apiClient
        .get(`/api/${url}`, { params: { search: searchString } })
        .then((response) =>
          response.data.status === "success"
            ? resolve(response)
            : // eslint-disable-next-line comma-dangle
              reject(response)
        )
        .catch((error) => reject(error));
    });
    return result;
  },
  getOrderTypeList() {
    const result = new Promise((resolve, reject) => {
      apiClient
        .get("/api/get-order-type-list")
        .then((response) => {
          if (response.data.status === "success") {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  },
  getOrderStatusList() {
    const result = new Promise((resolve, reject) => {
      apiClient
        .get("/api/get-order-status-list")
        .then((response) => {
          if (response.data.status === "success") {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  },
  getInsurerList() {
    const result = new Promise((resolve, reject) => {
      apiClient
        .get("/api/get-insurer-list")
        .then((response) =>
          response.data.status === "success"
            ? resolve(response)
            : // eslint-disable-next-line comma-dangle
              reject(response)
        )
        .catch((error) => reject(error));
    });
    return result;
  },
  getProductCategoryList() {
    const result = new Promise((resolve, reject) => {
      apiClient
        .get("/api/get-prod-cat-list")
        .then((response) => {
          if (response.data.status === "success") {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  },
  getCallendarEvents(query) {
    const result = new Promise((resolve, reject) => {
      apiClient
        .get("/api/get-calendar-events", {
          params: query,
        })
        .then((response) => {
          if (response.data.status === "success") {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  },
  createRodoApkSurvey(orderId, form) {
    return new Promise((resolve, reject) => {
      apiClient
        .post(`/api/create-rodo-apk-survey/${orderId}`, form)
        .then((response) => {
          if (response.data.status === "success") {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getRodoFile(orderId) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`/api/get-rodo-pdf-file-from-survey/${orderId}`)
        .then((response) => {
          if (response.data.status === "success") {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
