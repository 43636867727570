import axios from 'axios';

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  // withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json',
  },
});

export default {
  getProductList() {
    const result = new Promise((resolve, reject) => {
      apiClient.get('/api/get-product-list')
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  getProductsActiveAtDate(params) {
    const result = new Promise((resolve, reject) => {
      apiClient.get('/api/get-product-list-to-select', { params })
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  getProductListReadOnly() {
    const result = new Promise((resolve, reject) => {
      apiClient.get('/api/get-product-list-read-only')
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  addProduct(newProduct) {
    const result = new Promise((resolve, reject) => {
      apiClient.post('/api/add-product', newProduct)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  duplicateProduct(id, fields) {
    const result = new Promise((resolve, reject) => {
      apiClient.patch(`/api/duplicate-product/${id}`, fields)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  getAllRelatedProducts(productId) {
    const result = new Promise((resolve, reject) => {
      apiClient.get(`/api/get-all-related-products/${productId}`)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  updateProduct(id, fields) {
    const result = new Promise((resolve, reject) => {
      apiClient.patch(`/api/edit-product/${id}`, fields)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  addProvisionLevel() {
    const result = new Promise((resolve, reject) => {
      apiClient.patch('/api/add-provision-level')
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  delProvisionLevel(id) {
    const result = new Promise((resolve, reject) => {
      apiClient.delete(`/api/delete-provision-level/${id}`)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  updateProvisionLevelName(id, fields) {
    const result = new Promise((resolve, reject) => {
      apiClient.patch(`/api/update-provision-level-name/${id}`, fields)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  getProvLevlPS2() {
    const result = new Promise((resolve, reject) => {
      apiClient.get('/api/get-prov-lvls-ps2')
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  updateProvisionLevel(id, fields) {
    const result = new Promise((resolve, reject) => {
      apiClient.patch(`/api/update-provision-level/${id}`, fields)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
};