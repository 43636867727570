<template>
  <div id="customer-order-list" class="w-full p-3 bg-white dark:bg-gray-900 rounded-lg shadow-lg">
    <div class="flex items-center -mt-3 mb-0.5 -mx-3 px-3 py-1 border-b dark:border-gray-600">
      <div class="flex flex-grow items-center">
        <div class="flex gap-3 items-center  text-muted">
          <span class="uppercase font-semibold">polisy/oferty klienta</span>
        </div>
      </div>
      <div v-if="validating === 1">
        <button @click="addOrder" class="tw--btn-icon p-0" title="dodaj polisę/ofertę"><FontAwesomeIcon icon="plus" /><FontAwesomeIcon :icon="['far', 'file-alt']" size="lg" /></button>
      </div>
      <span v-else-if="validating === -1" class="mt-1 text-xs text-muted">
        błąd modułu!<FontAwesomeIcon class="ml-2 text-yellow-400 dark:text-yellow-600" icon="exclamation-triangle" size="lg" />
      </span>
      <span v-else class="mt-1 text-xs text-muted">wczytuję<FontAwesomeIcon class="ml-2" icon="sync-alt" size="lg" spin /></span>
    </div>

    <div class="flex flex-col">

      <div class="text-xs text-muted italic mb-2">
        <div v-if="currentUserType == 'root' || currentUserType == 'admin' || currentUserType == 'asystent' || currentUserType == 'pracownik_b'">* polisy/oferty wszystkich agentów</div>
        <div v-else-if="currentUserType == 'manager' " class="help is-info">* oferty/polisy zalogowanego użytkownika oraz podległych agentów</div>
        <div v-else class="help is-info">* oferty/polisy zalogowanego agenta</div>
      </div>

      <div class="flex flex-col gap-y-2">
        <CustomerOrderListItem v-for="order in orderList" :key="order.id" :order="order" />
      </div>
    </div>
  </div>
</template>

<script>
import OrderService from '../../services/OrderService';
import { errorHandler } from '../../mixins/errorHandler';
import CustomerOrderListItem from './CustomerOrderListItem.vue';

export default {
  name: 'CustomerOrderList',
  components: {
    CustomerOrderListItem,
  },
  mixins: [errorHandler],
  props: {
    customerId: {
      required: true,
    },
  },
  computed: {
    currentUserType() {
      return this.$store.getters.currentUserType;
    },
  },
  data() {
    return {
      orderList: [],
      ordersQuantity: 0,
      validating: 0,
    };
  },
  created() {
    this.getOrders();
  },
  methods: {
    getOrders() {
      this.validating = 0;
      OrderService.getCustomersShortOrderList(this.customerId)
        .then((resp) => {
          this.orderList = resp.data.orderList;
          this.ordersQuantity = resp.data.count_all_results;
          this.validating = 1;
        })
        .catch((error) => {
          this.resolveError(error);
          this.validating = -1;
        });
    },
    addOrder() {
      this.$emit('addOrder');
    },
  },
};
</script>
