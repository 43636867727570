import axios from 'axios';

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  // withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json',
  },
});

export default {
  getNotes(params) {
    const result = new Promise((resolve, reject) => {
      apiClient.get('/api/get-notes', { params })
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  addTextNote(formData) {
    const result = new Promise((resolve, reject) => {
      apiClient.post('/api/add-note', formData)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  addFileNote(formData) {
    const result = new Promise((resolve, reject) => {
      apiClient.post('/api/add-note-file', formData)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  updateNote(id, fields) {
    const result = new Promise((resolve, reject) => {
      apiClient.patch(`/api/edit-note/${id}`, fields)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  delNote(id) {
    const result = new Promise((resolve, reject) => {
      apiClient.delete(`/api/delete-note/${id}`)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
};
