<template>
  <BaseModal
    @closeModal="$emit('close')"
    :cancel="true"
    :ok="true"
    okLabel="zapisz"
    @ok="save"
  >
    <template #header>
      Edycja ośw. zgód
    </template>
    <template #default>
      <div class="flex flex-col gap-y-3">
        <div>
          <BaseInputWithAddon class="w-full sm:w-1/2" v-model="form.set_date_h" type="date" labelTop="data udzielenie zgód" :error="errors.set_date_h !== undefined ? errors.set_date_h : ''" />
        </div>
        <div class="w-full flex-col gap-y-1">
          <div>Koniec obowiązywania oświadczenia</div>
          <div class="flex flex-wrap gap-x-3 gap-y-1 items-center">
            <BaseInputWithAddon v-model="form.due_date_h" type="date" labelAddon="data" :error="errors.due_date_h !== undefined ? errors.due_date_h : ''" />
            <div class="text-muted">lub</div>
            <label for="do-odwolania">
              <input
                id="do-odwolania"
                type="checkbox"
                true-value="1" false-value="0"
                v-model="form.till_dismiss"
                :disabled="Number(form.till_dismiss) === 1"
              > do odwołania
            </label>
            <label for="koniec-polisy" class="flex items-center gap-1" title="pokaż również nieaktywnych pracowników">
              <input
                id="koniec-polisy"
                type="checkbox"
                true-value="1" false-value="0"
                v-model="fakePolicyEndDate"
                :disabled="Number(fakePolicyEndDate) === 1"
              > do końca polisy
            </label>
          </div>
        </div>
        <label for="consent-order-service" class="flex items-center gap-1" title="pokaż również nieaktywnych pracowników">
          <input
            id="consent-order-service"
            type="checkbox"
            v-model="form.order_service"
            true-value="1" false-value="0"
          > Zgoda na przetwarzanie danych osobowych oraz kopii dokumentów dla celów obsługi polis ubezpieczeniowych
        </label>
        <label for="consent-email-order-service" class="flex items-center gap-1" title="pokaż również nieaktywnych pracowników">
          <input
            id="consent-email-order-service"
            type="checkbox"
            v-model="form.email_order_service"
            true-value="1" false-value="0"
          > Zgoda na elektroniczną obsługę umów ubezpieczeń, droga mailową
        </label>
        <label for="consent-marketing" class="flex items-center gap-1" title="pokaż również nieaktywnych pracowników">
          <input
            id="consent-marketing"
            type="checkbox"
            v-model="form.marketing"
            true-value="1" false-value="0"
          > Zgoda na otrzymywanie drogą elektroniczną informacji handlowych (ustwa o świadczeniu usług drogą elektroniczną (Dz.U. z 2002 Nr 144 poz. 1204))
        </label>
        <label for="consent-telecom" class="flex items-center gap-1" title="pokaż również nieaktywnych pracowników">
          <input
            id="consent-telecom"
            type="checkbox"
            v-model="form.telecom"
            true-value="1" false-value="0"
          > Zgoda na wykorzystanie telekomunikacyjnych urządzeń końcowych dla celów marketingu bezpośredniego (Art. 172 ust. Prawo telekomunikacyjne)
        </label>
        <label for="consent-profiling" class="flex items-center gap-1" title="pokaż również nieaktywnych pracowników">
          <input
            id="consent-profiling"
            type="checkbox"
            v-model="form.profiling"
            true-value="1" false-value="0"
          > Zgoda na profilowanie (procesy zautomatyzowanego podejmowania decyzji)
        </label>
      </div>
      <div v-if="consent.file_path !== ''" class="flex flex-col gap-y-0.5  mt-1 border-t dark:border-gray-600">
          <p class="text-muted text-xs italic">* wstawiony załącznik nie może być edytowany. Aby go podmienić, należy usunąć całe oświadczenie i dodać ponownie z prawdłowym załącznikiem.</p>
          <div v-if="consent.file_type.includes('image')">
            <img @contextmenu.prevent="downloadConfirm" class="rounded w-full" :src="`${apiUrl}/personals/show-image/${consent.id}`" alt="Podgląd">
          </div>
          <div v-else class="w-full flex justify-center mb-2">
            <a
              :href="`${apiUrl}/personals/show-pd-gdpr-consent-file/${consent.id}`"
              target="_blank" title="kliknij, aby otworzyć/pobrać załącznik"
              class="group max-w-min flex flex-col items-center cursor-pointer"
            >
              <div class="max-w-min mb-2 bg-yellow-200 text-gray-400 group-hover:bg-yellow-300 dark:bg-yellow-900 dark:group-hover:bg-yellow-800 dark:text-gray-800 rounded-lg">
                <FontAwesomeIcon v-if="consent.file_type === 'application/pdf'" :icon="['far', 'file-pdf']" size="4x" class="p-1" />
                <FontAwesomeIcon v-else :icon="['far', 'file-pdf']" size="4x" class="p-1" />
              </div>
              <span class="group-hover:underline">{{ consent.file_path }}</span>
            </a>
          </div>
        </div>
    </template>
  </BaseModal>
</template>

<script>
import { errorHandler } from '../../mixins/errorHandler';
import { confirm } from '../../mixins/confirm';
import GdprConsentService from '../../services/GdprConsentSerevice';

export default {
  name: 'ConsentEdit',
  mixins: [errorHandler, confirm],
  props: {
    consent: {
      type: Object,
      required: true,
    },
    allowEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL,
      saving: false,
      errors: {},
      form: {
        till_dismiss: '',
        due_date_h: '',
      },
      fakePolicyEndDate: '',
    };
  },
  created() {
    Object.assign(this.form, this.consent);
    if (this.consent.due_date_h === '' && Number(this.consent.till_dismiss) === 0) {
      this.fakePolicyEndDate = '1';
    }
  },
  methods: {
    save() {
      this.saving = true;
      const formData = new FormData();
      Object.keys(this.form).forEach((key) => {
        formData.append(key, this.form[key]);
      });
      GdprConsentService.updateConsent(this.consent.id, formData)
        .then((response) => {
          this.$emit('updated', response.data.consent);
          this.$store.dispatch('notifications/add', { type: 'success', message: 'Zapisano zmiany' });
          this.saving = true;
        })
        .catch((error) => {
          this.errors = this.resolveError(error);
          this.saving = false;
        });
    },
    downloadConfirm() {
      this.confirm(() => this.download(), 'Pobrac zdjęcie na dysk?');
    },
    download() {
      const newWindow = window.open(null, this.note.id);
      newWindow.location.href = `${this.apiUrl}/personals/show-pd-gdpr-consent-file/${this.consent.id}`;
    },
  },
  watch: {
    'form.till_dismiss': function(newVal) {
      if (Number(newVal) === 1) {
        this.form.due_date_h = '';
        this.fakePolicyEndDate = '0';
      }
    },
    fakePolicyEndDate(newVal) {
      if (Number(newVal) === 1) {
        this.form.due_date_h = '';
        this.form.till_dismiss = '0';
      }
    },
    'form.due_date_h': function(newVal) {
      if (newVal !== '') {
        this.fakePolicyEndDate = '0';
        this.form.till_dismiss = '0';
      } else {
        this.form.till_dismiss = '1';
      }
    },
  },
};
</script>
