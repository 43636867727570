import Vue from 'vue';
import moment from 'moment';

Vue.filter('phoneNo', value => {
  let phoneStyled = value;
  if (/^\d{9}$/.test(value)) { // Jeśli 9 cyfr
    const accountArr = value.split('');
    // Jeśli zawiera kierunkowy miasta, to znaczy, że stacjonarny i dziel 99 999 99 99
    if (['12', '13', '14', '15', '16', '17', '18', '22', '23', '24', '25', '29', '32', '33', '34', '41', '42', '43', '44', '46', '48', '52', '54', '55', '56', '58', '59', '61', '62', '63', '65', '67', '68', '71', '74', '75', '76', '77', '81', '82', '83', '84', '85', '86', '87', '89', '91', '94', '95'].includes(value.substr(0, 2))) {
      accountArr.splice(7, 0, '-');
      accountArr.splice(5, 0, '-');
      accountArr.splice(2, 0, ')');
      accountArr.splice(0, 0, '(');
    } else {
      accountArr.splice(6, 0, '-');
      accountArr.splice(3, 0, '-');
    }
    phoneStyled = accountArr.join('');
  }
  return phoneStyled;
});
Vue.filter('datePl', value => (value ? moment(value).format('YYYY-MM-DD') : ''));
Vue.filter('dateTimePl', value => (value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : ''));
Vue.filter('bankAcc', value => {
  let accStyled = value;
  if (/^\d{26}$/.test(value)) { // Jeśli 26 cyfr
    const accountArr = value.split('');
    for (let i = 22; i > 0; i -= 4) {
      accountArr.splice(i, 0, ' ');
    }
    accStyled = accountArr.join('');
  }
  return accStyled;
});
Vue.filter('moneyPL', value => {
  const money = value ? Number(value) : 0;
  return money.toLocaleString('pl-PL', { style: 'currency', currency: 'PLN' });
});
Vue.filter('percentPL', (value) => {
  const percent = value ? Number(value) : 0;
  return percent.toLocaleString('pl-PL');
});
Vue.filter('currency', (value) => {
  if (!value) return '0,00';
  return value.replace('.', ',');
});
Vue.filter('currencyNullAllow', (value) => {
  if (value === '') {
    return '-,--';
  }
  return value.replace('.', ',');
});
