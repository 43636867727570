<template>
  <div class="h-screen w-screen overflow-x-hidden overflow-y-auto flex flex-col items-center lg:items-stretch lg:flex-none lg:grid lg:grid-cols-5" :style="`background-image: url(\'${require('@/assets/login_bg_hd.jpg')}\')`">
    <div class="relative flex items-center max-w-lg lg:max-w-none lg:col-span-3 lg:justify-end">
      <div class="lg:hidden absolute top-1 right-2">
        <label class="text-gray-400 text-xs" for="dark-mode">
          <span class="mr-2">tryb ciemny</span>
          <input id="dark-mode" class="text-gray-400 border-gray-400 focus:ring-gray-400 rounded-sm" type="checkbox" :true-value="true" :false-value="false" v-model="darkModeBox">
        </label>
      </div>
      <div class="bg-gradient-to-br from-transparent via-white dark:from-gray-900 dark:via-gray-800 dark:text-gray-400 2xl:w-4/5 2xl:h-4/5 lg:p-10 lg:shadow-xl 2xl:rounded-l-md lg:z-10 lg:overflow-y-hidden">
        <div class="flex flex-col lg:mr-24 2xl:pl-24">
          <img class="max-w-xs max-h-16 self-start lg:max-h-24 xl:max-h-32 2xl:max-h-48" :src="companyLogoUrl" alt="Logo">
          <h1 class="font-semibold px-5 lg:p-0 lg:text-xl" v-html="welcome.header"></h1>
          <p class="px-5 lg:p-0 lg:text-lg" v-html="welcome.content" />
          <footer class="font-semibold px-5 pb-3 lg:p-0 lg:text-lg" v-html="welcome.footer" />
        </div>
      </div>
    </div>
    <div class="w-full max-w-lg lg:max-w-none lg:w-auto lg:col-span-2 flex items-center lg:bg-gray-400 lg:dark:bg-gray-800">
      <div class="relative w-full pl-20 pr-28 pt-12 pb-10 bg-gradient-to-tl lg:w-auto lg:-ml-28 lg:border lg:rounded-lg lg:shadow-md lg:bg-gradient-to-l from-white via-gray-50 dark:border-gray-700 dark:from-gray-900 dark:via-gray-700 z-10">
        <div class="hidden lg:block absolute top-1 right-2">
          <label class="text-gray-400 text-xs" for="dark-mode">
            <span class="mr-2">tryb ciemny</span>
            <input id="dark-mode" class="text-gray-400 border-gray-400 focus:ring-gray-400 rounded-sm" type="checkbox" :true-value="true" :false-value="false" v-model="darkModeBox">
          </label>
        </div>
        <form class="" @submit.prevent="login" autocomplete="off">
          <input
            v-focus
            id="input-1"
            @focus = "message = ''"
            ref="username"
            name="username"
            v-model="form.login"
            placeholder="login lub email"
            type="text"
            class="tw--form-big my-2 w-full"
            :class="{'border-red-500': message.length > 0 }"
            :disabled="isBusy"
            required
          >
          <div class="relative">
            <input
              id="input-2"
              @focus = "message = ''"
              name="password"
              v-model="form.password"
              placeholder="hasło"
              :type="passVisible ? 'text' : 'password'"
              class="tw--form-big my-2 w-full"
              :class="{'border-red-500': message.length > 0 }"
              :disabled="isBusy"
              required
            >
            <div @click="passVisible = !passVisible" class="absolute top-0 pt-4 text-gray-500 cursor-pointer" style="right:-2em" :title="passVisible ? 'ukryj hasło' : 'pokaż hasło'">
              <FontAwesomeIcon :icon="passVisible ? ['far', 'eye'] : ['far', 'eye-slash']" size="lg"/>
            </div>
          </div>
          <button type="submit" class="tw--btn tw--btn-big w-full mt-2" :disabled="isBusy">
            <span v-if="!isBusy">Zaloguj</span>
            <span v-else>Logowanie...</span>
          </button>
        </form>
        <div class="text-right mt-1">
          <router-link :to="{ name: 'PasswordEmail' }" class="tw--link">nie pamiętam hasła</router-link>
          <!-- <a href="#" class="tw--link">nie pamiętam hasła</a> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from '../services/AuthService';

export default {
  name: 'Login',
  data() {
    return {
      darkModeBox: false,
      form: {
        login: '',
        password: '',
      },
      message: '',
      isBusy: false,
      welcome: {
        header: 'Witamy w aplikacji e-Multiagencja.',
        content: `Wprowadź dane logowania, lub użyj opcji "nie pamiętam hasła". Aplikacja jest monitorowana, wszelkie nieuprawnione próby dostępu do aplikacji
          są niezgodne z obowiązującymi normami prawnymi będą zgłaszane oraganom ścigania.<br><br>Życzymy przyjemnej pracy!`,
        footer: '',
      },
      passVisible: false,
    };
  },
  computed: {
    backToURL() {
      return this.$store.getters.backToURL;
    },
    configParams() {
      return this.$store.getters.configParams;
    },
    companyLogoUrl() {
      let url = this.configParams.company_logo_l;
      if (url === undefined) {
        url = '';
      } else if (url === '') {
        url = '/images/logo/logotyp-emultiagencja-full.png';
      } else {
        url = `/${url}`;
      }
      return url;
    },
    darkMode() {
      return this.$store.getters.darkMode;
    },
  },
  created() {
    this.darkModeBox = this.$store.getters.darkMode;
  },
  mounted() {
    if (this.$store.getters.loggedIn) {
      this.$router.push({ name: 'Logout' });
    }
    this.welcome.footer = this.configParams.company_short_name;
  },
  methods: {
    login() {
      this.isBusy = true;
      const formData = new FormData();
      formData.append('username', this.form.login);
      formData.append('password', this.form.password);
      AuthService.login(formData)
        .then(() => {
          this.$store.dispatch('loginSuccess');
          this.$store.dispatch('employees/getEmployeeSipleList');
          if (this.backToURL !== null) {
            this.$router.push(this.backToURL);
            this.$store.dispatch('clearCurrentUrl');
          } else {
            this.$router.push({ name: 'Calendar' });
          }
        })
        .catch(error => {
          this.message = error.response.data.msg;
          this.isBusy = false;
          this.form = { login: '', password: '' };
          this.$store.dispatch('notifications/add', {
            type: 'warning',
            message: 'Niepoprawne dane logowania',
          });
        });
    },
    setDarkMode() {
      this.$store.dispatch('setDarkMode', !this.darkMode);
    },
  },
  watch: {
    darkModeBox(newVal) {
      this.$store.dispatch('setDarkMode', newVal);
    },
  },
};
</script>
