<template>
  <div>

    <EmailsPhonesEdit v-if="edit"
      :ownerType="ownerType"
      :ownerId="ownerId"
      :type="type"
      :items="items"
      @close="edit = false"
      @added="added"
      @updated="updated"
      @deleted="deleted"
    />

    <div class="flex gap-3 border border-blue-500 dark:border-blue-700 rounded-sm text-xs sm:text-base">
      <div class="flex items-center px-2 py-1 bg-blue-500 dark:bg-blue-700 group-hover:bg-blue-300 dark:group-hover:bg-blue-500 rounded-l-sm">
        <FontAwesomeIcon :icon="type === 'emails' ? ['fas', 'at'] : ['fas', 'phone-alt']" />
      </div>
      <div class="flex-grow flex items-center">
        <div v-if="items.length === 0"><span class="italic text-muted">nie podano</span></div>
        <div v-else class="flex gap-3">
          <div v-show="!showAllItems" class="flex flex-wrap items-center gap-x-2 rounded-sm">
            <a :href="`${type === 'emails' ? 'mailto' : 'tel'}:${items[0].value}`"
              class="tw--link whitespace-nowrap"
              :title="`${type === 'emails' ? 'wyślij: ' : 'zadzwoń: '}${items[0].value}`"
            >{{ items[0].value | phoneNo }}</a>
            <span v-show="items.length > 1" @click="showAllItems = !showAllItems" class="text-xs italic tw--link font-normal underline cursor-pointer">
              więcej&gt;
            </span>
          </div>
          <div v-show="showAllItems && items.length > 1" class="flex flex-wrap items-center gap-x-3">
            <div v-for="item in items" :key="item.id">
              <a :href="`${type === 'emails' ? 'mailto' : 'tel'}:${item.value}`"
                class="tw--link whitespace-nowrap"
                :title="`${type === 'emails' ? 'wyślij: ' : 'zadzwoń: '}${item.value}`"
              >{{ item.value | phoneNo }}</a>
            </div>
            <span v-show="items.length > 1" @click="showAllItems = !showAllItems" class="text-xs italic tw--link font-normal underline cursor-pointer">
              &lt;mniej
            </span>
          </div>
        </div>
      </div>
      <button
        @click.stop="edit = true"
        class="flex-shrink tw--btn-icon border-l border-blue-500 dark:border-blue-700 px-2 hover:bg-blue-100 dark:hover:bg-blue-900 rounded-r-sm"
        title="edytuj"
      ><FontAwesomeIcon :icon="['far', 'edit']" /></button>
    </div>
  </div>
</template>

<script>
import EmailsPhonesEdit from './EmailsPhonesEdit.vue';

export default {
  name: 'EmailsPhones',
  components: { EmailsPhonesEdit },
  props: {
    ownerType: {
      validator: function(value) {
        return ['customer', 'employee', 'customerContactPerson'].indexOf(value) !== -1;
      },
    },
    ownerId: {
      type: [String, Number],
      required: true,
    },
    type: {
      type: String,
      default: 'emails',
    },
    phones: {
      type: Array,
      default() {
        return [];
      },
    },
    emails: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      showAllItems: false,
      edit: false,
    };
  },
  computed: {
    items() {
      return this.type === 'emails' ? this.emails : this.phones;
    },
  },
  methods: {
    added(item) {
      this.$emit('added', item);
    },
    updated(item) {
      this.$emit('updated', item);
    },
    deleted(id) {
      if (this.items.length < 3) {
        this.showAllItems = false;
      }
      this.$emit('deleted', id);
    },
  },
};
</script>
