import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import notifications from '@/store/modules/notifications';
import confirmation from '@/store/modules/confirmation';
import orders from '@/store/modules/orders';
import products from '@/store/modules/products';
import employees from '@/store/modules/employees';
import customers from '@/store/modules/customers';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentUserData: {},
    progOptions: {},
    configParams: {},
    backToURL: null,
    darkMode: false,
    loadingData: false,
    changePasswordModal: false,
  },
  mutations: {
    SET_DARK_MODE(state, payload) {
      state.darkMode = !!payload;
      localStorage.setItem('darkMode', state.darkMode);
    },
    SET_USER_DATA(state, userData) {
      state.currentUserData = userData;
    },
    SET_PROG_OPTIONS(state, payload) {
      state.progOptions = {
        sessionExpTime: Number(payload.session_exp_time),
        rowsOnPage: Number(payload.rows_on_page),
        maxRowsOnPage: Number(payload.max_rows_on_page),
        provision_system: Number(payload.provision_system),
        daysToRenewOrder:
          payload.days_to_renew_order === undefined
            ? 30
            : Number(payload.days_to_renew_order),
        copywriterProvision: Number(payload.copywriter_provision),
        allowNullPayProvReport: payload.allow_null_pay_prov_report
          .split(",")
          .map((el) => Number(el)),
        orderViewFinancialDataFirst:
          payload.order_view_financial_data_first === 1 ||
          payload.order_view_financial_data_first === "1",
        exclusiveReports:
          payload.exclusive_reports === "0" || payload.exclusive_reports === 0
            ? false
            : payload.exclusive_reports,
        leadFunctionality:
          payload.allow_lead_functionality === 1 ||
          payload.allow_lead_functionality === "1",
        rodoApkModule:
          payload.rodo_apk_module === 1 || payload.rodo_apk_module === "1",
        apkTypesAllowed:
          payload.apk_types_allowed.split(",").map((el) => Number(el)) || [],
      };
    },
    UNSET_PROG_OPTIONS(state, payload) {
      state.progOptions = payload;
    },
    SET_CONFIG_PARAMS(state, configParams) {
      state.configParams = configParams;
    },
    STORE_CURRENT_URL(state, url) {
      state.backToURL = url !== '' ? url : null;
    },
    SET_LOADING_DATA(state, payload) {
      state.loadingData = payload;
    },
    SET_PASSWORD_MODAL(state, payload) {
      state.changePasswordModal = payload;
    },
  },
  actions: {
    getCurrentUserData({ commit }) {
      const result = new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}/api/get-current-user-data`)
          .then((response) => {
            if (response.status === 200 && response.data.currentUserData) {
              commit('SET_USER_DATA', response.data.currentUserData);
              resolve();
            } else {
              reject(response);
            }
          })
          .catch(error => reject(error));
      });
      return result;
    },
    loginSuccess({ dispatch }) {
      dispatch('getCurrentUserData');
      dispatch('getProgOptions');
    },
    logout({ commit }) {
      commit('SET_USER_DATA', {});
      commit('UNSET_PROG_OPTIONS', {});
    },
    getProgOptions({ commit }) {
      const result = new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}/api/get-prog-options`)
          .then((response) => {
            if (response.status === 200 && response.data.progOptions) {
              commit('SET_PROG_OPTIONS', response.data.progOptions);
              resolve();
            } else {
              reject(response);
            }
          })
          .catch(error => reject(error));
      });
      return result;
    },
    getConfigParams({ commit }) {
      const result = new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}/api/get-c-config-short`)
          .then((response) => {
            if (response.status === 200 && response.data.cConfigShort) {
              commit('SET_CONFIG_PARAMS', response.data.cConfigShort);
              resolve();
            } else {
              reject(response);
            }
          })
          .catch(error => reject(error));
      });
      return result;
    },
    setDarkMode({commit}, mode = false) {
      commit('SET_DARK_MODE', mode);
    },
    storeCurrentUrl({commit}, url = null) {
      commit('STORE_CURRENT_URL', url);
    },
    clearCurrentUrl({commit}) {
      commit('STORE_CURRENT_URL', null);
    },
    setLoadingData({commit}, state = false) {
      commit('SET_LOADING_DATA', state);
    },
    setPasswordModal({commit}, state = false) {
      commit('SET_PASSWORD_MODAL', state);
    },
  },
  getters: {
    loggedIn(state) {
      return !!state.currentUserData.user_name;
    },
    currentUserData(state) {
      return state.currentUserData;
    },
    currentUserType(state) {
      return state.currentUserData.user_rights !== undefined ? state.currentUserData.user_rights.user_type : null;
    },
    progOptions(state) {
      return state.progOptions;
    },
    daysToRenewOrder(state) {
      return state.progOptions.daysToRenewOrder;
    },
    maxRowsOnPage(state) {
      return state.progOptions.maxRowsOnPage;
    },
    configParams(state) {
      return state.configParams;
    },
    backToURL(state) {
      return state.backToURL;
    },
    darkMode(state) {
      return state.darkMode;
    },
    loadingData(state) {
      return state.loadingData;
    },
    changePasswordModal(state) {
      return state.changePasswordModal;
    },
  },
  modules: {
    notifications,
    confirmation,
    orders,
    products,
    employees,
    customers,
  },
});
