<template>
  <Transition name="fade">
    <!-- <div v-show="activeModal && !closing" @click.self="bgClose ? closeModal() : null" class="fixed py-16 inset-0 w-full h-screen flex items-center justify-center backdrop-filter backdrop-blur-sm z-50"> -->
    <div v-if="activeModal && open"
      @click.self="bgClose ? closeModal() : null"
      class="fixed inset-0 w-full h-screen flex items-center justify-center backdrop-filter backdrop-blur-sm blur--support z-50"
      :class="{'py-5': minHeight === 'full', 'py-16': minHeight === ''}"
      ref="modalContainer"
      @keydown="keyHandler"
    >
      <div
        class="relative flex flex-col min-h-20 max-h-full bg-white dark:bg-gray-900 dark:text-gray-400 border dark:border-gray-600 rounded-lg shadow-lg"
        :class="{
          'lg:w-4/5 md:w-5/6 w-11/12': size === '2xl',
          'xl:w-3/5 lg:w-2/3 md:w-5/6 w-11/12': size === 'xl',
          'xl:w-2/5 lg:w-3/5 md:w-4/5 w-11/12': size === 'lg',
          'xl:w-2/6 lg:w-1/2 md:w-2/3 w-10/12': size === 'md',
          '2xl:w-3/12 xl:w-2/6 lg:w-2/5 md:w-1/2 sm:w-2/3 w-11/12': size === 'sm'
        }"
      >
        <Transition name="fade">
          <div v-if="busy" class="absolute inset-0 rounded-lg overflow-hidden z-50">
            <slot name="busy">
              <div class="absolute top-0 left-0 w-full h-full">
                <div class="w-full h-full bg-white dark:bg-black opacity-80"></div>
                <div class="absolute top-0 left-0 flex w-full h-full items-center justify-center">
                  <FontAwesomeIcon class="mr-3" icon="spinner" size="2x" spin />
                  <div class="font-bold">
                    <p>Zapisuję dane...</p>
                    <p>Nie zamykaj okna przeglądarki do zakończenia procesu...</p>
                  </div>
                </div>
              </div>
            </slot>
          </div>
        </Transition>
        <div class="flex items-center justify-between bg-gray-100 dark:bg-gray-800 border-b dark:border-gray-600 rounded-t-lg">
          <header class="text-base font-semibold px-4 py-1 rounded-t-lg uppercase text-muted">
            <slot name="header" />
          </header>
          <button @click="closeModal" aria-label="close" class="tw--btn-icon tw--btn-icon-secondary mx-3 py-0">
            <FontAwesomeIcon icon="times" />
          </button>
        </div>

        <div class="relative max-h-full overflow-y-auto px-6 py-3 scrollbar">
          <slot />
        </div>

        <slot name="footer">
          <div class="flex items-center justify-end gap-3 py-2 px-3 bg-gray-100 dark:bg-gray-800 border-t dark:border-gray-600 rounded-b-lg">
            <button v-if="cancel" class="tw--btn tw--btn-secondary" @click="closeModal">{{ cancelLabel }}</button>
            <button v-if="close" class="tw--btn tw--btn-secondary" @click="closeModal">zamknij</button>
            <button v-if="delBtn" class="tw--btn tw--btn-danger" @click="$emit('delete')">{{ delBtnLabel }}</button>
            <button v-if="ok" class="tw--btn" @click="$emit('ok')" :disabled="okDisabled">{{ okLabel }}</button>
          </div>
        </slot>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  name: 'BaseModal',
  props: {
    activeModal: {
      type: Boolean,
      default: true,
    },
    busy: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'lg',
    },
    minHeight: {
      type: String,
      default: '',
    },
    bgClose: {
      type: Boolean,
      default: false,
    },
    cancel: {
      type: Boolean,
      default: true,
    },
    cancelLabel: {
      type: String,
      default: 'anuluj',
    },
    // close modal on ESC key
    escCancel: {
      type: Boolean,
      default: false,
    },
    ok: {
      type: Boolean,
      default: true,
    },
    okLabel: {
      type: String,
      default: 'ok',
    },
    okDisabled: {
      type: Boolean,
      default: false,
    },
    delBtn: {
      type: Boolean,
      default: false,
    },
    delBtnLabel: {
      type: String,
      default: 'usuń',
    },
    close: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      open: false,
      closing: false,
      focusableList: [],
    };
  },
  mounted() {
    this.open = true;
    this.$nextTick(() => {
      const inputs = this.$refs.modalContainer.querySelectorAll('input:not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([tabindex="-1"])');
      if (inputs.length > 0) {
        inputs[0].focus();
      } else {
        this.refreshFocusableList();
        this.focusableList[this.focusableList.length - 1].focus();
      }
    });
  },
  methods: {
    refreshFocusableList() {
      this.focusableList = this.$refs.modalContainer.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
    },
    closeModal() {
      this.open = false;
      this.$emit('closeModal');
    },
    keyHandler(event) {
      if (event.key === 'Escape' && this.escCancel) {
        this.closeModal();
        return;
      }
      this.refreshFocusableList();
      const last = this.focusableList.length - 1;
      if (event.key === 'Tab' && !event.shiftKey && event.target === this.focusableList[last]) {
        event.preventDefault();
        this.focusableList[0].focus();
      } else if (event.key === 'Tab' && event.shiftKey && event.target === this.focusableList[0]) {
        event.preventDefault();
        this.focusableList[last].focus();
      }
    },
  },
  watch: {
    activeModal(newVal) {
      if (!newVal) this.closeModal();
    },
  },
};
</script>
