<template>
  <BaseModal
    @closeModal="$emit('close')"
    :cancel="true"
    :ok="true"
    :busy="saving"
    okLabel="zapisz"
    @ok="save"
  >
    <template #header>
      Edycja klienta
    </template>
    <template #default>
      <div id="not-exclusive-to-user-info" v-if="!customerExclusiveToUser" class="py-1 px-3 -mx-3 mb-1 bg-yellow-50 dark:bg-yellow-500 dark:bg-opacity-20 text-xs rounded-sm">
        Klient jest obsługiwany również przez innego agenta. Zmiany będa widoczne we wszystkich powiązanych polisach.
      </div>
      <CustomerForm
        :customer="customerCache"
        :edit="true"
        :allowEditSensitiveData = allowEditSensitiveData
        :validationErrors="{ errors: saveErrors, warnings: {} }"
        @formChanged="setCustomerData"
      />
    </template>
  </BaseModal>
</template>

<script>
import { errorHandler } from '../../mixins/errorHandler';
import CustomerService from '../../services/CustomerService';
import CustomerForm from '../customer/CustomerForm.vue';

export default {
  name: 'CustomerEdit',
  components: { CustomerForm },
  mixins: [errorHandler],
  props: {
    customer: {
      type: Object,
      required: true,
    },
    customerExclusiveToUser: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      customerCache: {},
      saveErrors: {},
      saving: false,
    };
  },
  computed: {
    currentUserType() {
      return this.$store.getters.currentUserType;
    },
    allowEditSensitiveData() {
      return this.currentUserType === 'root' || this.currentUserType === 'admin';
    },
  },
  mounted() {
    this.setCustomerCache();
  },
  methods: {
    setCustomerCache() {
      const personOrCompany = Number(this.customer.is_company) === 1 ? 'company' : 'person';
      this.customerCache.id = this.customer.id;
      this.customerCache.cust_type_id = this.customer.cust_type_id;
      this.customerCache.pref_contact_form = this.customer.pref_contact_form;
      this.customerCache.is_company = this.customer.is_company;
      this.customerCache.surname = this.customer.person.surname ? this.customer.person.surname : '';
      this.customerCache.forename1 = this.customer.person.forename1 ? this.customer.person.forename1 : '';
      this.customerCache.forename2 = this.customer.person.forename2 ? this.customer.person.forename2 : '';
      this.customerCache.pesel = this.customer.person.pesel ? this.customer.person.pesel : '';
      this.customerCache.birth_date_h = this.customer.person.birth_date_h ? this.customer.person.birth_date_h : '';
      this.customerCache.foreigner = this.customer.person.foreigner ? this.customer.person.foreigner : '';
      this.customerCache.nationality = this.customer.person.nationality ? this.customer.person.nationality : '';
      this.customerCache.name = this.customer.company.name ? this.customer.company.name : '';
      this.customerCache.shortname = this.customer.company.shortname ? this.customer.company.shortname : '';
      this.customerCache.regon = this.customer.company.regon ? this.customer.company.regon : '';
      this.customerCache.nip = this.customer.company.nip ? this.customer.company.nip : '';
      this.customerCache.vat_ue = this.customer.company.vat_ue ? this.customer.company.vat_ue : '';
      this.customerCache.street = this.customer[personOrCompany].street;
      this.customerCache.house_ap_no = this.customer[personOrCompany].house_ap_no;
      this.customerCache.postal_code = this.customer[personOrCompany].postal_code;
      this.customerCache.city = this.customer[personOrCompany].city;
      this.customerCache.region = this.customer[personOrCompany].region;
      this.customerCache.country = this.customer[personOrCompany].country;
      this.customerCache.c_street = this.customer[personOrCompany].c_street;
      this.customerCache.c_house_ap_no = this.customer[personOrCompany].c_house_ap_no;
      this.customerCache.c_postal_code = this.customer[personOrCompany].c_postal_code;
      this.customerCache.c_city = this.customer[personOrCompany].c_city;
      this.customerCache.c_region = this.customer[personOrCompany].c_region;
      this.customerCache.c_country = this.customer[personOrCompany].c_country;
      if (this.allowEditSensitiveData) {
        this.customerCache.bank = this.customer[personOrCompany].bank;
        this.customerCache.bank_acc_no = this.customer[personOrCompany].bank_acc_no;
      }
    },
    setCustomerData(customerForm) {
      Object.assign(this.customerCache, customerForm);
    },
    save() {
      this.saving = true;
      CustomerService.updateCustomer(this.customer.id, this.customerCache)
        .then((response) => {
          this.saveErrors = {};
          this.$emit('customerUpdated', response.data.updatedCustomerFields);
          this.$store.dispatch('notifications/add', { type: 'success', message: 'Dane klienta zostały zmodyfikowane'});
        })
        .catch(error => {
          this.saveErrors = this.resolveError(error);
          this.saving = false;
        });
    },
  },
};
</script>
