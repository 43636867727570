<template>
  <div class="flex items-center gap-2">
    <span class="text-muted" :title="title">
      {{ name }}
      <FontAwesomeIcon v-if="title.length > 0" :icon="['far', 'question-circle']" size="sm" class="cursor-help" />
    </span>
    <span v-if="state" class="font-bold text-base text-green-400 dark:text-green-600 cursor-help" title="zgoda wydana">&check;</span>
    <span v-else class="cursor-help" title="brak zgody"><FontAwesomeIcon icon="times" /></span>
  </div>
</template>

<script>
export default {
  name: 'ConsentDetailElementItem',
  props: {
    name: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    state: {
      type: Boolean,
      required: true,
    },
  },
};
</script>