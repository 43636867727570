import axios from 'axios';

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  // withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json',
  },
});

export default {
  getOrders(params) {
    const result = new Promise((resolve, reject) => {
      apiClient.get('/api/get-order-list', { params })
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  getCustomersShortOrderList(customerId) {
    const result = new Promise((resolve, reject) => {
      apiClient.get(`/api/get-customers-order-short-list/${customerId}`)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  getFullOrderData(id) {
    const result = new Promise((resolve, reject) => {
      apiClient.get(`/api/orders/${id}`)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  /**
   * Pobiera rozszerzoną listę pracowników (dodatkowe pola, więcej niż w EmployeeSimpleList)
   * @returns Promise
   */
  addOrderInit() {
    const result = new Promise((resolve, reject) => {
      apiClient.get('/api/add-order-init')
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  /**
   * Weryfikuje uzupełnione dane z formularza na bieżąco (pola dotyczące tabeli orders)
   * zwraca stan uzupełnienia formularza, ale nie jako błąd, tylko state:
   *  staus: 'success', state: 'errors', errors: {field1: 'msg', field2: 'msg', ...}, error_warnings: {...}, empty_warnings: {...} lub:
   *  staus: 'success', state: 'ok', errors: {}, error_warnings: {}, empty_warnings: {}
   * @param {Object} form -dane z formularza
   * @returns Promise
   */
  validateNewOrderDetails(form) {
    const result = new Promise((resolve, reject) => {
      apiClient.post('/api/orders/validate-new-order-data-only-order-details', form)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  /**
   * Weryfikuje uzupełnione dane z formularza na bieżąco (pola dotyczące tabeli danego przedmiotu ubezpieczenia)
   * zwraca stan uzupełnienia formularza, ale nie jako błąd, tylko state:
   *  staus: 'success', state: 'errors', errors: {field1: 'msg', field2: 'msg', ...}, error_warnings: {...}, empty_warnings: {...} lub:
   *  staus: 'success', state: 'ok', errors: {}, error_warnings: {}, empty_warnings: {}
   * @param {Object} form -dane z formularza
   * @returns Promise
   */
  validateNewOrderSubject(orderTypeId, form) {
    const result = new Promise((resolve, reject) => {
      apiClient.post(`/api/orders/validate-new-subject-form/${orderTypeId}`, form)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  createOrder(form) {
    const result = new Promise((resolve, reject) => {
      apiClient.post('/api/create-order', form)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  changeOrderStatus(id, fields) {
    const result = new Promise((resolve, reject) => {
      apiClient.patch(`/api/change-order-status/${id}`, fields)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  updateOrder(id, fields) {
    const result = new Promise((resolve, reject) => {
      apiClient.patch(`/api/update-order/${id}`, fields)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  getSubjectForm(orderType) {
    const result = new Promise((resolve, reject) => {
      apiClient.get(`/api/get-subject-form/${orderType}`)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  addOrderSubject(orderId, form) {
    const result = new Promise((resolve, reject) => {
      apiClient.post(`/api/add-subject/${orderId}`, form)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  updateOrderSubject(orderId, form) {
    const result = new Promise((resolve, reject) => {
      apiClient.put(`/api/update-subject/${orderId}`, form)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  delOrderSubject(subjectId) {
    const result = new Promise((resolve, reject) => {
      apiClient.delete(`/api/del-subject/${subjectId}`)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  getOrderSubjects(orderId, orderTypeId) {
    const result = new Promise((resolve, reject) => {
      apiClient.get(`/api/get-order-subjects/${orderId}`, { params: { type_id: orderTypeId } })
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  // Order Items - składki
  addOrderItem(fields) {
    const result = new Promise((resolve, reject) => {
      apiClient.post(`/api/add-order-item`, fields)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  updateOrderItem(id, fields) {
    const result = new Promise((resolve, reject) => {
      apiClient.patch(`/api/update-order-item/${id}`, fields)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  delOrderItem(id) {
    const result = new Promise((resolve, reject) => {
      apiClient.delete(`/api/delete-order-item/${id}`)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  // Installments - raty
  addOrderInstallment(fields) {
    const result = new Promise((resolve, reject) => {
      apiClient.post(`/api/add-installment`, fields)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  updateOrderInstallment(id, fields) {
    const result = new Promise((resolve, reject) => {
      apiClient.put(`/api/update-installment/${id}`, fields)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  changeOrderInstallments(orderId, installments) {
    const result = new Promise((resolve, reject) => {
      apiClient.put(`/api/change-installments/${orderId}`, installments)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  delOrderInstallment(id) {
    const result = new Promise((resolve, reject) => {
      apiClient.delete(`/api/del-installment/${id}`)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  delOrderInstallments(orderId) {
    const result = new Promise((resolve, reject) => {
      apiClient.delete(`/api/delete-installments/${orderId}`)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  setInstallmentsAsReminded(formData) {
    const result = new Promise((resolve, reject) => {
      apiClient.post('/api/set-installments-as-reminded', formData)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  setInstallmentsAsSettled(formData) {
    const result = new Promise((resolve, reject) => {
      apiClient.post('/api/set-installments-as-settled', formData)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  // Order Provisions - prowizje
  addOrderProvision(fields) {
    const result = new Promise((resolve, reject) => {
      apiClient.post(`/api/add-provision`, fields)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  updateOrderProvision(id, fields) {
    const result = new Promise((resolve, reject) => {
      apiClient.patch(`/api/update-provision/${id}`, fields)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  delOrderProvision(id) {
    const result = new Promise((resolve, reject) => {
      apiClient.delete(`/api/del-provision/${id}`)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  uploadOrderScan(orderId, formData) {
    const result = new Promise((resolve, reject) => {
      apiClient.post(`/api/upload-order-scan/${orderId}`, formData)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  delOrderScan(orderId) {
    const result = new Promise((resolve, reject) => {
      apiClient.patch(`/api/del-order-scan/${orderId}`)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  getOrderDataForRenew(orderId) {
    const result = new Promise((resolve, reject) => {
      apiClient.get(`/api/get-order-data-for-renew/${orderId}`)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  renewOrder(orderId, fields) {
    const result = new Promise((resolve, reject) => {
      apiClient.post(`/api/renew-order/${orderId}`, fields)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
};
