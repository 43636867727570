<template>
  <div id="new-order-customer" class="p-3 bg-white dark:bg-gray-900 rounded-lg shadow-lg">
    <div class="flex -mt-3 mb-3 -mx-3 px-3 py-1 border-b dark:border-gray-600">
      <div class="flex-grow uppercase font-semibold text-muted">Klient</div>
      <span v-if="validating === 1" class="text-xs text-muted">
        ok&nbsp;<span class="font-bold text-base text-green-400 dark:text-green-600">&check;</span>
      </span>
      <span v-else-if="validating === -1" class="mt-1 text-xs text-muted">
        uzupełnij<FontAwesomeIcon class="ml-2 text-yellow-400 dark:text-yellow-600" icon="exclamation-triangle" size="lg" />
      </span>
      <span v-else class="mt-1 text-xs text-muted">weryfikuję<FontAwesomeIcon class="ml-2" icon="sync-alt" size="lg" spin /></span>
    </div>
    <div class="flex justify-between pb-2 mb-2 border-b dark:border-gray-600">
      <button class="tw--btn tw--btn-secondary" @click="clearCustomer">Wyczyść formularz</button>
      <div class="flex w-1/2 items-center">
        <HelpContainer>
          <strong>Wyszukiwanie klientów po:</strong>
          <p>nazwisko, nazwa firmy, pesel, regon, nip. Jeśli klient istnieje w bazie ale agent nie posiada ofert powiązanych, należy numer pesel/regon/NIP wpisać w całości.</p>
        </HelpContainer>
        <FindCustomer class="flex-grow" @selected="getCustomer" />
      </div>
    </div>
    <CustomerForm :customer="customer" @selected="getCustomer" @formChanged="setCustomerData" :validationErrors="{ errors: saveErrors, warnings: saveEmptyErrors }" :clear="clearForm" />
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
// import { debounce } from 'lodash';
import { errorHandler } from '../../mixins/errorHandler';
import CustomerService from '../../services/CustomerService';
import FindCustomer from './FindCustomer.vue';
import CustomerForm from '../customer/CustomerForm.vue';

export default {
  name: 'OrderAddSetCustomer',
  components: { FindCustomer, CustomerForm },
  mixins: [errorHandler],
  props: {
    value: String,
    customerImported: {
      type: Boolean,
      default: false,
    },
    customerImportedData: {
      type: Object,
      default() {
        return {
          id: '',
          pesel: '',
          paszport: '',
          regon: '',
        };
      },
    },
  },
  data() {
    return {
      customer: {},
      clearForm: false,
      saveErrors: {},
      saveEmptyErrors: {},
      validating: -1, // -1 errors, 0 validating, 1 no errors
    };
  },
  mounted() {
    if (this.customerImported) {
      this.getCustomer(this.customerImportedData);
    }
  },
  methods: {
    getCustomer(customer) {
      const { pesel, paszport, regon } = customer;
      CustomerService.getCustomerToAddOrder(customer.id, {pesel, paszport, regon})
        .then(response => {
          this.customer = response.data.result;
          this.validating = 1;
          this.saveErrors = {};
          this.saveEmptyErrors = {};
          this.$emit('changed', this.customer);
        })
        .catch(error => this.resolveError(error)); //mixin
    },
    setCustomerData(customerForm) {
      if (this.customer.id === undefined) {
        Object.assign(this.customer, customerForm);
        this.$emit('changed', this.customer);
        this.validating = 0;
        this.validateCustomerForm();
      } else {
        this.validating = 1;
        this.saveErrors = {};
        this.saveEmptyErrors = {};
      }
    },
    validateCustomerForm: debounce(function() {
      CustomerService.validateNewCustomerForm(this.customer)
        .then(response => {
          if (response.data.state === 'ok') {
            this.validating = 1;
            this.saveErrors = {};
            this.saveEmptyErrors = {};
          } else {
            this.validating = -1;
            this.saveErrors = response.data.error_warnings.length !== 0 ? response.data.error_warnings : {};
            this.saveEmptyErrors = response.data.empty_warnings.length !== 0 ? response.data.empty_warnings : {};
          }
        })
        .catch(error => this.resolveError(error)); //mixin
    }, 1000),
    clearCustomer() {
      this.customer = {};
      this.$emit('changed', this.customer);
      this.clearForm = !this.clearForm;
      this.$nextTick(() => { this.clearForm = false; });
    },
  },
  watch: {
    validating(newVal) {
      this.$emit('validated', newVal);
    },
  },
};
</script>
