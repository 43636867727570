<template>
  <div class="h-10 flex flex-shrink-0 items-center justify-between shadow-md bg-white dark:bg-gray-900 z-10 print-hidden">
    <div class="flex items-center h-full">
      <button @click="$emit('toggleMenu')"
        class="flex h-full px-3 cursor-pointer text-blue-600 hover:text-blue-400 text-2xl dark:text-gray-400 dark:hover:text-gray-200"
        :class="{'hidden': menuVisible}"
         title="pokaż menu"
      >
        <FontAwesomeIcon v-if="!menuVisible" class="inline-block self-center" icon="bars" />
      </button>
      <span class="inline-block ml-5 font-semibold text-gray-600 dark:text-gray-400 text-xs sm:text-base">{{ breadcrumbs }}</span>
      <button
        @click="setDarkMode"
        class="flex items-center ml-5 p-0 border-2 border-gray-500 hover:bg-gray-400"
        :class="{'flex-row-reverse': darkMode, 'md:flex': !menuVisible, 'md:hidden': menuVisible}"
        title="Zmień motyw"
      >
        <div class="px-1 py-2 mr-0 bg-gradient-to-r from-gray-100 to-gray-300"></div>
        <div class="px-1 py-2 bg-gradient-to-r from-gray-900 to-gray-600"></div>
      </button>
    </div>
    <div class="flex items-center h-full">
      <span
        class="flex items-center font-mono text-xs sm:text-base px-2 h-full"
        :class="{'text-gray-300 dark:text-gray-500': timeToSessEnd > 60000, 'text-xl text-red-600 font-semibold bg-yellow-200 dark:bg-yellow-900 dark:text-red-400 animate-pulse': timeToSessEnd <=60000 }"
      >
        {{ sessionTime }}
      </span>
      <button
        @click="$store.dispatch('setPasswordModal', true)"
        title="Zmiana hasła do aplikacji"
        class="flex items-center h-full px-2 text-gray-500 hover:text-white hover:no-underline hover:bg-blue-400 dark:hover:bg-gray-400"
      >
        <FontAwesomeIcon class="text-xl" :icon="['far', 'user-circle']" />
        <span class="hidden sm:block ml-1 font-semibold">{{ userName }}</span>
      </button>
      <router-link
        :to="{name: 'Logout'}"
        class="flex items-center px-2 h-full bg-blue-500 text-white hover:text-blue-100 hover:bg-blue-400 dark:text-gray-300 dark:bg-gray-500 dark:hover:text-gray-100 dark:hover:bg-gray-400"
        title="Wyloguj"
      >
        <FontAwesomeIcon class="text-2xl" icon="sign-out-alt" />
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainTopBar',
  props: {
    menuVisible: {
      type: Boolean,
      required: true,
    },
    breadcrumbs: {
      type: String,
      default: '',
    },
    userName: {
      type: String,
      required: true,
    },
    timeToSessEnd: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    darkMode() {
      return this.$store.getters.darkMode;
    },
    sessionTime() {
      const pad = (n) => (`00${n}`).slice(-2);
      let time = this.timeToSessEnd;
      const ms = time % 1000;
      time = (time - ms) / 1000;
      const s = time % 60;
      const ss = pad(s);
      time = (time - s) / 60;
      const m = time % 60;
      const mm = pad(m);
      time = (time - m) / 60;
      const hh = pad(time);

      return time > 0 ? `${hh}:${mm}:${ss}` : `${mm}:${ss}`;
    },
  },
  methods: {
    setDarkMode() {
      this.$store.dispatch('setDarkMode', !this.darkMode);
    },
  },
};
</script>
