/* eslint-disable no-shadow */
import EmployeeService from '../../services/EmployeeService';

const state = {
  employeeSimpleList: [],
  emplTypeList: [],
  emplFormList: [],
  emplManagerList: [],
  emplProvLvlList: [],
};

const mutations = {
  UPDATE_EMPL_SIMPLE_LIST(state, payload) {
    state.employeeSimpleList = payload;
  },
  UPDATE_EMPL_TYPES(state, payload) {
    state.emplTypeList = payload;
  },
  UPDATE_EMPL_FORMS(state, payload) {
    state.emplFormList = payload;
  },
  UPDATE_MANAGER_LIST(state, payload) {
    state.emplManagerList = payload;
  },
  UPDATE_PROV_LVL_LIST(state, payload) {
    state.emplProvLvlList = payload;
  },
};

const actions = {
  getEmployeeSipleList({ commit }) {
    const result = new Promise((resolve, reject) => {
      EmployeeService.getEmployeeSimpleList()
        .then(response => {
          commit('UPDATE_EMPL_SIMPLE_LIST', response.data.employeeSimpleList);
          resolve();
        })
        .catch(error => reject(error));
    });
    return result;
  },
  clearEmployeeSimpleList({ commit }) {
    commit('UPDATE_EMPL_SIMPLE_LIST', []);
  },
  getEmplTypeList({ commit }) {
    const result = new Promise((resolve, reject) => {
      EmployeeService.getEmployeeTypeList()
        .then(response => {
          commit('UPDATE_EMPL_TYPES', response.data.empl_types);
          resolve();
        })
        .catch(error => reject(error));
    });
    return result;
  },
  getEmplFormList({ commit }) {
    const result = new Promise((resolve, reject) => {
      EmployeeService.getEmployeeFormList()
        .then(response => {
          commit('UPDATE_EMPL_FORMS', response.data.empl_forms);
          resolve();
        })
        .catch(error => reject(error));
    });
    return result;
  },
  getManagerList({ commit }) {
    const result = new Promise((resolve, reject) => {
      EmployeeService.getManagerList()
        .then(response => {
          commit('UPDATE_MANAGER_LIST', response.data.managers);
          resolve();
        })
        .catch(error => reject(error));
    });
    return result;
  },
  clearManagerList({ commit }) {
    commit('UPDATE_MANAGER_LIST', []);
  },
  getEmplProvList({ commit }) {
    const result = new Promise((resolve, reject) => {
      EmployeeService.getEmployeeProvList()
        .then(response => {
          commit('UPDATE_PROV_LVL_LIST', response.data.empl_prov_lvls);
          resolve();
        })
        .catch(error => reject(error));
    });
    return result;
  },
  clearEmplProvList({commit }) {
    commit('UPDATE_PROV_LVL_LIST', []);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
