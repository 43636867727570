export const confirm = {
  methods: {
    /**
     * Metoda zmieniająca stan (store) okna potwierdzenia (Confirm)
     * @param {Function} callback Funkcja wywoływana po zatwierdzeniu przciskiem OK
     * @param {Function} cancelCallback Opcjonalnie - Funkcja wywoływana po znulowaniu przciskiem Anuluj
     * @param {string} message Opcjonalny parametr - tytuł okna Confirm
     */
    confirm(callback, message, cancelCallback = () => false) {
      this.$store.dispatch('confirmation/openDialog', { callback, message, cancelCallback });
    },
  },
};
