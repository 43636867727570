<template>
  <div class="flex flex-col gap-4">
    <div class="flex flex-col lg:flex-row gap-4">
      <CustomerCard v-if="customer.id !== undefined"
        class="w-full lg:w-1/2"
        :customer="customer"
        :contactPersons="contactPersons"
        :permissions="permissions"
        :showContactPersons="true"
        @customerUpdated="customerUpdated"
        @phoneAdded="customerPhoneAdded"
        @emailAdded="customerEmailAdded"
        @phoneUpdated="customerPhoneUpdated"
        @emailUpdated="customerEmailUpdated"
        @phoneDeleted="customerPhoneDeleted"
        @emailDeleted="customerEmailDeleted"
        @contactPersonAdded="contactPersonAdded"
        @contactPersonUpdated="contactPersonUpdated"
        @contactPersonDeleted="contactPersonDeleted"
        @ccpPhoneAdded="ccpPhoneAdded"
        @ccpEmailAdded="ccpEmailAdded"
        @ccpPhoneUpdated="ccpPhoneUpdated"
        @ccpEmailUpdated="ccpEmailUpdated"
        @ccpPhoneDeleted="ccpPhoneDeleted"
        @ccpEmailDeleted="ccpEmailDeleted"
        @consentAdded="consentAdded"
        @consentUpdated="consentUpdated"
        @consentDeleted="consentDeleted"
      />
      <NotesCard
        class="w-full lg:w-1/2"
        :notes="notes"
        parent="customer"
        :parentId="customer.id"
        @noteUpdated="noteUpdated"
        @noteDeleted="noteDeleted"
        @noteAdded="noteAdded"
      />
    </div>
    <ShortSalseSummary :summary="salesSummary" />
    <CustomerOrderList :customerId="$route.params.id" @addOrder="addOrder" />
  </div>
</template>

<script>
import { errorHandler } from '../mixins/errorHandler';
import CustomerCard from '../components/customer/CustomerCard.vue';
import NotesCard from '../components/notes/NotesCard.vue';
import CustomerService from '../services/CustomerService';
import NoteService from '../services/NoteService';
import CustomerOrderList from '../components/customer/CustomerOrderList.vue';
import ShortSalseSummary from '../components/ShortSalesSummary.vue';

export default {
  name: 'Customer',
  mixins: [errorHandler],
  components: {
    CustomerCard,
    NotesCard,
    CustomerOrderList,
    ShortSalseSummary,
  },
  data() {
    return {
      customer: {},
      permissions: {},
      notes: [],
      contactPersons: [],
      salesSummary: {},
    };
  },
  created() {
    this.getCustomer();
  },
  methods: {
    getCustomer() {
      CustomerService.getCustomer(this.$route.params.id)
        .then(response => {
          this.customer = response.data.customer;
          this.permissions = response.data.allow;
          NoteService.getNotes({ el_rel_to: 'customer', el_id: this.customer.id })
            .then(resp => {
              this.notes = resp.data.notes;
            })
            .catch(error => this.resolveError(error));
          CustomerService.getContactPersonsFull(this.customer.id)
            .then(resp => {
              this.contactPersons = resp.data.contactPersons;
            })
            .catch(error => this.resolveError(error));
          CustomerService.getCustomerReport(this.customer.id)
            .then(response => {
              this.salesSummary = response.data.report;
            })
            .catch(error => this.resolveError(error));
        })
        .catch(error => this.resolveError(error));
    },
    customerUpdated() {
      this.getCustomer();
    },
    customerPhoneAdded(item) {
      this.customer.phones.push(item);
    },
    customerEmailAdded(item) {
      this.customer.emails.push(item);
    },
    customerPhoneUpdated(item) {
      const updated = this.customer.phones.find(obj => Number(obj.id) === Number(item.id));
      Object.assign(updated, item);
    },
    customerEmailUpdated(item) {
      const updated = this.customer.emails.find(obj => Number(obj.id) === Number(item.id));
      Object.assign(updated, item);
    },
    customerPhoneDeleted(id) {
      this.customer.phones = this.customer.phones.filter(obj => Number(obj.id) !== Number(id));
    },
    customerEmailDeleted(id) {
      this.customer.emails = this.customer.emails.filter(obj => Number(obj.id) !== Number(id));
    },
    contactPersonAdded(ccp) {
      this.contactPersons.push(ccp);
    },
    contactPersonUpdated(ccp) {
      const ccpToUpdate = this.contactPersons.find(obj => Number(obj.id) === Number(ccp.id));
      Object.assign(ccpToUpdate, ccp);
    },
    contactPersonDeleted(id) {
      this.contactPersons = this.contactPersons.filter(obj => Number(obj.id) !== Number(id));
    },
    ccpPhoneAdded(object) {
      const ccp = this.contactPersons.find(obj => Number(obj.id) === Number(object.ccpId));
      ccp.phones.push(object.phone);
    },
    ccpPhoneUpdated(object) {
      const ccp = this.contactPersons.find(obj => Number(obj.id) === Number(object.ccpId));
      const element = ccp.phones.find(obj => Number(obj.id) === Number(object.phone.id));
      Object.assign(element, object.phone);
    },
    ccpPhoneDeleted(object) {
      const ccp = this.contactPersons.find(obj => Number(obj.id) === Number(object.ccpId));
      ccp.phones = ccp.phones.filter(obj => Number(obj.id) !== Number(object.id));
    },
    ccpEmailAdded(object) {
      const ccp = this.contactPersons.find(obj => Number(obj.id) === Number(object.ccpId));
      ccp.emails.push(object.email);
    },
    ccpEmailUpdated(object) {
      const ccp = this.contactPersons.find(obj => Number(obj.id) === Number(object.ccpId));
      const element = ccp.emails.find(obj => Number(obj.id) === Number(object.email.id));
      Object.assign(element, object.email);
    },
    ccpEmailDeleted(object) {
      const ccp = this.contactPersons.find(obj => Number(obj.id) === Number(object.ccpId));
      ccp.emails = ccp.emails.filter(obj => Number(obj.id) !== Number(object.id));
    },
    consentAdded(consent) {
      this.customer.consents.unshift(consent);
    },
    consentUpdated(consent) {
      const consentToUpdate = this.customer.consents.find(obj => Number(obj.id) === Number(consent.id));
      Object.assign(consentToUpdate, consent);
    },
    consentDeleted(id) {
      this.customer.consents = this.customer.consents.filter(obj => Number(obj.id) !== Number(id));
    },
    noteUpdated(note) {
      const updated = this.notes.find(obj => Number(obj.id) === Number(note.id));
      Object.assign(updated, note);
    },
    noteDeleted(id) {
      this.notes = this.notes.filter(note => note.id !== id);
    },
    noteAdded(note) {
      this.notes.unshift(note);
    },
    addOrder() {
      const query = {
        customer_id: this.customer.id,
        pesel: Number(this.customer.is_company) === 0 && Number(this.customer.person.foreigner) === 0 ? this.customer.person.pesel : '',
        paszport: Number(this.customer.is_company) === 0 && Number(this.customer.person.foreigner) === 1 ? this.customer.person.pesel : '',
        regon: Number(this.customer.is_company) === 1 ? this.customer.company.regon : '',
      };
      this.$router.push({ name: 'OrderAdd', query });
    },
  },
};
</script>
