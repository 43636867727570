<template>
  <BaseModal
    @closeModal="$emit('close')"
    :cancel="true"
    :ok="true"
    okLabel="zapisz"
    @ok="save"
    size="md"
  >
    <template #header>
      Edycja notatki
    </template>
    <template #default>
      <div class="flex flex-col gap-y-3">
        <BaseInputWithAddon v-model="form.title" type="text" labelTop="tytuł" :error="saveErrors.title !== undefined ? saveErrors.title : ''" />
        <BaseTextareaWithAddon v-model="form.description" labelTop="treść" :error="saveErrors.description !== undefined ? saveErrors.description : ''" rows="5" />
        <div v-if="note.filename !== ''" class="flex flex-col gap-y-0.5">
          <p class="text-muted text-xs italic">* wstawiony załącznik nie może być edytowany. Aby go podmienić, należy usunąć całą notatkę i dodać ponownie z prawdłowym załącznikiem.</p>
          <div v-if="Number(note.type_id) === 1">
            <img @contextmenu.prevent="downloadConfirm" class="rounded w-full" :src="`${apiUrl}/notatki/show-image/${note.id}`" alt="Podgląd">
          </div>
          <div v-else-if="Number(note.type_id) === 2"
            class="w-full flex justify-center mb-2"
          >
            <a
              :href="`${apiUrl}/notatki/show-file/${note.id}`"
              target="_blank" title="kliknij, aby otworzyć/pobrać załącznik"
              class="group max-w-min flex flex-col items-center cursor-pointer"
            >
              <div class="max-w-min mb-2 bg-yellow-200 text-gray-400 group-hover:bg-yellow-300 dark:bg-yellow-900 dark:group-hover:bg-yellow-800 dark:text-gray-800 rounded-lg">
                <FontAwesomeIcon v-if="note.file_type === 'application/pdf'" :icon="['far', 'file-pdf']" size="8x" class="p-1" />
                <FontAwesomeIcon v-else :icon="['far', 'file-pdf']" size="8x" class="p-1" />
              </div>
              <span class="group-hover:underline">{{ note.filename }}</span>
            </a>
          </div>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import NoteService from '../../services/NoteService';
import { confirm } from '../../mixins/confirm';
import { errorHandler } from '../../mixins/errorHandler';

export default {
  name: 'NoteEdit',
  mixins: [confirm, errorHandler],
  props: {
    note: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        title: '',
        description: '',
      },
      saveErrors: {},
      isBusy: false,
      apiUrl: process.env.VUE_APP_API_URL,
    };
  },
  mounted() {
    Object.assign(this.form, this.note);
  },
  methods: {
    save() {
      this.isBusy = true;
      NoteService.updateNote(this.note.id, this.form)
        .then(() => {
          this.$emit('updated', this.form);
          this.isBusy = false;
        })
        .catch(error => {
          this.saveErrors = this.resolveError(error);
          this.isBusy = false;
        });
    },
    downloadConfirm() {
      this.confirm(() => this.download(), 'Pobrac zdjęcie na dysk?');
    },
    download() {
      const newWindow = window.open(null, this.note.id);
      newWindow.location.href = `${this.apiUrl}/notatki/show-file/${this.note.id}`;
    },
  },
};
</script>
