<template>
  <div id="customer-card" class="p-3 bg-white dark:bg-gray-900 rounded-lg shadow-lg">
    <div class="flex items-center -mt-3 mb-3 -mx-3 px-3 py-1 border-b dark:border-gray-600 border-gray-400">
      <div class="flex flex-grow items-center">
        <div class="text-muted">
          <span class="uppercase font-semibold">Dane finansowe</span>
        </div>
      </div>
      <div v-if="validating === 1 && (permissions.change_order_made_by_office || permissions.change_order_employee)">
        <CardMenu>
          <template #default>
            <ul class="flex flex-col gap-2 text-sm whitespace-nowrap">
              <li v-if="permissions.change_order_made_by_office"
                @click="finDataEditModal = true"
                class="cursor-pointer hover:underline"
              >
                <FontAwesomeIcon class="text-blue-400 dark:text-blue-600" :icon="['far', 'edit']" /> edytuj
              </li>
              <li v-if="permissions.change_order_employee"
                @click="employeeChangeModal = true"
                class="cursor-pointer hover:underline"
              >
                <FontAwesomeIcon class="text-purple-400 dark:text-purple-600" icon="exchange-alt" /> zmień agenta
              </li>
            </ul>
          </template>
        </CardMenu>
      </div>
      <span v-else-if="validating === 0" class="mt-1 text-xs text-muted">wczytuję<FontAwesomeIcon class="ml-2" icon="sync-alt" size="lg" spin /></span>
    </div>
    <div class="flex flex-col gap-3">
      <div class="flex flex-col gap-1">
        <div class="w-full border-b dark:border-gray-600 border-gray-400 uppercase text-muted text-xs font-semibold">Obsługujący</div>
        <div class="flex flex-wrap justify-between">
          <div class="w-full md:w-auto flex gap-2 items-center"><span class="text-muted">agent</span><span class="text-bright font-bold text-lg">{{ employee.fullname }}</span></div>
          <div class="flex gap-1 items-center">
            <span class="text-muted"><FontAwesomeIcon icon="phone-alt" size="sm" /></span>
            <a v-if="employee.phone !== ''" :href="`tel:${employee.phone}`" class="tw--link">{{ employee.phone | phoneNo }}</a>
            <span v-else class="italic">nie podano</span>
          </div>
          <div class="flex gap-1 items-center">
            <span class="text-muted"><FontAwesomeIcon icon="at" size="sm" /></span>
            <a v-if="employee.email !== ''" :href="`mailto:${employee.email}`" class="tw--link">{{ employee.email }}</a>
            <span v-else class="italic">nie podano</span>
          </div>
          <div>
            <button @click="showInsurers = !showInsurers" class="tw--btn tw--btn-small" :disabled="employeeInsurers.length === 0" :title="employeeInsurers.length === 0 ? 'brak' : ''">
              <FontAwesomeIcon :icon="['far', 'building']" />upr. KNF
              <FontAwesomeIcon v-if="!showInsurers" icon="angle-down" />
              <FontAwesomeIcon v-else icon="angle-up" />
            </button>
          </div>
          <button v-if="permissions.change_order_employee" @click="employeeChangeModal = true" class="tw--btn-icon" title="zmień agenta"><FontAwesomeIcon :icon="['fas', 'exchange-alt']" /></button>
        </div>
        <transition name="fade">
          <div v-if="showInsurers" class="flex flex-wrap text-sm border dark:border-gray-600 rounded">
            <span v-for="insurer in employeeInsurers" :key="insurer.id" class="whitespace-nowrap px-1 bg-gray-200 dark:bg-gray-700 rounded m-0.5">{{ insurer.name }}</span>
          </div>
        </transition>
        <div class="flex flex-wrap justify-between border-t dark:border-gray-600 md:border-t-0">
          <div class="flex gap-2">
            <span class="text-muted">ofertę przygotował</span>
            <span class="text-bright">{{ Number(order.made_by_office) === 0 ? 'agent' : 'biuro' }}</span>
            <div v-if="leadFunctionalityAllowed && order.lead == 1">
              <span class="text-bright text-sm bg-green-300 dark:bg-green-700 px-1 rounded-sm">LEAD</span>
            </div>

          </div>
          <div class="flex gap-2">
            <span class="text-muted">wystawiający</span>
            <span class="text-bright">{{ copywriter.surname !== undefined ? `${copywriter.surname} ${copywriter.forename1}` : 'brak' }}</span>
          </div>
          <button v-if="permissions.change_order_made_by_office" @click="finDataEditModal = true" class="tw--btn-icon" title="edytuj parametry finansowe"><FontAwesomeIcon :icon="['far', 'edit']" /></button>
        </div>
      </div>
      <OrderFinancialDataItems
        :order="order"
        :items="items"
        :employee="employee"
        :copywriter="copywriter"
        :addItemsInstallmentsAllow="Number(permissions.add_items_installments_allow) === 1"
        @itemsChanged="itemsChanged"
        @itemDeleted="itemDeleted"
        @newPolicyNo="newPolicyNo"
      />
      <OrderFinancialDataInstallments
        :order="order"
        :installments="installments"
        :items="items"
        :addItemsInstallmentsAllow="Number(permissions.add_items_installments_allow) === 1"
        @installmentsChanged="installmentsChanged"
        @installmentDeleted="installmentDeleted"
      />
      <OrderFinancialDataProvisions  v-if="currentUserType !== 'owfca' && userRole !== ''"
        :order="order"
        :provisions="provisions"
        :items="items"
        @added="provisionAdded"
        @updated="provisionUpdated"
        @deleted="provisionDeleted"
      />

      <OrderFinancialDataEdit
        v-if="finDataEditModal && permissions.change_order_made_by_office"
        :order="order"
        @close="finDataEditModal = false"
        @updated="financialDataUpdated"
      />
      <OrderEmployeeChange
        v-if="employeeChangeModal && permissions.change_order_employee"
        :order="order"
        :employeeId="order.employee_id"
        @close="employeeChangeModal = false"
        @changeEmployee="changeEmployee"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import OrderFinancialDataItems from './OrderFinancialDataItems.vue';
import OrderFinancialDataInstallments from './OrderFinancialDataInstallments.vue';
import OrderFinancialDataProvisions from './OrderFinancialDataProvisions.vue';
import OrderFinancialDataEdit from './OrderFinancialDataEdit.vue';
import OrderEmployeeChange from './OrderEmployeeChange.vue';

export default {
  name: 'OrderFinancialData',
  components: {
    OrderFinancialDataItems,
    OrderFinancialDataInstallments,
    OrderFinancialDataProvisions,
    OrderFinancialDataEdit,
    OrderEmployeeChange,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    installments: {
      type: Array,
      required: true,
    },
    provisions: {
      type: Array,
      required: true,
    },
    permissions: {
      type: Object,
      required: true,
    },
    copywriter: {
      type: Object,
      required: true,
    },
    employee: {
      type: Object,
      required: true,
    },
    employeeInsurers: {
      type: Array,
      required: true,
    },
    employeeList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showInsurers: false,
      finDataEditModal: false,
      employeeChangeModal: false,
    };
  },
  computed: {
    ...mapGetters([
      'currentUserData',
      'currentUserType',
    ]),
    userRole() {
      let role = '';
      if (this.currentUserType === 'root' || this.currentUserType === 'admin') {
        role = 'admin';
      } else if (this.order.employee_id === this.currentUserData.empl_id) {
        role = 'agent';
      } else if (this.order.copywriter_id === this.currentUserData.empl_id && this.order.made_by_office === '1') {
        role = 'copyw';
      }
      return role;
    },
    leadFunctionalityAllowed() {
      return this.$store.getters.progOptions?.leadFunctionality;
    },
    validating() {
      return 1;
    },
  },
  methods: {
    itemsChanged(items) {
      this.$emit('itemsChanged', items);
    },
    itemDeleted(id) {
      this.$emit('itemDeleted', id);
    },
    newPolicyNo(no) {
      this.$emit('newPolicyNo', no);
    },
    installmentsChanged(installments) {
      this.$emit('installmentsChanged', installments);
    },
    installmentDeleted(id) {
      this.$emit('installmentDeleted', id);
    },
    provisionAdded(provision) {
      this.$emit('provisionAdded', provision);
    },
    provisionUpdated(provision) {
      this.$emit('provisionUpdated', provision);
    },
    provisionDeleted(id) {
      this.$emit('provisionDeleted', id);
    },
    financialDataUpdated() {
      this.$emit('financialDataUpdated');
    },
    changeEmployee(id) {
      this.$emit('changeEmployee', id);
      this.employeeChangeModal = false;
    },
  },
};
</script>
