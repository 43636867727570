<template>
  <div id="app" class="flex max-h-screen antialiased" :class="{'dark': darkMode}">
    <template v-if="!loading">
      <div v-if="menuVisible && loggedIn"
        class="fixed w-full bottom-0 min-h-16 bg-gradient-to-tl opacity-90 md:relative md:w-min md:min-w-min md:order-first md:opacity-100 md:h-screen md:border-r md:overflow-y-auto md:overflow-x-hidden scrollbar
          from-blue-900 to-blue-800 md:border-blue-800
          dark:from-black dark:to-gray-900 md:dark:border-gray-900 z-50 print-hidden"
      >
        <MainSidebar @toggleMenu="menuVisible = !menuVisible" />
      </div>
      <div class="relative flex flex-col h-screen w-full bg-gray-200 text-gray-800 dark:bg-black dark:text-gray-400 print-overflow-auto" :class="contentTailView ? 'flex-grow' : ''">
        <MainTopBar v-if="loggedIn" @toggleMenu="menuVisible = !menuVisible" :menuVisible="menuVisible" :userName="currentUserData.user_name" :breadcrumbs="breadcrumbs" :timeToSessEnd="timeToSessionEnd" />
        <router-view :class="{'overflow-y-auto flex-grow p-4 md:mb-0 scrollbar': loggedIn}" />
      </div>
    </template>
    <div v-else class="fixed h-screen w-screen inset-0 flex justify-center items-center bg-white dark:bg-black dark:text-gray-400 opacity-50 z-50">
      <div class="flex flex-row items-center">
        <FontAwesomeIcon class="mr-6" icon="spinner" size="2x" spin />
        <h1 class="text-4xl">Trwa ładowanie aplikacji...</h1>
      </div>
    </div>
    <transition name="fade">
      <div v-if="loadingData" class="fixed h-screen w-screen inset-0 flex justify-center items-center bg-white dark:bg-black dark:text-gray-400 bg-opacity-30 dark:bg-opacity-40 z-50">
        <div class="flex flex-row items-center">
          <FontAwesomeIcon class="text-gray-400 dark:text-gray-600 opacity-70" icon="circle-notch" size="6x" spin />
        </div>
      </div>
    </transition>

    <NotificationContainer/>
    <ConfirmDialog :show="$store.getters['confirmation/active']" />
    <ChangePassword v-if="$store.getters.changePasswordModal" />
  </div>
</template>

<script>
import axios from 'axios';
import throttle from 'lodash/throttle';
// import { throttle } from 'lodash';
import { mapGetters } from 'vuex';
import MainSidebar from '@/components/MainSidebar.vue';
import MainTopBar from '@/components/MainTopBar.vue';
import NotificationContainer from '@/components/NotificationContainer.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import ChangePassword from './components/user/ChangePassword.vue';

export default {
  name: 'Emultia2',
  components: {
    MainSidebar,
    MainTopBar,
    NotificationContainer,
    ConfirmDialog,
    ChangePassword,
  },
  data() {
    return {
      timeout: '',
      interval: '',
      sessExpTime: 60 * 1000,
      menuVisible: true,
      loading: true,
      timeToSessionEnd: 0,
    };
  },
  computed: {
    ...mapGetters([
      'loggedIn',
      'currentUserData',
      'darkMode',
      'loadingData',
    ]),
    breadcrumbs() {
      return this.$route.meta.niceName;
    },
    contentTailView() {
      return this.menuVisible && this.loggedIn;
    },
  },
  created() {
    axios.defaults.headers.common.Accept = 'application/json';
    this.menuVisible = window.innerWidth > 767;
    this.$store.dispatch('getConfigParams')
      .then(() => {
        // this.loading = false;
        this.$store.dispatch('getCurrentUserData')
          .then(() => {
            this.$store.dispatch('getProgOptions');
            if (this.$route.name === 'Login') {
              this.$router.push({ name: 'Calendar' });
            }
            this.loading = false;
          })
          .catch(() => {
            if (!['Login', 'PasswordEmail', 'PasswordReset'].includes(this.$route.name)) {
              this.$store.dispatch('storeCurrentUrl', this.$route.fullPath);
              this.$router.push({ name: 'Login' });
            }
            this.loading = false;
          });
      })
      .catch(() => {
        this.$store.dispatch('notifications/add', {
          type: 'danger',
          message: 'Nie można załadować aplikacji. Problem z odpowiedzią serwera. Spróbuj ponownie później.',
          time: 0,
        });
      });
  },
  methods: {
    sessionRefresh() {
      this.updateLastAct();
      clearTimeout(this.timeout);
      this.timeToSessionEnd = this.sessExpTime -1000; //dla ładniejszegoi wyświetlania
      this.timeout = setTimeout(() => {
        if (this.loggedIn) {
          this.$store.dispatch('storeCurrentUrl', this.$route.fullPath);
          this.$router.push({ name: 'Logout' });
        }
      }, this.sessExpTime);
    },
    updateLastAct: throttle(() => {
        axios.get(`${process.env.VUE_APP_API_URL}/api/refresh-last-activity`)
          .catch(() => {
            if (this.loggedIn) {
              this.$store.dispatch('storeCurrentUrl', this.$route.fullPath);
              this.$router.push({ name: 'Logout' });
              this.$store.dispatch('notifications/add', {
                type: 'warning',
                message: 'Twoja sesja wygasła',
                time: 10,
              });
            }
          });
      },
      10000,
      { trailing: false },
    ),
    // decreaseSessionTime() {
    //   this.timeToSessionEnd - 1000
    // }
  },
  watch: {
    loggedIn(newVal) {
      if (newVal) {
        this.sessExpTime = this.currentUserData.sess_exp_time * 60 * 1000;
        this.sessionRefresh();
        document.addEventListener('click', this.sessionRefresh);
        this.timeToSessionEnd = this.sessExpTime;
        this.interval = setInterval(() => { this.timeToSessionEnd = this.timeToSessionEnd - 1000; }, 1000);
      } else {
        document.removeEventListener('click', this.sessionRefresh);
        clearInterval(this.interval);
      }
    },
  },
};
</script>

<style src="./assets/tailwind.css">
