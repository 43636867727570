/* eslint-disable comma-dangle */
import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  // withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-type": "application/json",
  },
});

export default {
  /**
   * Zwraca pełne dane Customer (identyczne jak w Order->customer)
   * @param {String} id - id klienta
   * @returns API response
   */
  getCustomer(id) {
    const result = new Promise((resolve, reject) => {
      apiClient
        .get(`/api/customers/${id}`)
        .then((response) =>
          response.data.status === "success"
            ? resolve(response)
            : reject(response)
        )
        .catch((error) => reject(error));
    });
    return result;
  },
  getCustomers(params) {
    const result = new Promise((resolve, reject) => {
      apiClient
        .get("/api/get-customer-list", { params })
        .then((response) =>
          response.data.status === "success"
            ? resolve(response)
            : reject(response)
        )
        .catch((error) => reject(error));
    });
    return result;
  },
  getCustomerToAddOrder(id, params) {
    const result = new Promise((resolve, reject) => {
      apiClient
        .get(`/api/get-customer-to-add-order/${id}`, { params })
        .then((response) =>
          response.data.status === "success"
            ? resolve(response)
            : reject(response)
        )
        .catch((error) => reject(error));
    });
    return result;
  },
  searchCustomer(searchString) {
    const result = new Promise((resolve, reject) => {
      apiClient
        .get("/api/customers/search", { params: { search: searchString } })
        .then((response) =>
          response.data.status === "success"
            ? resolve(response)
            : reject(response)
        )
        .catch((error) => reject(error));
    });
    return result;
  },
  getCustomerTypes() {
    const result = new Promise((resolve, reject) => {
      apiClient
        .get("/api/get-cust-type-list")
        .then((response) =>
          response.data.status === "success"
            ? resolve(response)
            : reject(response)
        )
        .catch((error) => reject(error));
    });
    return result;
  },
  /**
   * Weryfikuje uzupełnione dane z formularza na bieżąco (dodawanie nowego klienta)
   * zwraca stan uzupełnienia formularza, ale nie jako błąd, tylko state:
   *  staus: 'success', state: 'errors', errors: {field1: 'msg', field2: 'msg', ...}, error_warnings: {...}, empty_warnings: {...} lub:
   *  staus: 'success', state: 'ok', errors: {}, error_warnings: {}, empty_warnings: {}
   * @param {Object} form -dane z formularza
   * @returns Promise
   */
  validateNewCustomerForm(form) {
    const result = new Promise((resolve, reject) => {
      apiClient
        .post("/api/orders/validate-new-customer-form", form)
        .then((response) =>
          response.data.status === "success"
            ? resolve(response)
            : reject(response)
        )
        .catch((error) => reject(error));
    });
    return result;
  },
  storeNewCustomer(form) {
    const result = new Promise((resolve, reject) => {
      apiClient
        .post("/api/customers", form)
        .then((response) =>
          response.data.status === "success"
            ? resolve(response)
            : reject(response)
        )
        .catch((error) => reject(error));
    });
    return result;
  },
  /**
   * Weryfikuje uzupełnione dane z formularza na bieżąco  (edycja istniejącego klienta)
   * zwraca stan uzupełnienia formularza, ale nie jako błąd, tylko state:
   *  staus: 'success', state: 'errors', errors: {field1: 'msg', field2: 'msg', ...}, error_warnings: {...}, empty_warnings: {...} lub:
   *  staus: 'success', state: 'ok', errors: {}, error_warnings: {}, empty_warnings: {}
   * @param {Object} form -dane z formularza
   * @returns Promise
   */
  validateUpdateCustomerForm(id, form) {
    const result = new Promise((resolve, reject) => {
      apiClient
        .post(`/api/orders/validate-update-customer-form/${id}`, form)
        .then((response) =>
          response.data.status === "success"
            ? resolve(response)
            : reject(response)
        )
        .catch((error) => reject(error));
    });
    return result;
  },
  updateCustomer(id, fields) {
    const result = new Promise((resolve, reject) => {
      apiClient
        .patch(`/api/customers/${id}`, fields)
        .then((response) =>
          response.data.status === "success"
            ? resolve(response)
            : reject(response)
        )
        .catch((error) => reject(error));
    });
    return result;
  },
  getContactPersonsFull(customerId) {
    const result = new Promise((resolve, reject) => {
      apiClient
        .get(`/api/get-contact-persons-full/${customerId}`)
        .then((response) =>
          response.data.status === "success"
            ? resolve(response)
            : reject(response)
        )
        .catch((error) => reject(error));
    });
    return result;
  },
  addContactPerson(fields) {
    const result = new Promise((resolve, reject) => {
      apiClient
        .post("/api/customer-contact-persons", fields)
        .then((response) =>
          response.data.status === "success"
            ? resolve(response)
            : reject(response)
        )
        .catch((error) => reject(error));
    });
    return result;
  },
  updateContactPerson(id, fields) {
    const result = new Promise((resolve, reject) => {
      apiClient
        .patch(`/api/customer-contact-persons/${id}`, fields)
        .then((response) =>
          response.data.status === "success"
            ? resolve(response)
            : reject(response)
        )
        .catch((error) => reject(error));
    });
    return result;
  },
  delContactPerson(id) {
    const result = new Promise((resolve, reject) => {
      apiClient
        .delete(`/api/customer-contact-persons/${id}`)
        .then((response) =>
          response.data.status === "success"
            ? resolve(response)
            : reject(response)
        )
        .catch((error) => reject(error));
    });
    return result;
  },
  getCustomerReport(id) {
    const result = new Promise((resolve, reject) => {
      apiClient
        .get(`/api/dashboard/customer/${id}`)
        .then((response) =>
          response.data.status === "success"
            ? resolve(response)
            : reject(response)
        )
        .catch((error) => reject(error));
    });
    return result;
  },
  checkCustomerIsExclusiveTouser(custId) {
    const result = new Promise((resolve, reject) => {
      apiClient
        .get(`/api/customers/${custId}/check-exclusivity`)
        .then((response) =>
          response.data.status === "success"
            ? resolve(response)
            : reject(response)
        )
        .catch((error) => reject(error));
    });
    return result;
  },
  anonymizePersonalData(personId) {
    const result = new Promise((resolve, reject) => {
      apiClient
        .patch(`/api/anonymize-personal-data/${personId}`)
        .then((response) =>
          response.data.status === "success"
            ? resolve(response)
            : reject(response)
        )
        .catch((error) => reject(error));
    });
    return result;
  },
};
