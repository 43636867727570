<template>
  <div id="add-customer-form" class="flex flex-col gap-1">
    <div id="cust-type-pref-cont-form" class="flex flex-wrap gap-4">
      <BaseSelectWithAddon
        class="w-full md:w-1/2 lg:w-full xl:w-1/2"
        v-model="newCustomer.cust_type_id"
        labelTop="źródło pozyskania"
        :options="custTypeList"
        :error="validationErrors.errors.cust_type_id !== undefined ? validationErrors.errors.cust_type_id : ''"
        :warning="validationErrors.warnings.cust_type_id !== undefined ? validationErrors.warnings.cust_type_id : ''"
        :disabled="customer.id !== undefined && !edit"
      />
      <BaseSelectWithAddon
        class="flex-grow"
        v-model="newCustomer.pref_contact_form"
        labelTop="preferowana forma kontaktu"
        :options="prefContactFormList"
        :error="validationErrors.errors.pref_contact_form !== undefined ? validationErrors.errors.pref_contact_form : ''"
        :warning="validationErrors.warnings.pref_contact_form !== undefined ? validationErrors.warnings.pref_contact_form : ''"
        :disabled="customer.id !== undefined && !edit"
      />
    </div>

    <div v-if="edit" id="cust-edit-phone-email" class="flex flex-wrap gap-4 mt-2">
      <span class="text-xs text-muted italic">* możliwość edycji numerów telefonów i adresów email znajduje się bezpośrednio w karcie klienta</span>
    </div>
    <div v-else id="cust-phone-email" class="flex flex-wrap gap-4 mt-2">
      <BaseInputWithAddon
        class="w-full md:w-1/3 lg:w-full xl:w-1/3"
        type="text"
        v-model="newCustomer.phone"
        labelIconAddon="phone-alt"
        :error="validationErrors.errors.phone !== undefined ? validationErrors.errors.phone : ''"
        :warning="validationErrors.warnings.phone !== undefined ? validationErrors.warnings.phone : ''"
        :disabled="customer.id !== undefined && !edit"
      />
      <BaseInputWithAddon
        class="flex-grow"
        type="text"
        v-model="newCustomer.email"
        labelIconAddon="at"
        :error="validationErrors.errors.email !== undefined ? validationErrors.errors.email : ''"
        :warning="validationErrors.warnings.email !== undefined ? validationErrors.warnings.email : ''"
        :disabled="customer.id !== undefined && !edit"
      />
    </div>
    <!-- <hr class="dark:border-gray-600"> -->
    <div id="customer-private-company" v-if="!edit" class="flex gap-4 items-center">
      <label for="prywatny">
        <input class="mr-2" type="radio" id="prywatny" v-model="newCustomer.is_company" value="0" :disabled="customer.id !== undefined && !edit">
        prywatny
      </label>
      <label for="firma">
        <input class="mr-2" type="radio" id="firma" v-model="newCustomer.is_company" value="1" :disabled="customer.id !== undefined && !edit">
        firma
      </label>
    </div>
    <hr v-else class="dark:border-gray-600 mt-1">
    <div id="company-name-shortname" v-show="newCustomer.is_company === '1'" class="flex flex-wrap gap-4">
      <BaseInputWithAddon v-if="edit"
        class="w-full md:w-2/3 lg:w-full xl:w-2/3"
        type="text"
        v-model="newCustomer.name"
        labelTop="pełna nazwa firmy"
        :error="validationErrors.errors.name !== undefined ? validationErrors.errors.name : ''"
        :warning="validationErrors.warnings.name !== undefined ? validationErrors.warnings.name : ''"
      />
      <BaseAutocompleteInput v-else
        class="w-full md:w-2/3 lg:w-full xl:w-2/3"
        @selected="setCustomer"
        v-model="newCustomer.name"
        labelTop="pełna nazwa firmy"
        :searchUrl="searchCompanyNameUrl"
        :error="validationErrors.errors.name !== undefined ? validationErrors.errors.name : ''"
        :warning="validationErrors.warnings.name !== undefined ? validationErrors.warnings.name : ''"
        :disabled="customer.id !== undefined && !edit"
      />
      <BaseInputWithAddon
        class="flex-grow"
        type="text"
        v-model="newCustomer.shortname"
        labelTop="nazwa skrócona"
        :error="validationErrors.errors.shortname !== undefined ? validationErrors.errors.shortname : ''"
        :warning="validationErrors.warnings.shortname !== undefined ? validationErrors.warnings.shortname : ''"
        :disabled="customer.id !== undefined && !edit"
      />
    </div>
    <div id="company-regon-nip" v-show="newCustomer.is_company === '1'" class="flex flex-wrap gap-x-4 gap-y-1">
      <BaseInputWithAddon v-if="edit"
        class="w-full"
        :class="edit ? 'sm:w-1/3' : 'sm:w-1/2'"
        type="text"
        v-model="newCustomer.regon"
        labelTop="regon"
        :error="validationErrors.errors.regon !== undefined ? validationErrors.errors.regon : ''"
        :warning="validationErrors.warnings.regon !== undefined ? validationErrors.warnings.regon : ''"
      />
      <BaseAutocompleteInput v-else
        class="w-full"
        :class="edit ? 'sm:w-1/3' : 'sm:w-1/2'"
        @selected="setCustomer"
        v-model="newCustomer.regon"
        labelTop="regon"
        :searchUrl="searchCompanyRegonUrl"
        :error="validationErrors.errors.regon !== undefined ? validationErrors.errors.regon : ''"
        :warning="validationErrors.warnings.regon !== undefined ? validationErrors.warnings.regon : ''"
        :disabled="customer.id !== undefined && !edit"
      />
      <BaseInputWithAddon
        :class="edit ? 'w-full sm:w-auto sm:flex-grow' : 'flex-grow'"
        type="text"
        v-model="newCustomer.nip"
        labelTop="nip"
        :error="validationErrors.errors.nip !== undefined ? validationErrors.errors.nip : ''"
        :warning="validationErrors.warnings.nip !== undefined ? validationErrors.warnings.nip : ''"
        :disabled="customer.id !== undefined && !edit"
      />
      <BaseInputWithAddon v-if="edit"
        class="flex-grow"
        type="text"
        v-model="newCustomer.vat_ue"
        labelTop="pesel (dla IDG)"
        :error="validationErrors.errors.vat_ue !== undefined ? validationErrors.errors.vat_ue : ''"
        :warning="validationErrors.warnings.vat_ue !== undefined ? validationErrors.warnings.vat_ue : ''"
        :disabled="customer.id !== undefined && !edit"
      />
    </div>
    <div id="surname-forename" v-show="newCustomer.is_company === '0'" class="flex flex-wrap gap-4">
      <BaseInputWithAddon v-if="edit"
        class="w-full lg:w-full"
        :class="edit ? 'md:w-2/5 xl:w-2/5' : 'md:w-3/5 xl:w-3/5'"
        type="text"
        v-model="newCustomer.surname"
        labelTop="nazwisko"
        :error="validationErrors.errors.surname !== undefined ? validationErrors.errors.surname : ''"
        :warning="validationErrors.warnings.surname !== undefined ? validationErrors.warnings.surname : ''"
      />
      <BaseAutocompleteInput v-else
        class="w-full lg:w-full"
        :class="edit ? 'md:w-2/5 xl:w-2/5' : 'md:w-3/5 xl:w-3/5'"
        @selected="setCustomer"
        v-model="newCustomer.surname"
        labelTop="nazwisko"
        :searchUrl="searchPersonSurnameUrl"
        :error="validationErrors.errors.surname !== undefined ? validationErrors.errors.surname : ''"
        :warning="validationErrors.warnings.surname !== undefined ? validationErrors.warnings.surname : ''"
        :disabled="customer.id !== undefined && !edit"
      />
        <!-- class="w-full md:w-auto md:flex-grow lg:w-full" -->
      <BaseAutocompleteInput
        class="flex-grow"
        v-model="newCustomer.forename1"
        labelTop="imię"
        :searchUrl="searchForenameUrl"
        :error="validationErrors.errors.forename1 !== undefined ? validationErrors.errors.forename1 : ''"
        :warning="validationErrors.warnings.forename1 !== undefined ? validationErrors.warnings.forename1 : ''"
        :disabled="customer.id !== undefined && !edit"
      />
      <BaseAutocompleteInput v-if="edit"
        class="flex-grow"
        v-model="newCustomer.forename2"
        labelTop="imię drugie"
        :searchUrl="searchForenameUrl"
        :error="validationErrors.errors.forename2 !== undefined ? validationErrors.errors.forename2 : ''"
        :warning="validationErrors.warnings.forename2 !== undefined ? validationErrors.warnings.forename2 : ''"
        :disabled="customer.id !== undefined && !edit"
      />
    </div>
    <div id="pesel-nationality" v-show="newCustomer.is_company === '0'" class="flex flex-wrap gap-4">
      <BaseInputWithAddon v-if="edit"
        class="w-full sm:w-2/5"
        type="text"
        v-model="newCustomer.pesel"
        :labelTop="newCustomer.foreigner === '0' ? 'pesel' : 'paszport'"
        :error="validationErrors.errors.pesel !== undefined ? validationErrors.errors.pesel : ''"
        :warning="validationErrors.warnings.pesel !== undefined ? validationErrors.warnings.pesel : ''"
      />
      <BaseAutocompleteInput v-else
        class="w-full sm:w-2/5"
        @selected="setCustomer"
        v-model="newCustomer.pesel"
        :labelTop="newCustomer.foreigner === '0' ? 'pesel' : 'paszport'"
        :searchUrl="searchPersonPeselUrl"
        :error="validationErrors.errors.pesel !== undefined ? validationErrors.errors.pesel : ''"
        :warning="validationErrors.warnings.pesel !== undefined ? validationErrors.warnings.pesel : ''"
        :disabled="customer.id !== undefined && !edit"
      />
      <BaseInputWithAddon
        v-if="edit"
        class="flex-grow"
        type="date"
        v-model="newCustomer.birth_date_h"
        labelTop="data urodzenia"
        :error="validationErrors.errors.birth_date_h !== undefined ? validationErrors.errors.birth_date_h : ''"
        :warning="validationErrors.warnings.birth_date_h !== undefined ? validationErrors.warnings.birth_date_h : ''"
      />
      <div class="flex flex-col">
        <div class="text-sm text-transparent">Obcokrajowiec</div>
        <label for="foreigner">
          <input
            id="foreigner"
            type="checkbox"
            true-value="1" false-value="0"
            v-model="newCustomer.foreigner"
            @change="newCustomer.nationality = ''"
            :disabled="customer.id !== undefined && !edit"
          > obcokrajowiec
        </label>
      </div>
      <BaseAutocompleteInput
        :class="edit ? 'w-full sm:w-1/2' : 'flex-grow'"
        v-model="newCustomer.nationality"
        labelTop="narodowość"
        :searchUrl="searchCountryUrl"
        :error="validationErrors.errors.nationality !== undefined ? validationErrors.errors.nationality : ''"
        :warning="validationErrors.warnings.nationality !== undefined ? validationErrors.warnings.nationality : ''"
        :disabled="(customer.id !== undefined && !edit) || newCustomer.foreigner === '0'"
      />
    </div>
    <div class="text-sm text-muted border-b dark:border-gray-600">adres</div>
    <div id="customer-main-address-street" class="flex flex-wrap gap-4">
      <BaseAutocompleteInput
        class="w-full md:w-2/3 lg:w-full xl:w-2/3"
        v-model="newCustomer.street"
        labelTop="ulica"
        :searchUrl="searchStreetUrl"
        :error="validationErrors.errors.street !== undefined ? validationErrors.errors.street : ''"
        :warning="validationErrors.warnings.street !== undefined ? validationErrors.warnings.street : ''"
        :disabled="customer.id !== undefined && !edit"
      />
      <BaseInputWithAddon
        class="flex-grow"
        type="text"
        v-model="newCustomer.house_ap_no"
        labelTop="nr domu/mieszkania"
        :error="validationErrors.errors.house_ap_no !== undefined ? validationErrors.errors.house_ap_no : ''"
        :warning="validationErrors.warnings.house_ap_no !== undefined ? validationErrors.warnings.house_ap_no : ''"
        :disabled="customer.id !== undefined && !edit"
      />
    </div>
    <div id="customer-main-address-city" class="flex flex-wrap gap-4">
      <BaseAutocompleteInput
        class="flex-grow"
        @selected="setCity"
        v-model="newCustomer.postal_code"
        labelTop="kod pocztowy"
        :searchUrl="searchPostalCodeCityUrl"
        :error="validationErrors.errors.postal_code !== undefined ? validationErrors.errors.postal_code : ''"
        :warning="validationErrors.warnings.postal_code !== undefined ? validationErrors.warnings.postal_code : ''"
        :disabled="customer.id !== undefined && !edit"
      />
      <BaseAutocompleteInput
        class="w-full md:w-2/3 lg:w-full xl:w-2/3"
        v-model="newCustomer.city"
        labelTop="miasto"
        :searchUrl="searchCityUrl"
        :error="validationErrors.errors.city !== undefined ? validationErrors.errors.city : ''"
        :warning="validationErrors.warnings.city !== undefined ? validationErrors.warnings.city : ''"
        :disabled="customer.id !== undefined && !edit"
      />
    </div>
    <div id="customer-main-address-region-country" class="flex flex-wrap gap-4">
      <BaseAutocompleteInput
        class="w-full md:w-1/2 lg:w-full xl:w-1/2"
        v-model="newCustomer.region"
        labelTop="województwo"
        :searchUrl="searchRegionUrl"
        :error="validationErrors.errors.region !== undefined ? validationErrors.errors.region : ''"
        :warning="validationErrors.warnings.region !== undefined ? validationErrors.warnings.region : ''"
        :disabled="customer.id !== undefined && !edit"
      />
      <BaseAutocompleteInput
        class="flex-grow"
        v-model="newCustomer.country"
        labelTop="państwo"
        :searchUrl="searchCountryUrl"
        :error="validationErrors.errors.country !== undefined ? validationErrors.errors.country : ''"
        :warning="validationErrors.warnings.country !== undefined ? validationErrors.warnings.country : ''"
        :disabled="customer.id !== undefined && !edit"
      />
    </div>
    <a v-if="edit && !showAddressForCorrespondence" href="#" @click.prevent="addAddrForCorresp = true" class="text-sm tw--link underline">
      dodaj adres korespondencyjny
    </a>
    <div v-if="showAddressForCorrespondence">
      <div class="text-sm text-muted border-b dark:border-gray-600">adres korespondencyjny <a href="#" class="tw--link underline" @click.prevent="delAddrForCorresp">usuń</a></div>
      <div id="customer-ext-address-street" class="flex flex-wrap gap-4">
        <BaseAutocompleteInput
          class="w-full md:w-2/3 lg:w-full xl:w-2/3"
          v-model="newCustomer.c_street"
          labelTop="ulica"
          :searchUrl="searchStreetUrl"
          :error="validationErrors.errors.c_street !== undefined ? validationErrors.errors.c_street : ''"
          :warning="validationErrors.warnings.c_street !== undefined ? validationErrors.warnings.c_street : ''"
          :disabled="customer.id !== undefined && !edit"
        />
        <BaseInputWithAddon
          class="flex-grow"
          type="text"
          v-model="newCustomer.c_house_ap_no"
          labelTop="nr domu/mieszkania"
          :error="validationErrors.errors.c_house_ap_no !== undefined ? validationErrors.errors.c_house_ap_no : ''"
          :warning="validationErrors.warnings.c_house_ap_no !== undefined ? validationErrors.warnings.c_house_ap_no : ''"
          :disabled="customer.id !== undefined && !edit"
        />
      </div>
      <div id="customer-ext-address-city" class="flex flex-wrap gap-4">
        <BaseAutocompleteInput
          class="flex-grow"
          @selected="setCCity"
          v-model="newCustomer.c_postal_code"
          labelTop="kod pocztowy"
          :searchUrl="searchPostalCodeCityUrl"
          :error="validationErrors.errors.c_postal_code !== undefined ? validationErrors.errors.c_postal_code : ''"
          :warning="validationErrors.warnings.c_postal_code !== undefined ? validationErrors.warnings.c_postal_code : ''"
          :disabled="customer.id !== undefined && !edit"
        />
        <BaseAutocompleteInput
          class="w-full md:w-2/3 lg:w-full xl:w-2/3"
          v-model="newCustomer.c_city"
          labelTop="miasto"
          :searchUrl="searchCityUrl"
          :error="validationErrors.errors.c_city !== undefined ? validationErrors.errors.c_city : ''"
          :warning="validationErrors.warnings.c_city !== undefined ? validationErrors.warnings.c_city : ''"
          :disabled="customer.id !== undefined && !edit"
        />
      </div>
      <div id="customer-ext-address-region-country" class="flex flex-wrap gap-4">
        <BaseAutocompleteInput
          class="w-full md:w-1/2 lg:w-full xl:w-1/2"
          v-model="newCustomer.c_region"
          labelTop="województwo"
          :searchUrl="searchRegionUrl"
          :error="validationErrors.errors.c_region !== undefined ? validationErrors.errors.c_region : ''"
          :warning="validationErrors.warnings.c_region !== undefined ? validationErrors.warnings.c_region : ''"
          :disabled="customer.id !== undefined && !edit"
        />
        <BaseAutocompleteInput
          class="flex-grow"
          v-model="newCustomer.c_country"
          labelTop="państwo"
          :searchUrl="searchCountryUrl"
          :error="validationErrors.errors.c_country !== undefined ? validationErrors.errors.c_country : ''"
          :warning="validationErrors.warnings.c_country !== undefined ? validationErrors.warnings.c_country : ''"
          :disabled="customer.id !== undefined && !edit"
        />
      </div>
    </div> <!-- Koniec Adres korespondencyjny -->
    <div id="customer-bank" v-if="edit && allowEditSensitiveData" class="flex flex-wrap gap-4 border-t dark:border-gray-600">
      <BaseInputWithAddon
        class="w-full sm:w-1/4"
        type="text"
        v-model="newCustomer.bank"
        labelTop="bank"
        :error="validationErrors.errors.bank !== undefined ? validationErrors.errors.bank : ''"
        :warning="validationErrors.warnings.bank !== undefined ? validationErrors.warnings.bank : ''"
        :disabled="customer.id !== undefined && !edit"
      />
      <BaseInputWithAddon
        class="flex-grow"
        type="text"
        v-model="newCustomer.bank_acc_no"
        labelTop="nr konta"
        :error="validationErrors.errors.bank_acc_no !== undefined ? validationErrors.errors.bank_acc_no : ''"
        :warning="validationErrors.warnings.bank_acc_no !== undefined ? validationErrors.warnings.bank_acc_no : ''"
        :disabled="customer.id !== undefined && !edit"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import debounce from 'lodash/debounce';
// import { debounce } from 'lodash';
import { errorHandler } from '../../mixins/errorHandler';

export default {
  name: 'CustomerForm',
  mixins: [errorHandler],
  props: {
    customer: {
      default() {
        return {};
      },
    },
    clear: {
      type: Boolean,
      default: false,
    },
    validationErrors: {
      type: [Object, Array],
      default() {
        return { errors: {}, warnings: {} };
      },
    },
    edit: {
      type: Boolean,
      default: false,
    },
    allowEditSensitiveData: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      newCustomer: {},
      addAddrForCorresp: false,
      searchPersonSurnameUrl: 'customers/search/surname',
      searchPersonPeselUrl: 'customers/search/pesel',
      searchCompanyNameUrl: 'customers/search/name',
      searchCompanyRegonUrl: 'customers/search/regon',
      searchForenameUrl: 'search-forename',
      searchStreetUrl: 'search-street',
      searchPostalCodeCityUrl: 'search-postal-code-city',
      searchCityUrl: 'search-city',
      searchCountryUrl: 'search-country',
      searchRegionUrl: 'search-region',
      // saveErrors: {},
    };
  },
  computed: {
    ...mapState('customers', [
      'custTypeList',
      'prefContactFormList',
    ]),
    showAddressForCorrespondence() {
      const dirty = this.newCustomer.c_street || this.newCustomer.c_house_ap_no || this.newCustomer.c_postal_code || this.newCustomer.c_city || this.newCustomer.c_region || this.newCustomer.c_country;
      return !!dirty || this.addAddrForCorresp;
    },
  },
  created() {
    this.resetCustomer();
    if (this.edit) {
      Object.keys(this.newCustomer).forEach(key => { this.newCustomer[key] = this.customer[key] !== undefined ? `${this.customer[key]}` : ''; });
    }
  },
  mounted() {
    if (this.custTypeList.length === 0) this.$store.dispatch('customers/getCustTypeList').catch(error => this.resolveError(error));
  },
  methods: {
    setCustomer(id) {
      this.$emit('selected', id);
    },
    setCity(selectedSuggestion) {
      this.newCustomer.city = selectedSuggestion.name;
    },
    setCCity(selectedSuggestion) {
      this.newCustomer.c_city = selectedSuggestion.name;
    },
    emitFormChanged: debounce(function() {
      this.$emit('formChanged', this.newCustomer);
    }, 500),
    delAddrForCorresp() {
      this.newCustomer.c_street = '';
      this.newCustomer.c_house_ap_no = '';
      this.newCustomer.c_postal_code = '';
      this.newCustomer.c_city = '';
      this.newCustomer.c_region = '';
      this.newCustomer.c_country = '';
      this.addAddrForCorresp = false;
    },
    resetCustomer() {
      this.newCustomer = {
        cust_type_id: '',
        pref_contact_form: '',
        phone: '',
        email: '',
        is_company: '0',
        surname: '',
        forename1: '',
        forename2: '',
        pesel: '',
        birth_date_h: '',
        foreigner: '0',
        nationality: '',
        name: '',
        shortname: '',
        regon: '',
        nip: '',
        vat_ue: '',
        street: '',
        house_ap_no: '',
        postal_code: '',
        city: '',
        region: '',
        country: '',
        c_street: '',
        c_house_ap_no: '',
        c_postal_code: '',
        c_city: '',
        c_region: '',
        c_country: '',
        bank: '',
        bank_acc_no: '',
      };
      if (!this.allowEditSensitiveData) {
        delete this.newCustomer.bank;
        delete this.newCustomer.bank_acc_no;
      }
    },
  },
  watch: {
    clear(val) {
      if (val) {
        this.resetCustomer();
      }
    },
    'customer.id': function (newVal) {
      if (newVal !== undefined && newVal !== '') {
        Object.keys(this.newCustomer).forEach(key => { this.newCustomer[key] = this.customer[key] !== undefined ? `${this.customer[key]}` : ''; });
      }
    },
    newCustomer: {
      handler: function() {
        if (this.customer.id === undefined || this.edit) {
          this.emitFormChanged();
        }
      },
      deep: true,
    },
  },
};
</script>
