<template>
  <BaseModal
    @closeModal="$emit('cancel')"
    @ok="save"
  >
    <template #header>{{ action === 'edit' ? 'Edycja pozycji' : 'Nowa pozycja' }}</template>
    <template #default>
      <div class="flex flex-col gap-2">
        <div v-if="order.lead == 1" id="lead-alert" class="px-2 py-1 bg-yellow-200 dark:bg-yellow-700 rounded-sm">
          <p class="text-bright">Polisa typu LEAD - pamiętaj, aby wybrać odpowiedni produkt!</p>
        </div>
        <div class="flex flex-wrap gap-4">
          <BaseInputWithAddon v-if="order.policy_no === ''"
            class="w-full md:w-1/2 lg:w-full xl:w-1/2"
            type="text"
            v-model="policyNo"
            labelTop="nr polisy"
            :error="saveErrors.policy_no !== undefined ? saveErrors.policy_no : ''"
          />
        </div>
        <div class="w-full flex flex-wrap gap-4">
          <BaseSelectWithAddon
            class="w-full md:w-1/2 lg:w-full xl:w-1/2"
            v-model="insurerId"
            labelTop="tow. ubezpieczeniowe"
            :options="insurerList"
            :nullOption="false"
            :disabled="hasItems"
            :error="saveErrors.insurer_id !== undefined ? saveErrors.insurer_id : ''"
          />
          <BaseSelectWithAddon
            class="flex-grow"
            v-model="categoryId"
            labelTop="kategoria prod."
            :options="productCategoryList"
            :nullOption="false"
            :disabled="hasItems"
            :error="saveErrors.category_id !== undefined ? saveErrors.category_id : ''"
          />
        </div>
        <div class="w-full flex flex-wrap gap-4">
          <BaseSelectWithAddon
            class="w-full md:w-2/3 lg:w-full xl:w-2/3"
            v-model="productId"
            labelTop="produkt"
            :options="productList"
            :nullOption="true"
            :disabled="insurerId == ''"
            :error="saveErrors.product_id !== undefined ? saveErrors.product_id : ''"
          />
          <BaseInputWithAddon
            class="flex-grow"
            type="text"
            v-model="itemValue"
            labelTop="składka"
            :error="saveErrors.itemValue !== undefined ? saveErrors.itemValue : ''"
            inputAlign="right"
            @enter="save"
          />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { mapGetters } from 'vuex';
import { errorHandler } from '../../mixins/errorHandler';
import ProductService from '../../services/ProductService';
import OrderService from '../../services/OrderService';

export default {
  name: 'OrderItemsAddEdit',
  mixins: [errorHandler],
  props: {
    order: {
      type: Object,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    item: {
      type: Object,
      required: false,
    },
    action: {
      type: String,
      default: 'add',
    },
  },
  data() {
    return {
      products: [],
      insurerId: '',
      categoryId: '',
      productId: '',
      itemValue: '',
      policyNo: '',
      saveErrors: {},
      saving: false,
    };
  },
  computed: {
    ...mapGetters('products', [
      'insurerList',
      'productCategoryList',
    ]),
    productList() {
      let prList;
      if (this.insurerId !== '' && this.categoryId !== '') {
        prList = this.products.filter(obj => obj.insurer_id === this.insurerId && obj.category_id === this.categoryId);
      } else if (this.insurerId !== '') {
        prList = this.products.filter(obj => obj.insurer_id === this.insurerId);
      } else {
        prList = [];
      }
      if (this.action === 'edit') {
        return prList.map(product => {
          return { id: product.product_id, currId: product.id, name: product.name };
        });
      }
      return prList;
    },
    hasItems() {
      return this.items.length > 0;
    },
  },
  created() {
    if (this.insurerList.length === 0) this.$store.dispatch('products/getInsurerList').catch(error => this.resolveError(error));
    if (this.productCategoryList.length === 0) this.$store.dispatch('products/getProductCategoryList').catch(error => this.resolveError(error));
    ProductService.getProductsActiveAtDate({ order_date_from: this.order.date_from })
      .then((response) => {
        this.products = response.data.products;
      })
      .catch((error) => {
        this.resolveError(error);
      });
    if (this.action === 'edit') {
      this.insurerId = this.item.insurer_id;
      this.categoryId = this.item.category_id;
      this.productId = this.item.product_id;
      this.itemValue = this.item.value.replace('.', ',');
    } else if (this.items.length > 0) {
      this.insurerId = this.items[0].insurer_id;
      this.categoryId = this.items[0].category_id;
    } else if (this.items.length === 0) {
      if (this.order.type_id === '1') {
        this.categoryId = '2';
      } else if (this.order.type_id === '5') {
        this.categoryId = '3';
      } else if (this.order.type_id === '12') {
        this.categoryId = '4';
      } else if (this.order.type_id === '13') {
        this.categoryId = '5';
      } else if (this.order.type_id === '14') {
        this.categoryId = '';
      } else {
        this.categoryId = '1';
      }
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    save() {
      if (this.validate()) {
        if (this.policyNo !== '') {
          this.saving = true;
          OrderService.updateOrder(this.order.id, { policy_no: this.policyNo })
            .then((response) => {
              this.policyNo = response.data.updOrder.policy_no;
              this.saveItem();
            })
            .catch((error) => {
              this.saving = false;
              this.resolveError(error);
            });
        } else {
          this.saveItem();
        }
      }
    },
    saveItem() {
      this.saving = true;
      const fields = { order_id: this.order.id, product_id: this.productId, value: this.itemValue };
      if (this.action === 'add') {
        OrderService.addOrderItem(fields)
          .then(response => {
            this.saving = false;
            this.$emit('changed', response.data.items);
            if (this.policyNo !== '') {
              this.$emit('newPolicyNo', this.policyNo);
            }
          })
          .catch(error => {
            this.saving = false;
            this.saveErrors = error.response.data.errors;
            this.resolveError(error);
          });
      } else if (this.action === 'edit') {
        fields.product_id = this.productList.find(product => product.id === this.productId)?.currId;
        OrderService.updateOrderItem(this.item.id, fields)
          .then(response => {
            this.saving = false;
            this.$emit('changed', response.data.items);
            if (this.policyNo !== '') {
              this.$emit('newPolicyNo', this.policyNo);
            }
          })
          .catch(error => {
            this.saving = false;
            this.saveErrors = error.response.data.errors;
            this.resolveError(error);
          });
      } else {
        return false;
      }
      return true;
    },
    validate() {
      const errors = {};
      let status = true;
      if (this.order.policy_no === '' && this.policyNo === '') {
        errors.policy_no = 'pole obowiązkowe';
        status = false;
      }
      if (this.insurerId === '') {
        errors.insurer_id = 'wybór obowiązkowy';
        status = false;
      }
      if (this.categoryId === '') {
        errors.category_id = 'wybór obowiązkowy';
        status = false;
      }
      if (this.productId === '') {
        errors.product_id = 'wybór produktu obowiązkowy';
        status = false;
      }
      if (this.itemValue === '') {
        errors.itemValue = 'pole obowiązkowe';
        status = false;
      }
      if (isNaN(this.itemValue.replace(',', '.'))) {
        errors.itemValue = 'błędny format liczby';
        status = false;
      }
      this.saveErrors = errors;
      return status;
    },
  },
};
</script>
