<template>
  <BaseModal
    @closeModal="$emit('cancel')"
    @ok="save"
  >
    <template #header>{{ action === 'edit' ? 'Edycja pozycji' : 'Nowa pozycja' }}</template>
    <template #default>
      <div class="flex flex-col gap-2">
        <div class="w-full flex flex-wrap md:flex-nowrap lg:flex-wrap xl:flex-nowrap gap-4">
          <BaseInputWithAddon
            class="w-full md:w-1/2 lg:w-full xl:w-1/2"
            type="date"
            v-model="form.due_date_h"
            labelTop="data należności"
            :error="saveErrors.due_date_h !== undefined ? saveErrors.due_date_h : ''"
          />
          <BaseInputWithAddon
            class="w-full md:w-1/2 lg:w-full xl:w-1/2"
            type="text"
            v-model="form.value"
            labelTop="kwota"
            :error="saveErrors.value !== undefined ? saveErrors.value : ''"
            inputAlign="right"
            @enter="save"
          />
        </div>
        <div class="w-full flex flex-wrap md:flex-nowrap lg:flex-wrap xl:flex-nowrap gap-4">
          <BaseSelectWithAddon
            class="w-full md:w-1/2 lg:w-full xl:w-1/2"
            v-model="form.inkaso"
            labelTop="forma pł."
            :options="installmentFormOptions"
            :nullOption="false"
            :error="saveErrors.inkaso !== undefined ? saveErrors.inkaso : ''"
          />
          <BaseSelectWithAddon
            title="Czy klient został powiadomiony o nadchodzącym terminie należności?"
            class="w-full md:w-1/2 lg:w-full xl:w-1/2"
            v-model="form.reminded"
            labelTop="klient powiadomiony"
            :options="remindedOptions"
            :nullOption="false"
            :disabled="form.inkaso === '1'"
            :error="saveErrors.reminded !== undefined ? saveErrors.reminded : ''"
          />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { errorHandler } from '../../mixins/errorHandler';
import moment from 'moment';
import OrderService from '../../services/OrderService';

export default {
  name: 'OrderInstallmentsAddEdit',
  mixins: [errorHandler],
  props: {
    installmentsSum: {
      type: Number,
      required: true,
    },
    installment: {
      type: Object,
      default() {
        return {};
      },
    },
    itemsSum: {
      type: Number,
      required: true,
    },
    order: {
      type: Object,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {
        order_id: '',
        value: '',
        due_date_h: '',
        inkaso: '',
        reminded: '',
      },
      installmentFormOptions: [{ id: '0', name: 'przelew'}, { id: '1', name: 'gotówka' }],
      remindedOptions: [{ id: '0', name: 'nie'}, { id: '1', name: 'tak' }],
      saveErrors: {},
      saving: false,
    };
  },
  mounted() {
    this.form.order_id = this.order.id;
    if (this.action === 'edit') {
      Object.assign(this.form, this.installment);
      this.form.value = this.installment.value.replace('.', ',');
      this.form.due_date_h = this.installment.due_date_h;
      this.form.inkaso = this.installment.inkaso;
      this.form.reminded = this.installment.reminded;
    } else {
      this.form.due_date_h = this.order.sale_date_h !== '' ? this.order.sale_date_h : moment().format('YYYY-MM-DD');
      this.form.inkaso = '0';
      this.form.reminded = '0';
      this.form.value = (this.itemsSum - this.installmentsSum).toFixed(2).toString().replace('.', ',');
    }
  },
  methods: {
    save() {
      this.saving = true;
      if (this.action === 'add') {
        OrderService.addOrderInstallment(this.form)
          .then(response => {
            this.saving = false;
            this.$emit('changed', response.data.installments);
          })
          .catch(error => {
            this.saving = false;
            this.saveErrors = error.response.data.errors;
            this.resolveError(error);
          });
      } else if (this.action === 'edit') {
        OrderService.updateOrderInstallment(this.installment.id, this.form)
          .then(response => {
            this.saving = false;
            this.$emit('changed', response.data.installments);
          })
          .catch(error => {
            this.saving = false;
            this.saveErrors = error.response.data.errors;
            this.resolveError(error);
          });
      } else {
        return false;
      }
      return true;
    },
  },
};
</script>
