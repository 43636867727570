/* eslint-disable no-shadow */
import CustomerService from '../../services/CustomerService';

const state = {
  custTypeList: [],
  prefContactFormList: [
    { id: 0, name: 'niepożądany' },
    { id: 1, name: 'email' },
    { id: 2, name: 'telefon' },
    { id: 3, name: 'sms' },
    { id: 4, name: 'poczta tradyc.' },
  ],
};

const mutations = {
  UPDATE_CUST_TYPES(state, payload) {
    state.custTypeList = payload;
  },
};

const actions = {
  getCustTypeList({ commit }) {
    const result = new Promise((resolve, reject) => {
      CustomerService.getCustomerTypes()
        .then(response => {
          commit('UPDATE_CUST_TYPES', response.data.cust_types);
          resolve();
        })
        .catch(error => reject(error));
    });
    return result;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
