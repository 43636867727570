<template>
<div class="flex h-screen w-screen justify-center items-center">
  <div>
    <h1 class="font-bold text-2xl text-red-500">Wybrany element nie istnieje!</h1>
    <p>Wybierz inną pozycję z menu lub wyszukaj element w przeznaczonym do tego miejscu.</p>
    <div class="flex flex-wrap gap-x-3 justify-center mt-3">
      <button class="tw--btn tw--btn-big" @click="$router.go(-1)">Powrót</button>
      <button class="tw--btn tw--btn-big" @click="$router.push({ name: 'Calendar' })">Strona główna</button>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>
