import axios from 'axios';

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  // withCredentials: false,
  // headers: {
    // Accept: 'application/json',
    // 'Content-type': 'application/json',
  // },
});

export default {
  login(formData) {
    const result = new Promise((resolve, reject) => {
      apiClient.post('/api/login', formData)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
    return result;
  },
  logout() {
    const result = new Promise((resolve, reject) => {
      apiClient.post('/api/logout')
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
    return result;
  },
  passwordEmail(formData) {
    const result = new Promise((resolve, reject) => {
      apiClient.post('/forgot-password', formData)
        .then(response => {
          if (response.data.status === 'success') {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
    return result;
  },
  passwordReset(token, formData) {
    const result = new Promise((resolve, reject) => {
      apiClient.post(`/reset-password/${token}`, formData)
        .then(response => {
          if (response.data.status === 'success') {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
    return result;
  },
};
