<template>
  <BaseModal
    @closeModal="$emit('cancel')"
    @ok="save"
    okLabel="zapisz"
  >
    <template #header>Edycja rat</template>
    <template #default>
      <div class="flex flex-col gap-2">
        <div class="flex flex-wrap justify-between">
          <div class="flex gap-3">
            <div class="text-muted">suma składek:</div><div class="text-bright">{{itemsSum | moneyPL}}</div>
          </div>
          <div class="flex gap-3 items-center">
            <div class="text-muted">suma rat:</div>
            <div class="text-bright" :class="{'text-red-600 dark:text-red-700 font-bold': installmentsSum !== itemsSum}">{{installmentsSum | moneyPL}}</div>
            <div v-show="installmentsSum !== itemsSum" class="text-sm italic font-bold text-red-600 dark:text-red-700">różnica: ({{ Number(itemsSum) - Number(installmentsSum) | moneyPL }})</div>
          </div>
        </div>

        <div class="flex flex-wrap md:flex-nowrap lg:flex-wrap xl:flex-nowrap gap-3">
          <BaseInputWithAddon
            class="w-full md:w-1/2 lg:w-full xl:w-1/2"
            type="number"
            v-model="instNo"
            @input="changeInstallmentsNo"
            labelAddon="ilość rat"
            inputAlign="right"
          />
          <BaseInputWithAddon
            class="w-full md:w-1/2 lg:w-full xl:w-1/2"
            type="date"
            v-model="instStartDate"
            labelAddon="data pierwszej raty"
          />
        </div>
        <div id="calc-installment-list-header" class="flex items-end gap-3 border-b dark:border-gray-600 text-muted text-sm">
          <div class="w-8 text-center">rata</div>
          <div class="w-1/4 md:w-1/3 lg:w-1/4 xl:w-1/3 flex-shrink-0">kwota</div>
          <div class="flex-grow">data należności</div>
          <div class="w-6">
            <button @click="calculate" class="tw--btn-icon py-0"><FontAwesomeIcon icon="calculator" /></button>
          </div>
        </div>
        <div id="calc-installment-list" v-for="(rata, index) in editedInstallments" :key="index" class="flex items-center gap-3">
          <div class="w-8 text-center">{{ index + 1 }}</div>
          <div class="w-1/4 md:w-1/3 lg:w-1/4 xl:w-1/3 flex-shrink-0">
            <BaseInputWithAddon class="w-full" type="text" inputAlign="right" v-model="rata.value" :error="isNaN(`${rata.value}`.replace(',', '.')) ? 'błędny format liczby' : ''" />
          </div>
          <div class="flex-grow"><BaseInputWithAddon class="w-full" type="date" v-model="rata.due_date_h"  /></div>
          <div class="w-6">
            <button @click="rata.autoCalc = !rata.autoCalc" class="tw--btn-icon">
              <FontAwesomeLayers>
                <FontAwesomeIcon icon="calculator" />
                <FontAwesomeIcon icon="ban" v-show="!rata.autoCalc" class="text-red-600 dark:text-red-700 opacity-70" transform="grow-8" />
              </FontAwesomeLayers>
            </button>
          </div>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import moment from 'moment';
import { errorHandler } from '../../mixins/errorHandler';
import OrderService from '../../services/OrderService';

export default {
  name: 'OrderInstallmentCalculate',
  mixins: [errorHandler],
  props: {
    order: {
      type: Object,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    installments: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      editedInstallments: [],
      instNo: 0,
      instStartDate: '',
      saving: false,
    };
  },
  computed: {
    itemsSum() {
      let sum = 0;
      this.items.forEach((item) => { sum += Number(item.value); });
      return sum.toFixed(2);
    },
    installmentsSum() {
      let sum = 0;
      this.editedInstallments.forEach((item) => {
        if (!isNaN(`${item.value}`.replace(',', '.'))) {
          sum += Number(`${item.value}`.replace(',', '.'));
        }
      });
      return sum.toFixed(2);
    },
  },
  created() {
    let rata = {};
    if (this.installments.length > 0) {
      this.editedInstallments = [];
      this.instNo = this.installments.length;
      this.instStartDate = this.installments[0].due_date_h;
      this.installments.forEach((obj) => {
        rata = {};
        Object.assign(rata, obj);
        // rata.value = Number(rata.value);
        rata.value = `${rata.value}`.replace('.', ',');
        rata.autoCalc = false;
        this.editedInstallments.push(rata);
      });
    } else {
      this.instNo = 1;
      this.instStartDate = this.order.date_from_h;
      this.editedInstallments.push({
        // value: Number(this.itemsSum),
        value: `${this.itemsSum}`.replace('.', ','),
        due_date_h: this.instStartDate,
        autoCalc: true,
      });
    }
  },
  methods: {
    closeModal() {
      // do poprawienia
      this.$store.commit('calculateInstallments', false);
    },
    changeInstallmentsNo() {
      const sumToDivide = Number(this.itemsSum);
      const subValue = (sumToDivide / Number(this.instNo)).toFixed(2);
      let interval = {};
      if (Number(this.order.date_to) > 0) {
        // +1 bo od 1-1-2001 do 31-12-2001 jest 365 dni, ale unix time jest od 1-1-2001 00:00 do 31-12-2001 00:00 co ucina nam 24h (23:59)
        const daysToDivide = ((Number(this.order.date_to) - Number(this.order.date_from)) / 60 / 60 / 24) + 1;
        const daysInterval = Math.round(daysToDivide / Number(this.instNo));
        const monthsInterval = Math.round(daysInterval / 30);
        if ((daysInterval % 30.41 < 3) || (daysInterval % 30.41 > 28)) {
          interval = { months: monthsInterval };
        } else {
          interval = { days: daysInterval };
        }
      } else {
        interval = { months: 1 };
      }
      this.editedInstallments = [];
      let stepDate = this.instStartDate;
      for (let i = 0; i < this.instNo; i += 1) {
        this.editedInstallments.push({
          value: `${subValue}`.replace('.', ','),
          due_date_h: stepDate,
          autoCalc: true,
        });
        stepDate = moment(stepDate).add(interval).format('YYYY-MM-DD');
      }
    },
    calculate() {
      let manualValue = 0;
      const notManualIndexes = [];
      let instNo = Number(this.instNo);
      this.editedInstallments.forEach((obj, index) => {
        if (!obj.autoCalc) {
          manualValue += Number(`${obj.value}`.replace(',', '.'));
          instNo -= 1;
        } else {
          notManualIndexes.push(index);
        }
      });
      if (instNo > 0) {
        const sumToDivide = Number(this.itemsSum) - manualValue;
        const subValue = (sumToDivide / Number(instNo)).toFixed(2);
        notManualIndexes.forEach((x) => { this.editedInstallments[x].value = `${subValue}`.replace('.', ','); });
      }
    },
    save() {
      this.saving = true;
      const form = [];
      this.editedInstallments.forEach(obj => {
        form.push({ due_date_h: obj.due_date_h, value: `${obj.value}`.replace(',', '.') });
      });
      OrderService.changeOrderInstallments(this.order.id, form)
        .then((response) => {
          this.saving = false;
          this.$store.dispatch('notifications/add', { type: 'success', message: response.data.msg });
          this.$emit('changed', response.data.installments);
        })
        .catch((error) => {
          this.saving = false;
          this.resolveError(error);
        });
    },
  },
};
</script>
