<template>
  <BaseModal
    @closeModal="$emit('close')"
    :cancel="true"
    :ok="true"
    okLabel="Generuj ankietę"
    :okDisabled="!validated.value"
    cancelLabel="Zamknij"
    @ok="sendRequest"
    size="sm"
  >
    <template #header>
      Generowanie ankiety Rodo-APK
    </template>
    <template #default>
      <div class="flex flex-col gap-y-3">
        <p class="text-sm italic text-justify">
          Po wybraniu rodzaju ankiety nastąpi zalogowanie w nowym oknie do aplikacji RODO-APK, celem wstępnego przygotowania
          formularza i zanaczenia w ankiecie odpowiedzi, które zostały wcześniej uzgodnione z klientem telefonicznie.
          Po zapisaniu, link do ankiety zostanie wysłany mailem do klienta, celem uzupełnienia i zatwierdzenia, co spowoduje
          umieszczenie załączników w kartotece klienta i/lub kartotece oferty/polisy.
        </p>
        <!-- <BaseSelectWithAddon class="flex-shrink" labelTop="Agent" v-model="employeeId" :options="agentOptions" :nullOption="false" /> -->
        <BaseSelectWithAddon class="flex-shrink" labelTop="Typ ankiety" v-model="survey_type_id" :options="surveyOptions" :nullOption="false" />
        <!-- <BaseInputWithAddon class="flex-shrink" labelTop="Nazwa skrócona klienta" type="text" v-model="customer_shortname" /> -->
        <p v-if="!validated.value" class="font-semibold text-red-600 dark:text-red-800">
          {{ validated.text }}
        </p>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import ApiService from '../../services/ApiService';
import { errorHandler } from '../../mixins/errorHandler';

export default {
  name: 'RodoApkRequest',
  mixins: [errorHandler],
  props: {
    order: {
      type: Object,
      required: true,
    },
    employee: {
      type: Object,
      required: true,
    },
    customer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      employeeId: this.order.employee_id,
      agentOptions: [
        { id: this.order.employee_id, name: 'Agent z polisy' },
        { id: this.$store.getters.currentUserData.empl_id || null, name: 'Agent zalogowany' },
      ],
      survey_type_id: '1',
      customer_shortname: Number(this.customer.isCompany) === 1 ? this.customer.company.shortname || '' : '',
    };
  },
  mounted() {
    this.survey_type_id = this.surveyOptions[0].id;
  },
  computed: {
    progOptions() {
      return this.$store.getters.progOptions;
    },
    surveyOptions() {
      let options;
      if (Number(this.customer.is_company) === 1) {
        options = [
          { id: '3', name: 'Ankieta APK' },
        ];
      } else if (!this.progOptions.apkTypesAllowed.includes(Number(this.order.type_id))) {
        options = [
          { id: '2', name: 'Ankieta RODO' },
        ];
      } else {
        options = [
          { id: '1', name: 'Ankieta RODO + APK' },
          { id: '2', name: 'Ankieta RODO' },
          { id: '3', name: 'Ankieta APK' },
        ];
      }
      return options;
    },
    formValidation() {
      return !!this.customer.emails[0]?.value;
    },
    validated() {
      if (!this.customer.emails.length) {
        return {
          value: false,
          text: 'Brak adresu email klienta',
        };
      }
      if (!this.progOptions.apkTypesAllowed.includes(Number(this.order.type_id)) && Number(this.customer.is_company) === 1) {
        return {
          value: false,
          text: 'Nie ma możliwości wygenerwania ankiety - btak dostępnuych ankiet APK dla tej polisy, brak możliwości wygenerowania ankiety RODO dla klienta biznesowego',
        };
      }
      if (!this.progOptions.apkTypesAllowed.includes(Number(this.order.type_id)) && this.survey_type_id !== '2') {
        return {
          value: false,
          text: 'Nie ma możliwości wygenerwania ankiety - brak dostępnych ankiet APK dla tej polisy',
        };
      }
      if (Number(this.customer.is_company) === 1 && this.survey_type_id !== '3') {
        return {
          value: false,
          text: 'Brak możliwości wygenerowania ankiety RODO dla klienta biznesowego',
        };
      }
      return {
        value: true,
        text: '',
      };
    },
  },
  methods: {
    sendRequest() {
      this.$store.dispatch('setLoadingData', true);
      ApiService.createRodoApkSurvey(this.order.id, {
        // employee_id: this.employeeId,
        survey_type_id: this.survey_type_id,
        // customer_shortname: this.customer_shortname,
      })
        .then((response) => {
          this.$store.dispatch('setLoadingData', false);
          window.open(`${process.env.VUE_APP_RODO_APK_FE_URL}/?token=${response.data.result.token}`, '_blank');
          this.$emit('close');
        })
        .catch(error => {
          this.$store.dispatch('setLoadingData', false);
          this.resolveError(error);
        });
    },
    getRodoFile() {
      this.$store.dispatch('setLoadingData', true);
      ApiService.getRodoFile(this.order.id)
        .then((response) => {
          this.$store.dispatch('setLoadingData', false);
          window.open(`${process.env.VUE_APP_RODO_APK_FE_URL}/?token=${response.data.result.token}`, '_blank');
          this.$emit('close');
        })
        .catch(error => {
          this.$store.dispatch('setLoadingData', false);
          this.resolveError(error);
        });
    },
  },
};
</script>
