<template>
  <BaseModal
    @closeModal="$emit('close')"
    :cancel="true"
    :ok="true"
    okLabel="zapisz"
    @ok="save"
    size="sm"
  >
    <template #header>
      Edycja parametrów rozliczeniowych
    </template>
    <template #default>
      <div class="flex flex-col gap-y-3">
        <div class="flex flex-wrap gap-4">
          <BaseSelectWithAddon
            v-model="form.made_by_office"
            class="flex-grow"
            labelTop="oferta samodzielna?"
            :options="madeByOfficeOptions"
            :nullOption="false"
            :error="saveErrors.made_by_office !== undefined ? saveErrors.made_by_office : ''"
          />
          <div v-if="leadFunctionalityAllowed" class="flex flex-col">
            <div class="font-semibold text-sm text-transparent">LEAD</div>
            <div class="flex">
              <label class="flex-grow" :class="{'opacity-50': form.made_by_office !== '1'}" for="lead">
                <input
                  id="lead"
                  type="checkbox"
                  true-value="1" false-value="0"
                  v-model="form.lead"
                  :disabled="form.made_by_office !== '1'"
                > lead
              </label>
            </div>
          </div>
        </div>
        <BaseSelectWithAddon
          v-model="form.copywriter_id"
          labelTop="przygotowujący ofertę / polisę"
          :options="employeeSimpleList"
          :error="saveErrors.made_by_office !== undefined ? saveErrors.made_by_office : ''"
        />
      </div>
    </template>
  </BaseModal>
</template>

<script>
import OrderService from '../../services/OrderService';
import { confirm } from '../../mixins/confirm';
import { errorHandler } from '../../mixins/errorHandler';

export default {
  name: 'OrderFinancialDataEdit',
  mixins: [confirm, errorHandler],
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        made_by_office: '',
        lead: '',
        copywriter_id: '',
      },
      madeByOfficeOptions: [
        { id: '0', name: 'samodzielna'},
        { id: '1', name: 'biuro'},
      ],
      saveErrors: {},
      isBusy: false,
    };
  },
  computed: {
    employeeSimpleList() {
      return this.$store.state.employees.employeeSimpleList;
    },
    leadFunctionalityAllowed() {
      return this.$store.getters.progOptions?.leadFunctionality;
    },
  },
  mounted() {
    this.$store.dispatch('employees/getEmployeeSipleList').catch(error => this.resolveError(error));
    this.form.made_by_office = this.order.made_by_office;
    this.form.lead = this.order.lead;
    this.form.copywriter_id = this.order.copywriter_id;
  },
  methods: {
    save() {
      this.isBusy = true;
      OrderService.updateOrder(this.order.id, this.form)
        .then(() => {
          this.$emit('updated');
          this.$emit('close');
          this.isBusy = false;
        })
        .catch(error => {
          this.saveErrors = this.resolveError(error);
          this.isBusy = false;
        });
    },
  },
};
</script>
