<template>
  <BaseModal
    @closeModal="$emit('close')"
    :cancel="true"
    :ok="true"
    okLabel="wznów"
    :busy="loading"
    @ok="renewOrder"
  >
    <template #header>
      {{ renewalTitle }}
    </template>
    <template #default>
      <div class="flex flex-col gap-y-3">
        <div class="flex flex-col gap-y-1">
          <div class="text-muted font-semibold border-b dark:border-gray-600">Dane do wznowienia</div>
          <div class="flex flex-wrap gap-x-3 gap-y-2">
            <BaseSelectWithAddon class="w-full sm:w-1/2" v-model="employeeId" :options="employeeSimpleList" :nullOption="false" labelTop="agent" />
            <BaseSelectWithAddon class="w-full sm:w-auto sm:flex-grow" v-model="madeByOffice" :options="madeByOfficeOptions" :nullOption="false" labelTop="przygotowujący ofertę" />
          </div>
          <div class="flex flex-wrap gap-x-3 gap-y-2">

            <BaseInputWithAddon type="date" :value="newOrderDateFrom" labelTop="start ochrony" :disabled="true" />

            <BaseInputWithAddon type="date" v-model="newOrderDateTo" labelTop="koniec ochrony" />
          </div>
        </div>
        <div class="flex flex-col">
          <div class="text-muted font-semibold border-b dark:border-gray-600">Dane wznawianej polisy</div>
          <div class="flex flex-wrap justify-between gap-y-1">
            <div class="flex flex-wrap gap-x-1">
              <div class="text-muted">numer polisy</div>
              <div class="text-bright">{{ order.policy_no }}</div>
            </div>
            <div class="flex flex-wrap gap-x-1">
              <div class="text-muted">rodzaj</div>
              <div class="text-bright">{{ order.type_name }}</div>
            </div>
            <div class="flex flex-wrap gap-x-1">
              <div class="text-muted">koniec ochrony</div>
              <div class="text-bright">{{ order.date_to_h }}</div>
            </div>
          </div>

          <div class="flex flex-wrap gap-x-3 text-sm">
            <div class="text-muted">klient:</div>
            <div class="text-bright">{{ order.cust_fullname }}</div>
            <div class="flex gap-x-1" v-if="order.cust_pesel != ''"><div class="text-muted">pesel:</div><div class="text-bright">{{ order.cust_pesel }}</div></div>
            <div class="flex gap-x-1" v-else><div class="text-muted">regon:</div><div class="text-bright">{{ order.cust_regon }}</div></div>
            <div class="w-full flex flex-wrap gap-x-1">
              <div class="text-muted">adres</div>
              <div class="text-bright">{{ order.cust_address_line1 ? `${order.cust_address_line1};` : '' }}</div>
              <div class="text-bright">{{ order.cust_address_line2 }}</div>
            </div>
          </div>
        </div>

        <div class="flex flex-col text-sm">
          <div id="renew--order-subjects" class="text-muted text-base font-semibold border-b dark:border-gray-600">Przedmiot ubezpieczenia</div>
          <span class="text-xs text-muted italic">* przedmiot ubezpieczenia również zostanie skopiowany do nowej oferty</span>
          <div class="flex flex-col gap-1">
            <div v-if="orderSubjects.subjects.length === 0" class="flex flex-wrap gap-1 justify-between py-1 border dark:border-gray-600 rounded cursor-pointer">
              <div v-for="(description, name, index) in orderSubjects.fieldListShort" :key="`${name}-${index}`" class="flex px-2 gap-2 items-center">
                <span class="text-muted">{{ description }}</span>
                <span class="italic text-sm">nie podano</span>
              </div>
            </div>
            <div v-for="subject in orderSubjects.subjects" :key="subject.id" class="flex flex-wrap gap-1 justify-between py-1 border dark:border-gray-600 rounded cursor-pointer">
              <div v-for="(description, name, index) in orderSubjects.fieldListShort" :key="`${name}-${index}`" class="flex px-2 gap-2 items-center">
                <span class="text-muted">{{ description }}</span>
                <span v-if="subject[name] !== '' && subject[name] !== ' ' && subject[name] !== 'Tak'" class="text-bright">{{ subject[name] }}</span>
                <span v-else-if="subject[name] === ''" class="italic text-sm">nie podano</span>
                <span v-else-if="subject[name] === 'Tak'" class="font-bold text-green-400 dark:text-green-600">&check;</span>
                <FontAwesomeIcon v-else-if="subject[name] === ' '" class="text-gray-300 dark:text-gray-600" icon="times" />
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-col">
          <div class="text-muted font-semibold border-b dark:border-gray-600">Informacja</div>
          <div class="text-sm italic">
            <p>Po kliknięciu przycisku [wznów] zostanie w aplikacji stworzona nowa oferta z kontynuacją daty ubezpieczenia, skopiowanymi danymi klienta, rodzajem polisy itp.</p>
            <p v-if="orderSubjects.subjects.length > 0">Dane przedmiotu(ów) ubezpieczenia zostaną również skopiowane. <strong>W utworzonej nowej ofercie neleży zweryfikować aktualność danych przedmiotu(ów) ubezpieczenia</strong> - niektóre dane mogły ulec zmianie.</p>
            <p>Należy zweryfikować datę końca ochrony nowej polisy oraz pozostałe dane, na podstawie których zostanie przygotowana nowa oferta.</p>
          </div>
        </div>

      </div>
    </template>
    <template #busy>
      <div class="absolute top-0 left-0 w-full h-full">
        <div class="w-full h-full bg-white dark:bg-black opacity-80"></div>
        <div class="absolute top-0 left-0 flex w-full h-full items-center justify-center">
          <FontAwesomeIcon class="mr-3" icon="spinner" size="2x" spin />
          <div class="font-bold">
            <p>Wczytuję dane...</p>
          </div>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import OrderService from '../../services/OrderService';
import { errorHandler } from '../../mixins/errorHandler';
import { confirm } from '../../mixins/confirm';
import moment from 'moment';

export default {
  name: 'RenewOrder',
  mixins: [confirm, errorHandler],
  props: {
    orderId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      order: {},
      customer: {},
      employeeId: '',
      madeByOffice: '',
      employee: {},
      orderSubjects: {
        subjects: [],
        fieldListShort: {},
        subjectFormRows: [],
      },
      newOrderDateFrom: '',
      newOrderDateTo: '',
      loading: false,
      notification: true,
      madeByOfficeOptions: [
        { id: '0', name: 'Samodzielna' },
        { id: '1', name: 'Biuro' },
      ],
    };
  },
  computed: {
    employeeSimpleList() {
      return this.$store.state.employees.employeeSimpleList;
    },
    renewalTitle() {
      return this.order.policy_no !== '' ? 'Wznowienie polisy' : 'Ponowienie  oferty';
    },
  },
  created() {
    this.loading = true;
    this.$store.dispatch('employees/getEmployeeSipleList').catch(error => this.resolveError(error));
    OrderService.getOrderDataForRenew(this.orderId)
      .then(response => {
        this.order = response.data.order;
        this.madeByOffice = response.data.order.made_by_office;
        this.newOrderDateFrom = moment(this.order.date_to_h).add(1, 'days').format('YYYY-MM-DD');
        this.newOrderDateTo = moment(this.newOrderDateFrom).add(1, 'years').subtract(1, 'days').format('YYYY-MM-DD');
        this.employeeId = response.data.order.employee_id;
        OrderService.getOrderSubjects(this.orderId, this.order.type_id)
          .then(resp => {
            this.orderSubjects.subjects = resp.data.subjects;
            this.orderSubjects.fieldListShort = resp.data.fieldListShort;
            this.orderSubjects.subjectFormRows = resp.data.subjectFormRows;
            this.loading = false;
          })
          .catch(error => {
            this.resolveError(error);
            this.loading = false;
          });
      })
      .catch(error => {
        this.resolveError(error);
        this.loading = false;
      });
  },
  methods: {
    renewOrder() {
      OrderService.renewOrder(this.orderId, {
        employee_id: this.employeeId,
        made_by_office: this.madeByOffice,
        date_to_h: this.newOrderDateTo,
      })
      .then(response => {
        this.$store.dispatch('notifications/add', { message: 'Przeniesiono do oferty wznowienia.', type: 'success' });
        this.$router.push({ name: 'Order', params: { id: response.data.newOrderId } });
        this.$emit('close');
      })
      .catch(error => this.resolveError(error));
    },
  },
};
</script>
