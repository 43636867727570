<template>
  <div v-if="options.length > 0 || selected.length > 0">
    <div
      class="flex items-stretch border rounded-sm focus-within:ring-1
        focus-within:border-blue-300 ring-blue-600 border-gray-400
        dark:focus-within:border-blue-600 dark:ring-blue-900 dark:border-gray-600"
      >
      <label class="flex items-center gap-1 font-semibold text-sm px-1 py-1 bg-gray-100 dark:bg-transparent border-r border-gray-400 dark:border-gray-600">
        <span class="whitespace-nowrap">{{ label }}</span> <FontAwesomeIcon v-if="labelIcon.length > 0" :icon="labelIcon" />
      </label>
      <!-- <label class="font-semibold text-sm px-1 py-1 border-r border-gray-300 dark:border-gray-600 text-gray-400">{{ label }}</label> -->
      <select
        @change="$emit('input', $event.target.value)"
        :value="value"
        class="w-full border-0 focus:ring-0"
        :class="{'bg-yellow-100 dark:bg-yellow-800': selected.length > 0}"
        :disabled="disabled"
      >
        <option value="" disabled selected hidden>{{ optionZero }}</option>
        <option v-for="option in options" :key="option.id" :value="option.id">{{ option.name ? option.name : option.fullname }}</option>
      </select>
    </div>
    <div class="flex flex-wrap">
      <div
        v-for="element in selected"
        :key="element.id"
        class="flex items-center text-sm pl-1 mt-0.5 mr-0.5 bg-yellow-100 border border-yellow-200 rounded dark:bg-yellow-800 dark:border-yellow-600"
      >
        <span>{{ element.name }}</span>
        <button @click="$emit('remove', element.id)" class="px-1 hover:bg-yellow-300 dark:hover:bg-yellow-500">
          <FontAwesomeIcon icon="times" size="xs" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseMultiselectWithAddOn',
  props: {
    value: String,
    label: {
      type: String,
      default: '',
    },
    labelIcon: {
      type: [String, Array],
      default() {
        return '';
      },
    },
    optionZero: {
      type: String,
      default: 'Wybierz 1 lub wiele...',
    },
    options: {
      type: Array,
    },
    selected: {
      type: Array,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
