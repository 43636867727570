<template>
  <div class="flex flex-col gap-1 px-2 py-1 border dark:border-gray-600 border-gray-400 rounded">

    <ImageShow
      v-if="showImageGallery"
      @close="showImageGallery = false"
      :images="[consent]"
      :startImageId="consent.id"
      container="personals"
    />

    <ConsentEdit
      v-if="editConsentModal"
      :consent="consent"
      :allowEdit="allowEdit"
      @close="editConsentModal = false"
      @updated="consentUpdated"
    />

    <div class="flex flex-wrap justify-between">
      <span v-if="Number(consent.admin_checked) === 1" class="flex gap-1"><span class="text-muted">data oświadczenia</span><span class="text-bright">{{ consent.set_date_h }}</span></span>
      <span v-if="Number(consent.admin_checked) === 0" class="font-semibold text-red-600 dark:text-red-800">załącznik do zatwierdzenia</span>
      <span v-else class="flex-gap-1">
        <span class="text-muted">data obowiązywania</span>
        <span
          class="font-semibold"
          :class="{
            'text-green-500 dark:text-green-700': consentDueDate.color === 'success',
            'text-red-600 dark:text-red-800': consentDueDate.color === 'danger',
            'text-yellow-600 dark:text-yellow-800': consentDueDate.color === 'warning'
          }"
        >
          {{ consentDueDate.title }}
        </span>
      </span>
      <div
        :id="`buttons-consent-id${consent.id}`"
        class="order-first md:order-last lg:order-first 2xl:order-last w-full md:w-auto lg:w-full 2xl:w-auto flex gap-4 md:gap-0 justify-end md:justify-between lg:justify-end 2xl:justify-between items-center"
      >
        <span
          v-if="consent.online && Number(consent.online) === 1"
          class="font-mono font-semibold text-xs tracking-tighter text-blue-400 dark:text-blue-900 cursor-default"
          title="Oświadczenie zostało złożone online"
        >online</span>
        <button
          @click="showConsentScanFile"
          class="tw--btn-icon"
          :class="{'tw--btn-icon-warning': consent.file_path === ''}"
          :title="consent.file_path === '' ? 'Nie załączono skanu' : 'Pokaż skan pliku'"
          :disabled="consent.file_path === ''"
        >
          <FontAwesomeIcon icon="cloud-download-alt" />
        </button>
        <button v-if="allowEdit" @click="editConsentModal = true" class="tw--btn-icon" title="edytuj"><FontAwesomeIcon :icon="['far', 'edit']" /></button>
        <button v-if="allowEdit" @click="delConsent" class="tw--btn-icon tw--btn-icon-danger" title="usuń oświadczenie zgód"><FontAwesomeIcon :icon="['far', 'trash-alt']" /></button>
      </div>

    </div>
    <div class="flex flex-wrap justify-between">
      <ConsentDetailElementItem name="Obsł. umów" title="Zgoda na przetwarzanie danych osobowych oraz kopii dokumentów dla celów obsługi polis ubezpieczeniowych" :state="Number(consent.order_service) === 1" />
      <ConsentDetailElementItem name="Obsł. elektr." title="Zgoda na elektroniczną obsługę umów ubezpieczeń, droga mailową" :state="Number(consent.email_order_service) === 1" />
      <ConsentDetailElementItem name="Marketing" title="Zgoda na otrzymywanie drogą elektroniczną informacji handlowych (ustwa o świadczeniu usług drogą elektroniczną (Dz.U. z 2002 Nr 144 poz. 1204))" :state="Number(consent.marketing) === 1" />
      <ConsentDetailElementItem name="Telefon" title="Zgoda na wykorzystanie telekomunikacyjnych urządzeń końcowych dla celów marketingu bezpośredniego (Art. 172 ust. Prawo telekomunikacyjne)" :state="Number(consent.telecom) === 1" />
      <ConsentDetailElementItem name="Profilowanie" title="Zgoda na profilowanie (zautomatyzowane podejmowanie decyzji)" :state="Number(consent.profiling) === 1" />
    </div>
  </div>
</template>

<script>
import { confirm } from '../../mixins/confirm';
import moment from 'moment';
import ConsentDetailElementItem from './ConsentDetailElementItem.vue';
import ConsentEdit from './ConsentEdit.vue';
import ImageShow from '../ImageShow.vue';

export default {
  name: 'ConsentItem',
  components: {
    ConsentDetailElementItem,
    ConsentEdit,
    ImageShow,
  },
  mixins: [confirm],
  props: {
    consent: {
      type: Object,
      required: true,
    },
    allowEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showImageGallery: false,
      apiUrl: process.env.VUE_APP_API_URL,
      editConsentModal: false,
    };
  },
  computed: {
    consentDueDate() {
      const today = moment().format('YYYY-MM-DD');
      let consentDueDate = {};
      if (this.consent.due_date_h !== '') {
        if (this.consent.due_date_h >= today) {
          consentDueDate = { title: this.consent.due_date_h, color: 'success' };
        } else {
          consentDueDate = { title: this.consent.due_date_h, color: 'danger' };
        }
      } else if (Number(this.consent.till_dismiss) === 1) {
        consentDueDate = { title: 'do odwołania', color: 'success' };
      } else {
        consentDueDate = { title: 'czas umowy', color: 'warning' };
      }
      return consentDueDate;
    },
  },
  methods: {
    showConsentScanFile() {
      if (this.consent.file_type.indexOf('image') !== -1) {
        this.showImageGallery = true;
      } else {
        const newWindow = window.open(null, this.consent.id);
        newWindow.location.href = `${this.apiUrl}/personals/show-pd-gdpr-consent-file/${this.consent.id}`;
      }
    },
    consentUpdated(consent) {
      this.$emit('consentUpdated', consent);
      this.editConsentModal = false;
    },
    delConsent() {
      this.confirm(() => this.delConfirmed(), 'Potwierdź usunięcie oświadczenia zgód');
    },
    delConfirmed() {
      this.$emit('delConsent', this.consent.id);
    },
  },
};
</script>
