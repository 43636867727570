<template>
  <BaseModal
    @closeModal="$emit('close')"
    :ok="false"
    :cancel="true"
    cancelLabel="zamknij"
    :bgClose="true"
    size="2xl"
    minHeight="full"
  >
    <template #header>
      <div class="flex gap-5">
        <div v-show="images.length > 1" class="flex gap-1">
          <button @click="changeImage(-1)" class="tw--btn tw--btn-secondary" :disabled="currIndex < 1" title="poprzednie">&lt;</button>
          <button @click="changeImage(1)" class="tw--btn tw--btn-secondary" :disabled="currIndex >= images.length - 1" title="następne">&gt;</button>
        </div>
        <button @click="rotateImage" class="tw--btn tw--btn-secondary" title="poprzednie">
          <FontAwesomeIcon icon="undo" flip="horizontal" />
        </button>
        <button @click="download" class="tw--btn tw--btn-secondary" title="pobierz zdjęcie na dysk">
          <FontAwesomeIcon icon="cloud-download-alt" />
        </button>
      </div>
    </template>
    <template #default>
      <!-- <div class="mb-4 transform" :class="{'rotate-0': rotationDeg === 0, 'rotate-90': rotationDeg === 90, 'rotate-180': rotationDeg === 180, '-rotate-90': rotationDeg === 270, }"> -->
      <div class="mb-4">
        <img @contextmenu.prevent="downloadConfirm"
          class="rounded w-full transform" :class="{'rotate-0': rotationDeg === 0, 'rotate-180': rotationDeg === 180}"
          :src="`${apiUrl}/${container}/show-image/${image.id}`" alt="Obraz"
        >
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { confirm } from '../mixins/confirm';

export default {
  name: 'ImageShow',
  mixins: [confirm],
  props: {
    images: {
      type: Array,
      required: true,
    },
    startImageId: {
      required: true,
    },
    container: {
      type: String,
      default: 'notatki',
    },
  },
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL,
      rotationDeg: 0,
      currIndex: 0,
      showing: true,
    };
  },
  computed: {
    image() {
      return this.images[this.currIndex];
    },
  },
  mounted() {
    this.currIndex = this.images.findIndex(obj => Number(obj.id) === Number(this.startImageId));
  },
  methods: {
    changeImage(skip) {
      if (this.rotationDeg !== 0) this.rotationDeg = 0;
      this.currIndex += skip;
    },
    downloadConfirm() {
      this.confirm(() => this.download(), 'Pobrać zdjęcie na dysk?');
    },
    download() {
      const newWindow = window.open(null, this.image.id);
      newWindow.location.href = `${this.apiUrl}/${this.container}/download-file/${this.image.id}`;
    },
    // rotateImageRight() {
    //   this.rotationDeg = this.rotationDeg < 270 ? this.rotationDeg + 90 : 0;
    // },
    rotateImage() {
      this.rotationDeg = this.rotationDeg !== 0 ? 0 : 180;
    },
  },
};
</script>
