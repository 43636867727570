/* eslint-disable no-shadow */
import ApiService from '../../services/ApiService';

const state = {
  orderTypeList: [],
  orderStatusList: [],
  editInstallments: false,
  ordersItemsSum: {
    value: 0,
    valueAgent: 0,
    valueBiuro: 0,
  },
};

const mutations = {
  UPDATE_ORDER_TYPES(state, payload) {
    state.orderTypeList = payload;
  },
  UPDATE_ORDER_STATUSES(state, payload) {
    state.orderStatusList = payload;
  },
  calculateInstallments(state, payload) {
    state.editInstallments = payload;
  },
  UPDATE_ORDERS_ITEMS_SUM(state, payload) {
    Object.assign(state.ordersItemsSum, payload);
  },
};

const actions = {
  getOrderTypeList({ commit }) {
    const result = new Promise((resolve, reject) => {
      ApiService.getOrderTypeList()
        .then(response => {
          commit('UPDATE_ORDER_TYPES', response.data.order_types);
          resolve();
        })
        .catch(error => reject(error));
    });
    return result;
  },
  getOrderStatusList({ commit }) {
    const result = new Promise((resolve, reject) => {
      ApiService.getOrderStatusList()
        .then(response => {
          commit('UPDATE_ORDER_STATUSES', response.data.order_statuses);
          resolve();
        })
        .catch(error => reject(error));
      });
      return result;
  },
};

const getters = {
  orderTypeList: state => state.orderTypeList,
  orderStatusList: state => state.orderStatusList,
  editInstallments: state => state.editInstallments,
  ordersItemsSum: state => state.ordersItemsSum,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
