<template>
  <BaseModal
    @closeModal="$emit('cancel')"
    @ok="save"
    okLabel="zapisz"
  >
    <template #header>{{ action === 'edit' ? 'Edycja pozycji' : 'Nowa pozycja' }}</template>
    <template #default>
      <div class="flex flex-col gap-2">
        <div class="w-full flex flex-wrap md:flex-nowrap lg:flex-wrap xl:flex-nowrap gap-4">
          <BaseInputWithAddon
            class="w-full md:w-1/2 lg:w-full xl:w-1/2"
            type="text"
            v-model="income"
            @input="incomeChanged"
            labelTop="przychód"
            :error="saveErrors.income !== undefined ? saveErrors.income : ''"
            inputAlign="right"
            @enter="save"
          />
          <BaseInputWithAddon
            class="w-full md:w-1/2 lg:w-full xl:w-1/2"
            type="date"
            v-model="payment_date_h"
            labelTop="data wpływu"
            :error="saveErrors.payment_date_h !== undefined ? saveErrors.payment_date_h : ''"
          />
        </div>
        <div class="w-full flex flex-wrap md:flex-nowrap lg:flex-wrap xl:flex-nowrap gap-4">
          <BaseInputWithAddon
            class="w-full md:w-1/2 lg:w-full xl:w-1/2"
            type="text"
            v-model="agent_value"
            @input="agentValueChanged"
            labelTop="agent"
            :error="saveErrors.agent_value !== undefined ? saveErrors.agent_value : ''"
            inputAlign="right"
            @enter="save"
          />
          <BaseInputWithAddon v-if="showCopywProvision"
            class="w-full md:w-1/2 lg:w-full xl:w-1/2"
            type="text"
            v-model="copyw_value"
            @input="copywValueChanged"
            labelTop="wystawiający"
            :error="saveErrors.copyw_value !== undefined ? saveErrors.copyw_value : ''"
            inputAlign="right"
            @enter="save"
          />
          <BaseInputWithAddon
            class="w-full md:w-1/2 lg:w-full xl:w-1/2"
            type="text"
            v-model="office_value"
            @input="officeValueChanged"
            labelTop="agencja"
            :error="saveErrors.office_value !== undefined ? saveErrors.office_value : ''"
            inputAlign="right"
            @enter="save"
          />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { errorHandler } from '../../mixins/errorHandler';
import moment from 'moment';
import OrderService from '../../services/OrderService';

export default {
  name: 'OrderProvisionAddEdit',
  mixins: [errorHandler],
  props: {
    order: {
      type: Object,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    provision: {
      type: Object,
      default() {
        return {};
      },
    },
    action: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      income: '0,00',
      payment_date_h: '',
      agent_value: '0,00',
      copyw_value: '0,00',
      office_value: '0,00',
      saveErrors: {},
      saving: false,
    };
  },
  computed: {
    copywriterProvision() {
      return this.$store.getters.progOptions.copywriterProvision;
    },
    showCopywProvision() {
      return (this.copywriterProvision === 1 || this.copywriterProvision === '1') && (this.order.made_by_office === 1 || this.order.made_by_office === '1');
    },
    itemsSum() {
      let valueAgent = 0;
      let valueCopyw = 0;
      let valueBiuro = 0;
      this.items.forEach((item) => {
        valueAgent += Number(item.prov_agent);
        valueCopyw += Number(item.prov_copyw);
        valueBiuro += Number(item.prov_agencja);
      });
      return { valueAgent, valueCopyw, valueBiuro };
    },
    incomeNum() {
      return isNaN(this.income.replace(',', '.')) ? 0 : Number(this.income.replace(',', '.'));
    },
    agentValueNum() {
      return isNaN(this.agent_value.replace(',', '.')) ? 0 : Number(this.agent_value.replace(',', '.'));
    },
    copywValueNum() {
      return isNaN(this.copyw_value.replace(',', '.')) ? 0 : Number(this.copyw_value.replace(',', '.'));
    },
    officeValueNum() {
      return isNaN(this.office_value.replace(',', '.')) ? 0 : Number(this.office_value.replace(',', '.'));
    },
    copywToIncome() {
      if (this.copywriterProvision === 1) {
        return this.itemsSum.valueCopyw / (this.itemsSum.valueAgent + this.itemsSum.valueCopyw + this.itemsSum.valueBiuro);
      }
      return 0;
    },
    agentToIncome() {
      return this.itemsSum.valueAgent / (this.itemsSum.valueAgent + this.itemsSum.valueCopyw + this.itemsSum.valueBiuro);
    },
  },
  mounted() {
    if (this.action === 'edit') {
      this.income = (Number(this.provision.agent_value) + Number(this.provision.copyw_value) + Number(this.provision.office_value)).toFixed(2).replace('.', ',');
      this.agent_value = Number(this.provision.agent_value).toFixed(2).replace('.', ',');
      this.copyw_value = Number(this.provision.copyw_value).toFixed(2).replace('.', ',');
      this.office_value = Number(this.provision.office_value).toFixed(2).replace('.', ',');
      this.payment_date_h = this.provision.payment_date_h;
    } else {
      this.payment_date_h = moment().format('YYYY-MM-DD');
    }
  },
  methods: {
    incomeChanged() {
      this.agent_value = (this.incomeNum * this.agentToIncome).toFixed(2).replace('.', ',');
      this.copyw_value = (this.incomeNum * this.copywToIncome).toFixed(2).replace('.', ',');
      this.office_value = (this.incomeNum - this.copywValueNum - this.agentValueNum).toFixed(2).replace('.', ',');
    },
    agentValueChanged() {
      this.office_value = (this.incomeNum - this.copywValueNum - this.agentValueNum).toFixed(2).replace('.', ',');
    },
    officeValueChanged() {
      this.agent_value = (this.incomeNum - this.copywValueNum - this.officeValueNum).toFixed(2).replace('.', ',');
    },
    copywValueChanged() {
      this.office_value = (this.incomeNum - this.agentValueNum - this.copywValueNum).toFixed(2).replace('.', ',');
    },
    save() {
      this.saving = true;
      const fields = {
        order_id: this.order.id,
        payment_date_h: this.payment_date_h,
        agent_value: `${this.agent_value}`.replace(',', '.'),
        copyw_value: `${this.copyw_value}`.replace(',', '.'),
        office_value: `${this.office_value}`.replace(',', '.'),
      };
      if (this.action === 'add') {
        OrderService.addOrderProvision(fields)
          .then(response => {
            this.saving = false;
            this.$emit('added', response.data.provision);
          })
          .catch(error => {
            this.saving = false;
            this.saveErrors = error.response.data.errors;
            this.resolveError(error);
          });
      } else if (this.action === 'edit') {
        OrderService.updateOrderProvision(this.provision.id, fields)
          .then(response => {
            this.saving = false;
            this.$emit('edited', response.data.provision);
          })
          .catch(error => {
            this.saving = false;
            this.saveErrors = error.response.data.errors;
            this.resolveError(error);
          });
      } else {
        return false;
      }
      return true;
    },
  },
};
</script>
