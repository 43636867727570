<template>
  <div class="relative top-0 right-0" ref="cardMenu">
    <div
      class="px-1 cursor-pointer tw--link hover:bg-gray-200 dark:hover:bg-gray-700 rounded-full"
      :class="{'bg-gray-200 dark:bg-gray-700': opened}"
      @click="opened = !opened"
      title="Menu"
    >
      <FontAwesomeIcon icon="ellipsis-h" />
    </div>
    <div v-show="opened" class="absolute top-6 right-0 bg-white dark:bg-gray-800 border dark:border-gray-900 rounded-sm shadow-lg px-3 py-2 z-10">
      <ul ref="menuContent">
        <slot>
          <li><a href="" @click.prevent="opened = false"><font-awesome-icon icon="times" class="text-gray-500" /> Zamknij</a></li>
        </slot>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardMenu',
  data() {
    return {
      opened: false,
    };
  },
  created() {
    document.addEventListener('click', this.documentClick);
  },
  destroyed() {
    document.removeEventListener('click', this.documentClick);
  },
  methods: {
    documentClick(e) {
      const el = this.$refs.cardMenu;
      const content = this.$refs.menuContent;
      const { target } = e;
      if ((el !== target && !el.contains(target)) || content === target || content.contains(target)) {
        this.opened = false;
      }
    },
  },
};
</script>
