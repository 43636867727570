<template>
  <div class="border dark:border-gray-600 border-gray-400 px-2 py-1 rounded shadow">
    <div class="flex justify-between items-center -mx-2 pb-1 px-2 border-b dark:border-gray-600 border-gray-400">
      <span class="uppercase text-muted text-xs font-semibold">Zaksięgowane Prowizje</span>
      <button v-if="showActions" @click="addEditModal = true" class="tw--btn-icon p-0" title="dodaj prowizję ręcznie">
        <FontAwesomeIcon :icon="['far', 'plus-square']" size="lg" />
      </button>
    </div>

    <OrderProvisionAddEdit
      v-if="addEditModal && showActions"
      @cancel="cancel"
      @added="provisionAdded"
      @edited="provisionEdited"
      :order="order"
      :items="items"
      :provision="provisionToEdit"
      :action="action"
    />

    <div v-if="provisions.length === 0" class="w-full text-base italic text-muted">brak informacji o prowizjach</div>
    <div v-else class="flex flex-col gap-1">
      <div id="provisions-header" class="hidden sm:flex lg:hidden xl:flex items-center gap-2 text-muted border-b-2 dark:border-gray-600 border-gray-400">
        <div class="flex-grow">data</div>
        <div v-if="userRole === 'admin' || userRole === 'agent'" class="w-2/12 text-right">agent</div>
        <div v-if="showCopywProvision && (userRole === 'admin' || userRole === 'copyw')" class="w-2/12 text-right">wyst.</div>
        <div v-if="showProvisionOffice" class="w-2/12 text-right">agencja</div>
        <div v-if="showActions" class="w-10 flex-shrink-0"></div>
      </div>

      <div id="provision-list" class="divide-y divide-dashed dark:divide-gray-600">
        <div v-for="provision in provisions" :key="provision.id"
          class="flex items-center flex-wrap gap-0 sm:flex-nowrap sm:gap-2 lg:flex-wrap lg:gap-0 xl:flex-nowrap xl:gap-2 py-1 text-sm text-bright"
          :class="{'font-normal': provisions.length > 1}"
        >
          <div class="order-first w-2/3 flex-grow-0 md:w-auto md:flex-grow lg:w-2/3 lg:flex-grow-0 xl:flex-grow xl:w-auto flex gap-2">
            <div class="block sm:hidden lg:block xl:hidden text-muted font-normal">data</div>
            <div class="font-mono">{{  provision.payment_date_h }}</div>
          </div>

          <div v-if="userRole === 'admin' || userRole === 'agent'"
            class="order-2 w-1/2 sm:w-2/12 lg:w-1/2 xl:w-2/12 justify-start sm:justify-end lg:justify-start xl:justify-end flex-shrink-0 flex gap-2"
          >
            <div class="block sm:hidden lg:block xl:hidden text-muted font-normal">{{ userRole === 'admin' ? 'agent' : 'kwota' }}</div>
            <div class="text-right font-mono">{{ provision.agent_value | moneyPL }}</div>
          </div>

          <div v-if="showCopywProvision && (userRole === 'admin' || userRole === 'copyw')"
            class="order-3 w-1/2 sm:w-2/12 lg:w-1/2 xl:w-2/12 justify-start sm:justify-end lg:justify-start xl:justify-end flex-shrink-0 flex gap-2"
          >
            <div class="block sm:hidden lg:block xl:hidden text-muted font-normal">{{ userRole === 'admin' ? 'wyst.' : 'kwota' }}</div>
            <div class="text-right font-mono">{{ provision.copyw_value | moneyPL }}</div>
          </div>

          <div v-if="showProvisionOffice"
            class="order-4 w-1/2 sm:w-2/12 lg:w-1/2 xl:w-2/12 justify-start sm:justify-end lg:justify-start xl:justify-end flex-shrink-0 flex gap-2"
          >
            <div class="block sm:hidden lg:block xl:hidden text-muted font-normal">agencja</div>
            <div class="text-right font-mono">{{ provision.office_value | moneyPL }}</div>
          </div>

          <div v-if="showActions"
            class="w-1/3 justify-end order-1 sm:w-10 sm:justify-between sm:order-last lg:w-1/3 lg:justify-end lg:order-1 xl:w-10 xl:justify-between xl:order-last flex items-center gap-2"
          >
            <button @click="edit(provision)" class="tw--btn-icon text-gray-400 dark:text-gray-500 p-0" title="edytuj"><FontAwesomeIcon :icon="['far', 'edit']" /></button>
            <button @click="del(provision.id)" class="tw--btn-icon tw--btn-icon-danger text-gray-400 dark:text-gray-500 p-0" title="usuń"><FontAwesomeIcon :icon="['far', 'trash-alt']" /></button>
          </div>
        </div>
      </div>

      <div id="provision-list-summary" v-if="provisions.length > 1"
        class="flex flex-wrap gap-x-0 sm:flex-nowrap sm:gap-x-2 lg:flex-wrap lg:gap-x-0 xl:flex-nowrap xl:gap-x-2 items-center text-sm text-bright border-t-2 dark:border-gray-600 border-gray-400"
      >
        <div class="w-full flex-grow-0 sm:w-auto sm:flex-grow lg:w-full lg:flex-grow-0 xl:w-auto xl:flex-grow pr-2 text-left sm:text-right lg:text-left xl:text-right">suma</div>
        <div v-if="userRole === 'admin' || userRole === 'agent'"
          class="w-1/2 sm:w-2/12 lg:w-1/2 xl:w-2/12 justify-start sm:justify-end lg:justify-start xl:justify-end flex-shrink-0 flex gap-2"
        >
          <div class="block sm:hidden lg:block xl:hidden text-muted font-normal">{{ userRole === 'admin' ? 'agent' : 'kwota' }}</div>
          <div class="text-right font-mono">{{ provisionSum.agent | moneyPL }}</div>
        </div>

        <div v-if="showCopywProvision && (userRole === 'admin' || userRole === 'copyw')"
          class="w-1/2 sm:w-2/12 lg:w-1/2 xl:w-2/12 justify-start sm:justify-end lg:justify-start xl:justify-end flex-shrink-0 flex gap-2"
        >
          <div class="block sm:hidden lg:block xl:hidden text-muted font-normal">{{ userRole === 'admin' ? 'wyst.' : 'kwota' }}</div>
          <div class="text-right font-mono">{{ provisionSum.copyw | moneyPL }}</div>
        </div>

        <div v-if="showProvisionOffice"
          class="w-1/2 sm:w-2/12 lg:w-1/2 xl:w-2/12 justify-start sm:justify-end lg:justify-start xl:justify-end flex-shrink-0 flex gap-2"
        >
          <div class="block sm:hidden lg:block xl:hidden text-muted font-normal">agencja</div>
          <div class="text-right font-mono">{{ provisionSum.office | moneyPL }}</div>
        </div>
        <div v-if="showActions" class="w-10 hidden sm:block lg:hidden xl:block"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import OrderService from '../../services/OrderService';
import { confirm } from '../../mixins/confirm';
import { errorHandler } from '../../mixins/errorHandler';
import OrderProvisionAddEdit from './OrderProvisionAddEdit.vue';

export default {
  name: 'OrderFinancialDataProvisions',
  components: { OrderProvisionAddEdit },
  mixins: [confirm, errorHandler],
  props: {
    provisions: {
      type: Array,
      required: true,
    },
    order: {
      type: Object,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      addEditModal: false,
      provisionToEdit: {},
      action: 'add',
    };
  },
  computed: {
    ...mapGetters([
      'currentUserData',
      'currentUserType',
    ]),
    copywriterProvision() {
      return this.$store.getters.progOptions.copywriterProvision;
    },
    userRole() {
      let role = '';
      if (this.currentUserType === 'root' || this.currentUserType === 'admin') {
        role = 'admin';
      } else if (Number(this.order.employee_id) === Number(this.currentUserData.empl_id)) {
        role = 'agent';
      } else if (Number(this.order.copywriter_id) === Number(this.currentUserData.empl_id) && (this.order.made_by_office === '1' || this.order.made_by_office === 1)) {
        role = 'copyw';
      }
      return role;
    },
    showCopywProvision() {
      return this.copywriterProvision === 1 && this.order.made_by_office === '1';
    },
    showProvisionOffice() {
      return (this.currentUserType === 'root' || this.currentUserType === 'admin');
    },
    showActions() {
      return (this.currentUserType === 'root' || this.currentUserType === 'admin');
    },
    provisionSum() {
      const sum = {
        agent: 0,
        copyw: 0,
        office: 0,
      };
      this.provisions.forEach((obj) => {
        sum.agent += Number(obj.agent_value);
        sum.copyw += Number(obj.copyw_value);
        sum.office += Number(obj.office_value);
      });
      return sum;
    },
  },
  methods: {
    edit(obj) {
      Object.assign(this.provisionToEdit, obj);
      this.action = 'edit';
      this.addEditModal = true;
    },
    cancel() {
      this.provisionToEdit = {};
      this.action = 'add';
      this.addEditModal = false;
    },
    provisionAdded(provision) {
      this.$emit('added', provision);
      this.addEditModal = false;
    },
    provisionEdited(provision) {
      this.$emit('updated', provision);
      this.cancel();
    },
    del(id) {
      this.confirm(() => this.confirmedDel(id), 'Potwierdź usunięcie pozycji prowizji');
    },
    confirmedDel(id) {
      OrderService.delOrderProvision(id)
        .then(() => {
          this.$store.dispatch('notifications/add', { type: 'success', message: 'Usunięto pozycję' });
          this.$emit('deleted', id);
        })
        .catch((error) => this.resolveError(error)); // mixin
    },
  },
};
</script>
