<template>
  <Transition name="fade">
    <div
      v-show="show"
      ref="dialogContainer"
      class="absolute flex items-center justify-center inset-0 backdrop-filter backdrop-blur-sm blur--support z-50"
      @keydown="keyHandler"
    >
      <div class="bg-white dark:bg-gray-900 dark:text-gray-400 py-5 px-10 border dark:border-gray-800 rounded-lg shadow-lg max-w-md md:max-w-lg lg:max-w-2xl">
        <p><strong v-html="message"></strong></p>
        <div class="flex gap-5 justify-center mt-2">
          <button
            ref="cancelButton"
            @click="onCancel"
            class="tw--btn tw--btn-secondary"
          >
            Anuluj
          </button>
          <button
            ref="okButton"
            @click="onOk"
            class="tw--btn tw--btn-danger"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  name: 'ConfirmDialog',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    message() {
      return this.$store.getters['confirmation/message'] || 'Czy jesteś pewny?';
    },
    callback() {
      return this.$store.getters['confirmation/callback'];
    },
    cancelCallback() {
      return this.$store.getters['confirmation/cancelCallback'];
    },
  },
  methods: {
    onOk() {
      this.callback();
      this.$store.dispatch('confirmation/closeDialog');
    },
    onCancel() {
      this.cancelCallback();
      this.$store.dispatch('confirmation/closeDialog');
    },
    keyHandler(event) {
      if (event.key === 'Escape') this.onCancel();
      if (event.key === 'Tab') {
        event.preventDefault();
        if (event.target === this.$refs.okButton) {
          this.$refs.cancelButton.focus();
        } else {
          this.$refs.okButton.focus();
        }
      }
    },
  },
  watch: {
    show(newVal) {
      if (newVal) {
        this.$nextTick(() => this.$refs.okButton.focus());
      }
    },
  },
};
</script>
