<template>
  <form @submit.prevent="save" class="w-full flex items-center gap-2" ref="wrapper">
    <div @click="editItem" :class="type === 'emails' ? 'w-2/3' : 'w-auto'">
      <input @keydown.esc="cancel" :type="type === 'emails' ? 'email' : 'text'" v-model="value" class="w-full text-right" :disabled="!edit" ref="inputValue">
    </div>
    <div v-show="!edit" class="flex items-center">
      <button type="button" @click="editItem" class="tw--btn-icon text-xl py-0 px-1"><FontAwesomeIcon :icon="['far', 'edit']" title="edytuj" /></button>
      <button type="button" @click="confirmDelete" class="tw--btn-icon tw--btn-icon-danger text-xl py-0 px-1"><FontAwesomeIcon :icon="['far', 'trash-alt']" title="usuń" /></button>
    </div>
    <div v-show="edit" class="flex gap-2">
      <button type="submit" class="tw--btn" :disabled="value === item.value">Zapisz</button>
      <button type="button" @click="cancel" class="tw--btn tw--btn-secondary">Anuluj</button>
    </div>
    <p v-if="saveErrors.value !== undefined" class="w-full text-red-400 dark:text-red-600 text-xs">{{ saveErrors.value }}</p>
  </form>
</template>

<script>
import { confirm } from '../../mixins/confirm';
import { errorHandler } from '../../mixins/errorHandler';
import EmailsPhonesService from '../../services/EmailsPhonesService';

export default {
  name: 'EmailsPhonesEditElement',
  mixins: [confirm, errorHandler],
  props: {
    ownerType: String,
    ownerId: [String, Number],
    type: {
      type: String,
      default: 'emails',
    },
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      edit: false,
      value: '',
      saveErrors: {},
    };
  },
  created() {
    document.addEventListener('click', this.documentClick);
  },
  destroyed() {
    document.removeEventListener('click', this.documentClick);
  },
  mounted() {
    this.value = this.item.value;
  },
  methods: {
    save() {
      let method = '';
      if (this.ownerType === 'customer') {
        method = this.type === 'emails' ? 'updateCustomerEmail' : 'updateCustomerPhone';
      } else if (this.ownerType === 'employee') {
        method = this.type === 'emails' ? 'updateEmployeeEmail' : 'updateEmployeePhone';
      } else if (this.ownerType === 'customerContactPerson') {
        method = this.type === 'emails' ? 'updateCustomerContactPersonEmail' : 'updateCustomerContactPersonPhone';
      } else {
        this.$store.dispatch('notifications/add', { type: 'danger', message: 'Nieprawidłowe wywołanie funkcji!'});
        return false;
      }
      EmailsPhonesService[method](this.ownerId, this.item.id, { value: this.value })
        .then(response => {
          this.$emit('updated', response.data.updatedElement);
          this.$store.dispatch('notifications/add', { type: 'success', message: 'Zmiany zostały zapisane' });
          this.edit = false;
        })
        .catch(error => {
          this.saveErrors = this.resolveError(error);
        });
    },
    cancel() {
      this.edit = false;
      this.value = this.item.value;
    },
    editItem() {
      this.edit = true;
      this.$nextTick(() => this.$refs.inputValue.focus());
    },
    confirmDelete() {
      this.confirm(() => this.deleteElement(), `Usunąć element ${this.item.value}?`);
    },
    deleteElement() {
      let method = '';
      if (this.ownerType === 'customer') {
        method = this.type === 'emails' ? 'delCustomerEmail' : 'delCustomerPhone';
      } else if (this.ownerType === 'employee') {
        method = this.type === 'emails' ? 'delEmployeeEmail' : 'delEmployeePhone';
      } else if (this.ownerType === 'customerContactPerson') {
        method = this.type === 'emails' ? 'delCustomerContactPersonEmail' : 'delCustomerContactPersonPhone';
      } else {
        this.$store.dispatch('notifications/add', { type: 'danger', message: 'Nieprawidłowe wywołanie funkcji!'});
        return false;
      }
      EmailsPhonesService[method](this.ownerId, this.item.id)
        .then(() => {
          this.$emit('deleted', this.item.id);
          this.$store.dispatch('notifications/add', { type: 'success', message: 'Element został usunięty' });
        })
        .catch(error => {
          this.saveErrors = this.resolveError(error);
        });
    },
    documentClick(e) {
      const { wrapper } = this.$refs;
      const { target } = e;
      if (!wrapper.contains(target)) {
        this.edit = false;
      }
    },
  },
};
</script>
