<template>
  <div
    v-if="options.length > 0"
    class="
    flex items-stretch border rounded-sm focus-within:ring-1
    focus-within:border-blue-300 ring-blue-600
    dark:focus-within:border-blue-600 dark:ring-blue-900 dark:border-gray-600 border-gray-400"
  >
    <label class="flex items-center gap-1 font-semibold text-sm px-1 py-1 bg-gray-100 dark:bg-transparent border-r border-gray-400 dark:border-gray-600">
      <span class="whitespace-nowrap">{{ label }}</span> <FontAwesomeIcon v-if="labelIcon.length > 0" :icon="labelIcon" />
    </label>
    <!-- <label class="font-semibold text-sm px-1 py-1 border-r border-gray-300 dark:border-gray-600 text-gray-400">{{ label }}</label> -->
    <select @change="$emit('input', $event.target.value)" :value="value" class="w-full border-0 focus:ring-0" :class="{'bg-yellow-100 dark:bg-yellow-800': value !== ''}" :disabled="disabled">
      <option value="" :disabled="!nullOption" :hidden="!nullOption">{{ nullOptionName }}</option>
      <option v-for="option in options" :key="option.id" :value="option.id">{{ option.name ? option.name : option.fullname }}</option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'BaseFiltertSelectWithAddon',
  props: {
    value: String,
    label: {
      type: String,
      default: '',
    },
    labelIcon: {
      type: [String, Array],
      default() {
        return '';
      },
    },
    options: {
      type: Array,
    },
    nullOption: {
      type: Boolean,
      default: true,
    },
    nullOptionName: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
