<template>
  <div class="flex flex-col">
    <label v-if="labelTop" class="font-semibold text-sm">{{ labelTop }}</label>
    <div
      class="relative w-full flex items-stretch border rounded-sm focus-within:ring-1
        focus-within:border-blue-300 ring-blue-600 border-gray-400
        dark:focus-within:border-blue-600 dark:ring-blue-900 dark:border-gray-600"
        :class="{'border-red-400 dark:border-red-600': error, 'border-yellow-400 dark:border-yellow-600': warning}"
    >
      <!-- <label v-show="`${labelAddon}${labelIconAddon}`.length > 0" class="flex items-center gap-1 font-semibold text-sm px-1 py-1 border-r border-gray-300 dark:border-gray-600 text-gray-400">
        <span class="whitespace-nowrap">{{ labelAddon }}</span> <FontAwesomeIcon v-if="labelIconAddon.length > 0" :icon="labelIconAddon" />
      </label> -->
      <textarea
        @keyup.enter="$emit('enter')"
        @input="$emit('input', $event.target.value)"
        :value="value"
        :placeholder="placeholder"
        class="w-full border-0 focus:ring-0"
        :class="{'bg-yellow-100 dark:bg-yellow-800': value !== '' && markDirty, 'bg-red-100 dark:bg-red-800': error}"
        :disabled="disabled"
        style="resize:none"
        :rows="rows"
      ></textarea>
    </div>
    <p v-if="error" class="text-red-400 dark:text-red-600 text-xs">{{ error }}</p>
  </div>
</template>

<script>
export default {
  name: 'BaseTextareaWithAddon',
  props: {
    value: [String, Number],
    labelTop: {
      type: String,
      default: '',
    },
    // labelAddon: {
    //   type: String,
    //   default: '',
    // },
    // labelIconAddon: {
    //   type: [String, Array],
    //   default() {
    //     return '';
    //   },
    // },
    rows: {
      default: '3',
    },
    placeholder: {
      type: String,
      default: '',
    },
    markDirty: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      required: false,
    },
    warning: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    // inputAlign: {
    //   type: String,
    //   default: 'left',
    // },
  },
};
</script>
