<template>
  <div
    id="new-order-employee"
    v-if="employee !== undefined"
    class="bg-white dark:bg-gray-900 p-3 rounded-lg shadow-lg"
    :class="{'hidden lg:block': disabled}"
  >
    <div class="flex -mt-3 mb-3 -mx-3 px-3 py-1 border-b dark:border-gray-600">
      <div class="flex-grow uppercase font-semibold text-muted">Agent</div>
      <span v-if="validating === 1" class="text-xs text-muted">
        ok&nbsp;<span class="font-bold text-base text-green-400 dark:text-green-600">&check;</span>
      </span>
      <span v-else-if="validating === -1" class="mt-1 text-xs text-muted">
        uzupełnij<FontAwesomeIcon class="ml-2 text-yellow-400 dark:text-yellow-600" icon="exclamation-triangle" size="lg" />
      </span>
      <span v-else class="mt-1 text-xs text-muted">weryfikuję<FontAwesomeIcon class="ml-2" icon="sync-alt" size="lg" spin /></span>
    </div>
    <div id="name-action" class="flex flex-wrap justify-between gap-y-1">
      <div v-if="!disabled" class="flex">
        <BaseSelectWithAddon
          labelIconAddon="user-tie"
          :options="employeeListToSelect"
          :nullOption="false"
          :value="value"
          @input="setEmployee"
        />
        <label for="include-not-active" class="flex items-center gap-1 border dark:border-gray-600 border-l-0 pl-1 pr-0.5" title="pokaż również nieaktywnych pracowników">
          <input
            id="include-not-active"
            type="checkbox"
            v-model="includeNotActive"
          > <FontAwesomeIcon icon="user-slash" />
        </label>
      </div>
      <div v-else class="text-base text-bright">{{ employee.fullname }}</div>
      <button v-show="!disabled" @click="employeeChangeModal = true" class="tw--btn">
        <FontAwesomeIcon icon="search" /> zaawansowane
      </button>
    </div>
    <div id="employee-type-provision-status" class="flex flex-wrap justify-between">
      <div class="flex flex-wrap gap-4">
        <div class="text-sm font-semibold">
          <span class="text-muted">stanowisko:</span> <span class="text-bright">{{ employee.empl_type_name }}</span>
        </div>
        <div class="text-sm font-semibold">
          <span class="text-muted">poziom prowizyjny:</span> <span class="text-bright">{{ employee.provision_lvl_name }}</span>
        </div>
      </div>
      <div class="font-semibold text-sm">
        <span class="text-muted">status:</span>&nbsp;
        <span :class="Number(employee.status_id) === 1 ? 'text-green-400 dark:text-green-600' : 'text-red-400 dark:text-red-700'">{{ employee.status_name }}</span>
      </div>
    </div>
    <!-- <div id="contact" class="flex flex-col md:flex-row gap-4 mt-4"> -->
    <div id="contact" class="flex flex-wrap gap-4 mt-4">
      <a v-if="employee.phone" :href="`tel:${employee.phone}`" class="group flex font-semibold mb-2">
        <div class="px-2 py-1 bg-blue-500 dark:bg-blue-700 group-hover:bg-blue-300 dark:group-hover:bg-blue-500 rounded-l-sm"><FontAwesomeIcon icon="phone-alt" /></div>
        <div class="px-2 py-1 bg-gray-200 dark:bg-gray-700 rounded-r-sm"><span class="tw--link">{{ employee.phone }}</span></div>
      </a>
      <div v-else class="flex font-semibold mb-2">
        <div class="px-2 py-1 bg-blue-500 dark:bg-blue-700 rounded-l-sm"><FontAwesomeIcon icon="phone-alt" /></div>
        <div class="px-2 py-1 bg-gray-200 dark:bg-gray-700 rounded-r-sm">nie podano</div>
      </div>
      <a v-if="employee.email" :href="`mailto:${employee.email}`" class="group flex font-semibold mb-2">
        <div class="px-2 py-1 bg-blue-500 dark:bg-blue-700 group-hover:bg-blue-300 dark:group-hover:bg-blue-500 rounded-l-sm"><FontAwesomeIcon icon="at" /></div>
        <div class="px-2 py-1 bg-gray-200 dark:bg-gray-700 rounded-r-sm"><span class="tw--link">{{ employee.email  }}</span></div>
      </a>
      <div v-else class="flex font-semibold mb-2">
        <div class="px-2 py-1 bg-blue-500 dark:bg-blue-700 rounded-l-sm"><FontAwesomeIcon icon="at" /></div>
        <div class="px-2 py-1 bg-gray-200 dark:bg-gray-700 rounded-r-sm">nie podano</div>
      </div>
    </div>
    <div id="uprawnienia-knf" class="flex gap-4 mt-2">
      <span class="text-muted whitespace-nowrap">uprawnienia KNF:</span>&nbsp;
      <div class="flex flex-wrap gap-1 text-xs font-semibold">
        <span v-for="insurer in employeeInsurers" :key="insurer.id" class="px-2 py-1 bg-purple-100 dark:bg-purple-900 rounded-sm">
          {{ insurer.name }}
        </span>
      </div>
    </div>
    <div id="manager" class="mt-4">
      <div class="font-semibold">
        <span class="text-muted">manager:</span>&nbsp;
        <span :class="employee.manager_id ? 'text-bright' : 'italic font-normal text-muted'">
          {{ employee.manager_id ? `${employee.mng_forename} ${employee.mng_surname}` : 'nie przydzielono' }}
        </span>
      </div>
    </div>

    <OrderEmployeeChange
      v-if="employeeChangeModal && !disabled"
      :employeeId="value"
      :confirmChange="false"
      @close="employeeChangeModal = false"
      @changeEmployee="setEmployee"
    />
  </div>
</template>

<script>
import { errorHandler } from '../../mixins/errorHandler';
import OrderService from '../../services/OrderService';
import EmployeeService from '../../services/EmployeeService';
import OrderEmployeeChange from './OrderEmployeeChange.vue';

export default {
  name: 'OrderAddSetEmployee',
  components: { OrderEmployeeChange },
  mixins: [errorHandler],
  props: {
    value: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      employeeList: [],
      employeeInsurers: [],
      employeeChangeModal: false,
      includeNotActive: false,
    };
  },
  computed: {
    employee() {
      return this.employeeList.find(obj => obj.id === this.value);
    },
    validating() {
      return this.employee.id !== undefined ? 1 : -1;
    },
    employeeListToSelect() {
      return this.employeeList.filter(obj => Number(obj.status_id) === 1 || this.includeNotActive || Number(obj.id) === Number(this.value));
    },
  },
  created() {
    OrderService.addOrderInit()
      .then((response) => {
        this.employeeList = response.data.employeeList;
      })
      .catch((error) => {
        this.resolveError(error); //mixin
      });
  },
  methods: {
    setEmployee(id) {
      this.$emit('input', id);
      this.employeeChangeModal = false;
    },
    getEmployeeInsurers() {
      this.empInsLoading = true;
      EmployeeService.getEmployeeInsurers(this.value)
        .then(response => {
          this.empInsLoading = false;
          this.employeeInsurers = response.data.result;
        })
        .catch(error => {
          this.empInsLoading = false;
          this.resolveError(error); //mixin
        });
    },
  },
  watch: {
    value() {
      this.getEmployeeInsurers();
    },
  },
};
</script>
