<template>
  <BaseModal
    @closeModal="$emit('close')"
    :cancel="true"
    :ok="true"
    :busy="saving"
    okLabel="zapisz"
    @ok="save"
  >
    <template #header>
      Edycja danych polisy
    </template>
    <template #default>
      <form @submit.prevent="save" class="flex flex-col gap-1">
        <div class="flex flex-wrap gap-4">
          <BaseInputWithAddon
            class="w-full md:w-1/2 lg:w-full xl:w-1/3"
            type="date"
            v-model="editOrderData.sale_date_h"
            labelTop="data wystawienia"
            :error="saveErrors.sale_date_h !== undefined ? saveErrors.sale_date_h : ''"
            :disabled="permissions.edit_policy_no_allow !== 1"
          />
          <BaseInputWithAddon
            class="w-full md:w-1/2 lg:w-full xl:w-1/3"
            type="text"
            v-model="editOrderData.policy_no"
            labelTop="nr polisy"
            :error="saveErrors.policy_no !== undefined ? saveErrors.policy_no : ''"
            :disabled="permissions.edit_policy_no_allow !== 1"
          />
          <BaseSelectWithAddon
            class="flex-grow"
            v-model="editOrderData.status_id"
            labelTop="status"
            :options="orderStatusList"
            :error="saveErrors.status_id !== undefined ? saveErrors.status_id : ''"
          />
        </div>
        <div class="flex flex-wrap gap-4">
          <BaseInputWithAddon
            class="w-full md:w-1/2 lg:w-full xl:w-1/2"
            type="date"
            v-model="editOrderData.date_from_h"
            labelTop="start ochrony"
            :error="saveErrors.date_from_h !== undefined ? saveErrors.date_from_h : ''"
          />
          <BaseInputWithAddon
            class="flex-grow"
            type="date"
            v-model="editOrderData.date_to_h"
            labelTop="koniec ochrony"
            :error="saveErrors.date_to_h !== undefined ? saveErrors.date_to_h : ''"
          />
        </div>
        <div class="flex flex-col">
          <div class="flex flex-wrap gap-4">
            <BaseSelectWithAddon
              class="w-full md:w-1/2 lg:w-full xl:w-1/2"
              @input="orderTypeChangeAlert"
              v-model="editOrderData.type_id"
              labelTop="rodzaj polisy"
              :options="orderTypeList"
              :error="saveErrors.type_id !== undefined ? saveErrors.type_id : ''"
            />
            <div class="flex-grow flex flex-col">
              <div class="font-semibold text-sm">opcje dodatkowe</div>
              <div class="w-full flex">
                <label for="is-leasing" class="flex-grow">
                  <input
                    id="is-leasing"
                    type="checkbox"
                    true-value="1" false-value="0"
                    v-model="editOrderData.is_leasing"
                    :disabled="editOrderData.type_id !== '1'"
                  > <span :class="{'text-muted': editOrderData.type_id !== '1'}">leasing</span>
                </label>
                <label for="priority" class="flex-grow">
                  <input
                    id="priority"
                    type="checkbox"
                    true-value="1" false-value="0"
                    v-model="editOrderData.priority"
                  > pilne!
                </label>
              </div>
            </div>
          </div>
          <div v-show="showTypeChangeAlert" class="text-yellow-500 text-sm leading-4 pt-1">
            Zmiana rodzaju polisy spowoduje usunięcie przedmiotów ubezpieczenia, ponieważ są one ściśle związane z rodzajem.
          </div>
        </div>
        <div class="flex flex-col">
          <BaseInputWithAddon
            class="w-full md:w-1/2 lg:w-full xl:w-2/3"
            type="text"
            v-model="editOrderData.bank_acc_no"
            labelTop="konto do wpł. skł."
            :error="saveErrors.bank_acc_no !== undefined ? saveErrors.bank_acc_no : ''"
            :disabled="permissions.edit_policy_bank_number !== 1"
            title="konto składki - do generowania pism z przypomnieniem o terminie raty"
          />
        </div>
        <div class="flex flex-col">
          <div class="flex flex-col">
            <div class="font-semibold text-sm">kontrolka</div>
            <div class="flex flex-wrap gap-x-3 gap-y-1">
              <label for="check-returned">
                <input
                  id="check-returned"
                  type="checkbox"
                  true-value="1" false-value="0"
                  v-model="editOrderData.check_returned"
                  :disabled="!showAssistant"
                > zdana
              </label>
              <label for="check-scan">
                <input
                  id="check-scan"
                  type="checkbox"
                  true-value="1" false-value="0"
                  v-model="editOrderData.check_scan"
                  :disabled="!showAssistant"
                > skan
              </label>
              <label for="check-att">
                <input
                  id="check-att"
                  type="checkbox"
                  true-value="1" false-value="0"
                  v-model="editOrderData.check_attachments"
                  :disabled="!showAssistant"
                > zdjęcia
              </label>
              <label for="check-resign">
                <input
                  id="check-resign"
                  type="checkbox"
                  true-value="1" false-value="0"
                  v-model="editOrderData.check_resignation"
                  :disabled="!showAssistant"
                > wypowiedzenie
              </label>
            </div>
          </div>
        </div>
      </form >
    </template>
  </BaseModal>
</template>

<script>
import { mapGetters } from 'vuex';
import { errorHandler} from '../../mixins/errorHandler';
import OrderService from '../../services/OrderService';

export default {
  name: 'OrderDetailsEdit',
  mixins: [errorHandler],
  props: {
    order: {
      type: Object,
      required: true,
    },
    orderSubjects: {
      type: Object,
      required: true,
    },
    permissions: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      editOrderData: {
        sale_date_h: '',
        policy_no: '',
        status_id: '',
        date_from_h: '',
        date_to_h: '',
        type_id: '',
        is_leasing: '0',
        priority: '0',
        bank_acc_no: '',
        check_returned: '',
        check_scan: '',
        check_attachments: '',
        check_resignation: '',
      },
      saving: false,
      saveErrors: {},
      showTypeChangeAlert: false,
    };
  },
  computed: {
    ...mapGetters([
      'currentUserType',
    ]),
    ...mapGetters('orders', [
      'orderTypeList',
      'orderStatusList',
    ]),
    showAssistant() {
      return this.currentUserType === 'root' || this.currentUserType === 'admin' || this.currentUserType === 'asystent';
    },
  },
  mounted() {
    Object.keys(this.editOrderData).forEach(key => {
      this.editOrderData[key] = this.order[key];
    });
    if (this.orderTypeList.length === 0) this.$store.dispatch('orders/getOrderTypeList').catch(error => this.resolveError(error));
    if (this.orderStatusList.length === 0) this.$store.dispatch('orders/getOrderStatusList').catch(error => this.resolveError(error));
  },
  methods: {
    save() {
      this.saving = true;
      OrderService.updateOrder(this.order.id, this.editOrderData)
        .then(response => {
          this.saving = false;
          this.$store.dispatch('notifications/add', { type: 'success', message: response.data.msg });
          this.$emit('updated');
        })
        .catch(error => {
          this.saving = false;
          this.saveErrors = this.resolveError(error);
        });
    },
    orderTypeChangeAlert() {
      console.log(this.editOrderData.type_id !== this.order.type_id);
      if (this.editOrderData.type_id !== this.order.type_id && this.orderSubjects.subjects.length > 0) {
        this.showTypeChangeAlert = true;
      } else {
        this.showTypeChangeAlert = false;
      }
    },
  },
};
</script>
