<template>
  <div class="flex flex-col gap-4">
    <!-- <div class="hidden md:block"> -->
    <div>
      <OrderAddSummary :validated="validated" @saveOrder="save" />
    </div>
    <div class="flex flex-col lg:flex-row gap-4">
      <OrderAddSetEmployee class="w-full lg:w-1/2" v-model="newOrder.employee_id" :disabled="!chooseAgentAllow" />
      <OrderAddSetOrder class="w-full lg:w-1/2" @setOrder="setOrder" @validated="setValidated($event, 'order')" />
    </div>
    <div class="flex flex-col lg:flex-row gap-4">
      <OrderAddSetCustomer
        class="w-full lg:w-1/2"
        @changed="setCustomer"
        @validated="setValidated($event, 'customer')"
        :customerImported="customerImported"
        :customerImportedData="customerImportedData"
      />
      <OrderAddSetSubjects class="w-full lg:w-1/2" :orderTypeId="newOrder.type_id" @input="setSubjects" @validated="setValidated($event, 'subject')" />
    </div>
    <OrderAddSummary :validated="validated" @saveOrder="save" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { errorHandler } from '../mixins/errorHandler';
import OrderService from '../services/OrderService';
import CustomerService from '../services/CustomerService';
import OrderAddSetEmployee from '../components/order/OrderAddSetEmployee.vue';
import OrderAddSetCustomer from '../components/order/OrderAddSetCustomer.vue';
import OrderAddSetOrder from '../components/order/OrderAddSetOrder.vue';
import OrderAddSetSubjects from '../components/order/OrderAddSetSubjects.vue';
import OrderAddSummary from '../components/order/OrderAddSummary.vue';

export default {
  name: 'OrderAdd',
  components: {
    OrderAddSetEmployee,
    OrderAddSetCustomer,
    OrderAddSetOrder,
    OrderAddSetSubjects,
    OrderAddSummary,
  },
  mixins: [errorHandler],
  data() {
    return {
      newOrder: {
        employee_id: '',
        customer_id: '',
        status_id: '',
        made_by_office: '',
        lead: '',
        type_id: '',
        is_leasing: '',
        priority: '',
        date_from: '',
        date_to: '',
      },
      createdOrderId: '',
      subjects: [],
      customer: {},
      customerImported: false,
      customerImportedData: {
        id: '',
        pesel: '',
        paszport: '',
        regon: '',
      },
      validated: {
        order: -1,
        customer: -1,
        subject: -2,
      },
    };
  },
  computed: {
    ...mapGetters([
      'currentUserType',
      'currentUserData',
    ]),
    chooseAgentAllow() {
      return (this.currentUserType === 'root' || this.currentUserType === 'admin' || this.currentUserType === 'asystent' || this.currentUserType === 'pracownik_b');
    },
    userEmployeeId() {
      return this.currentUserData.empl_id;
    },
  },
  created() {
    this.newOrder.employee_id = this.userEmployeeId;
    if (Object.keys(this.$route.query).length > 0) {
      if (this.$route.query.customer_id !== undefined && (this.$route.query.pesel !== undefined || this.$route.query.paszport !== undefined || this.$route.query.regon !== undefined)) {
        this.customerImported = true;
        this.customerImportedData = {
          id: this.$route.query.customer_id,
          pesel: this.$route.query.pesel ? this.$route.query.pesel: '',
          paszport: this.$route.query.paszport ? this.$route.query.paszport : '',
          regon: this.$route.query.regon ? this.$route.query.regon : '',
        };
      }
    }
  },
  methods: {
    setCustomer(customer) {
      this.customer = customer;
    },
    setOrder(order) {
      this.newOrder.made_by_office = order.made_by_office;
      this.newOrder.lead = order.lead;
      this.newOrder.status_id = order.status_id;
      this.newOrder.type_id = order.type_id;
      this.newOrder.is_leasing = order.is_leasing;
      this.newOrder.priority = order.priority;
      this.newOrder.date_from = order.date_from;
      this.newOrder.date_to = order.date_to;
    },
    setSubjects(subjects) {
      this.subjects = subjects;
    },
    setValidated(value, key) {
      this.validated[key] = value;
    },
    save() {
      this.$store.dispatch('setLoadingData', true);
      this.createOrGetCustomerId()
        .then(response => {
          this.newOrder.customer_id = response;
          OrderService.createOrder(this.newOrder)
            .then(response => {
              this.createdOrderId = response.data.newOrder.id;
              this.createOrderSubjects()
                .then(() => {
                  this.$store.dispatch('setLoadingData', false);
                  this.$router.push({ name: 'Order', params: { id: this.createdOrderId }});
                })
                .catch(error => {
                  this.$store.dispatch('setLoadingData', false);
                  this.resolveError(error);
                  // przekazać jeszcze błędy do component OrderAddSetOrder
                });
            })
            .catch(error => {
              this.$store.dispatch('setLoadingData', false);
              this.resolveError(error);
              // przekazać jeszcze błędy do component OrderAddSetOrder
            });
        })
        .catch(error => {
          this.$store.dispatch('setLoadingData', false);
          this.resolveError(error);
          // przekazać jeszcze błędy do component OrderAddSetCustomer
        });
    },
    createOrGetCustomerId() {
      const result = new Promise((resolve, reject) => {
        if (this.customer.id !== undefined) {
          resolve(this.customer.id);
        } else {
          CustomerService.storeNewCustomer(this.customer).then(response => resolve(response.data.newCustomerId)).catch(error => reject(error));
        }
      });
      return result;
    },
    createOrderSubjects() {
      const result = new Promise((resolve, reject) => {
        if (this.subjects.length > 0) {
          let count = 0;
          this.subjects.forEach(subject => {
            subject.type_id = this.newOrder.type_id;
            OrderService.addOrderSubject(this.createdOrderId, subject)
              .then(() => {
                count += 1;
                if (count === this.subjects.length) {
                  resolve();
                }
              })
              .catch(error => reject(error));
          });
        } else {
          resolve();
        }
      });
      return result;
    },
  },
};
</script>
