<template>
  <div class="flex flex-col gap-y-1 pb-1 border-b dark:border-gray-600">
    <div class="flex justify-between">
      <div class="flex-grow flex items-center gap-x-1">
        <span class="text-base">{{ contactPerson.forename }}</span>
        <span>{{ contactPerson.surname }}</span>
      </div>
      <div class="flex items-center gap-2" >
        <button @click="editItem" class="tw--btn-icon text-gray-600 dark:text-gray-500 p-0" title="edytuj"><FontAwesomeIcon :icon="['far', 'edit']" /></button>
        <button @click="delItemHandler" class="tw--btn-icon tw--btn-icon-danger text-gray-600 dark:text-gray-500 p-0" title="usuń"><FontAwesomeIcon :icon="['far', 'trash-alt']" /></button>
      </div>
    </div>
    <div id="customer-phones-emails" class="flex flex-wrap gap-2">
      <EmailsPhones
        ownerType="customerContactPerson"
        :ownerId="contactPerson.id"
        type="phones"
        :phones="contactPerson.phones"
        @added="phoneAdded"
        @updated="phoneUpdated"
        @deleted="phoneDeleted"
      />
      <EmailsPhones
        ownerType="customerContactPerson"
        :ownerId="contactPerson.id"
        type="emails"
        :emails="contactPerson.emails"
        @added="emailAdded"
        @updated="emailUpdated"
        @deleted="emailDeleted"
      />
    </div>
  </div>
</template>

<script>
import { errorHandler } from '../../mixins/errorHandler';
import { confirm } from '../../mixins/confirm';
import CustomerService from '../../services/CustomerService';
import EmailsPhones from '../emailsPhones/EmailsPhones.vue';

export default {
  name: 'ContactPersonItem',
  components: { EmailsPhones },
  mixins: [confirm, errorHandler],
  props: {
    contactPerson: {
      type: Object,
      required: true,
    },
  },
  methods: {
    editItem() {
      this.$emit('editContactPerson', this.contactPerson.id);
    },
    delItemHandler() {
      this.confirm(() => this.delItem(), 'Potwierdź usunięcie osoby kontaktowej.');
    },
    delItem() {
      CustomerService.delContactPerson(this.contactPerson.id)
        .then(response => {
          this.$store.dispatch('notifications/add', { type: 'success', message: response.data.msg });
          this.$emit('deleted', this.contactPerson.id);
        })
        .catch(error => this.resolveError(error));
    },
    phoneAdded(phone) {
      this.$emit('phoneAdded', { ccpId: this.contactPerson.id, phone });
    },
    phoneUpdated(phone) {
      this.$emit('phoneUpdated', { ccpId: this.contactPerson.id, phone });
    },
    phoneDeleted(id) {
      this.$emit('phoneDeleted', { ccpId: this.contactPerson.id, id });
    },
    emailAdded(email) {
      this.$emit('emailAdded', { ccpId: this.contactPerson.id, email });
    },
    emailUpdated(email) {
      this.$emit('emailUpdated', { ccpId: this.contactPerson.id, email });
    },
    emailDeleted(id) {
      this.$emit('emailDeleted', { ccpId: this.contactPerson.id, id });
    },
  },
};
</script>
