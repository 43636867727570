<template>
  <li @click="goTo({ name: 'Order', params: { id: order.id }})"
    :class="{'extended': showRenewal, 'urgent': Number(order.priority) === 1 && Number(order.status_id) < 8}"
  >

    <RenewOrder v-if="renewOrderModal" @close="renewOrderModal = false" :orderId="order.id" />

    <!-- Type Icon -->
    <div
      class="item order-type-icon"
      :class="{'is-leasing': Number(order.is_leasing) === 1}"
    >
      <span v-if="[3, 8, 9].includes(Number(order.type_id))" class="fake-icon">&sect;</span>
      <FontAwesomeIcon v-else :icon="orderTypeIcon" size="lg" />
    </div>

    <!-- Offer/Order details -->
    <div class="subgrid subgrid-details">

      <div class="item item--multirow">
        <div class="order-type"
          :class="order.type_name.length > 20 ? 'ek--text-xs' : 'ek--text-sm'"
        >
          {{ order.type_name }} <span v-if="order.is_leasing == 1">&nbsp;- LEASING</span>
        </div>
        <div v-if="order.subj_attr !== ''" class="long-text-content text-muted ek--text-xs" :title="order.subj_attr">
          <FontAwesomeIcon v-if="Number(order.type_id) !== 8" :icon="subjectTypeIcon" class="order-subject-icon inline-icon" />
          <span v-else class="order-subject-icon order-subject-fake-icon inline-icon">PKD</span>
          <span class="long-text-content text-muted">{{ order.subj_attr }}</span>
        </div>
      </div>

      <div class="item dates-container long-text-content text-bright">
        <FontAwesomeIcon :icon="['far', 'calendar-alt']" />
        <span>{{ order.date_from_h }}</span>
        <FontAwesomeIcon icon="long-arrow-alt-right" />
        <span>{{ order.date_to_h ? order.date_to_h : 'nieokreślony' }}</span>
        <FontAwesomeIcon
          icon="forward"
          :class="{'active': Number(order.has_next_ord) === 1}"
          :title="Number(order.has_next_ord) === 1 ? 'Polisa posiada kontynuację (wznowienie)' : ''"
        />
      </div>

    </div>

    <!-- Order -->
    <div class="subgrid subgrid-order">
      <div class="item">
        <span>{{ order.insurer_name }}</span>
        <span v-if="Number(order.priority) === 1 && Number(order.status_id) < 8 && order.insurer_name === ''"
          class="font-semibold"
        >pilne&nbsp;<FontAwesomeIcon icon="exclamation-triangle" class="text-yellow-400 dark:text-yellow-600" /></span>
      </div>

      <div class="item">
        <div v-if="order.policy_no !== ''" class="item-multirow">
          <div>
            <span class="ek--text-xs">nr: </span>
            <span @click.stop>
              <router-link :to="{ name: 'Order', params: { id: order.id }}" class="tw--link" :class="{'ek--text-xs': order.policy_no.length > 12}">
                {{ order.policy_no }}
              </router-link>
            </span>
          </div>
          <div class="ek--text-sm">
            {{ order.skladka | moneyPL }}
          </div>
        </div>
        <div v-else>
          <router-link @click.stop
            :to="{ name: 'Order', params: { id: order.id }}"
            class="tw--link"
          >Oferta</router-link>
        </div>
      </div>
    </div>

    <!-- Customer -->
    <div class="item item--multirow customer-container  long-text-content">

      <div class="long-text-content">
        <FontAwesomeIcon
          :icon="order.cust_is_company === '1' || order.cust_is_company === 1 ? ['far', 'building'] : ['far', 'user']"
          :title="order.cust_is_company === '1' || order.cust_is_company === 1 ? 'firma' : 'prywatny'"
          size="sm"
          class="inline-icon"
        />
        <span @click.stop>
          <router-link :to="{ name: 'Customer', params: { id: order.customer_id }}" class="tw--link">{{ order.customer_name }}</router-link>
        </span>
      </div>

      <address class="ek--text-sm long-text-content" :title="`${order.customer_city} ${order.customer_address}`">
        <span class="long-text-content"><em>{{ order.customer_address }}</em></span>
        <span class="font-semibold">{{ order.customer_city }}</span>
      </address>

    </div>

    <!-- Order status -->
    <div class="item item--multirow">
      <div class="status-container">
        <span :class="statusClass">{{ order.status_name }}</span>
        <FontAwesomeIcon :icon="['far', 'clock']" :title="`data ost. mod.: ${order.last_modification_h}`" />
      </div>
      <div class="employee-container long-text-content">
        <FontAwesomeIcon icon="user-tie" title="Agent" size="sm" />
        <span @click.stop class="long-text-content">
          <router-link
            :to="{ name: 'Employee', params: { id: order.employee_id }}"
            class="tw--link ek--text-sm"
            :class="{'ek--text-xs': order.employee_name.length > 20}"
          >
            {{ order.employee_name }}
          </router-link>
        </span>
      </div>
    </div>

    <div v-if="showRenewal" :title="order.date_to_h === '' ? 'nie dotyczy' : Number(order.has_next_ord) == 1 ? 'wznowiona' : ''">
      <button
        @click.stop="renewOrderModal = true"
        class="renew-btn tw--btn tw--btn-small"
        :disabled="renewButtonDisabled"
      >
        <FontAwesomeIcon icon="forward" /><br>Wznów
      </button>
    </div>
  </li>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import RenewOrder from './RenewOrder.vue';

export default {
  name: 'OrderListItemAsTable',
  components: { RenewOrder },
  props: {
    order: {
      type: Object,
      required: true,
    },
    showRenewal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      renewOrderModal: false,
    };
  },
  computed: {
    ...mapGetters([
      'currentUserType',
      'daysToRenewOrder',
    ]),
    showAdmin() {
      return this.currentUserType === 'root' || this.currentUserType === 'admin';
    },
    statusClass() {
      const status = this.order.status_id;
      let stClass = '';
      if (['1', '4'].includes(status)) {
        stClass = 'font-semibold text-indigo-600 dark:text-indigo-800';
      } else if (['3', '8'].includes(status)) {
        stClass = 'font-semibold text-red-600 dark:text-red-800';
      } else if (status === '6') {
        stClass = 'font-semibold text-green-600 dark:text-green-800';
      }
      return stClass;
    },
    renewButtonDisabled() {
      const outOfRange = !this.showAdmin && this.order.date_to_h > moment().add(this.daysToRenewOrder, 'days').format('YYYY-MM-DD');
      const notApplicable = this.order.date_to_h === '' || Number(this.order.has_next_ord) === 1;
      return outOfRange || notApplicable;
    },
    rowLabel() {
      let label = 'none__label';
      if (this.order.is_leasing === '1' || this.order.is_leasing === 1) {
        label = 'leasing__label';
      }
      return label;
    },
    orderTypeIcon() {
      const icons = [
        'car-side',
        'home',
        'file-signature',
        'plane-departure', //'luggage-cart',
        'heartbeat',
        'heartbeat',
        'warehouse',
        'file-signature',
        'file-signature',
        'seedling',
        'tractor',
        'users',
        'users',
        ['far', 'file-alt'],
      ];
      return icons[Number(this.order.type_id) - 1];
    },
    subjectTypeIcon() {
      let icon = 'file-signature';
      if (Number(this.order.type_id) === 1) {
        icon = 'car';
      } else if ([2,7].includes(Number(this.order.type_id))) {
        icon = 'map-marked-alt'; // 'home';
      } else if (Number(this.order.type_id) === 9) {
        icon = 'user-tie';
      } else if (Number(this.order.type_id) === 4) {
        icon = 'globe-americas';
      } else if (Number(this.order.type_id) === 10) {
        icon = 'seedling';
      }
      return icon;
    },
  },
  methods: {
    goTo(route) {
      const selection = window.getSelection().toString();
      if (selection.length === 0) {
        this.$router.push(route);
      }
    },
  },
};
</script>

<style scoped>
  li {
    display: grid;
    /* kolumny: ikona | typ, daty, subject | TU, polisa, składka | customer + address, employee | status */
    grid-template-columns: 2.5rem 5fr 4fr 4fr 11rem;
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    /* @apply border border-gray-400 dark:border-gray-600 rounded; */
    border-width: 1px;
    border-color: rgb(161, 161, 170);
    border-radius: 0.25rem;
  }
  li.extended {
    /* kolumny: jak li + renewBtn */
    grid-template-columns: 2.5rem 5fr 4fr 4fr 11rem 3rem;
  }
  li.urgent {
    /* @apply bg-yellow-100 bg-opacity-80; */
    background-color: rgba(254, 243, 199, 0.8);
  }
  .dark li.urgent {
    /* @apply bg-yellow-700 bg-opacity-10; */
    background-color: rgba(180, 83, 9, 0.1);
    border-color: rgb(180, 83, 9);
  }
  .dark li {
    /* @apply dark:border-gray-600 */
    border-color: rgb(82, 82, 91);
  }
  .subgrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(var(--column-width-min), 1fr));
  }
  .subgrid-two-third {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(var(--column-width-min), 1fr));
  }
  .subgrid-details {
    --column-width-min: 15rem;
  }
  .subgrid-order {
    --column-width-min: 10rem;
  }
  .subgrid-customer-employee {
    --column-width-min: 17rem;
  }
  .item {
    display: flex;
    flex-direction: row;
    column-gap: 0.25rem;
    align-items: center;
    justify-content: flex-start;
    border-right-width: 1px;
    border-color: rgb(212, 212, 216);
    padding: 0 0.5rem;
  }
  .dark .item {
    border-color: rgb(63, 63, 70);
  }
  li > div:last-child .item:last-child,
  li > .item:last-child {
    border-right-width: 0px;
  }
  .item--multirow {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .order-type-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    color: rgb(113, 113, 122);
  }
  .order-type-icon.is-leasing {
    background-color: rgb(254, 243, 199);
  }
  .dark .order-type-icon.is-leasing {
    background-color: rgb(180, 83, 9);
    color: rgb(39, 39, 42);
  }
  .order-type-icon .fake-icon {
    font-weight: 800;
    font-size: 1.5rem;
    line-height: 1.5rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .order-type {
    text-transform: uppercase;
    letter-spacing: -0.05em;
    font-weight: 600;
    color: rgb(79, 70, 229);
  }
  .order-subject-icon {
    color: rgb(52, 211, 153);
  }
  .dark .order-subject-icon {
    color: rgb(5, 150, 105);
  }
  .order-subject-fake-icon {
    font-size: 0.6rem;
    line-height: 0.6rem;
    background-color: rgb(52, 211, 153);
    color: white;
    font-weight: 600;
    border-radius: 0.125rem;
    padding-left: 2px;
    padding-right: 2px;
  }
  .dark .order-subject-fake-icon {
    background-color: rgb(5, 150, 105);
    color: hsl(240, 5%, 80%);
  }
  .dates-container [data-icon] {
    color: rgb(113, 113, 122);
  }
  .dates-container [data-icon="forward"] {
    color: transparent;
  }
  .dates-container [data-icon="forward"].active {
    color: rgb(110, 231, 183);
  }
  .dark .dates-container [data-icon="forward"].active {
    color: rgb(6, 96, 70);
  }
  .status-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .customer-container address {
    font-style: normal;
    display: flex;
    column-gap: 0.25rem;
  }
  .employee-container {
    display: flex;
    align-items: center;
    column-gap: 0.25rem;
  }
  .employee-container span {
    max-width: 100%;
  }
  .status-container [data-icon] {
    color: rgb(161, 161, 170);
    cursor: help;
    transition: all 0.3s ease-in-out;
  }
  .status-container [data-icon]:hover {
    color: rgb(219, 39, 119);
    transform: rotate(360deg);
  }
  .renew-btn {
    height: 100%;
    width: 100%;
    border: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .inline-icon {
    margin-right: 0.25rem;
  }
  .ek--text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  .ek--text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
  }
  .long-text-content {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @media screen and (max-width: 1023px) {
    li, li.extended {
      grid-template-columns: 1fr 1fr;
    }
    .item {
      border-right-width: 0;
    }
    .order-type-icon {
      border-bottom-left-radius: 0;
    }

    li>div { border-bottom-width: 1px; }
    li>div:nth-child(4), li>div:nth-child(6) { border-bottom-width: 0; }
    li>div:nth-child(-n+2) { border-right-width: 1px; }
    li>div:nth-child(1) { order:1; }
    li>div:nth-child(2) { order:3; }
    li>div:nth-child(3) { order:4; }
    li>div:nth-child(4) { order:5; grid-column: 1/3; }
    li>div:nth-child(5) { order:2; }
    li>div:nth-child(6) { order:6; grid-column: 1/3; }
    .renew-btn {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 0.25rem;
    }
  }
  @media screen and (max-width: 567px) {
    li>div:nth-child(2) { grid-column: 1/3; }
    li>div:nth-child(3) { grid-column: 1/3; }
    li>div:nth-child(2) { border-right-width: 0; }
  }
</style>
