<template>
  <form @submit.prevent="save"  class="w-full flex items-center gap-2" ref="wrapper">
    <input @keydown.esc="cancel" :type="type === 'emails' ? 'email' : 'text'" class="w-1/3" v-model="value" ref="inputValue" required>
    <div class="flex gap-2">
      <button type="submit" class="tw--btn" :disabled="value === ''">Zapisz</button>
      <button type="button" @click="cancel" class="tw--btn tw--btn-secondary">Anuluj</button>
    </div>
  </form>
</template>

<script>
import { errorHandler } from '../../mixins/errorHandler';
import EmailsPhonesService from '../../services/EmailsPhonesService';

export default {
  name: 'EmailsPhonesAdd',
  mixins: [errorHandler],
  props: {
    ownerType: String,
    ownerId: [String, Number],
    type: {
      type: String,
      default: 'emails',
    },
  },
  data() {
    return {
      value: '',
      saveErrors: {},
    };
  },
  mounted() {
    this.$nextTick(() => this.$refs.inputValue.focus());
    this.$nextTick(() => document.addEventListener('click', this.documentClick));
  },
  created() {
  },
  destroyed() {
    document.removeEventListener('click', this.documentClick);
  },
  methods: {
    save() {
      let method = '';
      if (this.ownerType === 'customer') {
        method = this.type === 'emails' ? 'addCustomerEmail' : 'addCustomerPhone';
      } else if (this.ownerType === 'employee') {
        method = this.type === 'emails' ? 'addEmployeeEmail' : 'addEmployeePhone';
      } else if (this.ownerType === 'customerContactPerson') {
        method = this.type === 'emails' ? 'addCustomerContactPersonEmail' : 'addCustomerContactPersonPhone';
      } else {
        this.$store.dispatch('notifications/add', { type: 'danger', message: 'Nieprawidłowe wywołanie funkcji!'});
        return false;
      }
      EmailsPhonesService[method](this.ownerId, { value: this.value })
        .then(response => {
          this.$emit('added', response.data.newElement);
          this.$store.dispatch('notifications/add', { type: 'success', message: 'Element został dodany' });
        })
        .catch(error => {
          this.saveErrors = this.resolveError(error);
        });
    },
    cancel() {
      this.$emit('cancel');
      this.value = '';
    },
    documentClick(e) {
      const { wrapper } = this.$refs;
      const { target } = e;
      if (!wrapper.contains(target)) {
        this.$emit('cancel');
      }
    },
  },
};
</script>
