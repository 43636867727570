import axios from 'axios';

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  // withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json',
  },
});

export default {
  addCustomerPhone(custId, fields) {
    return this.addElement(`/api/customers/${custId}/phones`, fields);
  },
  addCustomerEmail(custId, fields) {
    return this.addElement(`/api/customers/${custId}/emails`, fields);
  },
  updateCustomerPhone(custId, phoneId, fields) {
    return this.updateElement(`/api/customers/${custId}/phones/${phoneId}`, fields);
  },
  updateCustomerEmail(custId, emailId, fields) {
    return this.updateElement(`/api/customers/${custId}/emails/${emailId}`, fields);
  },
  delCustomerPhone(custId, phoneId) {
    return this.delElement(`/api/customers/${custId}/phones/${phoneId}`);
  },
  delCustomerEmail(custId, emailId) {
    return this.delElement(`/api/customers/${custId}/emails/${emailId}`);
  },
  addCustomerContactPersonPhone(ccpId, fields) {
    return this.addElement(`/api/customer-contact-persons/${ccpId}/phones`, fields);
  },
  addCustomerContactPersonEmail(ccpId, fields) {
    return this.addElement(`/api/customer-contact-persons/${ccpId}/emails`, fields);
  },
  updateCustomerContactPersonPhone(ccpId, phoneId, fields) {
    return this.updateElement(`/api/customer-contact-persons/${ccpId}/phones/${phoneId}`, fields);
  },
  updateCustomerContactPersonEmail(ccpId, emailId, fields) {
    return this.updateElement(`/api/customer-contact-persons/${ccpId}/emails/${emailId}`, fields);
  },
  delCustomerContactPersonPhone(ccpId, phoneId) {
    return this.delElement(`/api/customer-contact-persons/${ccpId}/phones/${phoneId}`);
  },
  delCustomerContactPersonEmail(ccpId, emailId) {
    return this.delElement(`/api/customer-contact-persons/${ccpId}/emails/${emailId}`);
  },
  addEmployeePhone(emplId, fields) {
    return this.addElement(`/api/employees/${emplId}/phones`, fields);
  },
  addEmployeeEmail(emplId, fields) {
    return this.addElement(`/api/employees/${emplId}/emails`, fields);
  },
  updateEmployeePhone(emplId, phoneId, fields) {
    return this.updateElement(`/api/employees/${emplId}/phones/${phoneId}`, fields);
  },
  updateEmployeeEmail(emplId, emailId, fields) {
    return this.updateElement(`/api/employees/${emplId}/emails/${emailId}`, fields);
  },
  delEmployeePhone(emplId, phoneId) {
    return this.delElement(`/api/employees/${emplId}/phones/${phoneId}`);
  },
  delEmployeeEmail(emplId, emailId) {
    return this.delElement(`/api/employees/${emplId}/emails/${emailId}`);
  },
  addElement(url, fields){
    const result = new Promise((resolve, reject) => {
      apiClient.post(url, fields)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  updateElement(url, fields) {
    const result = new Promise((resolve, reject) => {
      apiClient.put(url, fields)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  delElement(url) {
    const result = new Promise((resolve, reject) => {
      apiClient.delete(url)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
};
