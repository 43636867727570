<template>
  <div>
    <SubmenuWrapper>
    <!-- <div id="reports-submenu" class="flex flex-wrap gap-x-2 gap-y-1 -mt-2 mb-3 justify-between sm:justify-start"> -->
      <SubmenuButton destination="SalesReport">
        <template #header>Raport sprzedaży</template>
        <template #description>wg. daty wystawienia polisy</template>
      </SubmenuButton>
      <SubmenuButton v-if="currentUserType !== 'owfca'" destination="ProvisionEstimation">
        <template #header>Prognoza prowizji</template>
        <template #description>wg. daty należności rat</template>
      </SubmenuButton>
      <SubmenuButton destination="InstReminder">
        <template #header>Raport rat</template>
        <template #description>terminy należności rat</template>
      </SubmenuButton>
      <SubmenuButton destination="InkasoReport">
        <template #header>Rozliczenie gotówki</template>
      </SubmenuButton>
      <SubmenuButton destination="RodoReport">
        <template #header>Raport RODO</template>
        <template #description>raport braków zgód</template>
      </SubmenuButton>

      <!-- RAPORTY WYŁĄCZNE AGENCJI -->

      <SubmenuButton v-if="exclusiveReports" :destination="exclusiveReports">
        <template #header>Raporty {{ exclusiveReports }}</template>
        <template #description>raporty zaawansowane agencji</template>
      </SubmenuButton>
    </SubmenuWrapper>
    <!-- </div> -->
    <router-view />
  </div>
</template>

<script>
import SubmenuButton from '../components/SubmenuButton.vue';

export default {
  name: 'Reports',
  components: {
    SubmenuButton,
  },
  computed: {
    currentUserType() {
      return this.$store.getters.currentUserType;
    },
    showAdmin() {
      return this.currentUserType === 'root' || this.currentUserType === 'admin';
    },
    exclusiveReports() {
      return this.$store.getters.progOptions?.exclusiveReports;
    },
  },
  mounted() {
    if (this.$route.name === 'Reports') {
      this.$router.push({ name: 'SalesReport' });
    }
  },
};
</script>
