<template>
  <div class="flex flex-col gap-1">

    <ConsentAdd v-if="addConsentModal"
      :personId="personId"
      :allowEdit="allowEdit"
      @close="addConsentModal = false"
      @added="consentAdded"
    />

    <div id="consent-header" class="flex items-end justify-between text-muted border-b dark:border-gray-600 border-gray-400">
      <div class="flex gap-3">
        <span class="text-sm font-semibold uppercase">Zgody RODO</span>
        <span v-if="consents.length > 1" class="text-muted lowercase italic text-xs">
          ({{ consents.length }} pozycji <a href="" @click.prevent="showAll = !showAll" class="tw--link">{{ showAll ? 'ukryj' : 'pokaż wszystkie' }}</a>)
        </span>
      </div>
      <button @click="addConsentModal = true" class="tw--btn-icon pb-0" title="dodaj nowe oświadczenie zgód">
        <FontAwesomeIcon icon="plus" /> <FontAwesomeIcon :icon="['far', 'file-alt']" size="lg" class="-ml-1" />
      </button>
    </div>

    <div v-if="consents.length === 0" class="italic text-sm" :class="{'text-red-600 dark:text-red-700 font-semibold': alert}">brak oświadczenia zgód</div>
    <ConsentItem v-else :consent="consents[0]" :allowEdit="allowEdit" @consentUpdated="consentUpdated" @delConsent="delConsent" />
    <div v-show="showAll">
      <ConsentItem v-for="consent in consents.slice(1)" :key="consent.id" :consent="consent" :allowEdit="allowEdit" @consentUpdated="consentUpdated" @delConsent="delConsent" />
    </div>

  </div>
</template>

<script>
import { errorHandler } from '../../mixins/errorHandler';
import { confirm } from '../../mixins/confirm';
import GdprConsentSerevice from '../../services/GdprConsentSerevice';
import ConsentItem from './ConsentItem.vue';
import ConsentAdd from './ConsentAdd.vue';

export default {
  name: 'PersonConsents',
  components: {
    ConsentItem,
    ConsentAdd,
  },
  mixins: [confirm, errorHandler],
  props: {
    consents: {
      type: Array,
      required: true,
    },
    personId: {
      type: [String, Number],
      required: true,
    },
    allowEdit: {
      type: Boolean,
      default: false,
    },
    alert: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      addConsentModal: false,
      showAll: false,
    };
  },
  methods: {
    consentAdded(consent) {
      this.$emit('consentAdded', consent);
      this.addConsentModal = false;
    },
    consentUpdated(consent) {
      this.$emit('consentUpdated', consent);
    },
    delConsent(id) {
      GdprConsentSerevice.delConsent(id)
        .then(() => {
          this.$emit('consentDeleted', id);
          this.$store.dispatch('notifications/add', { type: 'success', message: 'Usunięto oświadczenie zgód' });
        })
        .catch(error => this.resolveError(error));
    },
  },
};
</script>
