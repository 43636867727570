<template>
  <div id="veryfication-panel" class="flex flex-col md:flex-row gap-3 p-3 bg-white dark:bg-gray-900 rounded-lg shadow-lg">
    <div class="flex flex-wrap gap-2 md:gap-4 flex-grow items-center text-sm">
      <div class="w-full md:w-auto font-semibold text-base uppercase">Podsumowanie:</div>
      <!-- Oferta -->
      <div class="flex flex-grow border dark:border-gray-600 rounded divide-x dark:divide-gray-600 items-center">
        <div class="font-semibold px-2 py-1 text-blue-600">oferta</div>
        <div class="px-2 py-1">
          <span v-if="validated.order === 1" class="text-xs text-muted">
            ok&nbsp;<span class="font-bold text-green-400 dark:text-green-600">&check;</span>
          </span>
          <span v-else-if="validated.order === -1" class="mt-1 text-xs text-muted">
            uzupełnij<FontAwesomeIcon class="ml-2 text-yellow-400 dark:text-yellow-600" icon="exclamation-triangle" size="lg" />
          </span>
          <span v-else-if="validated.order === 0" class="mt-1 text-xs text-muted">weryfikuję<FontAwesomeIcon class="ml-2" icon="sync-alt" size="lg" spin /></span>
          <span v-else class="mt-1 text-xs italic text-muted">brak (nieobowiązkowe)</span>
        </div>
      </div>
      <!-- Klient -->
      <div class="flex flex-grow border dark:border-gray-600 rounded divide-x dark:divide-gray-600 items-center">
        <div class="font-semibold px-2 py-1 text-blue-600">klient</div>
        <div class="px-2 py-1">
          <span v-if="validated.customer === 1" class="text-xs text-muted">
            ok&nbsp;<span class="font-bold text-green-400 dark:text-green-600">&check;</span>
          </span>
          <span v-else-if="validated.customer === -1" class="mt-1 text-xs text-muted">
            uzupełnij<FontAwesomeIcon class="ml-2 text-yellow-400 dark:text-yellow-600" icon="exclamation-triangle" size="lg" />
          </span>
          <span v-else-if="validated.customer === 0" class="mt-1 text-xs text-muted">weryfikuję<FontAwesomeIcon class="ml-2" icon="sync-alt" size="lg" spin /></span>
          <span v-else class="mt-1 text-xs italic text-muted">brak (nieobowiązkowe)</span>
        </div>
      </div>
      <!-- Klient -->
      <div class="flex flex-grow border dark:border-gray-600 rounded divide-x dark:divide-gray-600 items-center">
        <div class="font-semibold px-2 py-1 text-blue-600">przedmiot ubezpieczenia</div>
        <div class="px-2 py-1">
          <span v-if="validated.subject === 1" class="text-xs text-muted">
            ok&nbsp;<span class="font-bold text-green-400 dark:text-green-600">&check;</span>
          </span>
          <span v-else-if="validated.subject === -1" class="mt-1 text-xs text-muted">
            uzupełnij<FontAwesomeIcon class="ml-2 text-yellow-400 dark:text-yellow-600" icon="exclamation-triangle" size="lg" />
          </span>
          <span v-else-if="validated.subject === 0" class="mt-1 text-xs text-muted">weryfikuję<FontAwesomeIcon class="ml-2" icon="sync-alt" size="lg" spin /></span>
          <span v-else class="mt-1 text-xs italic text-muted">brak (nieobowiązkowe)</span>
        </div>
      </div>

    </div>
    <button
      @click="$emit('saveOrder')"
      class="tw--btn disabled:cursor-help"
      :disabled="!isFullValidated"
      :title="!isFullValidated ? 'Popraw wszystkie błędy w formularzach' : ''"
    >Zapisz ofertę</button>
  </div>
</template>

<script>
export default {
  name: 'OrderAddSummary',
  props: {
    validated: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isFullValidated() {
      return this.validated.order === 1 && this.validated.customer === 1 && (this.validated.subject === -2 || this.validated.subject === 1);
    },
  },
};
</script>
