<template>
  <div id="new-order-details" class="p-3 bg-white dark:bg-gray-900 rounded-lg shadow-lg">
    <div class="flex -mt-3 mb-3 -mx-3 px-3 py-1 border-b dark:border-gray-600">
      <div class="flex-grow uppercase font-semibold text-muted">Przedmiot ubezpieczenia</div>
      <span v-if="validationState === 1" class="text-xs text-muted">
        ok&nbsp;<span class="font-bold text-base text-green-400 dark:text-green-600">&check;</span>
      </span>
      <span v-else-if="validationState === -1" class="mt-1 text-xs text-muted">
        uzupełnij<FontAwesomeIcon class="ml-2 text-yellow-400 dark:text-yellow-600" icon="exclamation-triangle" size="lg" />
      </span>
      <span v-else-if="validationState === 0" class="mt-1 text-xs text-muted">weryfikuję<FontAwesomeIcon class="ml-2" icon="sync-alt" size="lg" spin /></span>
    </div>
    <div class="flex flex-col">
      <div v-if="orderTypeId" class="flex gap-2">
        <button class="tw--btn" @click="addNewSubject">dodaj <span v-show="subjects.length > 0">kolejny</span> przedmiot ubezpieczenia</button>
        <button v-show="addSubjectFormState && subjects.length > 0" class="tw--btn tw--btn-danger" @click="cancelNewSubject">anuluj (usuń)</button>
      </div>
      <p v-else class="italic text-muted">Wybierz rodzaj ubezpieczenia, aby dodac przedmiot</p>
      <div class="flex my-2">
        <div
          v-for="(name, index) in subjects"
          :key="index"
          class="px-2 border-gray-400 dark:border-gray-500"
          :class="index === current ? 'border border-b-0 rounded-sm' : 'border-b'"
        >
          <div
            class="flex gap-1 items-center"
            :class="{'cursor-pointer': index !== current}"
          >
            <div
              :class="{'tw--link': index !== current}"
              @click="index !== current ? showSubject(index) : null"
              :title="index !== current ? `wyświetl / edytuj przedmiot ${index + 1}` : ''"
            >przedmiot {{index + 1}}</div>
            <FontAwesomeIcon
              v-show="index !== current"
              class="text-red-600 hover:text-red-400 dark:text-red-900 cursor-pointer dark:hover:text-red-700" icon="times" @click.stop="delSubject(index)" title="usuń przedmiot" />
          </div>
        </div>
        <div v-show="subjects.length > 0" class="flex-grow border-gray-400 dark:border-gray-500 border-b">&nbsp;</div>
      </div>
      <SubjectForm
        v-if="addSubjectFormState && subjects.length > 0"
        :orderTypeId="orderTypeId"
        :saveErrors="saveErrors"
        v-model="subjects[current]"
        @input="subjectFormChanged"
      />
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
// import { debounce } from 'lodash';
import { errorHandler } from '../../mixins/errorHandler';
import OrderService from '../../services/OrderService';
import SubjectForm from '../subject/SubjectForm.vue';

export default {
  name: 'OrderAddSetSubjects',
  components: {SubjectForm},
  mixins: [errorHandler],
  props: {
    orderTypeId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      subjects: [],
      current: -1,
      saveErrors: {},
      addSubjectFormState: false,
      validating: -1,  // -1 errors, 0 validating, 1 no errors
    };
  },
  computed: {
    validationState() {
      return this.subjects.length > 0 ? this.validating : -2; // -2 = nie dotyczy
    },
  },
  methods: {
    showSubject(index) {
      this.validating = 0;
      this.apiValidation()
        .then(() => {
          this.current = index;
        })
        .catch(() => this.$store.dispatch('notifications/add', { type: 'error', message: 'Popraw błędy zanim przejdziesz do widoku innego przedmiotu' }));
      this.addSubjectFormState = true;
    },
    addNewSubject() {
      if (this.subjects.length > 0) {
        this.validating = 0;
        this.apiValidation()
          .then(() => {
            this.subjects.push({});
            this.validating = -1;
            this.addSubjectFormState = true;
            this.current = this.subjects.length - 1;
            this.emitSubjectsChanged();
          })
          .catch(() => this.$store.dispatch('notifications/add', { type: 'error', message: 'Popraw błędy zanim dodasz kolejny przedmiot' }));
      } else {
        this.subjects.push({});
        this.addSubjectFormState = true;
        this.current = this.subjects.length - 1;
        this.emitSubjectsChanged();
      }
    },
    cancelNewSubject() {
      this.subjects.splice(this.current, 1);
      this.current = -1;
      this.addSubjectFormState = false;
      this.emitSubjectsChanged();
    },
    delSubject(index) {
      this.subjects.splice(index, 1);
      if (this.current > index) this.current -= 1;
      this.emitSubjectsChanged();
    },
    subjectFormChanged() {
      this.validating = 0;
      this.emitSubjectsChanged();
      this.validateOnTheFly();
    },
    emitSubjectsChanged: debounce(function() {
      this.$emit('input', this.subjects);
    }, 500),
    validateOnTheFly: debounce(function() {
      this.apiValidation();
    }, 1000),
    apiValidation() {
      const result = new Promise((resolve, reject) => {
        OrderService.validateNewOrderSubject(this.orderTypeId, this.subjects[this.current])
          .then(response => {
            if (response.data.state === 'ok') {
              this.validating = 1;
              this.saveErrors = {};
              resolve();
            } else {
              this.validating = -1;
              this.saveErrors = response.data.errors;
              reject();
            }
          })
          .catch(error => {
            this.validating = -1;
            this.resolveError(error);
            reject();
          }); //mixin
      });
      return result;
    },
  },
  watch: {
    orderTypeId() {
      this.subjects = [];
    },
    validationState(newVal) {
      this.$emit('validated', newVal);
    },
  },
};
</script>
