<template>
  <div class="flex flex-col">
    <div v-if="labelTop" class="font-semibold text-sm">{{ labelTop }}</div>
    <div
      class="relative w-full flex items-stretch border rounded-sm focus-within:ring-1
        focus-within:border-blue-300 ring-blue-600
        dark:focus-within:border-blue-600 dark:ring-blue-900 dark:border-gray-600 border-gray-400"
      :class="{'border-red-400 dark:border-red-600': error, 'border-yellow-400 dark:border-yellow-600': warning}"
      ref="wrapper"
    >
      <label v-show="`${labelAddon}${labelIconAddon}`.length > 0"
        class="flex items-center gap-1 font-semibold text-sm px-1 py-1 bg-gray-100 dark:bg-transparent border-r border-gray-400 dark:border-gray-600"
      >
        <span class="whitespace-nowrap">{{ labelAddon }}</span> <FontAwesomeIcon v-if="labelIconAddon.length > 0" :icon="labelIconAddon" />
      </label>
      <input
        type="text"
        class="w-full border-0 focus:ring-0"
        :class="{'bg-red-100 dark:bg-red-800': error}"
        :value="value"
        @input="updateValue($event.target.value)"
        @keydown.enter="setSuggestion(-1)"
        @keydown.down="down"
        @keydown.up="up"
        @keydown.esc="open = false"
        @keydown.tab="open = false"
        :disabled="disabled"
      >
      <div v-show="openSuggestions" class="absolute top-0 mt-8 left-0 w-full bg-gray-100 dark:bg-gray-800 border dark:border-gray-600 border-gray-400 shadow-lg z-10">
        <div class="divide-y dark:divide-gray-600">
          <div
            class="text-sm px-2 py-1 cursor-pointer"
            :class="index === current ? 'bg-blue-400 dark:bg-blue-700 text-white dark:text-gray-300' : 'text-gray-800 dark:text-gray-400'"
            v-for="(suggestion, index) in suggestions"
            :key="index"
            @click.prevent="setSuggestion(index)"
            @mouseover="current = index"
          >
            {{ suggestion.autocompl_disp_name }}
          </div>
        </div>
      </div>
    </div>
    <p v-if="error" class="text-red-400 dark:text-red-600 text-xs">{{ error }}</p>
  </div>
</template>

<script>
import ApiService from '../services/ApiService';
import debounce from 'lodash/debounce';
// import { debounce } from 'lodash';
import { errorHandler } from '../mixins/errorHandler';

export default {
  name: 'BaseAutocompleteInput',
  mixins: [errorHandler],
  props: {
    value: {
      type: String,
    },
    searchUrl: {
      type: String,
      required: true,
    },
    labelTop: {
      type: String,
      default: '',
    },
    labelAddon: {
      type: String,
      default: '',
    },
    labelIconAddon: {
      type: [String, Array],
      default() {
        return '';
      },
    },
    error: {
      type: String,
      required: false,
    },
    warning: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      open: false,
      current: 0,
      suggestions: [],
    };
  },
  computed: {
    tooManySugg() {
      return this.suggestions.length > 10;
    },
    openSuggestions() {
      return this.value !== '' && this.suggestions.length !== 0 && this.open === true;
    },
  },
  created() {
    document.addEventListener('click', this.documentClick);
  },
  destroyed() {
    document.removeEventListener('click', this.documentClick);
  },
  methods: {
    updateValue(value) {
      if (this.open === false && value.length > 2) {
        this.suggestions = [];
        this.open = true;
        this.current = 0;
      }
      if (value.length > 2) {
        this.getSuggestions(value);
      }
      this.$emit('input', value);
      this.suggestions = [];
    },
    getSuggestions: debounce(function (value) {
      ApiService.autocompleteSearch(this.searchUrl, value)
        .then(response => { this.suggestions = response.data.result; })
        .catch(error => this.resolveError(error)); //mixin
    }, 400),
    setSuggestion(position) {
      const valueIndex = position === -1 ? this.current : position;
      if (this.suggestions.length === 0) {
        return false;
      }
      this.$emit('input', this.suggestions[valueIndex].autocompl_value);
      this.$emit('selected', this.suggestions[valueIndex]);
      this.open = false;
      return true;
    },
    up() {
      if (this.current > 0) {
        this.current -= 1;
      }
    },
    down() {
      if (this.current < this.suggestions.length - 1) {
        this.current += 1;
      }
    },
    documentClick(e) {
      const { wrapper } = this.$refs;
      const { target } = e;
      if (!wrapper.contains(target)) {
        this.open = false;
      }
    },
  },
};
</script>
