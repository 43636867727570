<template>
  <div class="submenu-wrapper -mx-4 -mt-4 mb-4 bg-gray-200 dark:border-gray-700 border-t border-gray-100 dark:bg-gray-900 print-hidden shadow">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'SubmenuWrapper',
};
</script>

<style scoped>
  .submenu-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
</style>
