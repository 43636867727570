<template>
  <div
    class="relative flex items-stretch border rounded-sm focus-within:ring-1
      focus-within:border-blue-300 ring-blue-600 border-gray-300
      dark:focus-within:border-blue-600 dark:ring-blue-900 dark:border-gray-600"
      ref="wrapper"
  >
    <label class="flex items-center gap-1 font-semibold text-sm px-1 py-1 border-r border-gray-300 dark:border-gray-600 text-gray-400">
      <span class="whitespace-nowrap">Wyszukaj</span> <FontAwesomeIcon icon="search" />
    </label>
    <input
      type="text"
      class="w-full border-0 focus:ring-0"
      :value="searchString"
      @input="updateValue($event.target.value)"
      @keydown.enter="selectCustomer(-1)"
      @keydown.down="down"
      @keydown.up="up"
      @keydown.esc="resetSearch"
      @keydown.tab="resetSearch"
    >
    <div v-show="suggestions.length > 0" class="absolute top-0 mt-8 left-0 w-full bg-gray-100 dark:bg-gray-800 border dark:border-gray-600 shadow-lg z-10">
      <div v-if="tooManySugg" class="p-2 text-sm text-red-500 dark:text-red-700">Zbyt wiele dopasowań - wpisz więcej znaków lub kompletny nr pesel/regon aby zawęzić wynik</div>
      <div v-else class="divide-y dark:divide-gray-600">
        <div
          class="text-sm px-2 py-1 cursor-pointer"
          :class="index === current ? 'bg-blue-400 dark:bg-blue-700 text-white dark:text-gray-300' : ''"
          v-for="(suggestion, index) in suggestions"
          :key="index"
          @click.prevent="selectCustomer(index)"
          @mouseover="current = index"
        >
          <div class="flex flex-wrap justify-between">
            <div class="font-semibold">
              <FontAwesomeIcon :icon="suggestion.regon ? ['far', 'building'] : ['far', 'user']" class="text-gray-400 dark:text-gray-500" />&nbsp;{{ suggestion.name }}
            </div>
            <div class="cursor-help" :title="suggestion.pesel ? 'pesel' : suggestion.paszport ? 'paszport' : 'regon'">
              {{ suggestion.pesel }}{{ suggestion.paszport }}{{ suggestion.regon }}
            </div>
          </div>
          <div class="flex flex-wrap gap-4">
            <div class="font-semibold">{{ suggestion.city }}</div>
            <div class="italic">{{ suggestion.street }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomerService from '../../services/CustomerService';
import debounce from 'lodash/debounce';
// import { debounce } from 'lodash';
import { errorHandler } from '../../mixins/errorHandler';

export default {
  name: 'FindCustomer',
  mixins: [errorHandler],
  data() {
    return {
      current: 0,
      searchString: '',
      suggestions: [],
    };
  },
  computed: {
    tooManySugg() {
      return this.suggestions.length > 10;
    },
  },
  created() {
    document.addEventListener('click', this.documentClick);
  },
  destroyed() {
    document.removeEventListener('click', this.documentClick);
  },
  methods: {
    updateValue(value) {
      this.searchString = value;
      this.suggestions = [];
      this.current = 0;
      if(value.length > 2) {
        this.getSuggestions(value);
      }
    },
    getSuggestions: debounce(function (value) {
      CustomerService.searchCustomer(value)
        .then(response => { this.suggestions = response.data.result; })
        .catch(error => this.resolveError(error)); //mixin
    }, 400),
    selectCustomer(position) {
      if (this.suggestions.length === 0) {
        return false;
      }
      let valueIndex = position;
      if (position === -1) valueIndex = this.current;
      this.$emit('selected', this.suggestions[valueIndex]);
      this.resetSearch();
      return true;
    },
    up() {
      if (this.current > 0) {
        this.current -= 1;
      }
    },
    down() {
      if (this.current < this.suggestions.length - 1) {
        this.current += 1;
      }
    },
    documentClick(e) {
      const { wrapper } = this.$refs;
      const { target } = e;
      if (!wrapper.contains(target)) {
        this.resetSearch();
      }
    },
    resetSearch() {
      this.suggestions = [];
      this.current = 0;
      this.searchString = '';
    },
  },
};
</script>
