import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/Login.vue';
import store from '../store';
import Logout from '../views/Logout.vue';
import Calendar from '../views/Calendar.vue';
import NotFound from '../views/NotFound.vue';
import ApiIssue from '../views/ApiIssue.vue';
import OrderList from '../views/OrderList.vue';
import Order from '../views/Order.vue';
import OrderAdd from '../views/OrderAdd.vue';
import CustomerList from '../views/CustomerList.vue';
import Customer from '../views/Customer.vue';
import Reports from '../views/Reports.vue';
import Provisions from '../views/Provisions.vue';
import Extras from '../views/Extras.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: { name: "Calendar" },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { forNotLogged: true },
  },
  {
    path: "/haslo-email",
    name: "PasswordEmail",
    component: () =>
      import(/* webpackChunkName: "passemail" */ "../views/PasswordEmail.vue"),
    meta: { forNotLogged: true },
  },
  {
    path: "/haslo-reset/:token",
    name: "PasswordReset",
    component: () =>
      import(/* webpackChunkName: "passreset" */ "../views/PasswordReset.vue"),
    meta: { forNotLogged: true },
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
    meta: { niceName: "do zobaczenia wkrótce..." },
  },
  {
    path: "/kalendarz",
    name: "Calendar",
    component: Calendar,
    meta: { niceName: "Kalendarz" },
  },
  {
    path: "/nowa-oferta",
    name: "OrderAdd",
    component: OrderAdd,
    meta: { niceName: "Dodaj ofertę" },
  },
  {
    path: "/sprzedaz/:id",
    name: "Order",
    component: Order,
    // props: true,
    meta: { niceName: "Karta oferty / polisy" },
  },
  {
    path: "/sprzedaz",
    name: "Orders",
    component: OrderList,
    meta: { niceName: "Sprzedaż" },
  },
  {
    path: "/klienci",
    name: "Customers",
    component: CustomerList,
    meta: { niceName: "Klienci" },
  },
  {
    path: "/klienci/:id",
    name: "Customer",
    component: Customer,
    props: true,
    meta: { niceName: "Klient" },
  },
  {
    path: "/raporty",
    name: "Reports",
    component: Reports,
    meta: { niceName: "Raporty" },
    children: [
      // {
      //   path: '',
      //   redirect: 'sprzedaz',
      // },
      {
        path: "sprzedaz",
        name: "SalesReport",
        component: () =>
          import(
            /* webpackChunkName: "salesReport" */ "../components/reports/SalesReport.vue"
          ),
        meta: { niceName: "Raporty" },
      },
      {
        path: "prognoza-prowizji",
        name: "ProvisionEstimation",
        component: () =>
          import(
            /* webpackChunkName: "provisionEstimation" */ "../components/reports/ProvisionEstimation.vue"
          ),
        meta: {
          niceName: "Raporty",
          restricted: true,
          roles: [
            "root",
            "admin",
            "manager",
            "asystent",
            "pracownik_b",
            "agent",
          ],
        },
      },
      {
        path: "nadchodzace-naleznosci",
        name: "InstReminder",
        component: () =>
          import(
            /* webpackChunkName: "instReminder" */ "../components/reports/InstReminder.vue"
          ),
        meta: { niceName: "Raporty" },
      },
      {
        path: "rozliczenie-gotowki",
        name: "InkasoReport",
        component: () =>
          import(
            /* webpackChunkName: "InkasoReport" */ "../components/reports/InkasoReport.vue"
          ),
        meta: { niceName: "Raporty" },
      },
      {
        path: "braki-rodo",
        name: "RodoReport",
        component: () =>
          import(
            /* webpackChunkName: "RodoReport" */ "../components/reports/RodoReport.vue"
          ),
        meta: { niceName: "Raporty" },
      },
    ],
  },
  {
    path: "/prowizje",
    name: "Provisions",
    component: Provisions,
    meta: {
      niceName: "Prowizje",
      restricted: true,
      roles: ["root", "admin", "manager", "asystent", "pracownik_b", "agent"],
    },
    children: [
      {
        path: "wczytywanie-prowizji",
        name: "ProvisionSettlement",
        component: () =>
          import(
            /* webpackChunkName: "ProvisionSettlement" */ "../components/provisions/ProvisionSettlement.vue"
          ),
        meta: {
          niceName: "Prowizje",
          restricted: true,
          roles: ["root", "admin"],
        },
      },
      {
        path: "zestawienie-prowizji",
        name: "ListPaydProvisions",
        component: () =>
          import(
            /* webpackChunkName: "ListPaydProvisions" */ "../components/provisions/ListPaydProvisions.vue"
          ),
        meta: {
          niceName: "Prowizje",
          restricted: true,
          roles: [
            "root",
            "admin",
            "manager",
            "asystent",
            "pracownik_b",
            "agent",
          ],
        },
      },
      {
        path: "prowizje-niewyplacone",
        name: "NullPaymentReport",
        component: () =>
          import(
            /* webpackChunkName: "NullPaymentReport" */ "../components/provisions/NullPaymentReport.vue"
          ),
        meta: { niceName: "Prowizje" },
      },
      {
        path: "wystawianie-faktur",
        name: "AdminInvoices",
        component: () =>
          import(
            /* webpackChunkName: "AdminInvoices" */ "../components/invoices/AdminInvoices.vue"
          ),
        meta: {
          niceName: "Prowizje",
          restricted: true,
          roles: ["root", "admin"],
        },
      },
      {
        path: "faktury",
        name: "EmployeeInvoices",
        component: () =>
          import(
            /* webpackChunkName: "EmployeeInvoices" */ "../components/invoices/EmployeeInvoices.vue"
          ),
        meta: {
          niceName: "Prowizje",
          restricted: true,
          roles: [
            "root",
            "admin",
            "manager",
            "asystent",
            "pracownik_b",
            "agent",
          ],
        },
      },
      {
        path: "zalacznik-prowizyjny",
        name: "ProductListReadOnly",
        component: () =>
          import(
            /* webpackChunkName: "ProductListReadOnly" */ "../components/products/ProductListReadOnly.vue"
          ),
        meta: {
          niceName: "Prowizje",
          restricted: true,
          roles: [
            "root",
            "admin",
            "manager",
            "asystent",
            "pracownik_b",
            "agent",
          ],
        },
      },
      {
        path: "inne-rozliczenia",
        name: "ExtraPayments",
        component: () =>
          import(
            /* webpackChunkName: "ExtraPayments" */ "../components/extra-payments/ExtraPaymentsList.vue"
          ),
        meta: {
          niceName: "Prowizje",
          restricted: true,
          roles: [
            "root",
            "admin",
            "manager",
            "asystent",
            "pracownik_b",
            "agent",
          ],
        },
      },
    ],
  },
  {
    path: "/przydatne",
    name: "Extras",
    component: Extras,
    meta: { niceName: "Linki" },
  },
  {
    path: "/pracownicy",
    name: "Employees",
    component: () =>
      import(/* webpackChunkName: "employees" */ "../views/EmployeeList.vue"),
    meta: {
      niceName: "Pracownicy",
      restricted: true,
      roles: ["root", "admin", "manager"],
    },
  },
  {
    path: "/pracownicy/:id",
    name: "Employee",
    component: () =>
      import(/* webpackChunkName: "employee" */ "../views/Employee.vue"),
    meta: {
      niceName: "Pracownik",
      restricted: true,
      roles: ["root", "admin", "manager"],
    },
  },
  {
    path: "/ustawienia",
    name: "Settings",
    component: () =>
      import(/* webpackChunkName: "Settings" */ "../views/Settings.vue"),
    meta: {
      niceName: "Ustawienia",
      restricted: true,
      roles: ["root", "admin"],
    },
    children: [
      {
        path: "produkty",
        name: "ProductList",
        component: () =>
          import(
            /* webpackChunkName: "ProductList" */ "../components/products/ProductList.vue"
          ),
        meta: {
          niceName: "Produkty",
          restricted: true,
          roles: ["root", "admin"],
        },
      },
      {
        path: "towarzystwa-ubezpieczeniowe",
        name: "Insurers",
        component: () =>
          import(
            /* webpackChunkName: "Insurers" */ "../components/insurers/Insurers.vue"
          ),
        meta: {
          niceName: "Produkty",
          restricted: true,
          roles: ["root", "admin"],
        },
      },
    ],
  },
  {
    path: "/raporty-exima",
    name: "exima",
    component: () =>
      import(
        /* webpackChunkName: "Exima" */ "../views/exclusive-reports/Exima.vue"
      ),
    beforeEnter: (to, from, next) => {
      if (store.getters.progOptions.exclusiveReports === undefined) {
        store.dispatch("getProgOptions").then(() => {
          if (store.getters.progOptions?.exclusiveReports === "exima") {
            next();
          } else {
            next({ name: "SalesReport" });
          }
        });
      } else {
        if (store.getters.progOptions?.exclusiveReports === "exima") {
          next();
        } else {
          next({ name: "Reports" });
        }
      }
    },
    meta: { niceName: "Raporty Exima" },
    children: [
      {
        path: "sprzedaz-nowe-wznowione",
        name: "EximaSalesReport",
        component: () =>
          import(
            /* webpackChunkName: "EximaSalesReport" */ "../components/exclusive-reports/exima/SalesReport.vue"
          ),
        meta: { niceName: "Sprzedaż nowe/wznowione" },
      },
      {
        path: "skutecznosc-agent",
        name: "EximaAgentEfficiencyReport",
        component: () =>
          import(
            /* webpackChunkName: "EximaAgentEfficiencyReport" */ "../components/exclusive-reports/exima/EximaAgentEfficiencyReport.vue"
          ),
        meta: { niceName: "Skuteczność agenta" },
      },
      {
        path: "skutecznosc-asystent",
        name: "EximaAssistantEfficiencyReport",
        component: () =>
          import(
            /* webpackChunkName: "EximaAssistantEfficiencyReport" */ "../components/exclusive-reports/exima/EximaAssistantEfficiencyReport.vue"
          ),
        meta: { niceName: "Skuteczność asystentki(-a)" },
      },
    ],
  },
  {
    path: "/404",
    name: "404",
    component: NotFound,
    meta: { forNotLogged: true },
    props: true,
  },
  {
    path: "/500",
    name: "500",
    component: ApiIssue,
    meta: { forNotLogged: true },
  },
  {
    path: "*",
    redirect: { name: 404 },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

const restrictPriv = (to, from, next) => {
  const userRole = store.getters.currentUserData.user_rights.user_type;
  if (!to.meta.roles.includes(userRole)) {
    store.dispatch('notifications/add', { message: 'Brak uprawnień do modułu! Próba dostępu została odnotowana w logach.', type: 'danger' });
    next({
      path: '/',
    });
  } else {
    next();
  }
};

router.beforeEach((to, from, next) => {
  if (to.meta.restricted) {
    if (!store.getters.currentUserType) {
      store.dispatch('getCurrentUserData')
        .then(() => restrictPriv(to, from, next));
    } else {
      restrictPriv(to, from, next);
    }
  } else {
    next();
  }
});

export default router;
