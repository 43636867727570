import axios from 'axios';

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  // withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json',
  },
});

export default {
  addUser(fields) {
    const result = new Promise((resolve, reject) => {
      apiClient.post('/api/create-user', fields)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  updateUser(id, fields) {
    const result = new Promise((resolve, reject) => {
      apiClient.put(`/api/update-user-item/${id}`, fields)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  getUserGroupList() {
    const result = new Promise((resolve, reject) => {
      apiClient.get('/api/get-user-groups-list')
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  changePassword(userId, formData) {
    const result = new Promise((resolve, reject) => {
      apiClient.post(`/api/users/${userId}/password`, formData)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
};
