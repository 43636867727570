<template>
  <BaseModal
    @closeModal="$emit('close')"
    :busy="saving"
    :cancel="true"
    :ok="true"
    okLabel="zapisz"
    @ok="save"
  >
    <template #header>
      Nowe ośw. zgód
    </template>
    <template #default>
      <div class="flex flex-col gap-y-3">
        <div v-if="allowEdit" class="flex-flex-col gap-y-3"> <!-- Pola warunkowe -->
          <div>
            <BaseInputWithAddon class="w-full sm:w-1/2" v-model="form.set_date_h" type="date" labelTop="data udzielenie zgód" :error="errors.set_date_h !== undefined ? errors.set_date_h : ''" />
          </div>
          <div class="w-full flex-col gap-y-1">
            <div>Koniec obowiązywania oświadczenia</div>
            <div class="flex flex-wrap gap-x-3 gap-y-1 items-center">
              <BaseInputWithAddon v-model="form.due_date_h" type="date" labelAddon="data" :error="errors.due_date_h !== undefined ? errors.due_date_h : ''" />
              <div class="text-muted">lub</div>
              <label for="do-odwolania">
                <input
                  id="do-odwolania"
                  type="checkbox"
                  true-value="1" false-value="0"
                  v-model="form.till_dismiss"
                  :disabled="Number(form.till_dismiss) === 1"
                > do odwołania
              </label>
              <label for="koniec-polisy" class="flex items-center gap-1" title="pokaż również nieaktywnych pracowników">
                <input
                  id="koniec-polisy"
                  type="checkbox"
                  true-value="1" false-value="0"
                  v-model="fakePolicyEndDate"
                  :disabled="Number(fakePolicyEndDate) === 1"
                > do końca polisy
              </label>
            </div>
          </div>
          <label for="consent-order-service" class="flex items-center gap-1" title="pokaż również nieaktywnych pracowników">
            <input
              id="consent-order-service"
              type="checkbox"
              v-model="form.order_service"
              true-value="1" false-value="0"
            > Zgoda na przetwarzanie danych osobowych oraz kopii dokumentów dla celów obsługi polis ubezpieczeniowych
          </label>
          <label for="consent-email-order-service" class="flex items-center gap-1" title="pokaż również nieaktywnych pracowników">
            <input
              id="consent-email-order-service"
              type="checkbox"
              v-model="form.email_order_service"
              true-value="1" false-value="0"
            > Zgoda na elektroniczną obsługę umów ubezpieczeń, droga mailową
          </label>
          <label for="consent-marketing" class="flex items-center gap-1" title="pokaż również nieaktywnych pracowników">
            <input
              id="consent-marketing"
              type="checkbox"
              v-model="form.marketing"
              true-value="1" false-value="0"
            > Zgoda na otrzymywanie drogą elektroniczną informacji handlowych (ustwa o świadczeniu usług drogą elektroniczną (Dz.U. z 2002 Nr 144 poz. 1204))
          </label>
          <label for="consent-telecom" class="flex items-center gap-1" title="pokaż również nieaktywnych pracowników">
            <input
              id="consent-telecom"
              type="checkbox"
              v-model="form.telecom"
              true-value="1" false-value="0"
            > Zgoda na wykorzystanie telekomunikacyjnych urządzeń końcowych dla celów marketingu bezpośredniego (Art. 172 ust. Prawo telekomunikacyjne)
          </label>
          <label for="consent-profiling" class="flex items-center gap-1" title="pokaż również nieaktywnych pracowników">
            <input
              id="consent-profiling"
              type="checkbox"
              v-model="form.profiling"
              true-value="1" false-value="0"
            > Zgoda na profilowanie (procesy zautomatyzowanego podejmowania decyzji)
          </label>
        </div> <!-- Pola warunkowe -->
        <div>
          <label>
            <input type="file" @change="fileChange($event.target.files)">
          </label>
        </div>
      </div>
    </template>
    <!-- <template #busy>
      <div v-if="saving" class="absolute top-0 left-0 w-full h-full">
        <div class="w-full h-full bg-white dark:bg-black opacity-80"></div>
        <div class="absolute top-0 left-0 flex w-full h-full items-center justify-center">
          <FontAwesomeIcon class="mr-3" icon="spinner" size="2x" spin />
          <div class="font-bold">
            <p>Zapisuję dane...</p>
            <p>Nie zamykaj okna przeglądarki do zakończenia procesu...</p>
          </div>
        </div>
      </div>
    </template> -->
  </BaseModal>
</template>

<script>
import moment from 'moment';
import { errorHandler } from '../../mixins/errorHandler';
import GdprConsentService from '../../services/GdprConsentSerevice';

export default {
  name: 'ConsentAdd',
  mixins: [errorHandler],
  props: {
    personId: {
      type: [String, Number],
      required: true,
    },
    allowEdit: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      filesToUpload: [],
      saving: false,
      errors: {},
      form: {
        set_date_h: '',
        due_date_h: '',
        till_dismiss: '0',
        order_service: '0',
        email_order_service: '0',
        marketing: '0',
        telecom: '0',
        profiling: '0',
      },
      fakePolicyEndDate: '0',
    };
  },
  mounted() {
    if (this.allowEdit) this.form.set_date_h = moment().format('YYYY-MM-DD');
    if (this.allowEdit) this.form.till_dismiss = '1';
  },
  methods: {
    fileChange(fileList) {
      this.filesToUpload = fileList;
    },
    save() {
      this.saving = true;
      const formData = new FormData();
      if (this.filesToUpload.length > 0) {
        formData.append('file', this.filesToUpload[0], this.filesToUpload[0].name);
      }
      formData.append('person_id', this.personId);
      Object.keys(this.form).forEach((key) => {
        formData.append(key, this.form[key]);
      });
      GdprConsentService.addConsent(formData)
        .then((response) => {
          this.$emit('added', response.data.consent);
          this.$store.dispatch('notifications/add', { type: 'success', message: 'Dodano oświadczenie zgód' });
          this.saving = true;
        })
        .catch((error) => {
          this.errors = this.resolveError(error);
          this.saving = false;
        });
    },
  },
  watch: {
    'form.till_dismiss': function(newVal) {
      if (Number(newVal) === 1) {
        this.form.due_date_h = '';
        this.fakePolicyEndDate = '0';
      }
    },
    fakePolicyEndDate(newVal) {
      if (Number(newVal) === 1) {
        this.form.due_date_h = '';
        this.form.till_dismiss = '0';
      }
    },
    'form.due_date_h': function(newVal) {
      if (newVal !== '') {
        this.fakePolicyEndDate = '0';
        this.form.till_dismiss = '0';
      } else {
        this.form.till_dismiss = '1';
      }
    },
  },
};
</script>
