<template>
  <div>
    <div id="filters" class="bg-white dark:bg-gray-900 p-3 mb-2 rounded-lg shadow-lg">
      <div id="order-basic-filters" class="flex flex-wrap mb-2 gap-2 items-center justify-between">
        <!-- Search Button with addons -->
        <div class="flex flex-none items-center order-1">
          <input
            id="filterInput"
            @keyup.enter="executeAllFilters"
            class="border-gray-300 dark:border-gray-600 rounded-r-none"
            :class="{'bg-yellow-100 dark:bg-yellow-800': activeFilters.search.length > 0}"
            v-model="activeFilters.search"
            type="text"
            placeholder="nazwa, pesel/regon/nip, nr.rej."
          >
          <button
            @click="resetSearch"
            class="tw--btn tw--btn-secondary rounded-none border-t border-b border-gray-300 dark:border-gray-600"
            type="button"
            :disabled="activeFilters.search === ''"
            title="wyczyść"
          ><FontAwesomeIcon icon="times"/></button>
          <button
            @click="executeAllFilters"
            class="tw--btn border border-blue-600 dark:border-blue-900 rounded-l-none"
            type="button"
            :disabled="activeFilters.search === ''"
          >
            Szukaj
          </button>
          <HelpContainer>
            <strong>Pole wyszukuje klientów po nastepujących parametrach:</strong>
            <ul>
              <li>nazwisko</li>
              <li>nazwa firmy</li>
              <li>pesel</li>
              <li>regon</li>
              <li>nip</li>
              <li>nr. rejestracyjny pojazdu (tylko w całości)</li>
            </ul>
          </HelpContainer>
        </div>
        <!-- End Search Button -->

        <div class="flex flex-shrink order-2 lg:order-3 gap-0.5">
          <button @click="resetFilters()" class="tw--btn tw--btn-filter shadow" title="wyczyść wszystkie filtry">
            <FontAwesomeIcon icon="times" size="xs" /> <FontAwesomeIcon icon="filter" />
          </button>
          <button @click="initList()" class="tw--btn tw--btn-filter shadow" title="odśwież dane z serwera">
            <FontAwesomeIcon icon="sync-alt" size="xs" /> <FontAwesomeIcon icon="database" />
          </button>
        </div>
      </div>

      <div class="flex gap-4 justify-end">
        <div class="flex rounded-sm items-center text-xs">
          <div class="px-2 py-1 rounded-l-sm bg-gray-800 text-gray-200 dark:bg-black dark:text-gray-400 font-semibold">Znaleziono</div>
          <div class="px-2 py-1 rounded-r-sm bg-blue-300 text-gray-700 dark:bg-blue-900 dark:text-gray-400 font-semibold">{{ pagiAllItems }} pozycji</div>
        </div>
      </div>

    </div>

    <div id="customer-list" class="bg-white dark:bg-gray-900 p-3 rounded-lg shadow-xl">
      <div class="flex flex-wrap justify-between">
        <SortList v-if="sortedBy.field !== ''" @sort="sortItemsBy" :fields="sortFields" :selected="sortedBy" />

        <Pagination
          :current="pagiCurrentPage"
          :total="pagiTotalPages"
          @page="goToPage"
          @next="goToPage(pagiCurrentPage+1)"
          @prev="goToPage(pagiCurrentPage-1)"
          v-model="rowsOnPage"
          @perPageChanged="executeAllFilters"
        />
      </div>
      <div class="flex flex-col">
        <CustomerListItem v-for="customer in customers" :customer="customer" :key="customer.id" />
      </div>
    </div>


  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { errorHandler } from '../mixins/errorHandler';
import CustomerService from '../services/CustomerService';
import CustomerListItem from '../components/CustomerListItem.vue';
import Pagination from '../components/Pagination.vue';
import SortList from '../components/SortList.vue';

export default {
  name: 'CustomerList',
  components: {
    CustomerListItem,
    Pagination,
    SortList,
  },
  mixins: [errorHandler],
  data() {
    return {
      customers: [],
      sortedBy: {
        field: '',
        dir: 1,
      },
      activeFilters: {
        search: '',
      },
      pagiCurrentPage: 1,
      rowsOnPage: 20,
      pagiAllItems: 0,
      sortFields: [
        { id: 'name', name: 'nazwisko / nazwa' },
        { id: 'address', name: 'miejscowość' },
        { id: 'pesel_regon', name: 'pesel / regon' },
        { id: 'phone', name: 'telefon' },
        { id: 'email', name: 'email'},
      ],
    };
  },
  computed: {
    ...mapGetters([
      'maxRowsOnPage',
      'currentUserType',
    ]),
    pagiTotalPages() {
      return Math.ceil(this.pagiAllItems / this.rowsOnPage);
    },
  },
  created() {
    this.initList();
  },
  methods: {
    initList() {
      if (this.$route.query.sf !== undefined && this.sortFields.find(obj => obj.id === this.$route.query.sf) !== undefined) {
        this.sortedBy.field = this.$route.query.sf;
        if (this.$route.query.sd !== undefined) {
          this.sortedBy.dir = Number(this.$route.query.sd) === -1 ? -1 : 1;
        } else {
          this.sortedBy.dir = 1;
        }
      } else {
        this.sortedBy.field = 'name';
        this.sortedBy.dir = 1;
      }
      if (this.$route.query.p !== undefined && Number.isInteger(Number(this.$route.query.p)) && Number(this.$route.query.p) > 0) {
        this.pagiCurrentPage = Number(this.$route.query.p);
      }
      if (this.$route.query.rop !== undefined && Number.isInteger(Number(this.$route.query.rop)) && Number(this.$route.query.rop) > 0 && Number(this.$route.query.rop) <= this.maxRowsOnPage) {
        this.rowsOnPage = Number(this.$route.query.rop);
      } else {
        this.rowsOnPage = Number(this.pagiRowsOnPage);
      }
      // Filtry
      Object.keys(this.activeFilters).forEach((key) => {
        if (this.$route.query[key] !== undefined && this.$route.query[key] !== '') {
          this.activeFilters[key] = decodeURI(this.$route.query[key]);
        }
      });
      this.getCustomers();
    },
    getCustomers() {
      this.$store.dispatch('setLoadingData', true);
      const params = {
        sf: this.sortedBy.field, sd: this.sortedBy.dir, p: this.pagiCurrentPage, rop: this.rowsOnPage,
      };
      Object.assign(params, this.activeFilters);
      CustomerService.getCustomers(params)
        .then((response) => {
          this.customers = response.data.customers;
          this.pagiAllItems = Number(response.data.count_all_results);
          this.pagiCurrentPage = Number(response.data.curr_page);
          this.rowsOnPage = Number(response.data.rows_on_page);
          this.setUrl();
          this.$store.dispatch('setLoadingData', false);
        })
        .catch((error) => {
          this.$store.dispatch('setLoadingData', false);
          this.resolveError(error); //mixin
        });
    },
    sortItemsBy(object) {
      this.sortedBy.field = object.field;
      this.sortedBy.dir = object.dir;
      this.getCustomers();
    },
    executeAllFilters() {
      this.pagiCurrentPage = 1;
      this.getCustomers();
    },
    resetSearch() {
      this.activeFilters.search = '';
      this.executeAllFilters();
    },
    resetFilters() {
      Object.keys(this.activeFilters).forEach((key) => {
        this.activeFilters[key] = '';
      });
      this.executeAllFilters();
    },
    goToPage(pageNumber) {
      if (Number.isInteger(Number(pageNumber)) && Number(pageNumber) !== this.pagiCurrentPage) {
        if (pageNumber >= 1 && pageNumber <= this.pagiTotalPages) {
          this.pagiCurrentPage = Number(pageNumber);
        } else if (pageNumber < 1) {
          this.pagiCurrentPage = 1;
        } else {
          this.pagiCurrentPage = Number(this.pagiTotalPages);
        }
        this.getCustomers();
      }
    },
    setUrl() {
      const query = {
        sf: this.sortedBy.field,
        sd: `${this.sortedBy.dir}`,
        p: `${this.pagiCurrentPage}`,
        rop: `${this.rowsOnPage}`,
        search: encodeURI(this.activeFilters.search),
      };
      const queryFilters = {};
      Object.keys(this.activeFilters).forEach((key) => {
        queryFilters[key] = encodeURI(this.activeFilters[key]);
      });
      Object.assign(query, queryFilters);
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router.replace({ name: 'Customers', query });
      }
    },
  },
};
</script>
