<template>
  <div class="p-5 absolute inset-0 flex items-start gap-3 text-lg backdrop-filter backdrop-blur-sm blur--support z-40">
    <FontAwesomeIcon class="text-gray-400 dark:text-gray-600" icon="spinner" size="lg" spin />
    <span class="italic font-thin text-muted">wczytuję</span>
  </div>
</template>

<script>
export default {
  name: 'DivLoadingSpinner',
};
</script>
