<template>
  <div>
    <!-- <div id="provisions-submenu" class="flex flex-wrap gap-x-2 gap-y-1 -mt-2 mb-3 justify-between sm:justify-start"> -->
    <SubmenuWrapper>
      <SubmenuButton v-if="showAdmin" destination="ProvisionSettlement">
        <template #header>Wczytaj prowizje</template>
        <template #description>pliki prowizyjne z TU</template>
      </SubmenuButton>
      <SubmenuButton destination="ListPaydProvisions">
        <template #header>Zestawienie prowizji</template>
        <template #description>prowizje zaksięgowane</template>
      </SubmenuButton>
      <SubmenuButton v-if="allowNullPayProvReport" destination="NullPaymentReport">
        <template #header>Prowizje niewypłacone</template>
        <template #description>(wersja beta raportu)</template>
      </SubmenuButton>
      <SubmenuButton v-if="showAdmin" destination="AdminInvoices">
        <template #header>Wystaw faktury</template>
      </SubmenuButton>
      <SubmenuButton destination="EmployeeInvoices">
        <template #header>Faktury</template>
        <template #description>lista faktur agenta</template>
      </SubmenuButton>
      <SubmenuButton destination="ProductListReadOnly">
        <template #header>Zał. prowizyjne</template>
        <template #description>aktualne stawki prowizji</template>
      </SubmenuButton>
      <SubmenuButton v-if="showAdmin" destination="ExtraPayments">
        <template #header>Inne rozliczenia</template>
        <template #description>dodatkowe potrącenia i dodatki</template>
      </SubmenuButton>
    </SubmenuWrapper>
    <!-- </div> -->
    <router-view />
  </div>
</template>

<script>
import SubmenuButton from '../components/SubmenuButton.vue';

export default {
  name: 'Provisions',
  components: {
    SubmenuButton,
  },
  computed: {
    currentUserType() {
      return this.$store.getters.currentUserType;
    },
    showAdmin() {
      return this.currentUserType === 'root' || this.currentUserType === 'admin';
    },
    allowNullPayProvReport() {
      return this.$store.getters.progOptions.allowNullPayProvReport;
    },
  },
  mounted() {
    if (this.$route.name === 'Provisions') {
      if (this.showAdmin) {
        this.$router.replace({ name: 'ProductListReadOnly'});
      } else {
        this.$router.replace({ name: 'ProductListReadOnly' });
      }
    }
  },
};
</script>
