<template>
  <BaseModal
    @closeModal="$emit('close')"
    :cancel="true"
    :ok="true"
    okLabel="wydrukuj (pdf)"
    cancelLabel="Zamknij"
    @ok="openPdf"
    size="sm"
  >
    <template #header>
      Drukowanie wypowiedzenia OC
    </template>
    <template #default>
      <div class="flex flex-col gap-y-3">
        <BaseInputWithAddon class="flex-shrink" labelTop="data wypowiedzenia" type="date" v-model="terminationDate" />
        <BaseSelectWithAddon class="flex-shrink" labelTop="tryb wypowiedzenia" v-model="terminationType" :options="terminationTypeOptions" :nullOption="false" />
      </div>
    </template>
  </BaseModal>
</template>

<script>
import moment from 'moment';

export default {
  name: 'PrintTermination',
  props: {
    orderId: {
      required: true,
    },
  },
  data() {
    return {
      terminationDate: '',
      terminationType: '1',
      apiUrl: process.env.VUE_APP_API_URL,
      terminationTypeOptions: [
        { id: '1', name: 'art. 28 ust. 1' },
        { id: '2', name: 'art. 31 ust. 1 (jako nabywca)' },
        { id: '3', name: 'art. 28a ust. 1 (w trybie klauzuli prolongacyjnej)' },
      ],
    };
  },
  computed: {
    pdfLink() {
      return `${this.apiUrl}/wypowiedzenie/${this.orderId}/${this.terminationType}/${this.terminationDate}`;
    },
  },
  mounted() {
    this.terminationDate = moment().format('YYYY-MM-DD');
  },
  methods: {
    openPdf() {
      const newWindow = window.open(null, this.orderId);
      newWindow.location.href = `${this.apiUrl}/wypowiedzenie/${this.orderId}/${this.terminationType}/${this.terminationDate}`;
    },
  },
};
</script>
