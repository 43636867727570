<template>
  <div class="mb-2">
    <div v-if="sortField !== ''"
      class="flex items-stretch border rounded-sm focus-within:ring-1
      focus-within:border-blue-300 border-gray-300
      dark:focus-within:border-blue-600 dark:border-gray-600"
      title="Pole i kierunek sortowania"
    >
      <label class="flex items-center gap-1 font-semibold text-sm px-1 py-1 bg-gray-100 dark:bg-transparent border-r border-gray-300 dark:border-gray-600">
        Sortuj
      </label>
      <select @change="$emit('sort', { field: sortField, dir: sortDir })" v-model="sortField" class="w-auto pl-1 border-0 focus:bg-blue-100 dark:focus:bg-blue-50 rounded-none">
        <option v-for="(field, index) in fields" :key="index" :value="field.id">{{ field.name }}</option>
      </select>
      <button @click="changeDir" class="px-1 focus:outline-none focus:bg-blue-100 dark:focus:bg-blue-50 border-l dark:border-gray-600">
        <FontAwesomeIcon :icon="sortDir === 1 ? 'sort-amount-down-alt' : 'sort-amount-down'" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SortList',
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: Object,
      default: () => {
        return  { field: '', dir: 1 };
      },
    },
  },
  data() {
    return {
      sortField: '',
      sortDir: '',
    };
  },
  created() {
    this.sortField = this.selected.field;
    this.sortDir = this.selected.dir;
  },
  methods: {
    changeDir() {
      this.sortDir = this.sortDir * -1;
      this.$emit('sort', { field: this.sortField, dir: this.sortDir });
    },
    changeField() {
      const currentField = this.fields.find(obj => obj.id === this.sortField);
      if (currentField.defaultDir !== undefined) {
        this.sortDir = currentField.defaultDir;
      } else {
        this.sortDir = 1;
      }
      this.$emit('sort', { field: this.sortField, dir: this.sortDir });
    },
  },
};
</script>
