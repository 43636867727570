<template>
  <BaseModal
    @closeModal="$emit('close')"
    :cancel="true"
    :ok="true"
    :busy="isBusy"
    okLabel="zapisz"
    @ok="save"
    size="md"
  >
    <template #header>
      Dodawanie notatki
    </template>
    <template #default>
      <div class="flex flex-col gap-y-3">
        <BaseInputWithAddon v-model="title" @enter="save" type="text" labelTop="tytuł" :error="errors.title !== undefined ? errors.title : ''" />
        <BaseTextareaWithAddon v-model="description" labelTop="treść" :error="errors.description !== undefined ? errors.description : ''" />
        <div>
          <label>
            <input type="file" :class="{'bg-red-500': fileErrors.length > 0}" multiple  @change="fileChange($event.target.files)">
            <div v-if="fileErrors.length > 0" class="px-3 text-red-500 font-normal text-sm">
              <div v-for="(errors, index) in fileErrors" :key="index">
                <p v-for="(fileErr, index) in errors" :key="index">{{ fileErr }}</p>
              </div>
            </div>
            <p v-if="errors.emptyFiles !== undefined" class="text-red-500 text-sm">{{ errors.emptyFiles }}</p>
          </label>
        </div>
      </div>
    </template>
    <template #busy>
      <!-- Spinner Loading -->
      <div v-if="isBusy" class="absolute top-0 left-0 w-full h-full">
        <div class="w-full h-full bg-white dark:bg-black opacity-80"></div>
        <div class="absolute top-0 left-0 flex w-full h-full items-center justify-center">
          <FontAwesomeIcon class="mr-3" icon="spinner" size="2x" spin />
          <div class="font-bold">
            <p>Zapisuję dane...</p>
            <p>Nie zamykaj okna przeglądarki aż do zakończenia procesu...</p>
            <div v-if="filesToUpload.length > 0" class="relative h-1 w-full bg-yellow-500">
              <div class="absolute top-0 left-0 h-1 bg-blue-500" :class="uploadProgress"></div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import NoteService from '../../services/NoteService';
import { errorHandler } from '../../mixins/errorHandler';

export default {
  name: 'NoteAdd',
  mixins: [errorHandler],
  props: {
    parentId: {
      type: [String, Number],
      required: true,
    },
    parent: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      filesToUpload: [],
      title: '',
      description: '',
      notesLoadTable: [],
      errors: {},
      fileErrors: [],
      isBusy: false,
    };
  },
  computed: {
    uploadProgress() {
      let progressClass = 'w-0';
      let percent = 1;
      if (this.filesToUpload.length > 0) {
        percent = this.notesLoadTable.length / this.filesToUpload.length;
      }
      if (percent === 0) {
        progressClass = 'w-full';
      } else if (percent <= 0.2) {
        progressClass = 'w-4/5';
      } else if (percent <= 0.4) {
        progressClass = 'w-3/5';
      } else if (percent <= 0.6) {
        progressClass = 'w-2/5';
      } else if (percent <= 0.8) {
        progressClass = 'w-1/5';
      }
      return progressClass;
    },
  },
  methods: {
    fileChange(fileList) {
      this.fileErrors = [];
      delete this.errors.emptyFiles;
      this.filesToUpload = fileList;
    },
    save() {
      this.isBusy = true;
      if (this.filesToUpload.length > 0 && this.filesToUpload.length <= 50) {
        const form = [];
        for (let index = 0; index < this.filesToUpload.length; index += 1) {
          form.push(new FormData());
          form[index].append('element_id', this.parentId);
          form[index].append('element_related_to', this.parent);
          form[index].append('category_id', 1);
          form[index].append('title', this.title);
          form[index].append('description', this.description);
          form[index].append('file', this.filesToUpload[index], this.filesToUpload[index].name);
          this.notesLoadTable.push(index);
          NoteService.addFileNote(form[index])
            .then(response => {
              this.notesLoadTable.shift();
              this.$emit('added', response.data.newNote);
              if (this.notesLoadTable.length === 0) {
                this.title = '';
                this.description = '';
                this.filesToUpload = [];
                this.isBusy = false;
                this.$emit('close');
                if (this.fileErrors.length > 0) {
                  this.$store.dispatch('notifications/add', { type: 'warning', message: 'Notatki/załączniki zostały dodane, jednak wystapiły pojedyncze błędy - sprawdź komunikaty' });
                } else {
                  this.$store.dispatch('notifications/add', { type: 'success', message: 'Notatki/załączniki zostały dodane' });
                }
              }
            })
            .catch(error => {
              const errors = this.resolveError(error);
              Object.keys(errors).forEach(key => {
                errors[key] = `${this.filesToUpload[index].name}: ${errors[key]}`;
              });
              this.fileErrors.push(errors);
              this.notesLoadTable.shift();
              if (this.notesLoadTable.length === 0) this.isBusy = false;
            });
        }
      } else if (this.filesToUpload.length === 0) {
        const form = new FormData();
        form.append('element_id', this.parentId);
        form.append('element_related_to', this.parent);
        form.append('category_id', 1);
        form.append('title', this.title);
        form.append('description', this.description);
        NoteService.addTextNote(form)
          .then(response => {
            this.title = '';
            this.description = '';
            this.$emit('added', response.data.newNote);
            this.$emit('close');
            this.isBusy = false;
          })
          .catch(error => {
            this.errors = this.resolveError(error);
            this.isBusy = false;
          });
      } else {
        this.fileErrors.push({ length: 'Możesz za jednym razem wczytać maksymalnie 50 plików.' });
        this.isBusy = false;
      }
    },
  },
};
</script>
