<template>
  <Transition name="fade">
    <div
      v-show="show"
      class="relative px-10 py-3 font-semibold sm:rounded sm:mb-2 opacity-75 shadow-2xl bg-gradient-to-r"
      :class="{'from-red-400 via-red-500 to-red-200': variant === 'danger', 'from-green-400 via-green-500 to-green-200': variant === 'success', 'from-yellow-400 via-yellow-500 to-yellow-200': variant === 'warning'}"
    >
      <FontAwesomeIcon @click="close()" class="absolute right-0 mx-3 my-1 text-gray-400 cursor-pointer" icon="times" />
      {{ notification.message }}
    </div>
  </Transition>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      timeout: null,
      tFade: null,
      show: false,
    };
  },
  mounted() {
    let time = 5000;
    this.show = true;
    if (this.notification.time !== 0) {
      if (this.notification.time !== undefined) {
        time = this.notification.time * 1000;
      }
      this.timeout = setTimeout(() => this.close(), time);
    }
  },
  computed: {
    variant() {
      return this.notification.type === 'error' ? 'danger' : this.notification.type;
    },
  },
  methods: {
    ...mapActions('notifications', ['remove']),
    close() {
      this.show = false;
      setTimeout(() => this.remove(this.notification), 800);
    },
  },
};
</script>

<style scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.8s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
</style>
