<template>
  <div class="flex flex-col gap-2 bg-white dark:bg-gray-900 p-3 mb-2 rounded-lg shadow-lg">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'BaseCard',
};
</script>
