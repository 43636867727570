<template>
  <div class="border dark:border-gray-600 border-gray-400 px-2 py-1 rounded shadow">
    <div class="flex justify-between items-center -mx-2 pb-1 px-2 border-b dark:border-gray-600 border-gray-400">
      <span class="uppercase text-muted text-xs font-semibold">Raty</span>
      <FontAwesomeIcon v-show="!checkItemsSum" icon="exclamation-triangle" class="text-red-600 dark:text-red-800" />
      <div class="flex items-center gap-2">
        <button v-if="addItemsInstallmentsAllow"
          @click="calculateModal = true"
          class="tw--btn-icon p-0"
          :title="hasInkaso ? 'Kalkulator niedostepny przy formie \'Gotówka\'' : 'kalkulator rat'"
          :disabled="hasInkaso || items.length === 0">
          <FontAwesomeIcon :icon="['fas', 'calculator']" size="lg" />
        </button>
        <button v-if="addItemsInstallmentsAllow" @click="addEditModal = true" class="tw--btn-icon p-0" title="dodaj pozycję" :disabled="items.length === 0">
          <FontAwesomeIcon :icon="['far', 'plus-square']" size="lg" />
        </button>
      </div>
    </div>

    <OrderInstallmentsAddEdit
      v-if="addEditModal && addItemsInstallmentsAllow"
      @cancel="cancelAddEdit"
      @changed="installmentsChanged"
      :order="order"
      :installmentsSum="installmentsSum"
      :installment="installmentToEdit"
      :itemsSum="itemsSum"
      :action="action"
    />

    <OrderInstallmentsCalculate
      v-if="calculateModal && addItemsInstallmentsAllow"
      @cancel="calculateModal = false"
      @changed="installmentsChanged"
      :order="order"
      :items="items"
      :installments="installments"
    />

    <div v-show="!checkItemsSum" class="w-full font-semibold text-sm text-red-600 dark:text-red-700 border-b border-dashed dark:border-gray-600 border-gray-400">suma rat różni się od sumy składek!</div>
    <div v-if="installments.length === 0" class="w-full text-base italic text-muted">brak informacji o ratach</div>
    <div v-else class="flex flex-col gap-1">
      <div id="installments-header"
        class="items-center gap-2 text-muted border-b-2 dark:border-gray-600 border-gray-400"
        :class="hasInkaso ? 'hidden 2xl:flex' : 'hidden sm:flex lg:hidden xl:flex'"
      >
        <div class="w-8">rata</div>
        <div class="flex-grow">data należności</div>
        <div class="w-2/12 text-right">kwota</div>
        <div class="w-2/12  flex-shrink-0 text-center">forma</div>
        <div v-if="hasInkaso" class="w-40 flex-shrink-0">data pobrania</div>
        <div v-if="hasInkaso" class="w-10 flex-shrink-0 flex gap-0.5 cursor-help justify-center" title="gotówka rozliczona">
          <FontAwesomeIcon :icon="['fas', 'coins']" /><FontAwesomeIcon :icon="['far', 'check-square']" />
        </div>
        <div class="w-10 flex-shrink-0 flex cursor-help justify-center" title="klient powiadomiony">
          <FontAwesomeIcon :icon="['fas', 'phone-volume']" /><FontAwesomeIcon :icon="['fas', 'user-clock']" />
        </div>
        <div v-if="addItemsInstallmentsAllow" class="w-10 flex-shrink-0"></div>
      </div>

      <div id="installment-list" class="divide-y divide-dashed dark:divide-gray-600">
        <div v-for="(rata, index) in installments" :key="rata.id"
          class="flex items-center py-1 text-sm text-bright"
          :class="{
            'flex-wrap gap-0 2xl:flex-nowrap 2xl:gap-2': hasInkaso,
            'flex-wrap gap-0 sm:flex-nowrap sm:gap-2 lg:flex-wrap lg:gap-0 xl:flex-nowrap xl:gap-2': !hasInkaso,
            'font-normal': installments.length > 1,
          }"
        >
          <div class="flex-shrink-0 flex gap-2" :class="hasInkaso ? 'w-1/6 2xl:w-8' : 'w-1/6 sm:w-8 lg:w-1/6 xl:w-8'">
            <div class="text-muted font-normal" :class="hasInkaso ? 'block 2xl:hidden' : 'block sm:hidden lg:block xl:hidden'">rata</div>
            <div class="text-right">{{ index + 1 }}</div>
          </div>
          <div
            class="flex gap-2 w-5/6 flex-grow-0 justify-end"
            :class="hasInkaso ? '2xl:flex-grow 2xl:w-auto 2xl:justify-start' : 'sm:w-auto sm:flex-grow sm:justify-start lg:w-5/6 lg:flex-grow-0 lg:justify-end xl:flex-grow xl:w-auto xl:justify-start'"
          >
            <div class="text-muted font-normal" :class="hasInkaso ? 'block 2xl:hidden' : 'block sm:hidden lg:block xl:hidden'">data należności</div>
            <div class="font-mono">{{  rata.due_date_h }}</div>
          </div>
          <div
            class="flex-shrink-0 flex gap-2"
            :class="hasInkaso ? 'w-1/2 2xl:w-2/12 justify-start 2xl:justify-end' : 'w-1/2 sm:w-2/12 lg:w-1/2 xl:w-2/12 justify-start sm:justify-end lg:justify-start xl:justify-end'"
          >
            <div class="text-muted font-normal" :class="hasInkaso ? 'block 2xl:hidden' : 'block sm:hidden lg:block xl:hidden'">kwota</div>
            <div class="text-right font-mono">{{ rata.value | moneyPL }}</div>
          </div>
          <div class="w-1/2 flex-shrink-0 flex gap-2 justify-end" :class="hasInkaso ? '2xl:w-2/12 2xl:justify-center' : 'sm:w-2/12 sm:justify-center lg:w-1/2 lg:justify-end xl:w-2/12 xl:justify-center'">
            <div class="text-muted font-normal" :class="hasInkaso ? 'block 2xl:hidden' : 'block sm:hidden lg:block xl:hidden'">forma</div>
            <div v-if="rata.inkaso === '1' || rata.inkaso === 1">Gotówka</div>
            <div v-else>Przelew</div>
          </div>
          <div v-if="hasInkaso" class="flex-shrink-0 w-2/3 2xl:w-40">
            <div v-show="rata.inkaso === '1' || rata.inkaso === 1" class="flex gap-2 items-center">
              <div class="text-muted font-normal" :class="hasInkaso ? 'block 2xl:hidden' : 'block sm:hidden lg:block xl:hidden'">data pobrania</div>
              <div class="text-xs">{{ rata.inkaso_date_h }}</div>
            </div>
          </div>
          <div v-if="hasInkaso" class="flex-shrink-0 w-1/3 2xl:w-10">
            <div v-show="rata.inkaso === '1' || rata.inkaso === 1" class="flex gap-2 justify-end 2xl:justify-center">
              <div class="text-muted font-normal" :class="hasInkaso ? 'block 2xl:hidden' : 'block sm:hidden lg:block xl:hidden'">rozliczona</div>
              <div v-if="rata.settled === '1' || rata.settled === 1" class="text-green-600 dark:text-green-700 text-center">tak</div>
              <div v-else-if="rata.settled === '0' || rata.settled === 0" class="text-red-600 dark:text-red-700 text-center">nie</div>
            </div>
          </div>
          <div class="flex-shrink-0 flex gap-2 justify-start" :class="hasInkaso ? 'w-full 2xl:w-10 2xl:justify-center' : 'w-full sm:w-10 sm:justify-center lg:w-full lg:justify-start xl:w-10 xl:justify-center'">
            <div class="text-muted font-normal" :class="hasInkaso ? 'block 2xl:hidden' : 'block sm:hidden lg:block xl:hidden'">klient powiadomiony</div>
            <div v-if="rata.reminded === '1' || rata.reminded === 1" class="text-green-600 dark:text-green-700">tak</div>
            <div v-else>nie</div>
          </div>
          <div v-if="addItemsInstallmentsAllow"
            class="flex items-center gap-2"
            :class="hasInkaso ? 'w-full justify-end order-first 2xl:w-10 2xl:justify-between 2xl:order-last' : 'w-full justify-end order-first sm:w-10 sm:justify-between sm:order-last lg:w-full lg:justify-end lg:order-first xl:w-10 xl:justify-between xl:order-last'"
          >
            <button @click="edit(rata)" class="tw--btn-icon text-gray-400 dark:text-gray-500 p-0" title="edytuj"><FontAwesomeIcon :icon="['far', 'edit']" /></button>
            <button @click="del(rata.id)" class="tw--btn-icon tw--btn-icon-danger text-gray-400 dark:text-gray-500 p-0" title="usuń"><FontAwesomeIcon :icon="['far', 'trash-alt']" /></button>
          </div>
        </div>
      </div>

      <div id="installment-list-summary" v-if="installments.length > 1"
        class="flex items-center gap-2 text-sm text-bright border-t-4 border-double dark:border-gray-600 border-gray-400"
      >
        <div class="flex-grow pr-2 text-left" :class="hasInkaso ? '2xl:text-right' : 'sm:text-right lg:text-left xl:text-right'">suma</div>
        <div class="flex-shrink-0 font-mono w-1/2 text-right" :class="hasInkaso ? '2xl:w-2/12' : 'sm:w-2/12 lg:w-1/2 xl:w-2/12'">
          {{ installmentsSum | moneyPL }}
        </div>
        <div class="hidden flex-shrink-0" :class="hasInkaso ? '2xl:block 2xl:w-2/12' : 'sm:block sm:w-2/12 lg:hidden xl:block xl:w-2/12'">&nbsp;</div>
        <div v-show="hasInkaso" class="hidden flex-shrink-0 2xl:block 2xl:w-40">&nbsp;</div>
        <div v-show="hasInkaso" class="hidden flex-shrink-0 2xl:block 2xl:w-10">&nbsp;</div>
        <div class="hidden flex-shrink-0" :class="hasInkaso ? '2xl:block 2xl:w-10' : 'sm:block sm:w-10 lg:hidden xl:block xl:w-10'">&nbsp;</div>
        <div class="hidden flex-shrink-0" :class="hasInkaso ? '2xl:block 2xl:w-10' : 'sm:block sm:w-10 lg:hidden xl:block xl:w-10'">&nbsp;</div>
      </div>

    </div>
  </div>
</template>

<script>
import OrderInstallmentsAddEdit from './OrderInstallmentsAddEdit.vue';
import OrderInstallmentsCalculate from './OrderInstallmentsCalculate.vue';
import { confirm } from '../../mixins/confirm';
import { errorHandler } from '../../mixins/errorHandler';
import OrderService from '../../services/OrderService';

export default {
  name: 'OrderFinancialDataInstallments',
  components: {
    OrderInstallmentsAddEdit,
    OrderInstallmentsCalculate,
  },
  mixins: [confirm, errorHandler],
  props: {
    installments: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    order: {
      type: Object,
      required: true,
    },
    addItemsInstallmentsAllow: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      calculateModal: false,
      addEditModal: false,
      installmentToEdit: {},
      action: 'add',
    };
  },
  computed: {
    currentUserType() {
      return this.$store.getters.currentUserType;
    },
    installmentsSum() {
      return this.installments.reduce((prev, curr) => ({ value: Number(prev.value) + Number(curr.value) }), { value: 0 }).value;
    },
    itemsSum() {
      return this.items.reduce((a, b) => ({ value: Number(a.value) + Number(b.value) }), { value: 0 }).value;
    },
    checkItemsSum() {
      return this.itemsSum.toFixed(2) === this.installmentsSum.toFixed(2);
    },
    hasInkaso() {
      if (this.installments.filter(obj => obj.inkaso === '1').length > 0) {
        return true;
      }
      return false;
    },
  },
  methods: {
    edit(obj) {
      Object.assign(this.installmentToEdit, obj);
      this.action = 'edit';
      this.addEditModal = true;
    },
    cancelAddEdit() {
      this.installmentToEdit = {};
      this.action = 'add';
      this.addEditModal = false;
    },
    installmentsChanged(installments) {
      this.$emit('installmentsChanged', installments);
      this.cancelAddEdit();
      this.calculateModal = false;
    },
    del(id) {
      this.confirm(() => this.confirmedDel(id), 'Potwierdź usunięcie raty');
    },
    confirmedDel(id) {
      OrderService.delOrderInstallment(id)
        .then(() => {
          this.$store.dispatch('notifications/add', { type: 'success', message: 'Usunięto pozycję' });
          this.$emit('installmentDeleted', id);
        })
        .catch((error) => this.resolveError(error)); // mixin
    },
  },
};
</script>
