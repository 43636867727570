import axios from 'axios';

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  // withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json',
  },
});

export default {
  getEmployeeFull(id) {
    const result = new Promise((resolve, reject) => {
      apiClient.get(`/api/employees/${id}`)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  addEmployee(fields) {
    const result = new Promise((resolve, reject) => {
      apiClient.post('/api/employees', fields)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  updateEmployee(id, fields) {
    const result = new Promise((resolve, reject) => {
      apiClient.patch(`/api/employees/${id}`, fields)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  updateEmployeeCompany(employeeId, fields) {
    const result = new Promise((resolve, reject) => {
      apiClient.patch(`/api/employees/${employeeId}/company`, fields)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  delEmployeeCompany(employeeId) {
    const result = new Promise((resolve, reject) => {
      apiClient.delete(`/api/employees/${employeeId}/company`)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  getEmployees() {
    const result = new Promise((resolve, reject) => {
      apiClient.get('/api/get-employee-list')
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  getEmployeeSimpleList() {
    const result = new Promise((resolve, reject) => {
      apiClient.get('/api/get-employee-simple-list')
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
  return result;
  },
  getEmployeeTypeList() {
    const result = new Promise((resolve, reject) => {
      apiClient.get('/api/get-empl-type-list')
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
  });
    return result;
  },
  getEmployeeFormList() {
    const result = new Promise((resolve, reject) => {
      apiClient.get('/api/get-empl-form-list')
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  getManagerList() {
    const result = new Promise((resolve, reject) => {
      apiClient.get('/api/get-employee-managers')
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  getEmployeeProvList() {
    const result = new Promise((resolve, reject) => {
      apiClient.get('/api/get-employee-prov-lvl-list')
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  getEmployeeInsurers(id) {
    const result = new Promise((resolve, reject) => {
      apiClient.get(`/api/employee/${id}/insurers`)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  updateEmployeeInsurers(employeeId, insurerIds) {
    const result = new Promise((resolve, reject) => {
      apiClient.put(`/api/employee/${employeeId}/insurers`, { ids: insurerIds })
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  getEmployeeListExtended() {
    const result = new Promise((resolve, reject) => {
      apiClient.get('/api/get-employee-list-extended')
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  getEmployeeReport(id) {
    const result = new Promise((resolve, reject) => {
      apiClient.get(`/api/dashboard/employee/${id}`)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
};
