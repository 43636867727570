<template>
  <div class="flex gap-3 flex-col xl:flex-row">
    <div v-for="category in links" :key="category.category_id"
      class="flex-1 flex flex-col gap-2 bg-white dark:bg-gray-900 p-3 mb-2 rounded-lg shadow-lg"
    >
      <h5 class="text-bright">{{ category.category_name }}</h5>
      <div class="flex flex-wrap gap-x-3 gap-y-2">
        <a v-for="link in category.links" :key="link.id" :href="link.link" class="tw--btn" target="_blank">{{ link.name }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { errorHandler } from '../mixins/errorHandler';
import ExtrasService from '../services/ExtrasService';

export default {
  name: 'Extras',
  mixins: [errorHandler],
  data() {
    return {
      links: [],
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      ExtrasService.getLinks()
        .then((response) => {
          this.links = response.data.links;
        })
        .catch((error) => this.resolveError(error));
    },
  },
};
</script>
