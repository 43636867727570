<template>
  <div id="new-order-details" class="p-3 bg-white dark:bg-gray-900 rounded-lg shadow-lg">
    <div class="flex -mt-3 mb-3 -mx-3 px-3 py-1 border-b dark:border-gray-600">
      <div class="flex-grow uppercase font-semibold text-muted">Oferta</div>
      <span v-if="validating === 1" class="text-xs text-muted">
        ok&nbsp;<span class="font-bold text-base text-green-400 dark:text-green-600">&check;</span>
      </span>
      <span v-else-if="validating === -1" class="mt-1 text-xs text-muted">
        uzupełnij<FontAwesomeIcon class="ml-2 text-yellow-400 dark:text-yellow-600" icon="exclamation-triangle" size="lg" />
      </span>
      <span v-else class="mt-1 text-xs text-muted">weryfikuję<FontAwesomeIcon class="ml-2" icon="sync-alt" size="lg" spin /></span>
    </div>
    <div class="flex flex-col gap-2">
      <div id="made-by-status" class="flex flex-wrap gap-4">
        <div class="flex-grow flex flex-wrap gap-4">
          <BaseSelectWithAddon
            class="flex-grow"
            v-model="newOrder.made_by_office"
            labelTop="oferta"
            :options="madeByOfficeOptions"
            :nullOption="false"
            :error="saveErrors.made_by_office !== undefined ? saveErrors.made_by_office : ''"
          />
          <div v-if="leadFunctionalityAllowed" class="flex flex-col">
            <div class="font-semibold text-sm text-transparent">LEAD</div>
            <div class="flex">
              <label class="flex-grow" :class="{'opacity-50': newOrder.made_by_office !== '1'}" for="lead">
                <input
                  id="lead"
                  type="checkbox"
                  true-value="1" false-value="0"
                  v-model="newOrder.lead"
                  :disabled="newOrder.made_by_office !== '1'"
                > lead
              </label>
            </div>
          </div>
        </div>
        <BaseSelectWithAddon
          class="w-full md:w-1/2 lg:w-full xl:w-1/2"
          v-model="newOrder.status_id"
          labelTop="status"
          :options="orderStatusList"
          :nullOption="false"
          :error="saveErrors.status_id !== undefined ? saveErrors.status_id : ''"
        />
      </div>
      <div id="type-leasing-priority" class="flex flex-wrap gap-4">
        <BaseSelectWithAddon
          class="flex-grow"
          v-model="newOrder.type_id"
          labelTop="rodzaj ubezpieczenia"
          :options="orderTypeList"
          :error="saveErrors.type_id !== undefined ? saveErrors.type_id : ''"
          :warning="saveEmptyErrors.type_id !== undefined ? saveEmptyErrors.type_id : ''"
        />
        <div class="flex flex-col w-full md:w-1/2 lg:w-full xl:w-1/2">
          <div class="font-semibold text-sm">opcje dodatkowe</div>
          <div class="flex">
            <label class="flex-grow" :class="{'opacity-50': newOrder.type_id !== '1'}" for="leasing">
              <input
                id="leasing"
                type="checkbox"
                true-value="1" false-value="0"
                v-model="newOrder.is_leasing"
                :disabled="newOrder.type_id !== '1'"
              > leasing
            </label>
            <label class="flex-grow" for="priority">
              <input
                id="priority"
                type="checkbox"
                true-value="1" false-value="0"
                v-model="newOrder.priority"
              > pilne!
            </label>
          </div>
        </div>
      </div>
      <div id="type-leasing-priority" class="flex flex-wrap gap-4">
        <BaseInputWithAddon
          class="flex-grow"
          type="date"
          v-model="newOrder.date_from"
          labelTop="start ochrony"
          :error="saveErrors.date_from !== undefined ? saveErrors.date_from : ''"
          :warning="saveEmptyErrors.date_from !== undefined ? saveEmptyErrors.date_from : ''"
        />
        <div class="w-full md:w-1/2 lg:w-full xl:w-1/2 flex flex-col gap-1">
          <BaseInputWithAddon
            type="date"
            v-model="newOrder.date_to"
            labelTop="koniec ochrony"
            :error="saveErrors.date_to !== undefined ? saveErrors.date_to : ''"
            :warning="saveEmptyErrors.date_to !== undefined ? saveEmptyErrors.date_to : ''"
          />
          <div>
            <span class="text-xs">Zostaw puste jeśli umowa na 12 m-cy (majątkowa) lub na czas nieokreślony (życie, grupowe)</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrderService from '../../services/OrderService';
import debounce from 'lodash/debounce';
// import { debounce } from 'lodash';
import { errorHandler } from '../../mixins/errorHandler';
import { mapGetters } from 'vuex';

export default {
  name: 'OrderAddSetOrder',
  mixins: [errorHandler],
  data() {
    return {
      newOrder: {
        status_id: '1',
        made_by_office: '0',
        lead: '0',
        type_id: '',
        date_from: '',
        date_to: '',
        is_leasing: '0',
        priority: '0',
      },
      madeByOfficeOptions: [
        { id: '0', name: 'samodzielna'},
        { id: '1', name: 'biuro'},
      ],
      validating: -1, // -1 errors, 0 validating, 1 no errors
      saveErrors: {},
      saveEmptyErrors: {},
    };
  },
  computed: {
    ...mapGetters('orders', [
      'orderTypeList',
      'orderStatusList',
    ]),
    leadFunctionalityAllowed() {
      return this.$store.getters.progOptions?.leadFunctionality;
    },
  },
  mounted() {
    if (this.orderTypeList.length === 0) this.$store.dispatch('orders/getOrderTypeList').catch(error => this.resolveError(error));
    if (this.orderStatusList.length === 0) this.$store.dispatch('orders/getOrderStatusList').catch(error => this.resolveError(error));
    this.validateOrderDetails();
  },
  methods: {
    newOrderChanged() {
      if (this.newOrder.type_id !== '1' && this.newOrder.is_leasing === '1') {
        this.newOrder.is_leasing = '0';
      }
      if (this.newOrder.made_by_office !== '1' && this.newOrder.lead === '1') {
        this.newOrder.lead = '0';
      }
      this.$emit('setOrder', this.newOrder);
      this.validating = 0;
      this.validateOrderDetails();
    },
    validateOrderDetails: debounce(function() {
      OrderService.validateNewOrderDetails(this.newOrder)
       .then(response => {
         if (response.data.state === 'ok') {
           this.validating = 1;
           this.saveErrors = {};
           this.saveEmptyErrors = {};
         } else {
           this.validating = -1;
           this.saveErrors = response.data.error_warnings;
           this.saveEmptyErrors = response.data.empty_warnings;
         }
       })
       .catch(error => this.resolveError(error)); //mixin
    }, 1000),
  },
  watch: {
    newOrder: {
      handler: 'newOrderChanged',
      deep: true,
    },
    validating(newVal) {
      this.$emit('validated', newVal);
    },
  },
};
</script>
