<template>
  <div>
    <div class="bg-white dark:bg-gray-900 p-5 rounded-lg shadow-lg">
      <nav class="flex items-center flex-wrap space-x-4">
        <button
          @click="goToday"
          class="tw--link px-1 -mx-1 text-xl font-normal focus:outline-none focus:ring-1 focus:ring-blue-100 dark:ring-gray-700"
          :disabled="isCurrentMonth"
        >Przejdź do dzisiaj</button>
        <div class="flex items-center">
          <button @click="prevMonth(today.year, today.month)" class="tw--link text-2xl px-2 py-1 focus:outline-none focus:ring-1 focus:ring-blue-100 dark:ring-gray-700"><FontAwesomeIcon icon="chevron-left" /></button>
          <h1 class="font-semibold text-2xl mx-1">{{ today.tableHeader }}</h1>
          <button @click="nextMonth(today.year, today.month)" class="tw--link text-2xl px-2 py-1 focus:outline-none focus:ring-1 focus:ring-blue-100 dark:ring-gray-700"><FontAwesomeIcon icon="chevron-right" /></button>
        </div>
        <div v-if="allowAllEvents">
          <button
            @click="showAllFilter ? toggleShowAll() : null"
            class="font-semibold px-1 leading-5 text-lg focus:outline-none focus:ring-1 focus:ring-yellow-200 dark:ring-gray-700"
            :class="{'border-b-2 border-yellow-500 dark:border-yellow-700': !showAllFilter}"
          >własne
          </button>
          <button
            @click="!showAllFilter ? toggleShowAll() : null"
            class="font-semibold px-1 leading-5 text-lg focus:outline-none focus:ring-1 focus:ring-yellow-200 dark:ring-gray-700"
            :class="{'border-b-2 border-yellow-500 dark:border-yellow-700': showAllFilter}"
          >wszystkie
          </button>
        </div>
        <div v-if="allowAllEvents && showAllFilter">
          <select v-model="agentFilter" @change="changeEmployee" class="text-sm py-1 px-2 my-0 scrollbar">
            <option value="">wszyscy</option>
            <option v-for="employee in employeeList" :key="employee.id" :value="employee.id">{{ employee.fullname }}</option>
          </select>
        </div>
        <div class="space-x-2">
          <button
            @click="installmentsFilter = !installmentsFilter"
            class="font-semibold px-1 leading-5 text-lg focus:outline-none focus:ring-1 focus:ring-yellow-200 dark:ring-gray-700"
            :class="{'border-b-2 border-green-400 dark:border-green-600': installmentsFilter}"
          >płatności
          </button>
          <button
            @click="endofordersFilter = !endofordersFilter"
            class="font-semibold px-1 leading-5 text-lg focus:outline-none focus:ring-1 focus:ring-yellow-200 dark:ring-gray-700"
            :class="{'border-b-2 border-yellow-400 dark:border-yellow-600': endofordersFilter}"
          >koniec ochrony
          </button>
          <button
            @click="birthdaysFilter = !birthdaysFilter"
            class="font-semibold px-1 leading-5 text-lg focus:outline-none focus:ring-1 focus:ring-yellow-200 dark:ring-gray-700"
            :class="{'border-b-2 border-purple-400 dark:border-purple-600': birthdaysFilter}"
          >urodziny
          </button>
        </div>
      </nav>
      <div class="pt-2">
        <div v-if="cells.displayEventsCount === 0" class="lg:hidden h-24 my-2 flex justify-center items-center border-t dark:border-gray-700">
          <div class="font-bold">Brak wybranych zdarzeń w wyświetlanym miesiącu</div>
        </div>
        <div class="hidden lg:grid lg:grid-cols-7">
          <div
            class="font-semibold border-b border-gray-200 dark:border-gray-700 pl-2"
            :class="{ 'bg-gray-100 dark:bg-gray-800': index === 5 || index === 6 }"
            v-for="(dName, index) in days"
            :key="`dn--${index}`"
          >{{ dName }}</div>
        </div>
        <div class="grid grid-cols-1 lg:grid-cols-7 lg:divide-x divide-y dark:divide-gray-700">
          <div
            class="lg:flex flex-col lg:h-32 lg:max-h-32 px-2 pt-1 pb-2 bg-white dark:bg-gray-800"
            :class="[index === 0 ? `lg:col-start-${firstMonthDay}` : '',
                cell.events.length === 0 ? 'hidden' : '',
                todayYMD === cell.date ? '' : (firstMonthDay+cell.day)%7 === 0 || (firstMonthDay+cell.day-1)%7 === 0 ? 'bg-gray-100 opacity-70' : ''
              ]"
            v-for="(cell, index) in cells.cells"
            :key="`cell-${index}`"
          >
            <div>
              <h5 class="inline-block font-semibold" :class="{'px-2 bg-yellow-400 text-gray-700 rounded-md': todayYMD === cell.date}">{{ cell.day }}</h5>
              <span class="lg:hidden ml-3">{{ daysFull[(firstMonthDay+cell.day-2)%7] }}</span>
            </div>
            <div class="flex flex-col w-full overflow-x-hidden overflow-y-auto scrollbar">
              <span v-for="(event, index) in cell.events" :key="`ev-${cell.day}-${index}`" class="leading-3">
                <router-link
                  v-if="event.type === 'installments'"
                  class="text-sm hover:underline text-green-700 hover:text-green-500 dark:text-green-700 dark:hover:text-green-500"
                  :to="{ name: 'Order', params: { id: event.order_id } }"
                  :title="`Płatność: ${event.value}zł - ${event.customer_name} ${event.type_name}`"
                >
                  <span class="whitespace-nowrap"><FontAwesomeIcon icon="money-bill" />&nbsp;{{ event.customer_name }} {{ event.type_name }}</span>
                </router-link>
                <router-link
                  v-if="event.type === 'endoforders'"
                  class="text-sm hover:underline text-yellow-600 hover:text-yellow-400 dark:text-yellow-700 dark:hover:text-yellow-500"
                  :to="{ name: 'Order', params: { id: event.order_id } }"
                  :title="`Koniec polisy: ${event.customer_name}, ${event.type_name}`"
                >
                  <span class="whitespace-nowrap"><FontAwesomeIcon icon="step-forward" />&nbsp;{{ event.customer_name }}: {{ event.type_name }}</span>
                </router-link>
                <router-link
                  v-if="event.type === 'birthdays'"
                  class="text-sm hover:underline text-purple-700 hover:text-purple-500 dark:text-purple-700 dark:hover:text-purple-500"
                  :to="{ name: 'Customer', params: { id: event.customer_id } }"
                  :title="`Urodziny: ${event.customer_name}`"
                >
                  <span class="whitespace-nowrap"><FontAwesomeIcon icon="birthday-cake" />&nbsp;{{ event.customer_name }}</span>
                </router-link>
                <router-link
                  v-if="event.type === 'empl_birthdays'"
                  class="text-sm hover:underline text-indigo-700 hover:text-indigo-500 dark:text-indigo-700 dark:hover:text-indigo-500"
                  :to="{ name: 'Employee', params: { id: event.employee_id } }"
                  :title="`Urodziny: ${event.customer_name}`"
                >
                  <span class="whitespace-nowrap"><FontAwesomeIcon icon="birthday-cake" />&nbsp;<FontAwesomeIcon icon="user-tie" />&nbsp;{{ event.employee_name }}</span>
                </router-link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '../services/ApiService';
import { errorHandler } from '../mixins/errorHandler';
import moment from 'moment';

export default {
  name: 'Calendar',
  mixins: [errorHandler],
  data() {
    return {
      monthNames: ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'],
      days: ['Pon.', 'Wt.', 'Śr.', 'Czw.', 'Pt.', 'Sob.', 'Nie.'],
      daysFull: ['Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota', 'Niedziela'],
      now: new Date(),
      eventSubjectName: '',
      eventDate: '',
      events: [],
      installmentsFilter: true,
      endofordersFilter: true,
      birthdaysFilter: true,
      showAllFilter: false,
      agentFilter: '',
    };
  },
  computed: {
    // Lista pracowników do SELECT'a
    employeeList() {
      return this.$store.state.employees.employeeSimpleList;
    },
    // Sprawdza, czy dany użytkownik ma uprawnienia do widoczności zdarzeń innych pracowników
    allowAllEvents() {
      const userRights = this.$store.getters.currentUserData.user_rights;
      if (userRights === undefined) {
        return false;
      }
      return Array.isArray(userRights.employee) && (userRights.employee[1] === 1 || userRights.employee[1] === '1' || userRights.employee[1] === 62 || userRights.employee[1] === '62');
    },
    isCurrentMonth() {
      const currentMonth = moment().format('YYYY-MM');
      return currentMonth === moment(this.now).format('YYYY-MM');
    },
    todayYMD() {
      return moment().format('YYYY-MM-DD');
    },
    today() {
      return {
        // getDate() zwraca aktualny dzień, jeśli now to aktualny miesiąc - w przeciwnym razie zwraca 1
        day: this.now.getDate(),
        month: this.now.getMonth(),
        year: this.now.getFullYear(),
        monthName: this.monthNames[this.now.getMonth()],
        tableHeader: `${this.monthNames[this.now.getMonth()]} ${this.now.getFullYear()}`,
      };
    },
    // zwraca ilość dni w badanym miesiący, czyli przesuwany się z miesiącem +1 i ustawiamy dzień na 0, co daje ostatni dzień miesiąca
    daysInMonth() {
      return new Date(this.now.getFullYear(), this.now.getMonth() + 1, 0).getDate();
    },
    // jak wyżej, ale musimy pokombinować aby badac poprzedni miesiąc
    daysInPrevMonth() {
      const month = this.today.month === 0 ? 11 : this.today.month - 1;
      const year = this.today.month === 0 ? this.today.year - 1 : this.today.year;
      return new Date(year, month + 1, 0).getDate();
    },
    // zwracamy dzień tygodnia pierwszego dnia badanego miesiąca, w zakresie 0-6 gdzie 0 to niedziela, zamieniamy 0 na 7 aby uzyskać zakres 1-7
    firstMonthDay() {
      const firstDayOfMonth =  new Date(this.now.getFullYear(), this.now.getMonth(), 1).getDay();
      return firstDayOfMonth === 0 ? 7 : firstDayOfMonth;
    },
    cells() {
      const cells = [];
      let displayEventsCount = 0;
      for (let day = 1; day <= this.daysInMonth; day++) {
        const date = moment(`${this.today.year}-${this.today.month + 1}-${day}`, 'YYYY-MM-DD').format('YYYY-MM-DD');
        // ten dziwny warunek łączy ze sobą sprawdzanie, czy data to aktualnie dodawany dzień oraz włączone filtry w połączeniu z rodzajami eventów
        const events = this.events.filter((event) => event.date === date && (this.installmentsFilter || event.type !== 'installments') && (this.endofordersFilter || event.type !== 'endoforders') && (this.birthdaysFilter || event.type !== 'birthdays'));
        cells.push({
          day,
          date,
          events,
        });
        displayEventsCount = displayEventsCount + events.length;
      }
      return { cells, displayEventsCount };
    },
  },
  mounted() {
    let date = moment().format('YYYY-MM');
    if (this.$route.query.date !== undefined && this.$route.query.date) {
      const d = moment(this.$route.query.date);
      if (d.isValid()) {
        // date = moment(this.$route.query.date+'-01').format('YYYY-MM');
        date = moment(this.$route.query.date).format('YYYY-MM');
        this.now = new Date(date);
      }
    }
    if (this.$route.query.all !== undefined && this.$route.query.all === '1') {
      this.showAllFilter = true;
    }
    if (this.$route.query.employee !== undefined && this.$route.query.employee !== '') {
      this.agentFilter = this.$route.query.employee;
    }
    this.$store.dispatch('employees/getEmployeeSipleList').catch(error => this.resolveError(error));
    this.initEventsData(date);
  },
  methods: {
    initEventsData(date) {
      this.$store.dispatch('setLoadingData', true);
      ApiService.getCallendarEvents({
        date,
        show_all: this.showAllFilter ? 1 : 0,
        employee: this.agentFilter,
      })
        .then((response) => {
          this.$store.dispatch('setLoadingData', false);
          this.events = response.data.events;
          this.setUrl();
        })
        .catch(error => {
          this.resolveError(error);
          this.$store.dispatch('setLoadingData', false);
        }); //
    },
    goToday() {
      this.now = new Date();
      this.initEventsData(moment(this.now).format('YYYY-MM'));
    },
    prevMonth(y, m) {
      let newMonth = m;
      let newYear = y;
      if (m === 0) {
        newMonth = 11;
        newYear -= 1;
      } else {
        newMonth -= 1;
      }
      this.now = new Date(newYear, newMonth);
      this.initEventsData(moment(this.now).format('YYYY-MM'));
    },
    nextMonth(y, m) {
      let newMonth = m;
      let newYear = y;
      if (m === 11) {
        newMonth = 0;
        newYear += 1;
      } else {
        newMonth += 1;
      }
      this.now = new Date(newYear, newMonth);
      this.initEventsData(moment(this.now).format('YYYY-MM'));
    },
    toggleShowAll() {
      this.showAllFilter = !this.showAllFilter;
      this.initEventsData(moment(this.now).format('YYYY-MM'));
    },
    changeEmployee() {
      this.initEventsData(moment(this.now).format('YYYY-MM'));
    },
    setUrl() {
      const query = {
        date: encodeURI(moment(this.now).format('YYYY-MM')),
        all: encodeURI(this.showAllFilter ? 1 : 0),
        employee: encodeURI(this.agentFilter),
      };
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router.replace({ name: 'Calendar', query });
      }
    },
  },
};
</script>
