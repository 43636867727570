<template>
  <BaseModal
    @closeModal="$store.dispatch('setPasswordModal', false)"
    :cancel="true"
    :escCancel="true"
    :ok="true"
    :busy="saving"
    okLabel="zapisz"
    @ok="save"
    size="md"
  >
    <template #header>
      Zmiana hasła do aplikacji
    </template>
    <template #default>
      <div class="flex flex-col gap-y-2">
        <BaseInputWithAddon
          type="password"
          labelTop="obecne hasło"
          v-model="form.current_password"
          :error="saveErrors.current_password !== undefined ? saveErrors.current_password : ''"
        />
        <BaseInputWithAddon
          type="password"
          labelTop="wybierz nowe hasło"
          v-model="form.new_password"
          :error="saveErrors.new_password !== undefined ? saveErrors.new_password : ''"
        />
        <BaseInputWithAddon
          type="password"
          labelTop="potwierdź nowe hasło"
          v-model="form.passConf"
          :error="saveErrors.passconf !== undefined ? saveErrors.passconf : ''"
        />
        <div class="text-muted text-sm italic">
          <div>* Hasło musi składać się z minimum jedne wielkiej litery, jednej małej litery oraz cyfry lub znaku specjalnego. Przykład: "N1iep@mietaM"</div>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { errorHandler } from '../../mixins/errorHandler';
import UserService from '../../services/UserService';

export default {
  name: 'ChangePassword',
  mixins: [errorHandler],
  data() {
    return {
      form: {
        current_password: '',
        new_password: '',
        passConf: '',
      },
      saving: false,
      saveErrors: {},
    };
  },
  computed: {
    userId() {
      return this.$store.getters.currentUserData.id;
    },
  },
  methods: {
    save() {
      this.saving = true;
      const formData = new FormData();
      formData.append('current_password', this.form.current_password);
      formData.append('new_password', this.form.new_password);
      formData.append('passconf', this.form.passConf);
      UserService.changePassword(this.userId, formData)
        .then(response => {
          this.saving = false;
          this.$store.dispatch('setPasswordModal', false);
          this.$store.dispatch('notifications/add', { message: response.data.msg, type: 'success' });
        })
        .catch(error => {
          this.saving = false;
          this.saveErrors = this.resolveError(error);
        });
    },
  },
};
</script>
