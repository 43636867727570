<template>
<div class="flex h-screen w-screen justify-center items-center">
  <div class="bg-gray-50 dark:bg-gray-900 p-5 border dark:border-gray-800 rounded shadow-lg">
    <h1 class="font-bold text-2xl text-red-500">Serwer nie odpowiada</h1>
    <p>Chwilowa awaria serwera lub przekroczono czas odpowiedzi serwera (sieć zajęta).</p>
    <p>Spróbuj ponownie za jakiś czas lub odśwież stronę.</p>
    <div class="flex flex-wrap gap-x-3 justify-center mt-3">
      <button class="tw--btn tw--btn-big" @click="$router.go(-1)">Powrót</button>
      <button class="tw--btn tw--btn-big" @click="$router.push({ name: 'Calendar' })">Strona główna</button>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'ApiIssue',
};
</script>
