<template>
  <div id="order-details" class="p-3 bg-white dark:bg-gray-900 rounded-lg shadow-lg">

    <ImageShow
      v-if="showImageGallery"
      @close="showImageGallery = false"
      :images="[order]"
      :startImageId="order.id"
      container="orders"
    />

    <OrderDetailsEdit
      v-if="editModal"
      @close="editModal = false"
      @updated="updated"
      :order="order"
      :orderSubjects="orderSubjects"
      :permissions="permissions"
    />

    <PrintTermination v-if="printTerminationModal" @close="printTerminationModal = false" :orderId="order.id" />

    <RenewOrder v-if="renewOrderModal" @close="renewOrderModal = false" @orderRenewed="orderRenewed" :orderId="order.id" />

    <div class="flex items-center -mt-3 mb-3 -mx-3 px-3 py-1 border-b dark:border-gray-600 border-gray-400">
      <div class="flex-grow uppercase font-semibold text-muted">Dane podstawowe</div>
      <div v-if="validating === 1">
        <CardMenu>
          <template #default>
            <ul class="flex flex-col gap-2 text-sm whitespace-nowrap">
              <li @click="editModal = true" class="cursor-pointer hover:underline"><FontAwesomeIcon class="text-blue-400 dark:text-blue-600" :icon="['far', 'edit']" /> edytuj</li>
              <li v-if="Number(order.type_id) === 1"
                @click="printTerminationModal = true"
                class="cursor-pointer hover:underline"
                title="Drukuj wypowiedzenie"
              ><FontAwesomeIcon class="text-yellow-400 dark:text-yellow-600" icon="print"/> wypowiedz.</li>
              <li v-if="showRodoApkMenu"
                @click="$emit('rodoApkModal')"
                class="cursor-pointer hover:underline"
                title="Wygeneruj ankietę Rodo-APK online"
              ><FontAwesomeIcon class="text-green-400 dark:text-green-600" :icon="['fas', 'at']"/> rodo-apk</li>
            </ul>
          </template>
        </CardMenu>
      </div>
      <span v-else-if="validating === -1" class="mt-1 text-xs text-muted">
        uzupełnij<FontAwesomeIcon class="ml-2 text-yellow-400 dark:text-yellow-600" icon="exclamation-triangle" size="lg" />
      </span>
      <span v-else class="mt-1 text-xs text-muted">wczytuję<FontAwesomeIcon class="ml-2" icon="sync-alt" size="lg" spin /></span>
    </div>

    <div class="flex flex-wrap sm:flex-nowrap lg:flex-wrap xl:flex-nowrap justify-between items-center pb-2 mb-0.5 border-b dark:border-gray-600  border-gray-400">
      <div class="text-xs md:text-base"><span class="text-muted">Id: </span><span class="md:text-sm font-semibold">{{ this.order.id }}</span></div>
      <div
        class="order-first sm:order-none lg:order-first xl:order-none flex gap-2 px-0 sm:px-1 lg:px-0 xl:px-1 justify-start sm:justify-center lg:justify-start xl:justify-center items-center text-gray-400 dark:text-gray-500"
        :class="{'bg-yellow-100 text-gray-500 dark:bg-yellow-700 dark:text-gray-800': Number(order.is_leasing) === 1}"
      >
        <span v-if="[3, 8, 9].includes(Number(order.type_id))" class="font-semibold text-xl leading-3 px-1">&sect;</span>
        <FontAwesomeIcon v-else
          :icon="orderTypeIcon"
          size="lg"
        />
        <span class="text-bright text-sm md:text-base">{{ order.type_name }} <span v-if="order.is_leasing == 1">&nbsp;- LEASING</span></span>
      </div>
      <span v-if="Number(order.priority) === 1 && Number(order.status_id) < 8" class="text-sm md:text-base">
        pilne <FontAwesomeIcon class="text-yellow-400 dark:text-yellow-600" icon="exclamation-triangle" />
      </span>
      <BaseSelectWithAddon v-model="orderStatusId" class="w-full sm:w-auto lg:w-full xl:w-auto" @input="changeStatusHandler" labelAddon="status" :options="orderStatusList" :nullOption="false"  />
    </div>

    <div class="flex flex-col gap-2">
      <div v-if="order.policy_no === ''" class="flex justify-between">
        <div class="uppercase text-muted font-bold cursor-help" title="po uzupełnieniu numeru polisy/składek pojawią się dodatkowe dane">oferta <FontAwesomeIcon :icon="['far', 'question-circle']" size="sm" /></div>
        <div v-if="hasOrderScan">
          <button @click="showScanFile" class="tw--btn rounded-r-none" title="wyświetl">skan polisy <FontAwesomeIcon icon="cloud-download-alt" class="ml-2" /></button>
          <button @click="delOrderScanHandler" class="tw--btn tw--btn-secondary rounded-l-none" title="usuń"><FontAwesomeIcon :icon="['far', 'trash-alt']" /></button>
        </div>
        <div v-else>
          <!-- <button class="tw--btn tw--btn-warning"><FontAwesomeIcon icon="cloud-upload-alt" /> dołącz skan polisy</button> -->
          <label class="tw--btn tw--btn-warning cursor-pointer">
            <FontAwesomeIcon icon="cloud-upload-alt" /> dołącz skan polisy
            <input type="file" class="hidden"  @change="uploadOrderScan($event.target.files)" />
          </label>
        </div>
      </div>
      <div v-else class="flex flex-col">
        <div class="flex flex-wrap items-center justify-between gap-x-3">
          <div class="flex gap-1 items-center">
            <span class="text-muted">nr polisy</span><span class="text-bright font-bold text-lg">{{ order.policy_no }}</span>
          </div>
          <div class="flex-shrink flex gap-1">
            <span class="text-muted">tow. ub.</span><span :class="{'text-bright': insurerName, 'italic': !insurerName}">{{ insurerName ? insurerName : 'brak' }}</span>
          </div>
          <div class="flex gap-1">
            <span class="text-muted">data wystawienia</span><span :class="{'text-bright': order.sale_date_h, 'italic': !order.sale_date_h}">{{ order.sale_date_h ? order.sale_date_h : 'brak' }}</span>
          </div>
          <div v-if="hasOrderScan">
            <button @click="showScanFile" class="tw--btn rounded-r-none" title="wyświetl">skan polisy <FontAwesomeIcon icon="cloud-download-alt" class="ml-2" /></button>
            <button @click="delOrderScanHandler" class="tw--btn tw--btn-secondary rounded-l-none" title="usuń"><FontAwesomeIcon :icon="['far', 'trash-alt']" /></button>
          </div>
          <div v-else>
            <!-- <button class="tw--btn tw--btn-danger" title="wyświetl"><FontAwesomeIcon icon="cloud-upload-alt" /> dołącz skan polisy</button> -->
            <label class="tw--btn tw--btn-warning cursor-pointer">
              <FontAwesomeIcon icon="cloud-upload-alt" /> dołącz skan polisy
              <input type="file" class="hidden"  @change="uploadOrderScan($event.target.files)" />
            </label>
          </div>
        </div>
      </div>

      <div class="flex flex-wrap justify-between gap-3">
        <div class="flex flex-wrap items-center gap-x-3">
          <div class="flex items-center gap-1">
            <span class="text-muted">start ochrony</span><span class="text-bright font-bold text-lg">{{ order.date_from_h }}</span>
          </div>
          <div class="flex items-center gap-1">
            <span class="text-muted">koniec ochrony</span><span class="text-bright" :class="{' font-bold text-lg': order.date_to_h}">{{ order.date_to_h ? order.date_to_h : 'nieokreślony' }}</span>
          </div>
        </div>
        <div class="flex flex-wrap items-center gap-x-3">
          <div class="flex gap-1">
            <span class="text-muted">poprzednia</span>
            <router-link v-if="order.prev_ord_id" class="tw--link" :to="{ name: 'Order', params: { id: order.prev_ord_id } }">
              {{ order.prev_ord_policy_no ? order.prev_ord_policy_no : `Oferta id: ${order.prev_ord_id}` }}
            </router-link>
            <span v-else class="italic">brak</span>
          </div>
          <div class="flex gap-1 items-center">
            <span class="text-muted">kontynuacja</span>
            <router-link v-if="order.next_ord_id" class="tw--link" :to="{ name: 'Order', params: { id: order.next_ord_id } }">
              {{ order.next_ord_policy_no ? order.next_ord_policy_no : `Oferta id: ${order.next_ord_id}` }}
            </router-link>
            <span v-else>
              <span class="italic">brak&nbsp;</span>
              <button v-if="renewButtonShow"
                @click="renewOrderModal = true"
                class="tw--btn"
                :disabled="renewButtonDisabled"
                :title="renewButtonDisabled ? 'wznowienie będzie dostępne w ustalonym przez administratora czasie' : ''">wznów</button>
              <!-- <span v-if="renewButtonShow" class="text-sm font-light">
                (<a v-if="!renewButtonDisabled" @click.prevent="renewOrderModal = true" href="#" class="tw--link">wznów</a>
                <span v-else class="text-muted cursor-help" title="wznowienie będzie dostępne w ustalonym przez administratora czasie">wznów</span>)
              </span> -->
            </span>
          </div>
        </div>
      </div>

      <div class="flex justify-between">
        <div class="flex items-center">
          <span class="text-muted">nr konta do składki</span>
          <HelpContainer iconSize="sm" direction="right">
            <p>nr konta do wpłaty składki przez klienta - pomocne przy generowaniu do klienta przypomnień o terminie wpłaty składki/raty</p>
          </HelpContainer>
          <span :class="{'text-bright': order.bank_acc_no, 'italic': !order.bank_acc_no}">{{ order.bank_acc_no ? order.bank_acc_no : 'brak' }}</span>
        </div>
        <div v-if="rodoApkSurvey.status !== undefined">
          <span class="font-bold text-sm" v-if="rodoApkSurvey.status == 2">
            APK-RODO&nbsp;
            <span class="font-bold text-base text-green-400 dark:text-green-600">&check;</span>
          </span>
          <a v-else-if="rodoApkSurvey.status == 1" class="tw--link text-sm" :href="rodoApkSurvey.employee_url" target="_blank" title="Oczekuje na zatwierdzenie przez klienta">
            RODO-APK
            <FontAwesomeIcon icon="history" class="ml-1" />
          </a>
          <a
            v-else class="font-bold text-sm text-red-600 dark:text-red-800 hover:text-red-400 dark:hover:text-red-600"
            :href="rodoApkSurvey.employee_url"
            target="_blank"
            title="Wymagana reakcja pracownika - ankieta nie została przesłana do kienta"
          >
            RODO-APK
            <FontAwesomeIcon icon="history" class="ml-1" />
          </a>
        </div>
      </div>

      <SubjectList
        :orderId="order.id"
        :orderSubjects="orderSubjects"
        :orderTypeId="order.type_id"
        @added="subjectAdded"
        @updated="subjectUpdated"
        @deleted="subjectDeleted"
      />

      <div class="flex flex-wrap items-center justify-between">
        <div id="order-kontrolka" class="flex items-center gap-3">
          <span class="text-sm">Kontrolka:</span>
          <FontAwesomeIcon :icon="['fas', 'download']" class="cursor-help"
            :class="{
              'text-green-400 dark:text-green-600':order.check_returned === 1 || order.check_returned === '1',
              'text-red-400 dark:text-red-800':order.check_returned === 0 || order.check_returned === '0',
              'text-gray-300 dark:text-gray-700':order.check_returned === '',
            }"
            title="Polisa zdana do biura?"
          />
          <FontAwesomeIcon :icon="['fas', 'cloud-upload-alt']" class="cursor-help"
           :class="{
              'text-green-400 dark:text-green-600':order.check_scan === 1 || order.check_scan === '1',
              'text-red-400 dark:text-red-800':order.check_scan === 0 || order.check_scan === '0',
              'text-gray-300 dark:text-gray-700':order.check_scan === '',
            }"
            title="Skan dołączony do aplikacji?"
          />
          <FontAwesomeIcon :icon="['fas', 'camera-retro']" class="cursor-help"
           :class="{
              'text-green-400 dark:text-green-600':order.check_attachments === 1 || order.check_attachments === '1',
              'text-red-400 dark:text-red-800':order.check_attachments === 0 || order.check_attachments === '0',
              'text-gray-300 dark:text-gray-700':order.check_attachments === '',
            }"
            title="Agent podłączył zdjęcia?"
          />
          <FontAwesomeIcon :icon="['fas', 'file-signature']" class="cursor-help"
           :class="{
              'text-green-400 dark:text-green-600':order.check_resignation === 1 || order.check_resignation === '1',
              'text-red-400 dark:text-red-800':order.check_resignation === 0 || order.check_resignation === '0',
              'text-gray-300 dark:text-gray-700':order.check_resignation === '',
            }"
            title="Wypowiedzenie wysłane?"
          />
        </div>
        <div id="order-last-modification-time" v-if="order.last_modification_h != ''" class="flex">
          <span class="rounded-l text-xs px-2 py-1 text-gray-300 dark:text-gray-400 bg-black">ostatnie zmiany</span>
          <span class="rounded-r text-xs px-2 py-1 text-gray-100 bg-blue-500 dark:text-gray-400 dark:bg-blue-900">{{ order.last_modification_h }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import { confirm } from '../../mixins/confirm';
import { errorHandler } from '../../mixins/errorHandler';
import OrderService from '../../services/OrderService';
import ImageShow from '../ImageShow.vue';
import OrderDetailsEdit from './OrderDetailsEdit.vue';
import PrintTermination from './PrintTermination.vue';
import RenewOrder from './RenewOrder.vue';
import SubjectList from '../subject/SubjectList.vue';

export default {
  name: 'OrderDetails',
  components: {
    ImageShow,
    OrderDetailsEdit,
    PrintTermination,
    RenewOrder,
    SubjectList,
  },
  mixins: [confirm, errorHandler],
  props: {
    order: {
      type: Object,
      required: true,
    },
    customer: {
      type: Object,
      required: true,
    },
    insurerName: {
      type: String,
      required: true,
    },
    orderSubjects: {
      type: Object,
      required: true,
    },
    rodoApkSurvey: {
      type: Object,
      required: true,
    },
    permissions: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      orderStatusId: '',
      showImageGallery: false,
      editModal: false,
      addSubjectModal: false,
      printTerminationModal: false,
      renewOrderModal: false,
      apiUrl: process.env.VUE_APP_API_URL,
    };
  },
  computed: {
    ...mapGetters([
      'currentUserData',
      'currentUserType',
      'daysToRenewOrder',
      'progOptions',
    ]),
    ...mapGetters('orders', [
      'orderTypeList',
      'orderStatusList',
    ]),
    showAdmin() {
      return this.currentUserType === 'root' || this.currentUserType === 'admin';
    },
    validating() {// -1 błąd, 0 łąduję dane, 1 ok = MenuCard
      return this.order.status_id !== undefined ? 1 : 0;
    },
    orderTypeIcon() {
      const icons = [
        'car-side',
        'home',
        'file-signature',
        'luggage-cart',
        'heartbeat',
        'heartbeat',
        'warehouse',
        'file-signature',
        'file-signature',
        'seedling',
        'tractor',
        'users',
        'users',
        ['far', 'file-alt'],
      ];
      return icons[Number(this.order.type_id) - 1];
    },
    renewButtonShow() {
      return Number(this.order.has_next_ord) === 0 && this.order.date_to_h;
    },
    renewButtonDisabled() {
      return !this.showAdmin && this.order.date_to_h > moment().add(this.daysToRenewOrder, 'days').format('YYYY-MM-DD');
    },
    hasOrderScan() {
      return (this.order.scan_file !== undefined && this.order.scan_file !== '');
    },
    showRodoApkMenu() {
      return (this.progOptions.apkTypesAllowed.includes(Number(this.order.type_id)) || Number(this.customer.is_company) === 0)
        && this.progOptions.rodoApkModule;
    },
  },
  mounted() {
    this.orderStatusId = this.order.status_id;
    if (this.orderTypeList.length === 0) this.$store.dispatch('orders/getOrderTypeList').catch(error => this.resolveError(error));
    if (this.orderStatusList.length === 0) this.$store.dispatch('orders/getOrderStatusList').catch(error => this.resolveError(error));
  },
  methods: {
    changeStatusHandler(id) {
      if (id === '20' || id === 20) {
        this.confirm(() => this.changeStatus(id), 'Usunięcie spowoduje nieodwracalne utracenie niektórych danych. Kontynuować?', () => { this.orderStatusId = this.order.status_id; });
      } else {
        this.changeStatus(id);
      }
    },
    changeStatus(id) {
      OrderService.changeOrderStatus(this.order.id, { status_id: id })
        .then((response) => {
          const changedFields = {};
          Object.assign(changedFields, response.data.updatedFields);
          changedFields.status_name = this.orderStatusList.find(obj => Number(obj.id) === Number(id)).name;
          this.$emit('updated', changedFields);
          this.$store.dispatch('notifications/add', { type: 'success', message: response.data.msg });
        })
        .catch((error) => {
          this.resolveError(error);
        });
    },
    updated() {
      this.$emit('updated');
      this.editModal = false;
    },
    showScanFile() {
      if (this.order.scan_type.indexOf('image') !== -1) {
        this.showImageGallery = true;
      } else {
        const newWindow = window.open(null, this.order.id);
        newWindow.location.href = `${this.apiUrl}/orders/show-order-scan-file/${this.order.id}`;
      }
    },
    uploadOrderScan(filesToUpload) {
      if (filesToUpload.length > 0) {
        const formData = new FormData();
        formData.append('file', filesToUpload[0], filesToUpload[0].name);
        OrderService.uploadOrderScan(this.order.id, formData)
          .then(response => {
            this.$emit('orderScanUploaded', { scan_file: response.data.scan_file, scan_type: response.data.scan_type });
            this.$store.dispatch('notifications/add', { message: response.data.msg, type: 'success' });
          })
          .catch(error => this.resolveError(error));
      }
    },
    delOrderScanHandler() {
      this.confirm(() => this.delOrderScan(), 'Potwierdź usunięcie pliku ze skanem polisy');
    },
    delOrderScan() {
      OrderService.delOrderScan(this.order.id)
        .then((response) => {
          this.$emit('orderScanDeleted');
          this.$store.dispatch('notifications/add', { type: 'success', message: response.data.msg });
        })
        .catch(error => this.resolveError(error));
    },
    orderRenewed(newOrderId) {
      this.$router.push({ name: 'Order', params: { id: newOrderId } });
    },
    subjectAdded(subject) {
      this.$emit('subjectAdded', subject);
    },
    subjectUpdated(subject) {
      this.$emit('subjectUpdated', subject);
    },
    subjectDeleted(id) {
      this.$emit('subjectDeleted', id);
    },
  },
  watch: {
    'order.status_id': function(newVal) {
      this.orderStatusId = newVal;
    },
  },
};
</script>
