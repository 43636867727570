<template>
  <BaseModal
    @closeModal="$emit('close')"
    :cancel="true"
    :ok="true"
    okLabel="zapisz"
    @ok="save"
    :size="action === 'add' ? 'md' : 'sm'"
  >
    <template #header>
      {{ action === 'add' ? 'dodawanie' : 'edycja' }} osoby kontaktowej
    </template>
    <template #default>
      <form @submit.prevent="save" class="flex flex-col gap-y-3">
        <div class="flex flex-wrap gap-x-2">
          <BaseAutocompleteInput
            class="w-full"
            :class="{'sm:w-2/5': action === 'add'}"
            v-model="form.forename1"
            labelTop="imię"
            searchUrl="search-forename"
            :error="saveErrors.forename1 !== undefined ? saveErrors.forename1 : ''"
          />
          <BaseInputWithAddon
            class="flex-grow"
            type="text"
            v-model="form.surname"
            labelTop="nazwisko"
            :error="saveErrors.surname !== undefined ? saveErrors.surname : ''"
          />
        </div>
        <div v-if="action === 'add'" class="flex flex-wrap gap-x-2">
          <BaseInputWithAddon
            class="w-full sm:w-2/5"
            type="text"
            v-model="form.phone"
            labelTop="telefon"
            :error="saveErrors.phone !== undefined ? saveErrors.phone : ''"
          />
          <BaseInputWithAddon
            class="flex-grow"
            type="email"
            v-model="form.email"
            labelTop="email"
            :error="saveErrors.email !== undefined ? saveErrors.email : ''"
          />
        </div>
        <div v-else class="text-sm text-muted italic">* edycja telefonów i adresów email dostępna w oknie głównym karty klienta</div>
      </form>
    </template>
  </BaseModal>
</template>

<script>
import { errorHandler } from '../../mixins/errorHandler';
import CustomerService from '../../services/CustomerService';

export default {
  name: 'ContactPersonAddEdit',
  mixins: [errorHandler],
  props: {
    customerId: {
      type: [String, Number],
      required: true,
    },
    contactPerson: {
      type: Object,
      required: true,
    },
    action: {
      validator: function(value) {
        return ['add', 'edit'].indexOf(value) !== -1;
      },
    },
  },
  data() {
    return {
      form: {
        forename1: '',
        surname: '',
        phone: '',
        email: '',
      },
      saveErrors: {},
    };
  },
  mounted() {
    if (this.action === 'edit') {
      this.form.forename1 = this.contactPerson.forename;
      this.form.surname = this.contactPerson.surname;
    } else {
      this.form.customer_id = this.customerId;
    }
  },
  methods: {
    save() {
      this.saveErrors = {};
      if (this.action === 'add') {
        CustomerService.addContactPerson(this.form)
          .then(response => {
            this.$store.dispatch('notifications/add', { type: 'success', message: response.data.msg });
            this.$emit('added', response.data.newContactPerson);
          })
          .catch(error => {
            this.saveErrors = this.resolveError(error);
          });
      } else if (this.action === 'edit') {
        CustomerService.updateContactPerson(this.contactPerson.id, this.form)
          .then(response => {
            this.$store.dispatch('notifications/add', { type: 'success', message: response.data.msg });
            this.$emit('updated', response.data.updatedContactPerson);
          })
          .catch(error => {
            this.saveErrors = this.resolveError(error);
          });
      }
    },
  },
};
</script>
