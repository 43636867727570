<template>
  <BaseModal
    @closeModal="$emit('close')"
    :busy="saving"
    :cancel="true"
    :cancelLabel="actionCache === 'show' ? 'zamknij' : 'anuluj'"
    :ok="true"
    :okLabel="actionCache === 'show' ? 'edytuj' : actionCache === 'edit' ? 'zapisz zmiany' : 'zapisz'"
    :delBtn="actionCache === 'show'"
    @ok="actionCache !== 'show' ? save() : actionCache = 'edit'"
    @delete="deleteHandler"
  >
    <template #header>
      {{ action === 'add' ? 'nowy przedm. ubezp.' : 'przedmiot ubezpieczenia' }}
    </template>
    <template #default>
      <SubjectForm
        :orderTypeId="orderTypeId"
        :saveErrors="saveErrors"
        v-model="subjectCache"
        :disabled="actionCache === 'show'"
      />
    </template>
  </BaseModal>
</template>

<script>
import { confirm } from '../../mixins/confirm';
import { errorHandler } from '../../mixins/errorHandler';
import OrderService from '../../services/OrderService';
import SubjectForm from './SubjectForm.vue';

export default {
  name: 'SubjectModal',
  mixins: [confirm, errorHandler],
  components: {
    SubjectForm,
  },
  props: {
    subject: {
      type: Object,
      required: true,
    },
    orderId: {
      type: [Number, String],
      required: true,
    },
    orderTypeId: {
      type: [Number, String],
      required: true,
    },
    action: {
      required: true,
    },
  },
  data() {
    return {
      subjectCache: {},
      saveErrors: {},
      saving: false,
      actionCache: this.action,
    };
  },
  mounted() {
    this.actionCache = this.action === 'add' ? 'add' : 'show';
    Object.assign(this.subjectCache, this.subject);
    this.subjectCache.type_id = this.orderTypeId;
  },
  methods: {
    save() {
      this.saving = true;
      const method = this.actionCache === 'add' ? 'addOrderSubject' : 'updateOrderSubject';
      OrderService[method](this.orderId, this.subjectCache)
        .then(response => {
          this.$emit(this.actionCache === 'add' ? 'added' : 'updated', response.data.subject);
          this.$store.dispatch('notifications/add', { type: 'success', message: response.data.msg });
          this.saveErrors = {};
          this.saving = false;
        })
        .catch(error => {
          this.saveErrors = this.resolveError(error);
          this.saving = false;
        });
    },
    deleteHandler() {
      this.confirm(() => this.delSubject(), 'Potwierdź usunięcie przdmiotu ubezpieczenia');
    },
    delSubject() {
      this.saving = true;
      OrderService.delOrderSubject(this.subject.subject_id)
        .then(response => {
          this.$store.dispatch('notifications/add', { type: 'success', message: response.data.msg });
          this.$emit('deleted', this.subject.subject_id);
          this.saving = false;
        })
        .catch(error => {
          this.saveErrors = this.resolveError(error);
          this.saving = false;
        });
    },
  },
};
</script>
