<template>
  <router-link :to="{ name: destination }"
    class="group flex flex-col px-3 pb-1 border-r border-b border-gray-300 dark:border-gray-700"
    :class="{'tw--link text-black dark:text-gray-400 bg-gradient-to-br from-white to-gray-100 dark:from-gray-900 dark:to-gray-800': this.$route.name !== destination}"
    active-class="bg-gradient-to-br from-white to-blue-100 dark:from-gray-700 dark:to-gray-600 text-gray-800 hover:text-gray-800 dark:text-gray-300 dark:hover:text-gray-400 cursor-default z-10 extra--shadow"
  >
    <div
      class="text-base text-center"
      :class="this.$route.name !== destination ? 'font-normal' : 'font-semibold'"
    >
      <slot name="header"/>
    </div>
    <div class="text-xs italic text-center font-normal">
      <slot name="description" />
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'SubmenuButton',
  props: {
    destination: {
      type: String,
      default: 'Calendar',
    },
  },
};
</script>

<style scoped>
  .extra--shadow{
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1), -1px 0px 3px rgba(0, 0, 0, 0.25);
  }
</style>
