<template>
  <div class="flex flex-col gap-4">
    <div class="flex flex-col lg:flex-row gap-4">

      <RodoApkRequest
        v-if="rodoApkModal && rodoApkModule"
        :customer="customer"
        :employee="employee"
        :order="order"
        @close="rodoApkModal = false"
      />

      <OrderDetails v-if="order.id !== undefined"
        class="w-full lg:w-1/2"
        :order="order"
        :customer="customer"
        :insurerName="insurerName"
        :orderSubjects="orderSubjects"
        :rodoApkSurvey="rodoApkSurvey"
        :permissions="allow"
        @updated="orderDetailsUpdated"
        @orderScanDeleted="orderScanDeleted"
        @orderScanUploaded="orderScanUploaded"
        @subjectAdded="subjectAdded"
        @subjectUpdated="subjectUpdated"
        @subjectDeleted="subjectDeleted"
        @rodoApkModal="rodoApkModal = true"
      />
      <CustomerCard v-if="customer.id !== undefined && !orderViewFinancialDataFirst"
        class="w-full lg:w-1/2"
        :customer="customer"
        :permissions="allow"
        :changeCustomer="true"
        :notExclusiveInfo="false"
        @newCustomerId="newCustomerId"
        @customerUpdated="customerUpdated"
        @phoneAdded="customerPhoneAdded"
        @emailAdded="customerEmailAdded"
        @phoneUpdated="customerPhoneUpdated"
        @emailUpdated="customerEmailUpdated"
        @phoneDeleted="customerPhoneDeleted"
        @emailDeleted="customerEmailDeleted"
        @consentAdded="consentAdded"
        @consentUpdated="consentUpdated"
        @consentDeleted="consentDeleted"
      />
      <OrderFinancialData v-if="order.id !== undefined && orderViewFinancialDataFirst"
        class="w-full lg:w-1/2"
        :order="order"
        :items="orderItems"
        :installments="installments"
        :provisions="provisions"
        :permissions="allow"
        :copywriter="copywriter"
        :employee="employee"
        :employeeInsurers="employeeInsurers"
        :employeeList="employeeList"
        @itemsChanged="itemsChanged"
        @itemDeleted="itemDeleted"
        @newPolicyNo="newPolicyNo"
        @installmentsChanged="installmentsChanged"
        @installmentDeleted="installmentDeleted"
        @provisionAdded="provisionAdded"
        @provisionUpdated="provisionUpdated"
        @provisionDeleted="provisionDeleted"
        @financialDataUpdated="financialDataUpdated"
        @changeEmployee="changeEmployee"
      />

    </div>
    <div class="flex flex-col lg:flex-row gap-4">
      <CustomerCard v-if="customer.id !== undefined && orderViewFinancialDataFirst"
        class="w-full lg:w-1/2"
        :customer="customer"
        :permissions="allow"
        :changeCustomer="true"
        :notExclusiveInfo="false"
        @newCustomerId="newCustomerId"
        @customerUpdated="customerUpdated"
        @phoneAdded="customerPhoneAdded"
        @emailAdded="customerEmailAdded"
        @phoneUpdated="customerPhoneUpdated"
        @emailUpdated="customerEmailUpdated"
        @phoneDeleted="customerPhoneDeleted"
        @emailDeleted="customerEmailDeleted"
        @consentAdded="consentAdded"
        @consentUpdated="consentUpdated"
        @consentDeleted="consentDeleted"
      />
      <OrderFinancialData v-if="order.id !== undefined && !orderViewFinancialDataFirst"
        class="w-full lg:w-1/2"
        :order="order"
        :items="orderItems"
        :installments="installments"
        :provisions="provisions"
        :permissions="allow"
        :copywriter="copywriter"
        :employee="employee"
        :employeeInsurers="employeeInsurers"
        :employeeList="employeeList"
        @itemsChanged="itemsChanged"
        @itemDeleted="itemDeleted"
        @newPolicyNo="newPolicyNo"
        @installmentsChanged="installmentsChanged"
        @installmentDeleted="installmentDeleted"
        @provisionAdded="provisionAdded"
        @provisionUpdated="provisionUpdated"
        @provisionDeleted="provisionDeleted"
        @financialDataUpdated="financialDataUpdated"
        @changeEmployee="changeEmployee"
      />
      <NotesCard
        class="w-full lg:w-1/2"
        :notes="notes"
        parent="order"
        :parentId="order.id"
        @noteUpdated="noteUpdated"
        @noteDeleted="noteDeleted"
        @noteAdded="noteAdded"
      />
    </div>
  </div>
</template>

<script>
import OrderService from '../services/OrderService';
import { errorHandler } from '../mixins/errorHandler';
import OrderDetails from '../components/order/OrderDetails.vue';
import OrderFinancialData from '../components/order/OrderFinancialData.vue';
import CustomerCard from '../components/customer/CustomerCard.vue';
import NotesCard from '../components/notes/NotesCard.vue';
import RodoApkRequest from '../components/common/RodoApkRequest.vue';

export default {
  name: 'Order',
  components: {
    OrderDetails,
    CustomerCard,
    OrderFinancialData,
    NotesCard,
    RodoApkRequest,
  },
  mixins: [errorHandler],
  data() {
    return {
      order: {},
      customer: {},
      employee: {},
      employeeInsurers: [],
      copywriter: {},
      employeeList: [],
      orderSubjects: {},
      installments: [],
      orderItems: [],
      provisions: [],
      notes: [],
      allow: {},
      rodoApkModal: false,
      rodoApkSurvey: {},
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    rodoApkModule() {
      return this.$store.getters.progOptions.rodoApkModule;
    },
    orderViewFinancialDataFirst() {
      return this.$store.getters.progOptions.orderViewFinancialDataFirst;
    },
    insurerName() {
      return this.orderItems.length > 0 ? this.orderItems[0].insurer : '';
    },
  },
  mounted() {
    this.getOrder();
  },
  methods: {
    getOrder() {
      this.$store.dispatch('setLoadingData', true);
      OrderService.getFullOrderData(this.id)
        .then(response => {
          this.order = response.data.order;
          this.customer = response.data.customer;
          this.employee = response.data.employee;
          this.employeeInsurers = response.data.employeeInsurers;
          this.copywriter = response.data.copywriter;
          this.employeeList = response.data.employeeList;
          this.installments = response.data.installments;
          this.orderItems = response.data.items;
          this.provisions = response.data.provisions;
          this.notes = response.data.notes;
          this.allow = response.data.allow;
          this.orderSubjects = response.data.orderSubjects;
          this.rodoApkSurvey = response.data.rodoApkSurvey;
          this.$store.dispatch('setLoadingData', false);
        })
        .catch(error => {
          this.$store.dispatch('setLoadingData', false);
          this.resolveError(error);
        });
    },
    itemsChanged(items) {
      this.orderItems = items;
    },
    itemDeleted(id) {
      this.orderItems = this.orderItems.filter(obj => Number(obj.id) !== Number(id));
      // usuwanie rat, ponieważ po usunięciu składki na pewno nie będa się zgadzały
      OrderService.delOrderInstallments(this.order.id)
        .then(response => {
          this.installments = response.data.installments;
        })
        .catch(error => this.resolveError(error));

    },
    newPolicyNo(no) {
      this.order.policy_no = no;
    },
    installmentsChanged(items) {
      this.installments = items;
    },
    installmentDeleted(id) {
      this.installments = this.installments.filter(obj => Number(obj.id) !== Number(id));
    },
    provisionAdded(provision) {
      this.provisions.push(provision);
    },
    provisionUpdated(provision) {
      const provisionToEdit = this.provisions.find(obj => obj.id === provision.id);
      Object.assign(provisionToEdit, provision);
    },
    provisionDeleted(id) {
      this.provisions = this.provisions.filter(obj => obj.id !== id);
    },
    noteUpdated(note) {
      const updated = this.notes.find(obj => Number(obj.id) === Number(note.id));
      Object.assign(updated, note);
    },
    noteDeleted(id) {
      this.notes = this.notes.filter(note => note.id !== id);
    },
    noteAdded(note) {
      this.notes.unshift(note);
    },
    financialDataUpdated() {
      this.getOrder();
    },
    changeEmployee(employeeId) {
      OrderService.updateOrder(this.order.id, { employee_id: employeeId })
        .then(() => this.getOrder())
        .catch(error => this.resolveError(error));
    },
    consentAdded(consent) {
      this.customer.consents.unshift(consent);
    },
    consentUpdated(consent) {
      const consentToUpdate = this.customer.consents.find(obj => Number(obj.id) === Number(consent.id));
      Object.assign(consentToUpdate, consent);
    },
    consentDeleted(id) {
      this.customer.consents = this.customer.consents.filter(obj => Number(obj.id) !== Number(id));
    },
    newCustomerId(id) {
      if (Number(id) !== Number(this.customer.id)) {
         OrderService.updateOrder(this.order.id, { customer_id: id })
          .then(() => this.getOrder())
          .catch(error => this.resolveError(error));
      }
    },
    customerUpdated() {
      this.getOrder();
    },
    customerPhoneAdded(item) {
      this.customer.phones.push(item);
    },
    customerEmailAdded(item) {
      this.customer.emails.push(item);
    },
    customerPhoneUpdated(item) {
      const updated = this.customer.phones.find(obj => Number(obj.id) === Number(item.id));
      Object.assign(updated, item);
    },
    customerEmailUpdated(item) {
      const updated = this.customer.emails.find(obj => Number(obj.id) === Number(item.id));
      Object.assign(updated, item);
    },
    customerPhoneDeleted(id) {
      this.customer.phones = this.customer.phones.filter(obj => Number(obj.id) !== Number(id));
    },
    customerEmailDeleted(id) {
      this.customer.emails = this.customer.emails.filter(obj => Number(obj.id) !== Number(id));
    },
    orderDetailsUpdated() {
      this.getOrder();
    },
    orderScanUploaded(file) {
      this.order.scan_file = file.scan_file;
      this.order.scan_type = file.scan_type;
    },
    orderScanDeleted() {
      this.order.scan_file = '';
      this.order.scan_type = '';
    },
    subjectAdded(subject) {
      this.orderSubjects.subjects.push(subject);
    },
    subjectUpdated(subject) {
      const subjectToUpdate = this.orderSubjects.subjects.find(obj => Number(obj.subject_id) === Number(subject.subject_id));
      Object.assign(subjectToUpdate, subject);
    },
    subjectDeleted(id) {
      this.orderSubjects.subjects = this.orderSubjects.subjects.filter(obj => Number(obj.subject_id) !== Number(id));
    },
  },
  watch: {
    '$route.params.id': 'getOrder',
  },
};
</script>
