<template>
  <div>
    <BaseModal
      @closeModal="$emit('close')"
      @ok="save"
      okLabel="zapisz"
      size="xl"
      minHeight="full"
    >
      <template #header>Zmiana agenta</template>

      <template #default>
        <div class="flex flex-col">
          <div class="text-muted">Agent przypisany do {{ order.policy_no ? 'polisy' : 'oferty' }}</div>
          <div class="px-2 py-1 border border-gray-200 dark:border-gray-700 rounded shadow">
            <div class="flex flex-wrap items-end gap-x-3">
              <div class="text-bright">{{employeeCurrent.fullname}}</div>
              <div class="flex gap-x-1 items-center" :class="employeeCurrent.phone !== '' ? 'tw--link' : 'text-muted' ">
                <FontAwesomeIcon icon="phone-alt" />
                <a v-if="employeeCurrent.phone !== ''" :href="`tel:${employeeCurrent.phone}`">{{ employeeCurrent.phone }}</a>
                <span v-else class="italic">nie podano</span>
              </div>
              <div class="flex gap-x-1 items-center overflow-hidden max-w-full" :class="employeeCurrent.email !== '' ? 'tw--link' : 'text-muted' ">
                <a v-if="employeeCurrent.email !== ''" :href="`mailto:${employeeCurrent.email}`">{{ employeeCurrent.email }}</a>
                <span v-else class="italic"><FontAwesomeIcon icon="at" />&nbsp; nie podano</span>
              </div>
            </div>
            <div class="flex flex-wrap items-end gap-x-3">
              <div class="flex gap-x-1">
                <div class="text-muted">stanowisko</div><div class="text-bright">{{ employeeCurrent.type }}</div>
              </div>
              <div class="flex flex-grow gap-x-1">
                <div class="text-muted">prowizja</div><div class="text-bright">{{ employeeCurrent.provision_level }}</div>
              </div>
              <div class="flex gap-x-1">
                <div class="text-muted">manager</div><div :class="employeeCurrent.manager ? 'text-bright' : 'italic'">
                  {{ employeeCurrent.manager ? employeeCurrent.manager : 'brak' }}
                </div>
              </div>
            </div>
            <div v-if="showInsurers === '1'" class="flex flex-wrap gap-x-2 gap-y-0.5 items-center">
              <div class="text-sm text-muted">upr. KNF</div>
              <div v-for="insurer in employeeCurrent.insurers" :key="insurer.id" class="px-2 py-0 bg-purple-100 dark:bg-purple-900 text-sm rounded-sm">{{ insurer.name }}</div>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap gap-y-1 justify-between mt-2 pb-1 border-b dark:border-gray-600">
          <BaseInputWithAddon type="text" @input="filterList" v-model="filterSurname" labelIconAddon="search" placeholder="nazwisko" class="w-full sm:w-auto" />
          <BaseSelectWithAddon :options="insurerList" @input="filterList" v-model="filterInsurerId" labelAddon="TU" class="w-full sm:w-auto" />
          <BaseSelectWithAddon :options="emplManagerList" @input="filterList" v-model="filterManagerId" labelAddon="manager" class="w-full sm:w-auto" />
          <label for="show-insurers">
            <input
              id="show-insurers"
              type="checkbox"
              true-value="1" false-value="0"
              v-model="showInsurers"
            > pokaż TU
          </label>
          <label for="include-not-active" class="flex items-center gap-1" title="pokaż również nieaktywnych pracowników">
            <input
              id="include-not-active"
              type="checkbox"
              v-model="includeNotActive"
            > <FontAwesomeIcon icon="user-slash" />
          </label>
        </div>
        <div class="flex flex-col gap-y-1">

          <div class="w-full flex gap-x-1 text-sm">
            <div class="text-muted italic">pracowników: </div><div>{{ filteredEmployeeList.length }}</div><div class="text-muted">- kliknij w wiersz, aby wybrać</div>
          </div>

          <div v-for="employee in filteredEmployeeList" :key="employee.id" @click="save(employee)"
            class="px-2 py-1 border border-gray-200 dark:border-gray-700 rounded shadow hover:shadow-inner dark:hover:bg-black cursor-pointer"
            :class="{'border-red-400 dark:border-red-800': Number(employee.status_id) !== 1}"
          >
            <div class="flex flex-wrap items-end gap-x-3">
              <div class="text-bright">{{employee.fullname}}</div>
              <div class="flex gap-x-1 items-center" :class="employee.phone !== '' ? 'tw--link' : 'text-muted' ">
                <FontAwesomeIcon icon="phone-alt" />
                <a v-if="employee.phone !== ''" :href="`tel:${employee.phone}`" @click.stop>{{ employee.phone }}</a>
                <span v-else class="italic">nie podano</span>
              </div>
              <div class="flex gap-x-1 items-center overflow-hidden max-w-full" :class="employee.email !== '' ? 'tw--link' : 'text-muted'">
                <a v-if="employee.email !== ''" :href="`mailto:${employee.email}`" @click.stop>{{ employee.email }}</a>
                <span v-else class="italic"><FontAwesomeIcon icon="at" />&nbsp; nie podano</span>
              </div>
            </div>
            <div v-if="Number(employee.status_id) !== 1">
              <div class="flex gap-x-1">
                <div class="text-muted">status</div><div class="text-red-400 dark:text-red-800 font-bold">{{ employee.status_description }}</div>
              </div>
            </div>
            <div class="flex flex-wrap items-end gap-x-3">
              <div class="flex gap-x-1">
                <div class="text-muted">stanowisko</div><div>{{ employee.type }}</div>
              </div>
              <div class="flex-grow flex gap-x-1">
                <div class="text-muted">prowizja</div><div>{{ employee.provision_level }}</div>
              </div>
              <div class="flex gap-x-1">
                <div class="text-muted">manager</div><div :class="{'italic text-muted': !employee.manager}">{{ employee.manager ? employee.manager : 'brak' }}</div>
              </div>
            </div>
            <div v-if="showInsurers === '1'" class="flex flex-wrap gap-x-2 gap-y-0.5 items-center">
              <div class="text-sm text-muted">upr. KNF</div>
              <div v-if="employee.insurers.length === 0" class="text-sm text-muted italic">nie ustawiono</div>
              <div v-for="insurer in employee.insurers" :key="insurer.id" class="px-2 py-0 bg-purple-100 dark:bg-purple-900 text-sm rounded-sm">{{ insurer.name }}</div>
            </div>
          </div>
        </div>
      </template>
    </BaseModal>
  </div>
</template>

<script>
import { errorHandler } from '../../mixins/errorHandler';
import { confirm } from '../../mixins/confirm';
import EmployeeService from '../../services/EmployeeService';

export default {
  name: 'OrderEmployeeChange',
  mixins: [confirm, errorHandler],
  props: {
    order: {
      type: Object,
      default() {
        return {};
      },
    },
    employeeId: {
      type: [Number, String],
      required: true,
    },
    confirmChange: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      employeeList: [],
      employeeCurrent: {},
      includeNotActive: false,
      showInsurers: '1',
      filterInsurerId: '',
      filterSurname: '',
      filterManagerId: '',
      filteredEmployeeList: [],
    };
  },
  computed: {
    insurerList() {
      return this.$store.getters['products/insurerList'];
    },
    managerList() {
      return this.$store.state.employees.emplManagerList;
    },
    emplManagerList() {
      return this.managerList.map(obj => { return {
          id: obj.id,
          fullname: `${obj.surname} ${obj.forename}`,
        };
      });
    },
  },
  mounted() {
    EmployeeService.getEmployeeListExtended()
      .then(response => {
        this.employeeList = response.data.employeeListExtended;
        this.employeeCurrent = this.employeeList.find(obj => Number(obj.id) === Number(this.employeeId));
        this.filterList();
      })
      .catch(error => this.resolceError(error));
    if (this.insurerList.length === 0) this.$store.dispatch('products/getInsurerList').catch(error => this.resolveError(error));
    if (this.managerList.length === 0) this.$store.dispatch('employees/getManagerList').catch(error => this.resolveError(error));
  },
  methods: {
    filterList() {
      this.filteredEmployeeList = this.employeeList.filter(obj => {
        const cond1 = Number(obj.id) !== Number(this.employeeCurrent.id);
        let cond2 = true;
        let cond3 = true;
        let cond4 = true;
        let cond5 = true;
        if (!this.includeNotActive) cond2 = Number(obj.status_id) === 1;
        if (this.filterInsurerId !== '') cond3 = obj.insurers.map(ins => Number(ins.id)).includes(Number(this.filterInsurerId));
        if (this.filterSurname !== '') cond4 = obj.surname.toLowerCase().includes(this.filterSurname.toLowerCase());
        if (this.filterManagerId !== '') cond5 = Number(obj.manager_id) === Number(this.filterManagerId);
        return cond1 && cond2 && cond3 && cond4 && cond5;
      });
    },
    save(employee) {
      if (this.confirmChange) {
        this.confirm(() => this.saveConfirmed(employee.id), `Czy potwierdzasz zamianę agenta na ${employee.fullname}?`);
      } else {
        this.saveConfirmed(employee.id);
      }
    },
    saveConfirmed(employeeId) {
      this.$emit('changeEmployee', employeeId);
    },
  },
  watch: {
    includeNotActive() {
      this.filterList();
    },
  },
};
</script>
