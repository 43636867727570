<template>
  <nav v-show="total > 1" class="flex flex-wrap mb-2 gap-x-6 gap-y-1" role="navigation" aria-label="pagination">
    <ul v-if="total > 1" class="flex items-stretch divide-x border rounded-sm border-gray-300 dark:border-gray-600 dark:divide-gray-600">
      <li><button @click="prev" aria-label="Poprzednia strona" :disabled="current==1"><span class="block">&lt;</span></button></li>
      <li v-if="current > 1"><button @click="setPage(1)" aria-label="Do początku">1</button></li>
      <li v-if="current > 4 && total > 7" class="px-2">&hellip;</li>
      <li v-if="current == 4 || (current >= 4 && total <= 7)"><button @click="setPage(2)" aria-label="Strona 2">2</button></li>
      <li v-if="current >= 5 && total <=7"><button @click="setPage(3)" aria-label="Strona 3">3</button></li>
      <li v-if="current > total -3 && total > 7"><button @click="setPage(total-4)" :aria-label="`Strona ${total-4}`">{{ total-4 }}</button></li>
      <li v-if="current > 5 && total <= 7"><button @click="setPage(4)" aria-label="Strona 4">4</button></li>
      <li v-if="current > total -2 && total > 7"><button @click="setPage(total-3)" :aria-label="`Strona ${total-3}`">{{ total-3 }}</button></li>
      <li v-if="current == 7 && total <= 7"><button @click="setPage(5)" aria-label="Strona 5">5</button></li>
      <li v-if="current == total && total > 7"><button @click="setPage(total-2)" :aria-label="`Strona ${total-2}`">{{ total-2 }}</button></li>

      <li v-if="current > 2"><button @click="setPage(current-1)" :aria-label="`Strona ${current-1}`">{{ current-1 }}</button></li>
      <li class="bg-gray-300 dark:bg-gray-700">
        <button v-if="!write" @click="write = true" :aria-label="`Strona ${current}`" aria-current="page">{{ current }}</button>
        <input v-focus v-if="write" type="number"
          :value="current"
          @keydown.enter="setPage($event.target.value)"
          @keydown.esc="write = false"
          min="1"
          :max="total"
        >
      </li>
      <li v-if="current < total - 1"><button @click="setPage(current+1)" :aria-label="`Strona ${current+1}`">{{ current+1 }}</button></li>

      <li v-if="current == 1 && total > 3"><button @click="setPage(3)" aria-label="Strona 3">3</button></li>
      <li v-if="current < 3 && total > 4"><button @click="setPage(4)" aria-label="Strona 4">4</button></li>
      <li v-if="current < 4 && total > 5"><button @click="setPage(5)" aria-label="Strona 5">5</button></li>
      <li v-if="current < 5 && total == 7"><button @click="setPage(6)" aria-label="Strona 6">6</button></li>
      <li v-if="current < total - 3 && total > 7" class="px-2">&hellip;</li>
      <li v-if="current == total - 3 && total > 7"><button @click="setPage(total-1)" :aria-label="`Strona ${total-1}`">{{ total-1 }}</button></li>
      <li v-if="current < total"><button @click="setPage(total)" aria-label="Do końca">{{ total }}</button></li>
      <li><button @click="current < total ? setPage(current + 1) : ''" aria-label="Następna strona" :disabled="current==total">&gt;</button></li>
    </ul>

    <div
      class="flex items-stretch border rounded-sm focus-within:ring-1
      focus-within:border-blue-300 border-gray-300
      dark:focus-within:border-blue-600 dark:border-gray-600"
      title="ilość wierszy na stronie"
    >
      <label class="flex items-center gap-1 font-semibold text-sm px-1 py-1 bg-gray-100 dark:bg-transparent border-r border-gray-300 dark:border-gray-600">
        <FontAwesomeIcon icon="list-ol" class="text-lg" />
      </label>
      <select v-model="perPage" class="border-0 focus:ring-0">
        <option v-for="(option, index) in perPageOptions" :key="index" :value="option">{{ option }}</option>
      </select>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    current: {
      type: Number,
      required: true,
      default: 1,
    },
    total: {
      type: Number,
      required: true,
      default: 1,
    },
    value: {
      type: Number,
      required: true,
    },
    perPageOptions: {
      type: Array,
      default: () => [10, 20, 50, 100],
    },
  },
  data() {
    return {
      write: false,
    };
  },
  computed: {
    perPage: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
        this.$emit('perPageChanged', val);
      },
    },
  },
  methods: {
    next() {
      if (this.current < this.total) {
        this.$emit('next');
      }
    },
    prev() {
      if (this.current > 1) {
        this.$emit('prev');
      }
    },
    setPage(pageNumber) {
      this.$emit('page', pageNumber);
      this.write = false;
    },
  },
  // watch: {
    // total() {
      // this.$emit('page', 1);
    // },
  // },
};
</script>

<style scoped>
  li>button {
    @apply flex items-center px-2 h-full hover:bg-gray-300 dark:hover:bg-gray-700 disabled:cursor-default disabled:bg-transparent dark:disabled:bg-transparent;
  }
  li>button {
    @apply flex items-center px-2 h-full disabled:cursor-default;
  }
</style>
